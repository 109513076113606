import React from "react";

import {TableGridData} from "../../../engrator-core/ui";
import {UI} from "../../../engrator-core";
import {PipelineGroup} from "./pipeline-group.type";

export function createPipelinesGroupsTableGridData(groups: PipelineGroup[]): TableGridData {
    return {
        data: groups.map(group => [
            <UI.NavLink path={`/app/reporting/suite-run/${group.id}/flow/${group.id}/details`}>#{group.id}</UI.NavLink>,
            group.name
        ]),
        headers: ['ID', 'Name'],
    };
}