import React from 'react';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';

import {AuthToken, isJiraServerNativeApp, UI} from 'src/engrator-core';
import getintIoIcon from './getintio-icon.png';
import {HelpDropdown} from './help-dropdown';
import {fetchSystemInfo} from './rest-api';
import {TopNavigationSyncedItems} from './synced-items/top-navigation-synced-items';
import {ThreadsSummary} from './threads-summary.type';
import {ExpiredInfo, TrialWarningBar} from "../engagement";
import {useGlobalState} from "../global-store";
import {BreadCrumbItem} from "../global-store/create-global-state";

export function TopHorizontalMenu() {
    const [menu] = useGlobalState('menu');
    const [breadcrumbs] = useGlobalState('breadcrumbs');

    return <React.Fragment />;
    return <React.Fragment>
        <div className={`top-horizontal-menu ${ (menu.header) ? '' : 'no-menu' }`}>
            <div className={`item steps`}>
                <ul className={`breadcrumbs`}>
                    { breadcrumbs.map((breadcrumb: BreadCrumbItem, index: number) => <React.Fragment>
                        <li>{ breadcrumb.name }</li>
                        <li>/</li>
                    </React.Fragment> )}
                </ul>
            </div>
            <div className={`item item-synced-with`}>DEMO-1 <SyncedIcon/> 76123</div>
            <div className={`item`}>
                <div className={`btn-bg`}><PlusIcon/></div>
            </div>
            <div className={`item`}>
                <div className={`btn-bg`}><SearchIcon/></div>
            </div>
        </div>
    </React.Fragment>;
}

const PlusIcon = () => <svg className="interface-icon o__standard o__standard__new o__by-text" width="16" height="16"
                            viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8 2.5C8.46106 2.5 8.83482 2.87376 8.83482 3.33482V7.16518L12.6652 7.16518C13.1262 7.16518 13.5 7.53894 13.5 8C13.5 8.46106 13.1262 8.83482 12.6652 8.83482L8.83482 8.83482V12.6652C8.83482 13.1262 8.46106 13.5 8 13.5C7.53894 13.5 7.16518 13.1262 7.16518 12.6652V8.83482H3.33482C2.87376 8.83482 2.5 8.46106 2.5 8C2.5 7.53894 2.87376 7.16518 3.33482 7.16518H7.16518V3.33482C7.16518 2.87376 7.53894 2.5 8 2.5Z"></path>
</svg>;

const SyncedIcon = () => <svg className="interface-icon o__standard o__standard__active o__by-text" width="16"
                              height="16"
                              viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M4 2C2.89543 2 2 2.89543 2 4V7.25003H5.59901C5.90119 7.25003 6.17386 7.43138 6.29069 7.71006L6.85667 9.06012L8.68031 4.71003C8.79714 4.43135 9.06981 4.25 9.37199 4.25C9.67417 4.25 9.94684 4.43135 10.0637 4.71003L11.1285 7.24997H14V4C14 2.89543 13.1046 2 12 2H4ZM14 8.74997H10.6297C10.3275 8.74997 10.0548 8.56862 9.93797 8.28994L9.372 6.93988L7.54835 11.29C7.43152 11.5686 7.15886 11.75 6.85667 11.75C6.55449 11.75 6.28183 11.5687 6.16499 11.29L5.10019 8.75003H2V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V8.74997Z"></path>
</svg>;

const SearchIcon = () => <svg className="interface-icon o__standard o__standard__search" width="16" height="16"
                              viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2.8499 6.7999C2.8499 4.61838 4.61838 2.8499 6.7999 2.8499C8.98143 2.8499 10.7499 4.61838 10.7499 6.7999C10.7499 8.98143 8.98143 10.7499 6.7999 10.7499C4.61838 10.7499 2.8499 8.98143 2.8499 6.7999ZM6.7999 1.1499C3.67949 1.1499 1.1499 3.67949 1.1499 6.7999C1.1499 9.92031 3.67949 12.4499 6.7999 12.4499C8.05351 12.4499 9.21176 12.0416 10.1489 11.3508L13.3989 14.6007C13.7308 14.9327 14.269 14.9327 14.6009 14.6007C14.9329 14.2688 14.9329 13.7306 14.6009 13.3986L11.351 10.1487C12.0417 9.21155 12.4499 8.0534 12.4499 6.7999C12.4499 3.67949 9.92031 1.1499 6.7999 1.1499Z"></path>
</svg>;

export default withRouter(TopHorizontalMenu);
