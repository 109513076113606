import {FILTER_OPERATOR} from "./rest-api";

export const availableOperators = [
    { value: FILTER_OPERATOR.EQUALS, label: getName(FILTER_OPERATOR.EQUALS) },
    { value: FILTER_OPERATOR.NOT_EQUALS, label: getName(FILTER_OPERATOR.NOT_EQUALS) },
    { value: FILTER_OPERATOR.IS_EMPTY, label: FILTER_OPERATOR.IS_EMPTY },
    { value: FILTER_OPERATOR.NOT_EMPTY, label: FILTER_OPERATOR.NOT_EMPTY },
    { value: FILTER_OPERATOR.CONTAINS, label: getName(FILTER_OPERATOR.CONTAINS) },
    { value: FILTER_OPERATOR.CONTAINS_ONE_OF, label: getName(FILTER_OPERATOR.CONTAINS_ONE_OF) },
    { value: FILTER_OPERATOR.NOT_CONTAINS, label: getName(FILTER_OPERATOR.NOT_CONTAINS) },
    { value: FILTER_OPERATOR.MATCHES, label: getName(FILTER_OPERATOR.MATCHES) }
];

function getName(operator: FILTER_OPERATOR): string {
    if (operator === FILTER_OPERATOR.MATCHES) {
        return operator + ' (regex)';
    }
    return operator;
}