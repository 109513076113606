import React from 'react';

import {SoftwareName} from '../../software';
import video from './video.png';
import doc from './doc.png';
import {HelpCallButton} from "../../app/engagement/help-call-button";

type Props = {
    sofwareName: SoftwareName;
};

type State = {
    videoUrl?: string;
    docUrl?: string;
};

export class ConnectionSetupVideo extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            videoUrl: this.getVideoUrl(),
            docUrl: this.getDocUrl()
        };
    }

    render() {
        return <div className={`engagement-apps-sync-guide relative-position `}>
            <div className={`inner`}>
                { this.state.videoUrl && <a target={`blank`} className={`anim-fade-in`} href={ this.state.videoUrl }>
                    <img src={video}/>
                    Watch connection setup
                </a> }
                { this.state.docUrl && <a target={`blank`} href={ this.state.docUrl }>
                    <img src={ doc } height={ 24 }/>
                    Read documentation
                </a> }
                <HelpCallButton
                    blink={true}
                    // bookDemoPageClicked={ () => this.bookDemoPageClicked() }
                />
            </div>
        </div>;
    }

    private bookDemoPageClicked() {
        (window as any).open('https://getint.io/demo?src=app', '_blank').focus();
    }

    getVideoUrl(): string | undefined {
        switch (this.props.sofwareName) {
            case SoftwareName.Asana:
                return 'https://www.youtube.com/watch?v=PK9OPcLTNqs';
            case SoftwareName.Azure:
                return 'https://www.youtube.com/watch?v=WgHGdRHtF2c';
            case SoftwareName.Jira:
                return 'https://www.youtube.com/watch?v=2rxKNBKKxiA&feature=youtu.be';
            case SoftwareName.ServiceNow:
                return 'https://www.youtube.com/watch?v=zg7Q-_Hasfw';
            case SoftwareName.Monday:
                return 'https://youtu.be/M6Sbt9Jnahs';
        }
    }

    getDocUrl(): string | undefined {
        switch (this.props.sofwareName) {
            case SoftwareName.ClickUp:
                return 'https://docs.getint.io/getting-started-with-the-platform/how-to-use-getint/connection#clickup';
            case SoftwareName.Hubspot:
                return 'https://docs.getint.io/getting-started-with-the-platform/how-to-use-getint/connection#hubspot';
            case SoftwareName.Salesforce:
                return 'https://docs.getint.io/getting-started-with-the-platform/how-to-use-getint/connection#salesforce';
            case SoftwareName.Airtable:
                return 'https://docs.getint.io/getting-started-with-the-platform/how-to-use-getint/connection#airtable';
        }
    }
}
