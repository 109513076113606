import React, {ChangeEvent} from 'react';
import {NewPipelineStepComponentProps} from "src/generic";
import {JiraFindIssueConfiguration} from "./jira-find-issue-configuration";
import {EntityFormHelper, UI} from 'src/engrator-core';
import {JiraProjectIssuetypePicker, JiraProjectIssuetypePickerData} from "../common";
import {ArtifactDirection} from "../../../../generic/artifacts";
import {ArtifactsAutocomplete, InputChangeListener} from "../../../../engrator-core/ui";

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class JiraFindIssueNewStep extends React.Component<Props, State> {
    private configuration: JiraFindIssueConfiguration;
    private inputListener: InputChangeListener;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration as JiraFindIssueConfiguration;
        let inputListener: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
        this.inputListener = {
            subscribe: ((listener: (value: string, event: ChangeEvent<HTMLInputElement>) => void) => {
                inputListener = listener;
            }),
            notify: (newValue: string, event: ChangeEvent<HTMLInputElement>) => {
                inputListener(newValue, event);
            }
        }
    }

    render() {
        return <React.Fragment>
            { this.props.formError?.general && <UI.Message
                appearance={`error`}
                >{ this.props.formError.general}</UI.Message>
            }
            <JiraProjectIssuetypePicker
                connectionId={this.props.context.connectionId}
                onDataChanged={ this.projectIssueChanged.bind(this) }
                formError={ this.props.formError }
            />
            <UI.FormGroup
                label={`Issue Key`}
                isRequired={ true }
                description={`Provide key of the issue that should be found. You can use variables e.g. properties from other Artifacts.`}
                error={ this.props.formError?.id }>
                <UI.Input onChange={ (newValue, event) => this.onIdChanged(newValue, event) } />
                {/*<ArtifactsAutocomplete listener={ this.inputListener } availableArtifacts={ this.props.context.artifactsFactory.getAvailableArtifacts() } />*/}
            </UI.FormGroup>
        </React.Fragment>;
    }

    private projectIssueChanged(data: JiraProjectIssuetypePickerData): void {
        this.configuration = Object.assign(this.configuration, data);
        this.configurationChanged();
    }

    private onIdChanged(newValue: string, event: any) {
        this.configuration['id'] = newValue;
        // this.inputListener.notify(newValue, event);
        this.configurationChanged();
    }

    private configurationChanged() {
        if (this.configuration.project && this.configuration.issueType && this.configuration.id) {
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }
}