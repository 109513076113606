import {UI} from "src/engrator-core";
import React from "react";

type Props = { isEdit: any, validateStepBeforeAdd: any; deleteStepHandler?: any; isRequestPending: boolean };
export class StepRightPanelFooter extends React.Component<Props> {
    render() {
        let {isEdit, isRequestPending} = this.props;
        if (!isEdit) {
            return <UI.Button onClick={this.props.validateStepBeforeAdd.bind(this)} isLoading={isRequestPending} text={`Add Step`}/>;
        }
        if (!this.props.deleteStepHandler) {
            return <UI.Button onClick={this.props.validateStepBeforeAdd.bind(this)} isLoading={isRequestPending} text={`Update`}/>;
        }
        return <UI.ButtonsBar secondary={
            [<UI.Button appearance={"danger"} onClick={this.props.deleteStepHandler.bind(this)} text={`Delete`}/>]
        } primary={
                <UI.Button onClick={this.props.validateStepBeforeAdd.bind(this)} text={`Update`} isLoading={isRequestPending}/>
        } />

    }
}
