import React from 'react';
import { UI } from 'src/engrator-core';
import { DirectionPicker } from '..';
import { SmartIntDirection } from '../../../definition/smart-int-direction.type';
import cogIcon from '../../type-mappings/cog.icon.png';
import { ValueMapping } from './value-mapping.type';
import { ValueMappingsGroup } from './value-mappings-group.type';
import {Icon20} from "../../../../../../engrator-core/ui";

type Props = {
    group: ValueMappingsGroup;
    addMultipleToSide: '' | 'left' | 'right';
    addPropertyValue: (side: 'left' | 'right', group: ValueMappingsGroup) => void;
    onDeleteHandler: (mapping: ValueMapping) => void;
    valueConfigurationHandler?: (group: ValueMappingsGroup) => void;
};

type State = {};

export class ValueMappingsGroupContainer extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.addMultipleToSide !== this.props.addMultipleToSide) {
            this.forceUpdate();
        }
    }

    render() {
        return <div className={`row-two-cols value-mappings-group`}>
            <div className={`left center`}>
                {this.props.group.valueMappings.map((mapping, index) => <div key={index}>
                    {this.shouldBeDisplayed(index, 'left') && <span>{mapping.left.name} <br/>
                        <span className={`value-id`}>({mapping.left.value})</span>
                        {mapping.leftCondition && <span className={`condition`}>if: {mapping.leftCondition}</span>}
                    </span>}
                    {this.isDefault(mapping, 'left') && <span className={`is-default`}>(Default)</span>}
                    {this.props.group.multipleSide === 'left' && !this.isDefault(mapping, 'left') &&
                    <UI.Button className={`hidden default`}
                               onClick={() => this.setDefault(mapping)}
                               text={`Set Default`}
                               appearance={'link-inline'}/>}
                </div>)}
                {this.shouldShowAddHere('left') && <UI.Button
                    className={`add-here`}
                    onClick={() => this.props.addPropertyValue('left', this.props.group)}
                    text={`+ Add option here`}
                    appearance={'link-inline'}/>}
            </div>
            <DirectionPicker
                default={ this.props.group.direction }
                onChange={ (newDirection: SmartIntDirection) => this.props.group.direction = newDirection } />
            <div className={`right center`}>
                {this.props.group.valueMappings.map((mapping, index) => <div key={index}>
                    {this.shouldBeDisplayed(index, 'right') && <span>{mapping.right.name}<br/>
                        <span className={`value-id`}>({ mapping.right.value })</span>
                        {mapping.rightCondition && <span className={`condition`}>if: {mapping.rightCondition}</span>}
                    </span>}
                    {this.isDefault(mapping, 'right') && <span className={`is-default`}>(Default)</span>}
                    {this.props.group.multipleSide === 'right' && !this.isDefault(mapping, 'right') &&
                    <UI.Button className={`hidden default`}
                               onClick={() => this.setDefault(mapping)}
                               text={`Set Default`}
                               appearance={'link-inline'}/>}
                    {!this.isDefault(mapping, 'right') && !this.isDefault(mapping, 'left') &&
                    <div className={'delete'} onClick={() => this.removeMapping(mapping)}><UI.Icon
                        icon={'trash'}/></div>
                    }
                </div>)}
                {this.props.valueConfigurationHandler && <span onClick={() => this.props.valueConfigurationHandler!(this.props.group)}><UI.Icon icon20={ Icon20.Settings} /></span>}
                {this.shouldShowAddHere('right') && <UI.Button className={`add-here`}
                                                               onClick={() => this.props.addPropertyValue('right', this.props.group)}
                                                               text={`+ Add option here`}
                                                               appearance={'link-inline'}/>}
            </div>
        </div>;
    }

    private shouldShowAddHere(side: 'left' | 'right') {
        console.log('check', side, this.props.addMultipleToSide, this.props.group.multipleSide);
        if (this.props.addMultipleToSide === '') {
            return false;
        }
        if (side === this.props.addMultipleToSide && this.props.group.multipleSide === '') {
            return true;
        }
        return side as string === this.props.addMultipleToSide &&
            this.props.addMultipleToSide === this.props.group.multipleSide;
    }

    private isDefault(mapping: ValueMapping, side: 'left' | 'right') {
        return this.props.group.multipleSide === side && !!mapping.isDefault;
    }

    private shouldBeDisplayed(index: number, side: 'left' | 'right') {
        const opposite = (side === 'left') ? 'right' : 'left';
        return (this.props.group.multipleSide === opposite && index === 0) || this.props.group.multipleSide === '' || this.props.group.multipleSide === side;
    }

    private removeMapping(mapping: ValueMapping) {
        const index = this.props.group.valueMappings.indexOf(mapping);
        this.props.group.valueMappings.splice(index, 1);
        this.forceUpdate(() => {
            this.props.onDeleteHandler(mapping);
        });
    }

    private setDefault(mapping: ValueMapping) {
        this.props.group.valueMappings.forEach(valueMapping => valueMapping.isDefault = false);
        mapping.isDefault = true;
        this.forceUpdate();
    }
}
