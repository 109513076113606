type ValidationResult = {
    status: boolean;
    msg: string;
};

function isJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function jsonValidation(script: string): ValidationResult {
    if (!isJsonString(script)) {
        const msg = 'Invalid JSON';
        
        return { status: false, msg };
    } else {
        const obj = JSON.parse(script);

        //FIRST LEVEL: types, configurations, plugins, triggers, advancedConfiguration, save
        const firstLevelLookup = ['types', 'configurations', 'plugins', 'triggers', 'advancedConfiguration'];

        const keysFirstLevel = Object.keys(obj);
        const firstLevelResult = [];

        for (let i = 0; i < firstLevelLookup.length; i++) {
            if (!keysFirstLevel.includes(firstLevelLookup[i])) {
                firstLevelResult.push(firstLevelLookup[i]);
            }
        }
        if (firstLevelResult.length > 0) {
            const msg = `Missing properties: ${firstLevelResult.join(', ')}`;

            return { status: false, msg };
        }

        //CHECKING TYPES
        const typesFirstLevelLookup = [
            'direction',
            'propertiesConfiguration',
            'commentsConfiguration',
            'hierarchyConfiguration',
            'attachmentsConfiguration',
            'left',
            'right',
            'advancedConfiguration',
            'statusTransitionConfiguration',
            'status'
        ];

        for (let i = 0; i < obj['types'].length; i++) {
            const typesFirstLevelResult = [];
            const typesFirstLevel = Object.keys(obj['types'][i]);

            for (const el of typesFirstLevelLookup) {
                if (!typesFirstLevel.includes(el)) {
                    typesFirstLevelResult.push(el);
                }
            }

            if (typesFirstLevelResult.length > 0) {
                const msg = `Missing properties in TYPES iteration ${i}: ${typesFirstLevelResult.join(', ')}`;

                return { status: false, msg };
            }
        }

        const advancedConfigurationResult = [];
        //CHECKING advancedConfiguration
        if (!obj['advancedConfiguration']['scripting']) {
            advancedConfigurationResult.push('scripting');
        }

        if (!obj['advancedConfiguration']['customProperties']) {
            advancedConfigurationResult.push('customProperties');
        }

        if (advancedConfigurationResult.length > 0) {
            const msg = `Missing properties in ADVANCED CONFIGURATION: ${advancedConfigurationResult.join(', ')}`;

            return { status: false, msg };
        }
    }

    return { status: true, msg: '' };
}

export default jsonValidation;
