import React from 'react';
import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';

import { SmartIntTrigger } from 'src/app/integration/smartints/definition/smart-int-trigger.type';
import { DropdownOption } from '../../../engrator-core/ui';
import { FreshdeskSmartIntsEditTrigger } from './shotgrid-smart-ints-edit-trigger';
import {ShotgridSmartIntsTrigger} from "./shotgrid-smart-ints-trigger";

export class ShotgridSmartintsSupport extends AppSmartIntsSupport {
    getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <ShotgridSmartIntsTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
        />;
    }

    getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <FreshdeskSmartIntsEditTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
            trigger={trigger}
        />;
    }

    hasTriggerConfigurationComponent(): boolean {
        return false;
    }

    public getSubtasksRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
        return Promise.resolve([]);
    }
}
