import React from 'react';
import { UI } from 'src/engrator-core';
import { TenantsLicenses } from './licenses/tenants-licenses';

type State = {}
type Props = {};

export class ClusterTenantsLicensesPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <UI.Page className={`cluster-tenants-page`}>
                <TenantsLicenses/>
            </UI.Page>
        );
    }
}
