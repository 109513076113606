import {axiosWrapper} from "../../../engrator-core";
import {SupportPackageData} from "./support-package-popup";

const ENDPOINT_URL = '/reporting/support-package';

export function generateSupportPackage(data: SupportPackageData): Promise<void> {
    return new Promise((resolve, reject) => {
        axiosWrapper.postWithWholeResponse(ENDPOINT_URL, data, { responseType: 'arraybuffer'})
            .then( (response: any) => {
                const filename = response.headers?.filename;
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'suppackage' + new Date().getTime()  + '.zip'); //or any other extension
                document.body.appendChild(link);
                link.click();
                resolve();
            } )
            .catch( (error) => reject(error) );
    });
}
