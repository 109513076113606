import React from 'react';
import {UI} from 'src/engrator-core';
import {PropertyDefinition} from '../../../../../generic/artifacts';
import PropertyDataType from '../../../../../generic/artifacts/property-data-type';
import {PropertiesContext, PropertyMappingsPicker} from '../../configuration';
import {getStatuConfigurationWarning} from '../../configuration/configuration-warning-checker';

import {SmartIntDefinitionTypeMapping} from '../../definition/smart-int-definition--type-mapping.type';
import {SmartIntTrigger} from '../../definition/smart-int-trigger.type';
import {StatusFieldPicker} from '../components/status-field-picker';
import ValuesMappingsPicker from '../components/value-mappings/values-mappings-picker';
import {ValueMappingsGroup} from '../components/value-mappings/value-mappings-group.type';
import {createPropertyMapping, PropertyMapping} from '../property-mapping.type';
import {AppsSupport} from '../visual-integration-designer';

type Props = {
    mapping: SmartIntDefinitionTypeMapping;
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
    appsSupport: AppsSupport;
};

type State = {
    configuredValueGroup?: ValueMappingsGroup;
}

export class StatusMapping extends React.Component<Props, State> {
    private statusPropertyMapping: PropertyMapping;
    private groups: ValueMappingsGroup[];

    constructor(props: Readonly<Props> | Props) {
        super(props);
        if (!this.props.mapping.statusTransitionConfiguration.statusFieldsIds) {
            this.props.mapping.statusTransitionConfiguration.statusFieldsIds = {
                left: 'status', right: 'status'
            };
        }
        if (!this.props.mapping.statusTransitionConfiguration.statusFieldsIds.left) {
            this.props.mapping.statusTransitionConfiguration.statusFieldsIds.left = 'status';
        }
        if (!this.props.mapping.statusTransitionConfiguration.statusFieldsIds.right) {
            this.props.mapping.statusTransitionConfiguration.statusFieldsIds.right = 'status';
        }
        const statusFields = this.props.mapping.statusTransitionConfiguration.statusFieldsIds;
        const leftStatePropertyDefinition: PropertyDefinition = {
            dataType: PropertyDataType.IdLabel, id: statusFields.left, name: 'Status', options: {
                selectable: true, internalType: '', internalFieldId: statusFields.left
            }
        };
        const rightStatePropertyDefinition: PropertyDefinition = {
            dataType: PropertyDataType.IdLabel, id: statusFields.right, name: 'Status', options: {
                selectable: true, internalType: '', internalFieldId: statusFields.right
            }
        };
        this.state = {};
        this.props.mapping.statusTransitionConfiguration.mapping = {
            left: leftStatePropertyDefinition,
            right: rightStatePropertyDefinition
        };
        this.statusPropertyMapping = createPropertyMapping(leftStatePropertyDefinition, rightStatePropertyDefinition);
        this.groups = this.props.mapping.statusTransitionConfiguration.groups || [];
    }

    render() {
        const warning = getStatuConfigurationWarning(this.props.mapping);
        return <div className={`status-transition-configuration`}>
            <UI.Message appearance={"info"}>
                Configure status transition by selecting field that will represent status and mapping available options. <UI.DocumentationLink text={`Read more`} url={`https://docs.getint.io/getting-started-with-the-platform/how-to-use-getint/integration/type-mapping#status-transition`} />
            </UI.Message>
            {warning && <UI.Message appearance="warning">{warning}</UI.Message>}
            <UI.FormGroup>
                <UI.Checkbox
                    defaultValue={`${this.props.mapping.statusTransitionConfiguration.enabled}`}
                    onChange={() => this.switchFlag()}
                    checkedValue={'true'} uncheckedValue={'false'} label={`Enable`}/>
                {this.props.mapping.statusTransitionConfiguration.enabled &&
                    <React.Fragment>
                        <div className={`row-two-cols`}>
                            <div className={`left`}>
                                <StatusFieldPicker
                                    defaultValue={this.props.mapping.statusTransitionConfiguration.statusFieldsIds.left}
                                    trigger={this.props.leftTrigger}
                                    type={this.props.mapping.left}
                                    app={this.props.appsSupport.leftApp}
                                    onChange={(newValue: string, obj) => this.setStatusFieldId('left', newValue, obj)}
                                />
                            </div>
                            <div className={`right`}>
                                <StatusFieldPicker
                                    defaultValue={this.props.mapping.statusTransitionConfiguration.statusFieldsIds.right}
                                    trigger={this.props.rightTrigger}
                                    type={this.props.mapping.right}
                                    app={this.props.appsSupport.rightApp}
                                    onChange={(newValue: string, obj) => this.setStatusFieldId('right', newValue, obj)}
                                />
                            </div>
                        </div>
                        { this.props.mapping.statusTransitionConfiguration.statusFieldsIds.left && this.props.mapping.statusTransitionConfiguration.statusFieldsIds.right &&
                        <div className={`statuses-value-mappings`}>
                            <ValuesMappingsPicker
                                disableOptions={ true }
                                depth={ 1 }
                                appsSupport={this.props.appsSupport}
                                showNavBar={false}
                                onMappingsChangedHandler={this.valueMappingsChangedHandler.bind(this)}
                                groups={this.groups || []}
                                mapping={this.statusPropertyMapping}
                                leftTrigger={this.props.leftTrigger}
                                typeMapping={this.props.mapping}
                                rightTrigger={this.props.rightTrigger}
                                closeHandler={() => {
                                }}
                                defaults={this.props.mapping.statusTransitionConfiguration.defaults}
                                valueConfigurationHandler={this.valueConfigurationHandler.bind(this)}
                                empty={{left: '', right: ''}}/>
                        </div> }
                    </React.Fragment>
                }
                {this.state.configuredValueGroup && <UI.FullScreenModal
                    primaryBtnHandler={() => this.closeStatusMappingConfiguration() }
                    showPrimaryBtn={ false }
                    closeBtnHandler={() => this.closeStatusMappingConfiguration() }
                    depth={ 1}
                    maximized={ true }
                    header={`Transition fields`}
                >
                    <div className={`status-properties`}>
                        <div className={`content`}>
                            <PropertyMappingsPicker
                                valueGroup={this.state.configuredValueGroup}
                                properties={this.state.configuredValueGroup?.properties}
                                onMappingsChangedHandler={this.groupPropertiesChangedHandler.bind(this)}
                                appsSupport={this.props.appsSupport}
                                multiFieldsUsage={false}
                                mapping={this.props.mapping}
                                leftTrigger={this.props.leftTrigger}
                                rightTrigger={this.props.rightTrigger}
                                propertiesContext={PropertiesContext.Transition}
                            >
                                <UI.Message appearance={"info"}>Configure transition fields that will be set when selected status transition occur. <UI.DocumentationLink text={`Read more`} url={`https://docs.getint.io/getting-started-with-the-platform/how-to-use-getint/integration/type-mapping#transition-fields`} /></UI.Message>
                            </PropertyMappingsPicker>
                            {/*<div className={`btns`}>*/}
                            {/*    <UI.Button onClick={this.closeStatusMappingConfiguration.bind(this)} text={`Close`}*/}
                            {/*               appearance={'secondary'}/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </UI.FullScreenModal>}
            </UI.FormGroup>
        </div>;
    }

    private valueMappingsChangedHandler(groups: ValueMappingsGroup[]) {
        this.props.mapping.statusTransitionConfiguration.groups = groups;
    }

    private groupPropertiesChangedHandler(properties: PropertyMapping[]) {
        const groups = this.props.mapping.statusTransitionConfiguration.groups || [];
        groups.forEach(group => {
            if (group.leftValue === this.state.configuredValueGroup?.leftValue &&
                group.rightValue === this.state.configuredValueGroup?.rightValue) {
                group.properties = properties;
            }
        })
    }

    private switchFlag() {
        const configuration = this.props.mapping.statusTransitionConfiguration;
        configuration.enabled = !configuration.enabled;
        this.forceUpdate();
    }

    private closeStatusMappingConfiguration(): Promise<boolean> {
        this.setState({configuredValueGroup: undefined});
        return Promise.resolve(true);
    }

    private valueConfigurationHandler(group: ValueMappingsGroup) {
        this.setState({configuredValueGroup: group});
    }

    private setStatusFieldId(side: 'left' | 'right', fieldId: string, obj: any) {
        this.props.mapping.statusTransitionConfiguration.statusFieldsIds[side] = fieldId;
        if (this.props.mapping.statusTransitionConfiguration.mapping) {
            this.props.mapping.statusTransitionConfiguration.mapping[side] = obj;
            this.statusPropertyMapping = createPropertyMapping(this.props.mapping.statusTransitionConfiguration.mapping.left, this.props.mapping.statusTransitionConfiguration.mapping.right);
        }
        // this.statusPropertyMapping[side] = obj;
        this.forceUpdate();
    }
}
