import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {GitHubStepKey} from "../github-step-key.type";
import {GitHubFindItemNewStep} from "./github-find-item-new-step";
import {GitHubFindItemEditStep} from "./github-find-item-edit-step";
import {GitHubFindItemConfiguration} from "./github-find-item-configuration";


export const GitHubFindItemPipelineStep: PipelineStep<GitHubFindItemConfiguration> = {
    addStepComponentClass: GitHubFindItemNewStep,
    editStepComponentClass: GitHubFindItemEditStep,
    specification: {
        action: "Find issue",
        description: "Find issue with specific id",
        key: GitHubStepKey.GitHubFindItem,
        software: SoftwareName.GitHub,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `GitHubItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitHubFindItemConfiguration;
        return `Find issue with id='${stepConfiguration.id}'`;
    }
};