export type LastRun = {
    id: number,
    status: string,
    date: string,
    formattedExecutionTime: string,
    formattedStartTime: string
};

export type IntegrationListItem = {
    id: number,
    name: String,
    lastRun: LastRun,
    lastSuccessfulRun: LastRun,
    status: 'Enabled' | 'Disabled'
};

export function createIntegrationListItem(itemData: any): IntegrationListItem {
    return {
        id: itemData.id,
        name: itemData.name,
        lastRun: itemData.lastRun,
        lastSuccessfulRun: itemData.lastSuccessfulRun,
        status: itemData.status
    };
}