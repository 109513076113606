import React from 'react';
import {isJiraServerNativeApp} from 'src/engrator-core';
import {BrowserRouter, HashRouter} from "react-router-dom";

export class DynamicRouter extends React.Component {
    render() {
        if (isJiraServerNativeApp) {
            return <HashRouter>
                { this.props.children }
            </HashRouter>
        }
        return <BrowserRouter>
            { this.props.children }
        </BrowserRouter>
    }
}
