import { axiosWrapper } from 'src/engrator-core';

const ENDPOINT_URL = '/reporting/items-versions';

export type ItemVersionLookUp = {
    itemId: string;
    side: 'Left' | 'Right',
    suiteId: number;
};

export function getItemVersion(data: ItemVersionLookUp): Promise<any> {
    return new Promise((resolve, reject) => {
        axiosWrapper.post(ENDPOINT_URL, data)
            .then((data: any) => resolve(data))
            .catch((err) => reject(err));
    });
}
