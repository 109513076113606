import React from 'react';
import { FieldError } from 'src/engrator-core/field-error';
import {Message} from "../message";

type FormProps = {
    errors?: Array<FieldError>,
    error?: string
    useMaxWidthClass?: boolean;
    className?: string;
};

export class Form extends React.Component<FormProps> {
    componentDidUpdate(prevProps: FormProps) {
        if (JSON.stringify(this.props.errors) !== JSON.stringify(prevProps.errors)) {
            this.forceUpdate();
        }
        if (this.props.error !== prevProps.error) {
            this.forceUpdate();
        }
    }

    handleOnSubmit(e: any) {
        e.preventDefault();
    }

    render() {
        const classes = (this.props.useMaxWidthClass) ? 'max-width' : '';
        return <form className={ `engrator-form ${classes} ${this.props.className}` } onSubmit={ this.handleOnSubmit }>
            { this.props.error && <Message appearance="error">{ this.props.error }</Message> }
            { this.props.errors && this.props.errors.length > 0 && <p>{ JSON.stringify(this.props.errors) }</p> }
            { this.props.children }
        </form>;
    }
}
