import React from 'react';
import {UI} from 'src/engrator-core';
import {getSystemInfo} from "../top-navigation/rest-api";

type Props = {
};
type State = {
    showWarningAboutNotifications: boolean;
};

export class SetupNotificationsReminder extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showWarningAboutNotifications: false
        };
    }

    async componentDidMount() {
        console.log(await getSystemInfo());
        this.setState({
            showWarningAboutNotifications: (await getSystemInfo())?.showWarningAboutNotifications === true
        });
    }

    render() {
        // If at least 1 notification channel is setup do not render component
        if (!this.state.showWarningAboutNotifications) {
            return <React.Fragment/>;
        }
        return <div className={`engagement setup-notifications`}><UI.Message
            appearance={'warning'}
        >Stay updated with alerts regarding system errors. Visit the <UI.NavLink path={`/app/integration/notifications`}>Notifications</UI.NavLink> section to personalize your preferences across multiple platforms such as Slack and Email. All alerts about errors will be dispatched promptly through your chosen channel.</UI.Message></div>;
    }
}
