import React from 'react';
import { Message } from '.';

type Props = {
    appearance?: 'danger'
}

export class WarningBar extends React.Component<Props> {
    render() {
        return <div className={`warning-bar ${this.props.appearance}`}><Message appearance="warning">
            {this.props.children}
        </Message></div>;
    }
}
