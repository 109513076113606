import React, {Component} from "react";

import {UI} from 'src/engrator-core';
import {fetchReportData} from './rest-api';
import {ReportsTabBar} from "./reports-tab-bar";
import {ReportName} from "./report-name.type";
import {ReportData} from "./report-data.type";
import {createTableGridData} from "./report-row-factory";
import {TableGridData} from "../../engrator-core/ui";

type ConnectionsListPageState = {
    isLoading: boolean,
    changeParamsTime: number
};

export class SuiteRunsResultsPage extends Component<{}, ConnectionsListPageState> {
    private tableGridData?: TableGridData;

    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: true,
            changeParamsTime: 0
        };
    }

    componentDidMount(): void {
        this.fetchReportData();
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<ConnectionsListPageState>, snapshot?: any): void {
        if ((this.props as any).location.search !== (prevProps as any).location.search) {
            this.fetchReportData();
        }
    }

    render() {
        return (
            <UI.Page>
                { this.state.isLoading && <UI.Loader appearance={'darkgray'} visible={true} /> }
                { !this.state.isLoading && this.tableGridData && <div className="connections-list-page">
                    <UI.TableGrid
                        data={ this.tableGridData?.data }
                        headers={ this.tableGridData?.headers }
                    />
                </div> }
            </UI.Page>
        )
    }

    private fetchReportData(): void {
        this.setState({ isLoading: true });
        // fetchReportData(ReportName.PipelinesRuns)
        //     .then((reportData: ReportData) => {
        //         console.log(reportData);
        //             this.tableGridData = createTableGridData(ReportName.PipelinesRuns, reportData);
        //         this.setState({ isLoading: false });
        //     });
    }
}