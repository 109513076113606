import React from 'react';
import asanaLogo from 'src/software/asana/asana-logo.png';
import azureLogo from 'src/software/azure/azure-logo.png';
import gitHubLogo from 'src/software/github/github-logo.png';
import gitLabLogo from 'src/software/gitlab/gitlab-logo.png';
import intHubLogo from 'src/software/inthub/inthub-logo.png';

import jiraMark from 'src/software/jira/jira-logo.png';
import mondayLogo from 'src/software/monday/monday-logo.png';
import serviceNowLogo from 'src/software/servicenow/servicenow-logo.png';
import trelloLogo from 'src/software/apps-icons/trello-logo.png';
import wrikeLogo from 'src/software/wrike/wrike-logo.png';
import zendeskLogo from 'src/software/zendesk/zendesk-logo.png';
import { SoftwareName } from '../../software';
import {getAppIcon} from "../../software/apps-icons";

type Props = {
    softwareName: string;
};

export class SoftwareLogo extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <img src={ getAppIcon(this.props.softwareName as SoftwareName) } height={ 35 } />
            {/*{ this.props.softwareName === 'Jira' &&  <img src={ jiraMark } height={ 35 }/> }*/}
            {/*{ this.props.softwareName === 'Trello' &&  <img src={ trelloLogo } height={ 35 }/> }*/}
            {/*{ this.props.softwareName === SoftwareName.IntHub &&  <img src={ intHubLogo } height={ 35 }/> }*/}
            {/*{ this.props.softwareName === SoftwareName.Asana &&  <img src={ asanaLogo } height={ 35 }/> }*/}
            {/*{ this.props.softwareName === SoftwareName.Zendesk &&  <img src={ zendeskLogo } height={ 35 }/> }*/}
            {/*{ this.props.softwareName === SoftwareName.Azure &&  <img src={ azureLogo } height={ 35 }/> }*/}
            {/*{ this.props.softwareName === SoftwareName.ServiceNow &&  <img src={ serviceNowLogo } height={ 35 }/> }*/}
            {/*{ this.props.softwareName === SoftwareName.GitLab &&  <img src={ gitLabLogo } height={ 35 }/> }*/}
            {/*{ this.props.softwareName === SoftwareName.Wrike &&  <img src={ wrikeLogo } height={ 35 }/> }*/}
            {/*{this.props.softwareName === SoftwareName.GitHub && <img src={gitHubLogo} height={35}/>}*/}
            {/*{this.props.softwareName === SoftwareName.Monday && <img src={mondayLogo} height={35}/>}*/}
        </React.Fragment>
    }
}
