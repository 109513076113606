import React from 'react';
import { EntityFormHelper, FieldError, UI } from 'src/engrator-core';
import { FormError } from 'src/engrator-core/form-error.type';
import { DropdownOption } from 'src/engrator-core/ui';
import { fetchTables } from './../rest-api';
import { GetintArchiverBoardDTO } from './monday-board-dto.type';

export type GetintArchiverStepContextData = {
    board?: GetintArchiverBoardDTO;
};

type Props = {
    connectionId: number;
    onDataChanged: (data: GetintArchiverStepContextData, isValid: boolean) => void;
    formError?: FormError;
}

type State = {
    formError: FormError;
    isLoading: boolean;
}

export class GetintArchiverStepContext extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private options: DropdownOption[] = [];
    private contextData: GetintArchiverStepContextData = {};

    constructor(props: Props) {
        super(props);
        this.state = {formError: {}, isLoading: true};
        this.entityFormHelper = new EntityFormHelper((propertyName: 'board', newValue, additionalArgument?: any) => {
            if (!additionalArgument) {
                return;
            }
            if (propertyName === 'board') {
                this.contextData.board = additionalArgument as GetintArchiverBoardDTO;
            }
            const isValid = (!!this.contextData.board);
            this.props.onDataChanged(this.contextData, isValid);
            this.forceUpdate();
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.forceUpdate();
        }
    }

    componentDidMount(): void {
        fetchTables(this.props.connectionId)
            .then((organizations: GetintArchiverBoardDTO[]) => {
                this.options = organizations.map(board => ({
                    label: board.name,
                    value: board.id,
                    object: board,
                }));
                this.setState({isLoading: false});
            })
            .catch((error: FieldError) => {
                this.setState({isLoading: false, formError: {['pickerError']: error.message}});
            });
    }

    render() {
        return <React.Fragment>
            {this.state.formError.pickerError && <UI.Message appearance={'error'}>{this.state.formError.pickerError}</UI.Message>}
        </React.Fragment>;
    }
};
