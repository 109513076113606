import React from "react";

import { UI } from 'src/engrator-core';

type State = {};

type Props = {};

export class LogsFilter extends React.Component<Props, State> {
    render() {
        return <div>
            <UI.Tile>
                Some filters to leave only interesting logs
            </UI.Tile>
        </div>
    }
}
