import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { Software } from 'src/generic';
import { createGenericConnectionFactory } from 'src/generic/connections';
import { AuthMethod } from 'src/generic/connections/auth-method.type';
import { SoftwareName } from '../software-name';

// import {SalesforceSmartintsSupport} from "./smart-ints";
import {OAuthData, OAuthSupport} from "../../generic/connections/oauth/oauth-support";
import {SalesforceSmartintsSupport} from "../salesforce/smart-ints";
import {AirtableSmartintsSupport} from "./smart-ints";


export class AirtableSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.Airtable, [AuthMethod.OAuth]);
    }

    getName(): string {
        return 'Airtable';
    }

    getID() {
        return 'Airtable';
    }

    getAvailableSteps(): object[] {
        return [];
    }

    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new AirtableSmartintsSupport();
        // return null;
    }
}

export function getSalesforceOauth(): OAuthSupport {
    return {
        extractDataFromObject(data: any): OAuthData {
            return {
                token: data.access_token,
                refreshToken: data.refresh_token,
                instanceUrl: data.instance_url
            }
        },
        getUrl(): string {
            return 'https://app.getint.io/app/oauth/redirect?instanceUrl=https://getint-dev-ed.develop.my.salesforce.com';
        },
        getApiUrl(): string {
            return '';
        }
    };
}
