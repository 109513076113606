import React from 'react';

type Props = {
    fieldName: string;
    fieldValue: string;
}
type State = {
};

export class ArtifactVersionFieldValue extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    render() {
        console.log( this.props.fieldName );
        if (this.props.fieldName === '_url')
            return <a target={`blank`} href={ this.props.fieldValue}>{ this.props.fieldValue } </a>;
        if (this.props.fieldName.toLowerCase() === 'issuelinks' || this.props.fieldName === 'Linked Issues') {
            const links = this.extractLinks();
            return <span className={`field-value`} data-sel={`${ this.props.fieldName }`}>{ links.map((link) => <span>
            {link}<br/></span> ) }</span>;
        }
        return <div className={`field-value`} data-sel={`${ this.props.fieldName }`} dangerouslySetInnerHTML={{ __html: this.props.fieldValue }}/>
    }

    private extractLinks() {
        const regex = /label=(.*?)\]/gm;
        let m;
        const links: string[] = [];
        while ((m = regex.exec(this.props.fieldValue)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            links.push(m[1]);
            // The result can be accessed through the `m`-variable.
            // m.forEach((match, groupIndex) => {
            //     links.push(match);
            // });
        }
        return links;
    }
}
