import React from 'react';
import {FieldError, UI} from 'src/engrator-core';
import {BulkResyncItem, bulkResyncItems, fetchBulkResyncList} from "../rest-api";
import {ReportName} from "../report-name.type";

type Props = {
    filtersData: any;
}
type State = {
    showModal: boolean;
    error?: FieldError;
    bulkResyncItems?: BulkResyncItem[];
};

export class BulkResync extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    render() {
        return <React.Fragment>
            <UI.Button
                appearance={ "secondary" }
                className={`import-btn`}
                onClick={ () => this.showModalClicked() }
                text={ `Bulk Resync` }
            />
            { this.state.showModal && <UI.FullScreenModal
                primaryBtnHandler={ () => this.resyncClicked() }
                primaryBtnText={ `Bulk resync` }
                isPrimaryDisabled={ !this.state.bulkResyncItems }
                closeBtnHandler={ () => this.closeClicked() }
                header={`Bulk resync items`}
                error={ this.state.error?.message }
            >
                <UI.Message appearance={"info"}>
                    Bulk resync is limited to 1000 of items. Items will be added to Runs as soon as integrations that synced them in the past are run.
                    Disabled integrations won't resync items.
                </UI.Message>
                { this.state.bulkResyncItems && <React.Fragment>
                    <h3>Items to resync ({ this.state.bulkResyncItems?.length })</h3><br/>
                    <UI.TableGrid
                    headers={['ID', 'Item Id']}
                    data={ this.state.bulkResyncItems?.map(item => [
                        item.id, item.triggeredBy
                    ])} />
                </React.Fragment> }
            </UI.FullScreenModal> }
        </React.Fragment>
    }

    private closeClicked() {
        this.setState({ showModal: false, error: undefined });
    }

    private resyncClicked(): Promise<boolean> {
        const ids = this.state.bulkResyncItems!.map(item => item.artifactId);
        return new Promise((resolve) => {
            bulkResyncItems(ReportName.PerformedFlows, ids)
                .then(() => {
                    resolve(true);
                })
                .catch((error: FieldError) => {
                    this.setState({ error });
                    resolve(false);
                })
        })
    }

    private showModalClicked() {
        this.setState({ showModal: true }, () => {
            fetchBulkResyncList(ReportName.PerformedFlows, 0, this.props.filtersData)
                .then(bulkResyncItems => {
                    this.setState({ bulkResyncItems });
                })
                .catch(error => {
                    this.setState({ error });
                });
        });
    }
}
