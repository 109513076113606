import React from 'react';

type Props = {
    jiraUrl?: string;
};
type State = {
};

export class TenantLinks extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        if (this.props.jiraUrl) {
            const phrase = this.props.jiraUrl
                .replace('.atlassian.net', '')
                .replace('https://', '');
            return <p>
                <a 
                    target="_blank"
                    href={ `https://marketplace.atlassian.com/manage/vendors/1218845/reporting/licenses?text=${phrase}`}
                >
                    MPAC License
                </a>
            </p>;
        }
        return <div>
            
        </div>;
    }
}