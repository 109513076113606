import React from 'react';
import {UI} from 'src/engrator-core';
import {ContextMenuItem, Icon20, OnContextMenuItemSelected} from 'src/engrator-core/ui';
import {resyncItem} from "./artifacts-versions/resync";
import {rescheduleMigration} from "../integration/integration-suites/details/rest-api";

type Props = {
    onDetailsSelected: () => void;
    runId: number;
    suiteId: number;
    showReschedule: boolean;
};

type State = {
    isLoading: boolean;
    showDone: boolean;
    showMenu: boolean;
};

export function getAvailableContextItems(showReschedule: boolean): ContextMenuItem[] {
    const items: ContextMenuItem[] = [
        {
            id: 'details',
            name: 'Details'
        }];
    if (showReschedule) {
        items.push({
            id: 'reschedule',
            name: <span>Reschedule<UI.Tooltip
                position={'left'}
                message={`Run migration once again`}
            /></span>
        });
    }
    return items;
}

export class RunsContextMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showMenu: true,
            showDone: false,
            isLoading: false
        };
        if (!props.suiteId || !props.runId) {
            throw new Error('Params not okey ' + props.suiteId + ',' + props.runId);
        }
    }

    render() {
        return <div className={`integrations-list-context-menu`}>
            { this.state.showDone && <UI.Icon icon20={ Icon20.CheckGreen } /> }
            { this.state.isLoading &&  <UI.Loader visible={true} appearance={"darkgray"}/> }
            { this.state.showMenu && <UI.ContextMenu
                getItems={ () => getAvailableContextItems(this.props.showReschedule) }
                onSelected={ this.onSelectedHandler.bind(this) }
            /> }
        </div>
    }

    private async onSelectedHandler(item: ContextMenuItem) {
        if (item.id === 'details') {
            this.props.onDetailsSelected();
        } else if (item.id === 'reschedule') {
            await this.setState({ isLoading: true, showMenu: false });
            try {
                // EXECUTE API CALL HERE
                await rescheduleMigration(this.props.suiteId, this.props.runId);
                await this.setState({isLoading: false, showDone: true});
                setTimeout(() => {
                    this.setState({showDone: false, showMenu: true});
                }, 2 * 1000);
            } catch (e) {
                await this.setState({isLoading: false, showDone: false, showMenu: true});
            }
        }
    }
}
