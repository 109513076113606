import {UI} from "../../engrator-core";
import {Footer} from "../footer";
import React, {Component, useEffect} from "react";
import {useGlobalState} from "../global-store";
import {MainMenuItem} from "../global-store/create-global-state";
import {getCloudSettingsMenu} from "./cloud/cloud-settings-menu";
import {AccountAccessDTO, approveAccess, fetchAccessHistory, revokeAccess} from "./rest-api";

type Props = {
};
type State = {
    isLoading: boolean;
    actionInProgress: boolean;
    isAccessGiven: boolean;
    accessHistory: AccountAccessDTO[];
    error?: any;
};

class AccountAccess extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            isAccessGiven: false,
            actionInProgress: false,
            isLoading: false,
            accessHistory: []
        };
    }

    async componentDidMount() {
        await this.loadHistory();
    }

    render() {
        return <UI.Page>
            <UI.Tile>
                <div>
                    <h3>Give Getint access to your account</h3><br/>
                    <UI.Message appearance={'info'}>
                        <span>We may need to temporarily access your account to diagnose and resolve a customer support issue you’ve raised. Our access automatically expires after 14 days. </span>
                        <UI.DocumentationLink text={`Read more here`} url={`https://docs.getint.io/getintio-platform/settings/sharing-access-permission`}/>
                    </UI.Message>
                    { this.state.error && <UI.Message
                            appearance={'error-message'}
                            message={ this.state.error }
                    /> }
                    { !this.state.isLoading && <div>
                        { this.state.isAccessGiven && <p>Approved Getint's access to your account at { this.state.accessHistory[0].approvedAt }. Your approval will expire after { this.state.accessHistory[0].expiresAt }.</p> }
                        { this.state.isAccessGiven && <UI.Button
                            text={`Revoke Getint access`}
                            appearance={'danger'}
                            isLoading={ this.state.actionInProgress }
                            onClick={ () => this.revokeClicked() }
                        /> }
                        { !this.state.isAccessGiven && <UI.Button
                            text={`Approve Getint access`}
                            appearance={'secondary'}
                            isLoading={ this.state.actionInProgress }
                            onClick={ () => this.approveClicked() }
                        /> }
                        <br/>
                    </div> }
                    { this.state.isLoading && <UI.Loader visible={true} appearance={"darkgray"} /> }
                    { !this.state.isLoading && <React.Fragment>
                        <h3>Getint access approval history</h3><br/>
                        <UI.TableGrid
                            className={`account-access`}
                            headers={['Approved', 'Status']}
                            data={ this.state.accessHistory.map((r) => [
                                <span className={`${ (r.status === 'Active') ? 'active' : '' }`}>{ r.approvedAt }</span>,
                                <span className={`${ (r.status === 'Active') ? 'active' : '' }`}>{ r.statusHumanFormat }</span>
                            ])}
                        />
                    </React.Fragment> }
                </div>
            </UI.Tile>
            <Footer/>
        </UI.Page>;
    }

    private async loadHistory(): Promise<void> {
        await this.setState({ isLoading: true });
        const accessHistory = await fetchAccessHistory();
        const isAccessGiven = accessHistory[0]?.status === "Active";
        await this.setState({ accessHistory, isLoading: false, isAccessGiven });
    }

    private async approveClicked(): Promise<void> {
        await this.setState({ error: undefined, actionInProgress: true });
        try {
            await approveAccess();
            await this.setState({actionInProgress: false});
            await this.loadHistory();
        } catch (error) {
            this.setState({ actionInProgress: false, error });
        }
    }

    private async revokeClicked(): Promise<void> {
        await this.setState({ error: undefined, actionInProgress: true });
        try {
            await revokeAccess();
            await this.setState({actionInProgress: false});
            await this.loadHistory();
        } catch (error) {
            this.setState({ actionInProgress: false, error });
        }
    }
}

export function AccountAccessPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Settings);
        setMenu(getCloudSettingsMenu());
    }, []);
    return <AccountAccess />
}

