import React, {Component, useEffect} from "react";
import {fetchLicenseInfo, JiraServerSetting, saveSetting} from "./rest-api";
import {jiraServerSettingsVerticalMenu} from "./jiraserver-settings-vertical-menu";
import {useGlobalState} from "../../global-store";
import {MainMenuItem} from "../../global-store/create-global-state";
import {DedicatedLicense} from "../dedicated-license";

type State = {
};

class JiraServerSettingsMigrationLicensePageCmp extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <DedicatedLicense
            fetchLicense={ () => fetchLicenseInfo() }
            setLicense={ (newLicense: string) => saveSetting(JiraServerSetting.DedicatedLicense, newLicense) }
        />;
    }
}

export function JiraServerSettingsMigrationLicensePage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMenu(jiraServerSettingsVerticalMenu);
        setMainMenuItem(MainMenuItem.Settings);
    }, []);
    return <JiraServerSettingsMigrationLicensePageCmp />
}