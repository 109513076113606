import { axiosWrapper } from 'src/engrator-core';
import {NotificationAlert, WebhookHeaders} from "./notification-alert.type";
import {AlertChannel, NewAlert} from "./create/new-alert.type";

const ENDPOINT_URL = '/integration/notifications';

export enum AlertStatus {
    ENABLE = 'enable',
    DISABLE = 'disable'
}

export const fetchNotifications = (): Promise<NotificationAlert[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL)
                .then((data: any) => {
                    const obj = data.map((el: any) => ({
                        ...el,
                        webhookHeaders: el.webhookHeaders.map((header: WebhookHeaders, idx: number) => ({
                            ...header,
                            id: idx
                        }))
                    }))
                    resolve(obj);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function fetchAvailableChannels(): Promise<AlertChannel[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/settings/channels')
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function createAlert(alert: NewAlert): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL, alert)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function sendTestEmail(alert: NewAlert): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + '/test-email', alert)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function deleteAlert(alert: NotificationAlert): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.delete(ENDPOINT_URL + '/' + alert.id)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function handleAlertStatus(alert: NotificationAlert, action: AlertStatus): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.put(`${ENDPOINT_URL}/${alert.id}/${action}`, {})
                .then((data: any) => {
                    resolve(true);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function updateAlert(alert: NotificationAlert): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.put(`${ENDPOINT_URL}/${alert.id}`, alert)
                .then((data: any) => {
                    resolve(true);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}