import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";

import {GitLabStepKey} from "../gitlab-step-key.type";
import {GitLabUpdateItemEditStep} from "./gitlab-update-item-edit-step";
import {GitLabUpdateItemNewStep} from "./gitlab-update-item-new-step";
import {GitLabUpdateItemConfiguration} from "./gitlab-update-item-configuration";

export const GitLabUpdateItemPipelineStep: PipelineStep<GitLabUpdateItemConfiguration> = {
    addStepComponentClass: GitLabUpdateItemNewStep,
    editStepComponentClass: GitLabUpdateItemEditStep,
    specification: {
        action: "Update issue",
        description: "Update selected issue",
        key: GitLabStepKey.GitLabUpdateItem,
        software: SoftwareName.GitLab,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitLabUpdateItemConfiguration;
        return `Update issue '${stepConfiguration.targetArtifact}'`;
    }
}