import React from 'react';
import {FieldError, UI} from 'src/engrator-core';
import {
    AlreadyMigratedItemsOption,
    AlreadyMigratedOptions,
    getFromSideOptions,
    MigrationConfiguration,
} from './migration-configuration.type';
import {MigrationUsageInfoBox} from './migration-usage-info-box';
import {getSystemInfo} from "../../../top-navigation/rest-api";
import {Icon20} from "../../../../engrator-core/ui";
import {validateMigrationSettings} from "./rest-api";
import {saveIntegration} from "../../../../designer/container/integration-persistence";
import {SmartIntIntegration} from "../../../../generic";
import {IntegrationAccessLevel} from "../../integration-suites/details/integration-details-model";

type Props = {
    integration: SmartIntIntegration;
};

type State = {
    error?: FieldError;
    isLoading: boolean;
    saved: boolean;
    threadsNumber?: string;
    migrationConfiguration: MigrationConfiguration;
};

export class MigrationComponent extends React.Component<Props, State> {
    private fromOptions = getFromSideOptions(this.props.integration.definition.triggers.left.app, this.props.integration.definition.triggers.right.app);

    constructor(props: Props) {
        super(props);
        console.log('mig config', this.props.integration.migrationConfiguration);
        this.state = {
            isLoading: false,
            saved: false,
            migrationConfiguration: Object.assign({
                enabled: false,
                fromDateTime: '',
                toDateTime: '',
                fromSide: undefined,
                alreadyMigratedItems: undefined,
                disableIntegrationAfterRun: true
            }, this.props.integration.migrationConfiguration)
        };
    }

    async componentDidMount() {
        const threadsNumber = (await getSystemInfo())?.featureFlags['SYNC_THREADS_NUMBER'];
        this.setState({threadsNumber});
    }

    render() {
        return <div className={`migration-settings`}>
            <h1>Migration configuration</h1>
            <MigrationUsageInfoBox/>
            <UI.Form>
                <UI.Message appearance={'info'}>
                    The migration feature lets you move historical data from one app to the other.
                    Specify all the details here, start with enabling the migration. <UI.DocumentationLink
                    text={`Read more here`}
                    url={`https://docs.getint.io/getting-started-with-the-platform/migration`}/>
                </UI.Message>
                {this.state.error && <UI.Message appearance={'error-message'}>
                    We found problems when scheduling migration: {this.state.error?.message}
                </UI.Message>}
                <UI.FormGroup
                    description={<p></p>}
                >
                    <UI.Checkbox
                        defaultValue={`${this.state.migrationConfiguration.enabled}`}
                        onChange={this.switchEnabled.bind(this)}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                        label={`Enable the migration on the next run`}
                    />
                </UI.FormGroup>
                {this.state.migrationConfiguration.enabled && <React.Fragment>
                    <UI.FormGroup>
                        <UI.Dropdown
                            isRequired={true}
                            label={`Select a side
                                    from which you would like to move the items`}
                            options={this.fromOptions}
                            defaultValue={`${this.state.migrationConfiguration.fromSide}`}
                            onChange={(value: 'Left' | 'Right') => this.changeFromSide(value)}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup>
                        <UI.Dropdown
                            isRequired={true}
                            label={`Decide on what to do with the items, that were already migrated`}
                            options={AlreadyMigratedOptions}
                            defaultValue={`${this.state.migrationConfiguration.alreadyMigratedItems}`}
                            onChange={(value: AlreadyMigratedItemsOption) => this.changeAlreadyMigratedOption(value)}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Fields that should be resynced`}
                        description={'Provide list of fields separated with comma or _ALL_ to resync all fields including status'}
                    >
                        <UI.Input
                            placeholder={'Fields that should be resynced'}
                            defaultValue={`${this.state.migrationConfiguration.fieldsToResync || ''}`}
                            onChange={(value) => this.changeMigrationConfiguration('fieldsToResync', value)}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Items that should be migrated`}
                        description={'Provide list of comma separated items ids that should be migrated. All other items will be skipped from migration.'}
                    >
                        <UI.Input
                            placeholder={'Items ids e.g. DEMO-1, DEMO-2, DEMO-3'}
                            defaultValue={`${this.state.migrationConfiguration.itemsIdsToMigrate || ''}`}
                            onChange={(value) => this.changeMigrationConfiguration('itemsIdsToMigrate', value)}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        description={``}
                    >
                        <UI.Checkbox
                            defaultValue={`${this.state.migrationConfiguration.disableIntegrationAfterRun}`}
                            onChange={this.switchDisableIntegration.bind(this)}
                            checkedValue={`true`}
                            uncheckedValue={`false`}
                            label={`Disable the integration after the  migration run`}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Time range (in UTC timezone, e.g. 2021-01-31 23:59:59)`}
                        description={`The time range in which items that should be migrated were created/updated.`}
                    ></UI.FormGroup>
                    <div className={`flex row two-columns`}>
                        <div className={``}>
                            <UI.Input
                                isRequired={true}
                                defaultValue={`${this.state.migrationConfiguration.fromDateTime || ''}`}
                                label={`From`}
                                onChange={(value) => {
                                    this.changeMigrationConfiguration('fromDateTime', value);
                                }}
                            />
                        </div>
                        <div className={``}>
                            <UI.Input
                                isRequired={true}
                                defaultValue={`${this.state.migrationConfiguration.toDateTime || ''}`}
                                label={`To`}
                                onChange={(value) => {
                                    this.changeMigrationConfiguration('toDateTime', value);
                                }}
                            />
                        </div>
                    </div>
                    {this.state.threadsNumber && parseInt(this.state.threadsNumber) > 0 && <UI.FormGroup
                        label={`Threads number (max ${this.state.threadsNumber})`}
                        description={'Split data that must be migrated between multiple threads'}
                    >
                        <UI.Input
                            placeholder={`Provide threads number not higher than ${this.state.threadsNumber}`}
                            defaultValue={`${this.state.migrationConfiguration.threadsNumber || ''}`}
                            onChange={(value) => this.changeMigrationConfiguration('threadsNumber', value)}
                        />
                    </UI.FormGroup>}
                </React.Fragment>}
                <UI.ButtonsBar
                    primary={ (this.state.saved)
                        ? <UI.Message appearance={"success"}>Migration scheduled</UI.Message>
                        : <UI.Button
                            icon={<UI.Icon icon20={Icon20.UploadWhite}/>}
                            text={`Schedule migration`}
                            onClick={() => this.doneClicked()}
                            isLoading={this.state.isLoading}
                            disabled={this.state.isLoading || this.props.integration.accessLevel !== IntegrationAccessLevel.Write}
                        />
                    }
                />
            </UI.Form>
        </div>;
    }

    async doneClicked(): Promise<boolean> {
        // return new Promise((resolve) => {
        try {
            await this.setState({isLoading: true, error: undefined});
            const parsedConfiguration = await validateMigrationSettings(this.state.migrationConfiguration);
            this.props.integration.migrationConfiguration = this.state.migrationConfiguration;
            await saveIntegration(this.props.integration.id, this.props.integration);
            await this.setState({isLoading: false, saved: true });
            setTimeout(() => {
                this.setState({ saved: false })
            }, 3000)
            return true;
        } catch (e) {
            await this.setState({ isLoading: false, error: e });
            return false;
        }
    }

    switchDisableIntegration() {
        const configuration = this.state.migrationConfiguration;
        configuration.disableIntegrationAfterRun = !configuration.disableIntegrationAfterRun;
        this.setState({migrationConfiguration: configuration});
    }

    switchEnabled() {
        const configuration = this.state.migrationConfiguration;
        configuration.enabled = !configuration.enabled;
        this.setState({migrationConfiguration: configuration});
    }

    changeFromSide(value: 'Left' | 'Right') {
        const configuration = this.state.migrationConfiguration;
        configuration.fromSide = value;
        this.setState({migrationConfiguration: configuration});
    }

    changeMigrationConfiguration(field: 'fromDateTime' | 'toDateTime' | 'fieldsToResync' | 'itemsIdsToMigrate' | 'threadsNumber', value: string) {
        const configuration = this.state.migrationConfiguration;
        configuration[field] = value;
        this.setState({migrationConfiguration: configuration});
    }

    changeAlreadyMigratedOption(option: AlreadyMigratedItemsOption) {
        const configuration = this.state.migrationConfiguration;
        configuration.alreadyMigratedItems = option;
        this.setState({migrationConfiguration: configuration});
    }
}
