import React from 'react';
import {getPreferredApps} from '../system/user-activity';
import {SoftwareName} from '../../software';
import {SmartIntTrigger} from '../integration/smartints/definition/smart-int-trigger.type';
import {UI} from 'src/engrator-core';
import {Icon20} from "../../engrator-core/ui";
import {MoreOptions} from "../../designer/top-bar/more";

type Props = {
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
};
type State = {
    isOpened: boolean;
    appToSyncWithJira: SoftwareName | undefined;
};

export class AppsSyncGuide extends React.Component<Props, State> {
    private appToSyncFromPreferredApps: string | undefined;
    private wrapperRef: any;

    constructor(props: Props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.state = {
            isOpened: false,
            appToSyncWithJira: undefined
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        // console.log(this.props.leftTrigger.app, prevProps.leftTrigger.app);
        if (this.props.leftTrigger.app || prevProps.rightTrigger.app) {
            this.findAppToSyncWithJira();
            // } else if (this.props.rightTrigger.app !== prevProps.rightTrigger.app) {
            //     this.findAppToSyncWithJira();
        }
    }

    async componentDidMount(): Promise<void> {
        document.addEventListener('mousedown', this.documentClick.bind(this));
        document.addEventListener('touchstart', this.documentClick.bind(this));
        this.appToSyncFromPreferredApps = await getPreferredApps();
        this.findAppToSyncWithJira();
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    componentWillUnmount() {
            document.removeEventListener('mousedown', this.documentClick);
            document.removeEventListener('touchstart', this.documentClick);
    }

    documentClick(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpened) {
            this.setState({isOpened: false}, () => {
                // this.props.onCloseHandler();
            });
        }
    }

    private switchPopup() {
        this.setState({isOpened: !this.state.isOpened});
    }

    render() {
        // if (!this.state.appToSyncWithJira) {
        //     return <React.Fragment/>;
        // }
        const url = this.getVideoUrl(this.state.appToSyncWithJira);
        // if (!url) {
        //     return <React.Fragment />;
        // }
        return <div className={`engagement-apps-sync-guide`}>
            {/*<GuideTooltip*/}
            {/*    maxWidth={220}*/}
            {/*    styles={ {'position': 'absolute', 'top': '-100px'} }*/}
            {/*    closeable={ true }*/}
            {/*    action={ UserActivityAction.SyncGuideVideo }*/}
            {/*>Watch video with basic tutorial of how to build integration</GuideTooltip>*/}
            <div className={`inner`}>
                <div className={`guide-me`} ref={this.setWrapperRef}>
                    {/*<img src={video}/>*/}
                    <UI.Button
                        icon={ <UI.Icon icon20={ Icon20.Compass } /> }
                        text={`Guide Me`}
                        onClick={ () => this.setState({ isOpened: !this.state.isOpened }) }
                    />
                    { this.state.isOpened && <div className={`context-menu-popup`}>
                        <ul>
                            <li data-sel="settings" onClick={ () => this.optionClicked(MoreOptions.Settings)}>
                                <a href={'https://docs.getint.io'} target={'_blank'}>
                                    <UI.Icon icon20={ Icon20.Files } />
                                    Read preparation docs
                                </a>
                            </li>
                            <li data-sel="settings" onClick={ () => this.optionClicked(MoreOptions.Settings)}>
                                { url && <a href={ url } target={'_blank'}>
                                    <UI.Icon icon20={ Icon20.Files } />
                                    Check Jira-{this.state.appToSyncWithJira} integration guide
                                </a> }
                            </li>
                            {/*<li onClick={ () => this.optionClicked(MoreOptions.Advanced)}>*/}
                            {/*    { url && <a href={ url } target={'_blank'}>*/}
                            {/*        <UI.Icon icon20={ Icon20.Video } />*/}
                            {/*        Watch video*/}
                            {/*    </a> }*/}
                            {/*</li>*/}
                        </ul>
                    </div> }
                </div>
            </div>
        </div>;
    }

    private optionClicked(option: MoreOptions): void {
        // this.props.optionSelectedHandler(option);
        this.setState({ isOpened: false });
    }

    private extractAppFromPreferredApps(): SoftwareName | undefined {
        if (!this.appToSyncFromPreferredApps) {
            return;
        }
        if (this.appToSyncFromPreferredApps.indexOf('-azure') >= 0) {
            return SoftwareName.Azure;
        } else if (this.appToSyncFromPreferredApps.indexOf('-servicenow') >= 0) {
            return SoftwareName.ServiceNow;
        } else if (this.appToSyncFromPreferredApps.indexOf('-jira') >= 0) {
            return SoftwareName.Jira;
        } else if (this.appToSyncFromPreferredApps.indexOf('-asana') >= 0) {
            return SoftwareName.Asana;
        } else if (this.appToSyncFromPreferredApps.indexOf('-gitlab') >= 0) {
            return SoftwareName.GitLab;
        } else if (this.appToSyncFromPreferredApps.indexOf('-monday') >= 0) {
            return SoftwareName.Monday;
        }
    }

    private getVideoUrl(app?: SoftwareName): string {
        if (!app) {
            return '';
        }
        switch (app) {
            case SoftwareName.Azure:
                return 'https://docs.getint.io/getting-started-with-the-platform/integration-synchronization/jira-azure-devops-integration';
            case SoftwareName.Jira:
                return 'https://docs.getint.io/getting-started-with-the-platform/build-your-workflow/jira-jira-integration';
            case SoftwareName.Airtable:
                return 'https://docs.getint.io/guides/integration-synchronization/jira-airtable-integration';
            case SoftwareName.ClickUp:
                return 'https://docs.getint.io/guides/integration-synchronization/jira-clickup-integration';
            case SoftwareName.Hubspot:
                return 'https://docs.getint.io/guides/integration-synchronization/jira-hubspot-integration';
            case SoftwareName.Asana:
                return 'https://docs.getint.io/getting-started-with-the-platform/build-your-workflow/jira-asana-integration';
            case SoftwareName.ServiceNow:
                return 'https://docs.getint.io/getting-started-with-the-platform/integration-synchronization/jira-servicenow-integration';
            case SoftwareName.GitLab:
                return 'https://docs.getint.io/getting-started-with-the-platform/build-your-workflow/jira-gitlab';
            case SoftwareName.Monday:
                return 'https://docs.getint.io/getting-started-with-the-platform/integration-synchronization/jira-monday-integration';
            case SoftwareName.Zendesk:
                return 'https://docs.getint.io/guides/integration-synchronization/jira-zendesk-integration';
            case SoftwareName.Freshdesk:
                return 'https://docs.getint.io/guides/integration-synchronization/jira-freshdesk';
            case SoftwareName.Salesforce:
                return 'https://docs.getint.io/guides/integration-synchronization/jira-salesforce-integration';
            case SoftwareName.Wrike:
                return 'https://docs.getint.io/guides/integration-synchronization/jira-wrike-integration';
            case SoftwareName.Notion:
                return 'https://docs.getint.io/guides/integration-synchronization/jira-notion-integration';
        }
        return '';
    }

    private findAppToSyncWithJira(): void {
        // when someone selected both apps to sync, preferred app is ignored
        let appToSyncWithJira = undefined;
        if (this.props.leftTrigger.app && this.props.rightTrigger.app) {
            if (this.props.leftTrigger.app === SoftwareName.Jira) {
                appToSyncWithJira = (this.props.rightTrigger.app as SoftwareName);
            } else if (this.props.rightTrigger.app === SoftwareName.Jira) {
                appToSyncWithJira = (this.props.leftTrigger.app as SoftwareName);
            }
        } else {
            appToSyncWithJira = this.extractAppFromPreferredApps();
        }
        if (appToSyncWithJira !== this.state.appToSyncWithJira) {
            this.setState({ appToSyncWithJira });
        }
    }
}
