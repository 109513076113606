import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";

import {GitHubStepKey} from "../github-step-key.type";
import {GitHubUpdateItemEditStep} from "./github-update-item-edit-step";
import {GitHubUpdateItemNewStep} from "./github-update-item-new-step";
import {GitHubUpdateItemConfiguration} from "./github-update-item-configuration";

export const GitHubUpdateItemPipelineStep: PipelineStep<GitHubUpdateItemConfiguration> = {
    addStepComponentClass: GitHubUpdateItemNewStep,
    editStepComponentClass: GitHubUpdateItemEditStep,
    specification: {
        action: "Update issue",
        description: "Update selected issue",
        key: GitHubStepKey.GitHubUpdateItem,
        software: SoftwareName.GitHub,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitHubUpdateItemConfiguration;
        return `Update issue '${stepConfiguration.targetArtifact}'`;
    }
}