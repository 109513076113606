import React, {Component, useEffect} from 'react';
import {UI} from 'src/engrator-core';
import {Footer} from '../../footer';

import {SystemCustomProperties} from './system-custom-properties';
import {useGlobalState} from "../../global-store";
import {getSystemVerticalMenu} from "../system-vertical-menu";
import {MainMenuItem} from "../../global-store/create-global-state";
import {jiraServerSettingsVerticalMenu} from "../../settings/jiraserver/jiraserver-settings-vertical-menu";

type State = {};

class SystemCustomPropertiesPageCmp extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <UI.Page>
                <UI.Tile>
                    <SystemCustomProperties/>
                </UI.Tile>
                <Footer/>
            </UI.Page>
        );
    }
}

export function SystemCustomPropertiesPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        if (window.location.href.indexOf('jira-server') >= 0) {
            setMenu(jiraServerSettingsVerticalMenu);
            setMainMenuItem(MainMenuItem.Settings);
        } else {
            setMainMenuItem(MainMenuItem.SystemSettings);
            setMenu(getSystemVerticalMenu());
        }
    }, []);
    return <SystemCustomPropertiesPageCmp />
}