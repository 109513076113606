import React, {Component} from 'react';
import {GetintUserWorkspace, UI} from 'src/engrator-core';
import {
    addUserToWorkspace,
    AddUserToWorkspaceData, changeUserStatus, deleteUserFromWorkspace,
    EditMetaData,
    fetchEditMetaData,
    GetintUserRole
} from "../../system/users/users-management-rest-api";
import {EntityStatusValue, Icon20} from "../../../engrator-core/ui";
import {isUndefined} from "util";
import {UserRoleLabel} from "../../system/users/user-role-label";

type Props = {
    closeHandler: () => void;
    userId: number;
    refreshList: () => void;
};
type State = {
    addUserToWorkspaceData: AddUserToWorkspaceData;
    editMetaData?: EditMetaData;
    newStatus?: EntityStatusValue;
};

export class ClusterUsersEdit extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            addUserToWorkspaceData: {
                userId: this.props.userId
            }
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const editMetaData = await fetchEditMetaData(this.props.userId);
        this.setState({editMetaData})
    }

    async createClicked() {
        await addUserToWorkspace(this.state.addUserToWorkspaceData);
        this.setState({
            addUserToWorkspaceData: {
                userId: this.props.userId,
                workspaceId: undefined,
                role: undefined
            }
        });
        await this.loadData();
        this.props.refreshList();
    }

    async changeStatusClicked() {
        if (this.state.newStatus) {
            await changeUserStatus(this.props.userId, this.state.newStatus);
            this.props.refreshList();
        }
    }

    render() {
        return (
            <UI.FullScreenModal
                header={`Edit User`}
                closeBtnHandler={() => this.props.closeHandler()}
                primaryBtnHandler={() => Promise.resolve(true)}
                primaryBtnText={`Save`}
                showPrimaryBtn={false}
            >
                {this.state.editMetaData && <React.Fragment>
                    <UI.TableGrid headers={['Workspace', 'Role', 'Delete']} data={
                        this.state.editMetaData.userWorkspaces.map(w => [w.workspaceName,
                            <UserRoleLabel role={w.role}/>,
                            <UI.Button
                                appearance={'secondary'}
                                icon={ <UI.Icon icon20={Icon20.Trash} /> }
                                text={``}
                                onClick={ () => this.deleteUserAccess(w) }
                            />
                        ])
                    }/><br/>
                    <UI.Form>
                        <h3>Assign user to workspace</h3>
                        <UI.FormGroup
                            label={`Select workspace`}
                        >
                            <UI.Dropdown
                                defaultValue={this.state.addUserToWorkspaceData.workspaceId}
                                options={this.state.editMetaData.availableWorkspaces.map(o => ({
                                    label: o.name,
                                    value: o.id
                                }))}
                                onChange={(wId: string) => this.setWorkspaceId(wId)}
                            />
                        </UI.FormGroup>
                        <UI.FormGroup
                            label={`Select role`}
                        >
                            <UI.Dropdown
                                defaultValue={this.state.addUserToWorkspaceData.role}
                                options={this.state.editMetaData.roles.map(o => ({label: o, value: o}))}
                                onChange={(role: GetintUserRole) => this.setRole(role)}
                            />
                        </UI.FormGroup>
                        <UI.ButtonsBar
                            primary={<UI.Button
                                icon={<UI.Icon icon20={Icon20.CirclePlusWhite}/>}
                                className={`selenium-add-user`}
                                disabled={!(this.state.addUserToWorkspaceData.workspaceId && this.state.addUserToWorkspaceData.role)}
                                onClick={() => this.createClicked()}
                                text={`Add access`}
                            />}
                        />
                        <React.Fragment>
                            <UI.FormGroup
                                label={`Status`}
                                description={`Change user status`}
                            >
                                <UI.Dropdown
                                    options={ [{label: 'Enabled', value: EntityStatusValue.Enabled}, {label: 'Disabled', value: EntityStatusValue.Disabled}] }
                                    onChange={ (newValue: any) => this.setState({ newStatus: newValue }) }
                                />
                            </UI.FormGroup>
                            <UI.ButtonsBar
                                primary={<UI.Button
                                    icon={<UI.Icon icon20={Icon20.Save}/>}
                                    className={`selenium-change-status`}
                                    disabled={!(this.state.newStatus)}
                                    onClick={() => this.changeStatusClicked()}
                                    text={`Change`}
                                />}
                            />
                        </React.Fragment>
                    </UI.Form>
                </React.Fragment>}
            </UI.FullScreenModal>
        );
    }

    private setWorkspaceId(wId: string) {
        const data = this.state.addUserToWorkspaceData;
        data.workspaceId = wId;
        this.setState({addUserToWorkspaceData: data});
    }

    private setRole(role: GetintUserRole) {
        const data = this.state.addUserToWorkspaceData;
        data.role = role;
        this.setState({addUserToWorkspaceData: data});
    }

    private async deleteUserAccess(w: GetintUserWorkspace) {
        await deleteUserFromWorkspace(this.props.userId, w.workspaceId);
        await this.loadData();
        this.props.refreshList();
    }
}