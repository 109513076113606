import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";

import {WrikeStepKey} from "../wrike-step-key.type";
import {WrikeUpdateItemEditStep} from "./wrike-update-item-edit-step";
import {WrikeUpdateItemNewStep} from "./wrike-update-item-new-step";
import {WrikeUpdateItemConfiguration} from "./wrike-update-item-configuration";

export const WrikeUpdateItemPipelineStep: PipelineStep<WrikeUpdateItemConfiguration> = {
    addStepComponentClass: WrikeUpdateItemNewStep,
    editStepComponentClass: WrikeUpdateItemEditStep,
    specification: {
        action: "Update task",
        description: "Update selected task",
        key: WrikeStepKey.WrikeUpdateItem,
        software: SoftwareName.Wrike,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as WrikeUpdateItemConfiguration;
        return `Update task '${stepConfiguration.targetArtifact}'`;
    }
}