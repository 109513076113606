export type IntegrationChangesModel = {
    id: number | null;
    modifiedAt: Date;
    changeAuthorName: string;
    actions: string[];
    configurationAndOthers: string;
    integrationId: number;
    version: number;
};

export function createIntegrationChangesModel(itemData: IntegrationChangeDto): IntegrationChangesModel {
    if (!itemData) {
        throw new Error('Invalid item data');
    }
    return {
        id: itemData.id,
        modifiedAt: itemData.modifiedAt,
        changeAuthorName: itemData.changeAuthorName,
        actions: itemData.actions,
        configurationAndOthers: itemData.configurationAndOthers,
        integrationId: itemData.integrationId,
        version: itemData.version,
    };
}

export type IntegrationChangeDto = {
    id: number | null;
    modifiedAt: Date;
    changeAuthorName: string;
    integrationId: number;
    actions: string[];
    configurationAndOthers: string;
    version: number;
};
