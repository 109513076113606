import { axiosWrapper } from 'src/engrator-core';
import { TenantProperty } from './tenants/properties/tenant-property';

const ENDPOINT_URL = '/cluster';

export function fetchClusterTenants(): Promise<any> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                axiosWrapper.get(`${ENDPOINT_URL}/tenants`)
                    .then((data) => {
                        resolve(data);
                    })
                    .catch(error => reject(error));
            } catch (error) {
                reject(error);
            }
        }, 300);
    });
};

export function disableTenant(tenantName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const url = `${ENDPOINT_URL}/tenants/${tenantName}/disable`;
        return axiosWrapper.put(url, {})
            .then(response => {
                resolve(true);
            })
            .catch(error => alert(JSON.stringify(error)));
    });
}

export function createTenant(tenantData: any): Promise<any> {
    return new Promise((resolve, reject) => {
        return axiosWrapper.postAsJson(`${ENDPOINT_URL}`, tenantData)
            .then((resp: any) => {
                // return axiosWrapper.get(`${ENDPOINT_URL}/defaultAccess/${resp.tenantName}`)
                //     .then((response: any) => {
                        // response.tenantName = resp?.tenantName;
                        resolve(resp);
                    // })
                    // .catch(reject);
            })
            .catch(reject);
    });
}

export function setTenantProperty(tenantData: any): Promise<any> {
    return new Promise((resolve, reject) => {
        return axiosWrapper.put(`${ENDPOINT_URL}/properties`, tenantData, {'X-Tenant': tenantData.tenantName})
            .then(response => {
                resolve(response);
            })
            .catch(reject);
    });
}

export function getTenantProperties(tenantName: string): Promise<TenantProperty[]> {
    return new Promise((resolve, reject) => {
        return axiosWrapper.get(`${ENDPOINT_URL}/properties/${tenantName}`, false, {'X-Tenant': tenantName})
            .then((response: any) => {
                resolve(response);
            })
            .catch(reject);
    });
}
