import { axiosWrapper, EntityStatusEnum } from 'src/engrator-core';

const ENDPOINT_URL = '/system/api/integration-threads';

export type IntegrationThreadInfo = {
    id: number;
    name: string;
    status: EntityStatusEnum;
};

export function fetchIntegrationThreads(): Promise<IntegrationThreadInfo[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
