import React from 'react';
import {
    SmartIntAppAttachmentsConfiguration,
    SmartIntAttachmentsConfiguration
} from 'src/app/integration/smartints/definition/smart-int-definition-configuration.type';
import { UI } from 'src/engrator-core';
import { SoftwareName } from 'src/software';
import {AppsSupport} from "../../../app/integration/smartints/visual/visual-integration-designer";

type Props = {
    configuration: SmartIntAttachmentsConfiguration;
    appSupport: AppsSupport;
};
type State = {};

export class AttachmentsJiraInsertAsPublicComment extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                label={`Insert attachments to Jira in a public comment`}
                description={`To make attachments visible to your Jira Service Desk customers, you can insert attachments in a public comments`}
            >
                <div className={`flex row two-columns`}>
                    <div>
                        { this.props.appSupport.leftApp === SoftwareName.Jira && <UI.Checkbox
                            label={`${this.props.appSupport.leftApp}`}
                            defaultValue={`${this.props.configuration.insertInPublicComment?.left}`}
                            onChange={ () => this.switchFlag('left') } checkedValue={`true`} uncheckedValue={`false`} /> }
                    </div>
                    <div>
                        { this.props.appSupport.rightApp === SoftwareName.Jira && <UI.Checkbox
                            label={`${this.props.appSupport.rightApp}`}
                            defaultValue={`${this.props.configuration.insertInPublicComment?.right}`}
                            onChange={ () => this.switchFlag('right') } checkedValue={`true`} uncheckedValue={`false`} /> }
                    </div>
                </div>
            </UI.FormGroup>
        </React.Fragment>
    }

    private switchFlag(side: 'left' | 'right') {
        if (!this.props.configuration.insertInPublicComment) {
            this.props.configuration.insertInPublicComment = {
                left: false,
                right: false
            };
        }
        this.props.configuration.insertInPublicComment[side] = !this.props.configuration.insertInPublicComment[side];
    }
}
