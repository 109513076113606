import React from 'react';
import { UI } from 'src/engrator-core';
import { PropertyDefinition } from 'src/generic/artifacts';

type Props = {
    definition: PropertyDefinition;
};
type State = {
};

export class PropertyDefinitionInfo extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const infoParts = [
            this.props.definition.id,
            this.props.definition.dataType,
            (this.props.definition.options?.readonly) ? 'readonly' : undefined
        ];
        return <p>({ infoParts.join(', ') })</p>;
    }
}
