import React, {Component, useEffect} from 'react';

import { FieldError, UI } from 'src/engrator-core';
import { fetchIntegrationThreadsStates } from './../rest-api';
import { IntegrationThreadTile } from './integration-thread-tile';
import { IntegrationThreadState } from './integration-thread-state.type';
import { FormError } from '../../../engrator-core/form-error.type';
import {useGlobalState} from "../../global-store";
import {getReportingVerticalMenu} from "../../reporting/reporting-vertical-menu";
import {MainMenuItem} from "../../global-store/create-global-state";

type State = {
    threadsStates: IntegrationThreadState[];
    error?: FormError;
};

const REFRESH_THREADS_PER_MILISECONDS = 3000;

class IntegrationThreadsPageCmp extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            threadsStates: []
        };
    }

    componentDidMount(): void {
        this.fetchIntegrationThreadsStatesFromApi();
    }

    render() {
        return (
            <UI.Page>
                <div className={`integration-threads-page`}>
                    { this.state.error && <UI.Message appearance={"error"} message={ this.state.error?.general } /> }
                    <div className={`threads-list`}>
                    { this.state.threadsStates.map((threadState, index) => <IntegrationThreadTile
                            key={ index }
                            threadState={ threadState }
                        />
                    )}
                    </div>
                </div>
            </UI.Page>
        )
    }

    private fetchIntegrationThreadsStatesFromApi() {
        this.setState({ error: undefined }, () => {
            fetchIntegrationThreadsStates()
                .then(threadsStates => {
                    if (threadsStates != this.state.threadsStates) {
                        this.setState({threadsStates}, () => {
                            setTimeout(() => this.fetchIntegrationThreadsStatesFromApi(), REFRESH_THREADS_PER_MILISECONDS);
                        });
                    } else {
                        setTimeout(() => this.fetchIntegrationThreadsStatesFromApi(), REFRESH_THREADS_PER_MILISECONDS);
                    }
                }).catch((error: FieldError) => {
                this.setState({error: {['general']: error.message}});
                setTimeout(() => this.fetchIntegrationThreadsStatesFromApi(), REFRESH_THREADS_PER_MILISECONDS);
            })
        });
    }
}

export function IntegrationThreadsPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMenu(getReportingVerticalMenu());
        setMainMenuItem(MainMenuItem.Reporting);
    }, []);
    return <IntegrationThreadsPageCmp
    />
}