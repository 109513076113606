import React, { Component } from 'react'

import { ConnectionFactory } from './../../connections/connection-factory';
import SoftwareList from './SoftwareList';
import ActionsList from './ActionsList';
import { ConnectionDropdown } from './ConnectionDropdown';
import { Steps } from 'src/generic';
import { IntegrationStep, GenericSoftwareStep } from 'src/generic/software/step';
import {RightPanelContent} from "../../engrator-core/ui/right-panel-content";
import { SoftwareLogo } from './software-logo';

type StepPickerProps = {
    stepsFilter: any;
    stepFactory: any;
    addStep(selectedStep: any): void;
    connectionFactories: [{ softwareId: string, connectionFactory: ConnectionFactory }];
    artifactsFactory: any;
};

type State = {
    softwareList: Array<any>,
    actionsList: Array<any>,
    ConnectionComponent: any,
    currentStep: number,
    connectionId: number,
    configurationComponent: any | null,
    connectionFactory: ConnectionFactory | null
};

export default class StepPicker extends Component<StepPickerProps> {
    picker: any;
    selectedStep: GenericSoftwareStep | null;
    selectedSoftware: string;
    state: State = {
        softwareList: [],
        actionsList: [],
        ConnectionComponent: null,
        currentStep: 0,
        connectionFactory: null,
        connectionId: 0,
        configurationComponent: null
    };
    selectedKey: string;

    constructor(props: StepPickerProps) {
        super(props);
        this.picker = new Steps.StepPicker(props.stepFactory, this.onSelectCallback.bind(this));
        this.selectedSoftware = '';
        this.selectedKey = '';
        this.selectedStep = null;
        this.state = {
            ConnectionComponent: null,
            actionsList: [],
            softwareList: this.picker.getSoftwareList(),
            currentStep: 0,
            connectionFactory: null,
            connectionId: 0,
            configurationComponent: null
        };
    }

    onSelectCallback(selectedStep: any) {
        this.selectedStep = selectedStep;
    }

    selectSoftware(software: any) {
        this.selectedSoftware = software;
        this.picker.selectSoftware(software);
        this.setState({ actionsList: this.picker.getActionsForSoftware(this.props.stepsFilter), currentStep: 1 });
    }

    selectAction(action: string) {
        this.selectedKey = action;
        const pickedAction = this.state.actionsList.filter((action) => action.key === this.selectedKey)[0];
        if (pickedAction && pickedAction.requiresConnectionPicker === false) {
            this.picker.selectAction(this.selectedKey);
            const configurationComponent = this.selectedStep!.getConfigurationComponent(
                -1,
                this.props.artifactsFactory,
                this.addStep.bind(this),
                this.selectedStep!.getStepSpecification()
            );
            this.setState({ currentStep: 3, connectionId: -1, configurationComponent });
        } else {
            const factoryTuple = this.props.connectionFactories.filter(pair => pair.softwareId === this.selectedSoftware)[0];
            const connectionFactory = factoryTuple.connectionFactory;
            this.setState({currentStep: 2, connectionFactory});
        }
    }

    selectConnection(id: number): void {
        if (!id) {
            return alert('Please select connection');
        }
        this.picker.selectAction(this.selectedKey);
        const configurationComponent = this.selectedStep!.getConfigurationComponent(
            id,
            this.props.artifactsFactory,
            this.addStep.bind(this),
            this.selectedStep!.getStepSpecification()
        );
        this.setState({ currentStep: 3, connectionId: id, configurationComponent });
    }

    addStep(integrationStep: IntegrationStep) {
        this.props.addStep(integrationStep);
    }

    render() {
        return <React.Fragment>
            { this.state.currentStep === 0 && 
                <RightPanelContent header={`Select App`}>
                    <SoftwareList
                    list={ this.state.softwareList }
                    selectedSoftware={ this.selectedSoftware }
                    onSelect={ this.selectSoftware.bind(this) } />
                </RightPanelContent>
            }
            { this.state.currentStep === 1 && this.state.actionsList &&
                <RightPanelContent header={ <React.Fragment>
                    <SoftwareLogo softwareName={ this.selectedSoftware } />
                    Select { this.selectedSoftware } action</React.Fragment>
                }>
                    <ActionsList
                        selectedSoftware={ this.selectedSoftware }
                        list={ this.state.actionsList }
                        selectedAction={ this.selectedKey }
                        onSelect={ this.selectAction.bind(this) }
                    />
                </RightPanelContent>
            }
            { this.state.currentStep === 2 &&
                <ConnectionDropdown
                    software={ this.selectedSoftware }
                    connectionFactory={ this.state.connectionFactory as ConnectionFactory }
                    connectionPickedHandler={ this.selectConnection.bind(this) }/>
            }
            { this.state.currentStep === 3 &&
                <React.Fragment>
                    { this.state.configurationComponent.rightPanel }
                </React.Fragment>
            }
        </React.Fragment>
    }
}