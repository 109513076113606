import {PipelineStep} from "src/generic";
import {
    GitLabCreateCommentsPipelineStep,
    GitLabCreateItemPipelineStep,
    GitLabSearchItemsPipelineStep,
    GitLabUpdateItemPipelineStep,
    GitLabFindItemPipelineStep,
    GitLabStatusTransitionPipelineStep
} from "./steps";

export const GitLabPipelineSteps: PipelineStep<any>[] = [
    GitLabSearchItemsPipelineStep,
    GitLabCreateItemPipelineStep,
    GitLabCreateCommentsPipelineStep,
    GitLabUpdateItemPipelineStep,
    GitLabFindItemPipelineStep,
    GitLabStatusTransitionPipelineStep
];