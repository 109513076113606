import React from 'react';

import './header.css';

type HeaderProps = {
    title: string,
    buttons?: Array<any>
};

export class Header extends React.Component<HeaderProps> {
    render() {
        return <div className={`engrator-header`}>
            <div className="col">
                <h1>{ this.props.title }</h1>
            </div>
            <div className="col right">
                { this.props.buttons }
            </div>
        </div>;
    }
}