import {VerticalMenu} from "../global-store/create-global-state";
import {Icon20} from "../../engrator-core/ui";

export const homeVerticalMenu: VerticalMenu = {
    header: 'Home',
    items: [{
        name: 'Dashboard',
        path: '/app/dashboard'
    }, {
        name: 'Getting started',
        path: '/app/getting-started',
        icon: Icon20.Launch
    }]
};