import { axiosWrapper } from 'src/engrator-core';
import { BulkEditOperation } from './bulkd-edit-operation.type';

const ENDPOINT_URL = '/integration/suites/bulk';

export function bulkEditIntegrations(ids: number[], operation: BulkEditOperation): Promise<void>  {
    return new Promise((resolve, reject) => {
        try {
            const data = {
                ids,
                operation,
            };
            axiosWrapper.post(ENDPOINT_URL, data)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
