import {axiosWrapper} from "src/engrator-core";
import {SystemLicenseInfo} from "../../system/license/rest-api";

const ENDPOINT_URL = '/settings/cloud';
type GetSettingResponse = {
    properties: {
        propertyName: CloudSetting;
        value: string;
    }[]
};

export enum CloudSetting {
    DedicatedLicense = 'DedicatedLicense',
    CleanUpMaxDaysNumber = 'CleanUpMaxDaysNumber'
}

export function saveSetting(name: CloudSetting, value: string): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            const data = { propertyName: name, value };
            axiosWrapper.post(`${ENDPOINT_URL}`, data)
                .then((data: any) => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function getSetting(name: CloudSetting): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}`)
                .then((data: any) => {
                    resolve(
                        (data as GetSettingResponse).properties.filter(p => p.propertyName === name)[0]?.value
                    );
                    // resolve(data.properties[name]);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchLicenseInfo(): Promise<SystemLicenseInfo> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/license`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}