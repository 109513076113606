import React from 'react';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { UI } from 'src/engrator-core';
import { SoftwareName } from '../../software-name';
import { ZendeskOrganizationPicker, ZendeskOrganizationPickerData } from '../steps/common';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class ZendeskSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.Zendesk,
            artifactName: '',
            className: '',
            configuration: {
                organization: {
                    id: '', name: ''
                },
                query: ''
            },
            connectionId: this.props.connectionId
        };
        this.smartIntTrigger.artifactName = 'issue';
        this.props.onConfigured(this.smartIntTrigger!);
    }

    render() {
        return <React.Fragment>
            {!this.smartIntTrigger.configuration.project && <ZendeskOrganizationPicker
                connectionId={this.props.connectionId}
                onDataChanged={this.onDataChanged.bind(this)}
            />}
            {this.smartIntTrigger.configuration.repository && <UI.FormGroup>
                <UI.LabelValue
                    label={`Organization`}
                    value={this.smartIntTrigger.configuration.organization.name}
                />
            </UI.FormGroup>
            }
            {/*<UI.FormGroup*/}
            {/*    label={`Additional Query`}*/}
            {/*    description={`You can provide additional query params which will be appended to the url generated when searching for issues in Zendesk`}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={this.onJqlInputChanged.bind(this)}/>*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private onDataChanged(data: ZendeskOrganizationPickerData) {
        if (data.organization) {
            this.smartIntTrigger.configuration.organization = data.organization;
            this.smartIntTrigger.artifactName = 'issue';
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }
}
