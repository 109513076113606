import React from 'react';

import {GitHubFindItemConfiguration} from "./github-find-item-configuration";
import {EditPipelineStepComponentProps} from "src/generic/pipeline-step";
import {ArtifactDirection} from "../../../../generic/artifacts";
import {UI} from "../../../../engrator-core";
import {GitHubStepContextDisplay} from "../common";

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class GitHubFindItemEditStep extends React.Component<Props, State> {
    private configuration: GitHubFindItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as GitHubFindItemConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <GitHubStepContextDisplay context={ this.configuration.context } />
            <UI.LabelValue label={`Item Key`} value={ this.configuration.id } />
        </React.Fragment>;
    }
};