export enum FilterName {
    Status = 'status',
    RunMode = 'runMode',
    ExecutionTime = 'executionTime',
    DateRange = 'dateRange',
    Query = 'query',
    FailedFlows = 'failedFlows',
    SyncedArtifacts = 'syncedArtifacts',
    RunId = 'runId',
    Software = 'software',
    isForcedResync = 'isForcedResync'
};