import React from 'react';
import { UI } from 'src/engrator-core';

import { PropertyMapping } from '../../../property-mapping.type';
import { AppsSupport } from '../../../visual-integration-designer';
import { AddFormatter } from './add-formatter';

type Props = {
    appsSupport: AppsSupport;
    mapping: PropertyMapping;
};

type State = {};

export class Formatters extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        if (!this.props.mapping.formatters) {
            this.props.mapping.formatters = {
                left: [],
                right: []
            };
        }
        console.log(this.props.mapping.formatters);
    }

    render() {
        return <div>
            <UI.FormSection
                label={`Formatters that will be applied when setting up ${ this.props.mapping.left.name } field in ${ this.props.appsSupport.leftApp } (left side)`}
            >
                <AddFormatter
                    fromAppName={this.props.appsSupport.leftApp}
                    toAppName={this.props.appsSupport.rightApp}
                    formatters={this.props.mapping.formatters!.left}
                />
            </UI.FormSection>
            <UI.FormSection
                label={`Formatters that will be applied when setting up ${ this.props.mapping.right.name } field in ${ this.props.appsSupport.rightApp } (right side)`}
            >
                <AddFormatter
                    fromAppName={this.props.appsSupport.rightApp}
                    toAppName={this.props.appsSupport.leftApp}
                    formatters={this.props.mapping.formatters!.right}
                />
            </UI.FormSection>
        </div>;
    }
}
