import {axiosWrapper, FieldError} from "src/engrator-core";
import {SoftwareName} from "../../software";
import {ArtifactDirection} from "../artifacts";

export const ENDPOINT_URL = '/connectors/';

export enum StepAction {
    BeforeAdd ='BeforeAdd',
    BeforeUpdate = 'BeforeUpdate'
}
export const validateStep = (softwareName: SoftwareName, stepKey: string, stepAction: StepAction, configuration: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `${softwareName.toLowerCase()}/steps/${stepKey}/${stepAction}`, configuration)
                .then((data: any) => resolve())
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchArtifactProperties = (softwareName: SoftwareName, stepKey: string, direction: ArtifactDirection, configuration: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `${softwareName.toLocaleLowerCase()}/artifacts/properties/${direction}`, configuration)
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};