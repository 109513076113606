import React from 'react';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { UI } from 'src/engrator-core';
import { SoftwareName } from '../../software-name';
import { WrikeStepContext, WrikeStepContextData } from '../steps/common';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class WrikeSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.Wrike,
            artifactName: '',
            className: '',
            configuration: {
                space: undefined,
                folder: undefined,
                query: ''
            },
            connectionId: this.props.connectionId
        };
    }

    render() {
        return <React.Fragment>
            {!this.smartIntTrigger.configuration.project && <WrikeStepContext
                connectionId={this.props.connectionId}
                onDataChanged={this.onDataChanged.bind(this)}
            />}
            {this.smartIntTrigger.configuration.space && <UI.FormGroup>
                <UI.LabelValue
                    label={`Space`}
                    value={this.smartIntTrigger.configuration.space.name}
                />
                </UI.FormGroup>
            }
            {this.smartIntTrigger.configuration.folder && <UI.FormGroup>
                <UI.LabelValue
                    label={`Folder`}
                    value={this.smartIntTrigger.configuration.folder.name}
                />
                </UI.FormGroup>
            }
            {/*<UI.FormGroup*/}
            {/*    label={`Additional Query`}*/}
            {/*    description={`You can provide additional query params which will be appended to the url generated when searching for tasks in Wrike`}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={this.onJqlInputChanged.bind(this)}/>*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private onDataChanged(data: WrikeStepContextData) {
        if (data.folder && data.space) {
            this.smartIntTrigger.configuration.space = data.space;
            this.smartIntTrigger.artifactName = 'asanaIssue';
            this.smartIntTrigger.configuration.folder = data.folder;
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }
}
