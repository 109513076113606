import React from 'react';

import {EntityFormHelper, EntityStatusEnum, FieldError, UI} from "src/engrator-core";
import {SystemApiKey} from "./system-api-key.type";
import {UpdatedSystemApiKey} from "./updated-system-api-key.type";
import {EntityStatus} from "../../../engrator-core/ui";
import {updateSystemApiKey} from "./rest-api";

type Props = {
    closeHandler: () => void;
    onSystemApiKeyUpdated: () => void;
    apiKey: SystemApiKey;
};

type State = {
    isLoading: boolean
};

export class ApiKeyDetails extends React.Component<Props, State> {
    private updatedSystemApiKey: UpdatedSystemApiKey;
    private entityFormHelper: EntityFormHelper;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false
        };
        this.updatedSystemApiKey = {
            status: this.props.apiKey.status
        };
        this.entityFormHelper = new EntityFormHelper((propertyName: 'status', newValue) => {
            this.updatedSystemApiKey.status = newValue;
        });
    }

    private updateHandler() {
        this.setState({ isLoading: true }, () => {
            updateSystemApiKey(this.props.apiKey.id, this.updatedSystemApiKey)
                .then(() => {
                    this.props.onSystemApiKeyUpdated();
                })
                .catch((error: FieldError) => {
                    this.entityFormHelper.formError = {[error.fieldName]: error.message};
                    this.setState({isLoading: false});
                })
        });
    }

    render() {
        const buttonsBar = <UI.ButtonsBar
            primary={ <UI.Button appearance={"elementary"} isLoading={ this.state.isLoading } onClick={() => { this.updateHandler() } } text="Save" /> }
            secondary={ [ <UI.Button appearance="secondary" onClick={() => { this.props.closeHandler() } } text="Close" /> ]}
        />;
        return <UI.RightPanelContent header={`System Api Key`}
                                     footer={ buttonsBar } >
            <UI.Form errors={ [] }>
                <UI.FormGroup>
                    <UI.LabelValue label={`Name`} value={ this.props.apiKey.name } />
                </UI.FormGroup>
                <UI.FormGroup>
                    <UI.LabelValue label={`Key`} value={ this.props.apiKey.key } />
                </UI.FormGroup>
                <UI.FormGroup>
                    <UI.LabelValue label={`Created By`} value={ this.props.apiKey.createByUsername } />
                </UI.FormGroup>
                <UI.FormGroup>
                    <UI.LabelValue label={`Created At`} value={ this.props.apiKey.createdAt } />
                </UI.FormGroup>
                <UI.FormGroup
                    label={`Enabled`}
                    description={`You can enable and disable this API Key whenever you want. When key is disabled 
                    requests using this key wont be authorized and will fail.`}>
                    <UI.Checkbox
                        defaultValue={ this.props.apiKey.status }
                        checkedValue={ EntityStatusEnum.Enabled }
                        uncheckedValue={ EntityStatusEnum.Disabled }
                        onChange={ this.entityFormHelper.onChangeHandler('status') } />
                </UI.FormGroup>
            </UI.Form>
        </UI.RightPanelContent>;
    }
}