import React from 'react';
import { UI } from 'src/engrator-core';

type Props = {
    warning: string;
};
type State = {};

export class WarningFlag extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <div className={`warning-flag`}>
            <UI.Icon icon="exclamation-triangle" />
            <div className={`text-popup`}>
                { this.props.warning }
            </div>
        </div>;
    }
}
