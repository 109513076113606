import React, {Component} from "react";

import {UI} from 'src/engrator-core';
import {IntegrationThreadPersistedArtifact} from "./integration-thread-persisted-artifact.type";

type Props = {
    persistedArtifact: IntegrationThreadPersistedArtifact
};

export class IntegrationThreadPersistedArtifactInfo extends Component<Props, {}> {
    render() {
        return <div className={`artifact-info`}>
            <UI.NavLink path={`/app/reporting/suite-run/${this.props.persistedArtifact.runId}/flow/${this.props.persistedArtifact.flowId}/details?artifactId=${this.props.persistedArtifact.identifier}&stepIndex=${this.props.persistedArtifact.stepIndex}`}>
                { this.props.persistedArtifact.identifier }
            </UI.NavLink>
        </div>
    }
}