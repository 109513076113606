import React from 'react';
import {UI} from 'src/engrator-core';
import {ContextMenuItem, Icon20, OnContextMenuItemSelected} from 'src/engrator-core/ui';
import {resyncItem} from "./artifacts-versions/resync";
import {IntegrationAccessLevel} from "../integration/integration-suites/details/integration-details-model";

type Props = {
    onSelected: OnContextMenuItemSelected;
    listItem: any;
};

type State = {
    showResync: boolean;
    isLoading: boolean;
    showDone: boolean;
    showMenu: boolean;
};

export function getAvailableContextItems(item: any): ContextMenuItem[] {
    const items: ContextMenuItem[] = [
        {
            id: 'details',
            name: 'Details'
        }
    ];
    if (item.accessLevel === IntegrationAccessLevel.Write) {
        items.push({
            id: 'resync',
            name: <span>Resync<UI.Tooltip
                position={'left'}
                message={`Only recent changes in selected fields will be re-synced.`}
            /></span>
        });
        items.push({
            id: 'hardresync',
            name: <span>Hard Resync<UI.Tooltip
                position={'left'}
                message={`All item fields will be re-synced.`}
            /></span>
        });
    }
    return items;
}

export class SyncedItemsContextMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showResync: false,
            showMenu: true,
            showDone: false,
            isLoading: false
        };
    }

    render() {
        return <div className={`integrations-list-context-menu`}>
            { this.state.showDone && <UI.Icon icon20={ Icon20.CheckGreen } /> }
            { this.state.isLoading &&  <UI.Loader visible={true} appearance={"darkgray"}/> }
            {/*{ this.state.showResync && <ResyncItem*/}
            {/*    suiteId={ this.props.listItem.suiteId }*/}
            {/*    side={ this.props.listItem.triggerSide }*/}
            {/*    itemId={ this.props.listItem.triggeredByDescription }*/}
            {/*/> }*/}
            { this.state.showMenu && <UI.ContextMenu
                getItems={ () => getAvailableContextItems(this.props.listItem) }
                onSelected={ this.onSelectedHandler.bind(this) }
            /> }
        </div>
    }

    private async onSelectedHandler(item: ContextMenuItem) {
        this.props.onSelected(item);
        if (item.id === 'resync' || item.id === 'hardresync') {
            const hardResync = (item.id === 'hardresync');
            const listItem = this.props.listItem;
            await this.setState({ isLoading: true, showMenu: false });
            try {
                await resyncItem(listItem.suiteId, listItem.triggerSide, listItem.triggeredByDescription, hardResync);
                await this.setState({isLoading: false, showDone: true});
                setTimeout(() => {
                    this.setState({showDone: false, showMenu: true});
                }, 2 * 1000);
            } catch (e) {
                await this.setState({isLoading: false, showDone: false, showMenu: true});
            }
        }
    }
}
