import React from 'react';
import { UI } from 'src/engrator-core';
import { ContextMenuItem, OnContextMenuItemSelected } from 'src/engrator-core/ui';
import { createTypeMappingConfiguration, SmartIntDefinitionTypeMapping } from '../../../definition/smart-int-definition--type-mapping.type';
import { clearCopiedConfiguration, getAvailableContextItems, setSelectedContextMenuItem, TypeMappingContextMenuItemsIds } from './context-menu-item';

type Props = {
    onSelected: OnContextMenuItemSelected;
    mapping: SmartIntDefinitionTypeMapping;
    onConfigurationCopied: (configuration: SmartIntDefinitionTypeMapping) => void;
    isLast: boolean;
    isFirst: boolean;
};

type State = {
};

export class TypeMappingContextMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(this.props.mapping) !== JSON.stringify(prevProps.mapping) ||
            this.props.isFirst !== prevProps.isFirst ||
            this.props.isLast !== prevProps.isLast) {
            this.forceUpdate();
        }
    }

    render() {
        return <div>
            <UI.ContextMenu
                getItems={ () => getAvailableContextItems(this.props.isFirst, this.props.isLast, this.props.mapping) }
                onSelected={ this.onSelectedHandler.bind(this) }
            />
        </div>
    }

    private onSelectedHandler(item: ContextMenuItem) {
        if (item.id === TypeMappingContextMenuItemsIds.COPY) {
            const copy = createTypeMappingConfiguration(this.props.mapping);
            setSelectedContextMenuItem(item, copy);
            this.props.onSelected(item);
            this.props.onConfigurationCopied(copy);
        } else {
            setSelectedContextMenuItem(item);
            this.props.onSelected(item);
            clearCopiedConfiguration();
        }
    }
}