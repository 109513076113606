import { axiosWrapper } from 'src/engrator-core';
import { ReSyncSettings } from './re-sync-settings.type';
import {Side} from "../../../../generic";

const ENDPOINT_URL = '/resync';

export function getShouldResyncState(id: number): Promise<ReSyncSettings> {
    return new Promise((resolve, reject) => {
        axiosWrapper.get(ENDPOINT_URL + '/' + id)
            .then((data: any) => resolve(data))
            .catch((err) => reject(err));
    });
}

export function updateShouldResyncState(id: number, reSyncSettings: ReSyncSettings): Promise<void> {
    return new Promise((resolve, reject) => {
        axiosWrapper.put(ENDPOINT_URL + '/' + id, reSyncSettings)
            .then(() => resolve())
            .catch((err) => reject(err));
    });
}

export function resyncItem(suiteId: number, side: Side, itemId: string, hardResync: boolean): Promise<void> {
    const reSyncSettings: ReSyncSettings = {
        resyncFields: (hardResync)? "_ALL_" : "", shouldResync: true
    };
    return new Promise((resolve, reject) => {
        axiosWrapper.put(ENDPOINT_URL + `/item/${suiteId}/${side}/${itemId}` , reSyncSettings)
            .then(() => resolve())
            .catch((err) => reject(err));
    });
}
