import React from 'react';
import { UI } from 'src/engrator-core';
import { DropdownOption } from 'src/engrator-core/ui';
import { AppSmartIntsSupport } from '../../../app-smart-ints-support';
import { SmartIntTrigger } from '../../../definition/smart-int-trigger.type';

export enum RelationshipPickerType {
  Epics,
  Subtasks
};

type Props = {
  label: string;
  appSupport: AppSmartIntsSupport;
  onChange: (value: string) => void;
  defaultValue: string;
  trigger: SmartIntTrigger;
  pickerType: RelationshipPickerType;
};

type State = {
    options?: DropdownOption[];
};

export class RelationshipPicker extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
      if (this.props.pickerType === RelationshipPickerType.Epics) {
        this.props.appSupport.getEpicRelationshipsOptions(this.props.trigger)
        .then((options) => {
          this.setState({options});
        });
      } else if (this.props.pickerType === RelationshipPickerType.Subtasks) {
        this.props.appSupport.getSubtasksRelationshipsOptions(this.props.trigger)
        .then((options) => {
          this.setState({options});
        });
      }
    }

    render() {
        return <React.Fragment>
            { this.state.options === undefined && <UI.Loader visible={true} appearance="darkgray" /> }
            { this.state.options && <UI.Dropdown
                label={ this.props.label }
                isRequired={ true }
                onChange={ (value: string) => this.props.onChange(value) }
                options={ this.state.options }
                defaultValue={ this.props.defaultValue }
            /> }
        </React.Fragment>
    }
}
