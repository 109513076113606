import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { Software } from 'src/generic';
import { createGenericConnectionFactory } from 'src/generic/connections';
import { AuthMethod } from 'src/generic/connections/auth-method.type';
import { PipelineGenericStep } from 'src/generic/pipeline-step';
import { SoftwareName } from '../software-name';

import { GetintArchiverPipelineSteps } from './archiver-pipeline-steps';
import { GetintArchiverSmartintsSupport } from './smart-ints';


export class GetintArchiverSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.GetintArchiver, [AuthMethod.BasicAuth]);
    }

    getName(): string {
        return 'GetintArchiver';
    }

    getID() {
        return 'GetintArchiver';
    }

    getAvailableSteps(): object[] {
        return GetintArchiverPipelineSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }

    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new GetintArchiverSmartintsSupport();
    }
}
