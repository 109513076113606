import React, {ChangeEvent} from 'react';
import {UI} from 'src/engrator-core';
import {Icon20} from "../../../../../engrator-core/ui";

type Props = {
    onChange: (newValue: string, event?: ChangeEvent<HTMLInputElement>) => void;
    defaultValue: string;
};
type State = {
};

export class InlineName extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {  };
    }

    render() {
        return <div className={`inline-name`}>
            <UI.Input defaultValue={ this.props.defaultValue } onChange={ this.props.onChange } placeholder={`Name your integration`} />
        </div>;
    }
}
