import { axiosWrapper } from 'src/engrator-core';
import { SoftwareName } from 'src/software';
import { Formatter } from './formatter.type';

const ENDPOINT_URL = '/integration/smart-ints';

export const fetchFormatters = (fromAppName: SoftwareName, toAppName: SoftwareName): Promise<Formatter[]> => {
    // return Promise.resolve([
    //     { id: 'id', name: 'Markdown to HTML', checked: false },
    //     { id: 'id2', name: 'HTML to MarkDown', checked: false }
    // ]);
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + `/${fromAppName}/formatters/${toAppName}`)
                .then((data: any) => {
                    resolve(data.list);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
