import React from 'react';

import { UI } from 'src/engrator-core';

export enum FieldsMappingNavBarItemName {
    Options = 'Options',
    Formatters = 'Formatters'
};

type Props = {
    selectedItem: FieldsMappingNavBarItemName;
    onChange: (tabItemName: FieldsMappingNavBarItemName) => void;
};

export class NavBar extends React.Component<Props> {
    render() {
        return <React.Fragment>
            <div>
                <UI.TabBar>
                    <UI.TabButton
                        onClick={() => this.props.onChange(FieldsMappingNavBarItemName.Options)}
                        text={FieldsMappingNavBarItemName.Options}
                        isSelected={this.props.selectedItem === FieldsMappingNavBarItemName.Options}/>
                    <UI.TabButton
                        onClick={() => this.props.onChange(FieldsMappingNavBarItemName.Formatters)}
                        text={FieldsMappingNavBarItemName.Formatters}
                        isSelected={this.props.selectedItem === FieldsMappingNavBarItemName.Formatters}/>
                </UI.TabBar>
            </div>
        </React.Fragment>
    }
}
