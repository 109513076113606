import React from 'react';

import './page.css';

type PageProps = {
    title?: string,
    className?: string
};

export class Page extends React.Component<PageProps> {
    render() {
        let className = 'engrator-page ' + ((this.props.className) ? this.props.className : '');
        return <section className={ className }>
            {/*{ this.props.verticalMenu && <React.Fragment>{ this.props.verticalMenu }</React.Fragment> }*/}
            { this.props.title && <h1>{ this.props.title }</h1> }
            { this.props.children }
        </section>
    }
}