import React, { Fragment, Component } from 'react'
import { UI } from 'src/engrator-core';
import {FormSection} from "../../../../../../engrator-core/ui";
import { AdvancedScripting } from '../advanced-configuration';
import Editor from 'react-simple-code-editor';
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/nightOwl'
import {SoftwareName} from "../../../../../../software";
import { CodeEditor } from '../code-editor';

type Props = {
    scripting: AdvancedScripting;
    leftApp?: string;
    rightApp?: string;
};
type State = {
    options: {
        showBeforeRun: boolean;
        showBeforeRequestLeft: boolean;
        showBeforeRequestRight: boolean;
        showStatusTransitionLeft: boolean;
        showStatusTransitionRight: boolean;
        showBeforeCommentLeft: boolean;
        showBeforeCommentRight: boolean;
    }
};

export class Scripting extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            options: {
                showBeforeRun: !!this.props.scripting.onBeforeIntegrationRuns,
                showBeforeRequestLeft: !!this.props.scripting.onBeforeRequestObjectSent.left,
                showBeforeRequestRight: !!this.props.scripting.onBeforeRequestObjectSent.right,
                showStatusTransitionLeft: !!this.props.scripting.onBeforeStatusTransition?.left,
                showStatusTransitionRight: !!this.props.scripting.onBeforeStatusTransition?.right,
                showBeforeCommentLeft: !!this.props.scripting.onBeforeCommentCreated.left,
                showBeforeCommentRight: !!this.props.scripting.onBeforeCommentCreated.right
            }
        }
        if (!this.props.scripting.onBeforeStatusTransition) {
            this.props.scripting.onBeforeStatusTransition = {
                left: '', right: ''
            };
        }
    }

    switchFlag(property: 'showBeforeRun' | 'showBeforeRequestLeft' | 'showBeforeRequestRight' | 'showBeforeCommentLeft' | 'showBeforeCommentRight' | 'showStatusTransitionLeft' | 'showStatusTransitionRight') {
        const options = this.state.options;
        options[property] = !options[property];
        this.setState({ options }, () => {
            if (property === 'showBeforeRun') {
                this.props.scripting.onBeforeIntegrationRuns = '';
            } else if (property === 'showBeforeRequestLeft') {
                this.props.scripting.onBeforeRequestObjectSent.left = '';
            } else if (property === 'showBeforeRequestRight') {
                this.props.scripting.onBeforeRequestObjectSent.right = '';
            } else if (property === 'showStatusTransitionLeft') {
                this.props.scripting.onBeforeStatusTransition.left = '';
            } else if (property === 'showStatusTransitionRight') {
                this.props.scripting.onBeforeStatusTransition.right = '';
            } else if (property === 'showBeforeCommentLeft') {
                this.props.scripting.onBeforeCommentCreated.left = '';
            } else if (property === 'showBeforeCommentRight') {
                this.props.scripting.onBeforeCommentCreated.right = '';
            }
        });
    }

    render() {
        return <UI.Form>
            {/*<FormSection*/}
            {/*    label={``}*/}
            {/*>*/}
                <UI.Message appearance={"info"}>
                    The advanced scripting feature is here to address challenging situations that can't be handled through the usual UI configuration. You can utilize JavaScript syntax to implement your own custom logic. For further details, check out the <UI.DocumentationLink text={`information here`} url={`https://docs.getint.io/getting-started-with-the-platform/how-to-use-getint/integration/advanced-scripting`} />
                </UI.Message>
                <UI.FormGroup
                    label={`Before integration runs`}
                    description={ <UI.Checkbox
                        label={`Perform custom logic just before integration runs`}
                        onChange={() => this.switchFlag('showBeforeRun') }
                        defaultValue={`${ this.state.options.showBeforeRun }`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                    /> }
                >
                    { this.state.options.showBeforeRun && <div className={``}>
                        <CodeEditor
                            defaultValue={ this.props.scripting.onBeforeIntegrationRuns || '' }
                            onChange={ (newValue) => { this.props.scripting.onBeforeIntegrationRuns = newValue } }
                        />
                    </div> }
                </UI.FormGroup>

                <UI.FormGroup
                    label={`Before item request object sent (left app)`}
                    description={ <UI.Checkbox
                        label={`Modify item request object just before it is sent to the left app (${ this.props.leftApp })`}
                        onChange={() => this.switchFlag('showBeforeRequestLeft') }
                        defaultValue={`${ this.state.options.showBeforeRequestLeft }`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                    /> }
                >
                    { this.state.options.showBeforeRequestLeft && <div className={`flex row two-columns`}>
                        <div className={``}>
                            <CodeEditor
                                defaultValue={ this.props.scripting.onBeforeRequestObjectSent.left }
                                onChange={ (newValue) => { this.props.scripting.onBeforeRequestObjectSent.left = newValue } }
                            />
                        </div>
                    </div> }
                </UI.FormGroup>
                <UI.FormGroup
                    label={`Before item request object sent (right app)`}
                    description={ <UI.Checkbox
                        label={`Modify item request object just before it is sent to the right app (${ this.props.rightApp })`}
                        onChange={() => this.switchFlag('showBeforeRequestRight') }
                        defaultValue={`${ this.state.options.showBeforeRequestRight }`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                    /> }
                >
                    { this.state.options.showBeforeRequestRight && <div className={`flex row two-columns`}>
                        <div className={``}>
                            <CodeEditor
                                defaultValue={ this.props.scripting.onBeforeRequestObjectSent.right }
                                onChange={ (newValue) => { this.props.scripting.onBeforeRequestObjectSent.right = newValue } }
                            />
                        </div>
                    </div> }
                </UI.FormGroup>

                <UI.FormGroup
                    label={`Before status transition request sent (left app)`}
                    description={ <UI.Checkbox
                        label={`Modify status transition request object just before it is sent to the left app (${ this.props.leftApp })`}
                        onChange={() => this.switchFlag('showStatusTransitionLeft') }
                        defaultValue={`${ this.state.options.showStatusTransitionLeft }`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                    /> }
                >
                    { this.state.options.showStatusTransitionLeft && <div className={`flex row two-columns`}>
                        <div className={``}>
                            <CodeEditor
                                defaultValue={ this.props.scripting.onBeforeStatusTransition.left }
                                onChange={ (newValue) => { this.props.scripting.onBeforeStatusTransition.left = newValue } }
                            />
                        </div>
                    </div> }
                </UI.FormGroup>
                <UI.FormGroup
                    label={`Before status transition request sent (right app)`}
                    description={ <UI.Checkbox
                        label={`Modify status transition request object just before it is sent to the right app (${ this.props.rightApp })`}
                        onChange={() => this.switchFlag('showStatusTransitionRight') }
                        defaultValue={`${ this.state.options.showStatusTransitionRight }`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                    /> }
                >
                    { this.state.options.showStatusTransitionRight && <div className={`flex row two-columns`}>
                        <div className={``}>
                            <CodeEditor
                                defaultValue={ this.props.scripting.onBeforeStatusTransition.right }
                                onChange={ (newValue) => { this.props.scripting.onBeforeStatusTransition.right = newValue } }
                            />
                        </div>
                    </div> }
                </UI.FormGroup>

                <UI.FormGroup
                    label={`Before comment created (left app)`}
                    description={ <UI.Checkbox
                        label={`Modify comment content before it is created in left app (${ this.props.leftApp })`}
                        onChange={() => this.switchFlag('showBeforeCommentLeft') }
                        defaultValue={`${ this.state.options.showBeforeCommentLeft }`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                    /> }
                >
                    { this.state.options.showBeforeCommentLeft && <div className={`flex row two-columns`}>
                        <div className={``}>
                            <CodeEditor
                                defaultValue={ this.props.scripting.onBeforeCommentCreated?.left || '' }
                                onChange={ (newValue) => { this.props.scripting.onBeforeCommentCreated.left = newValue } }
                            />
                        </div>
                    </div> }
                </UI.FormGroup>

                <UI.FormGroup
                    label={`Before comment created (right app)`}
                    description={ <UI.Checkbox
                        label={`Modify comment content before it is created in right app (${ this.props.rightApp })`}
                        onChange={() => this.switchFlag('showBeforeCommentRight') }
                        defaultValue={`${ this.state.options.showBeforeCommentRight }`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                    /> }
                >
                    { this.state.options.showBeforeCommentRight && <div className={`flex row two-columns`}>
                        <div className={``}>
                            <CodeEditor
                                defaultValue={ this.props.scripting.onBeforeCommentCreated?.right || '' }
                                onChange={ (newValue) => { this.props.scripting.onBeforeCommentCreated.right = newValue } }
                            />
                        </div>
                    </div> }
                </UI.FormGroup>
            {/*</FormSection>*/}
        </UI.Form>;
    }
}


