import { saveIntegration } from '../../../../../designer/container/integration-persistence';

export type SmartIntToDuplicate = {
    id: number;
}

export function createDuplicatedIntegration(integration: any, callback: any) {
    setTimeout(() => {
        saveIntegration(undefined, integration)
            .then((integrationId) => {
                callback(integrationId);
            }).catch(error => {
            callback(0, error);
        });
    }, 300);
}
