import React from 'react';
import { UI } from 'src/engrator-core';
import { ContextMenuItem, OnContextMenuItemSelected } from 'src/engrator-core/ui';

type Props = {
    onSelected: OnContextMenuItemSelected;
};

type State = {
};

export function getAvailableContextItems(): ContextMenuItem[] {
    const items = [
        {
            id: 'Edit',
            name: 'Edit',
        },
        {
            id: 'ChangePassword',
            name: 'Change password',
        }
    ];
    return items;
}

export class ClusterUsersContextMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div className={`integrations-list-context-menu`}>
            <UI.ContextMenu
                getItems={ () => getAvailableContextItems() }
                onSelected={ this.onSelectedHandler.bind(this) }
            />
        </div>
    }

    private onSelectedHandler(item: ContextMenuItem) {
        this.props.onSelected(item);
    }
}
