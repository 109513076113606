import React, {SyntheticEvent} from 'react';
import {SmartIntDirection} from "../../definition/smart-int-direction.type";

import arrowLeft from './arrow_l.png';
import arrowRight from './arrow_r.png';

type Props = {
    default?: SmartIntDirection;
    onChange: (newDirection: SmartIntDirection) => void;
};
type State = {
    current: SmartIntDirection;
};

export class DirectionPicker extends React.Component<Props, State> {
    private options: SmartIntDirection[];

    constructor(props: Props) {
        super(props);
        this.options = [
            SmartIntDirection.Both,
            SmartIntDirection.ToRight,
            SmartIntDirection.ToLeft
        ];
        this.state = { current: (this.props.default) ? this.props.default : SmartIntDirection.Both };
    }

    render() {
        return <div className={`direction-picker`}>
            <div className={`dir-btn ${ (this.state.current === SmartIntDirection.ToLeft) ? 'selected' : '' }`} onClick={ (event) => this.changeOption(SmartIntDirection.ToLeft, event) }><img src={ arrowLeft } /> </div>
            <div className={`dir-btn ${ (this.state.current === SmartIntDirection.Both) ? 'selected' : '' }`} onClick={ (event) => this.changeOption(SmartIntDirection.Both, event) }><img src={ arrowLeft } /> <img src={ arrowRight } /> </div>
            <div className={`dir-btn ${ (this.state.current === SmartIntDirection.ToRight) ? 'selected' : '' }`} onClick={ (event) => this.changeOption(SmartIntDirection.ToRight, event) }><img src={ arrowRight } /> </div>
        </div>;
    }

    private changeOption(newDirection: SmartIntDirection, event: SyntheticEvent) {
        event.stopPropagation();
        const currentIndex = this.options.indexOf(this.state.current);
        const nextIndex = ((currentIndex + 1) % this.options.length);
        this.setState({ current: newDirection }, () => {
            this.props.onChange(newDirection);
        });
    }
}
