import React from 'react';
import {PerformedFlow} from "./run-results/performed-flow";
import {UI} from 'src/engrator-core';
import {Icon20} from "../../engrator-core/ui";

export type SubItemData = {
    subItemType: 'Comment' | 'Attachment' | 'RelationLink';
    leftId: string;
    rightId: string;
};

export enum SubItemsMode {
    NotWarnings = 'NotWarnings',
    Warnings = 'Warnings',
    All = 'All'
}

export type SubItemsData = {
    commentsCount: number;
    attachmentsCount: number;
    linksCount: number;
    subItems: SubItemData[];
};

type Props = {
    subItemsData: SubItemsData;
    performedFlow: PerformedFlow;
    mode?: SubItemsMode;
};

type State = {
    mode: SubItemsMode;
}

export class SubItems extends React.Component<Props,  State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            mode: this.props.mode || SubItemsMode.All
        };
    }

    render() {
        if (!this.props.subItemsData) {
            return <span />;
        }
        const elements = [];
        if (this.props.mode === SubItemsMode.All || this.props.mode === SubItemsMode.Warnings) {
            elements.push(...this.buildItemsForWarning());
        }
        if (this.props.mode === SubItemsMode.All || this.props.mode === SubItemsMode.NotWarnings) {
            elements.push(...this.buildNotWarningItems());
        }
        if (elements.length === 0) {
            return <span></span>;
        }
        return <ul className={`subitems-data`}>
            { elements }
        </ul>;
    }

    private buildItemsForWarning() {
        if (this.props.mode !== SubItemsMode.All) {
            // If its on a table, just show cumulated warnings number
            const warningsCount = (this.props.performedFlow?.warningLogsCount || 0)
                + (this.props.performedFlow?.missingOptionsCount || 0);
            if (warningsCount === 0) {
                return [];
            }
            return [
                <span className={`subitem-tag warn`}><UI.Icon icon={'exclamation-triangle'} /> { warningsCount }</span>
            ];
        }
        return [
            this.props.performedFlow?.warningLogsCount > 0 ? <span className={`subitem-tag warn`}><UI.Icon icon={'exclamation-triangle'} /> LOGS: { this.props.performedFlow.warningLogsCount }</span> : '',
            this.props.performedFlow?.missingOptionsCount > 0 ? <span className={`subitem-tag warn`}><UI.Icon icon={'exclamation-triangle'} /> MAPPINGS: { this.props.performedFlow.missingOptionsCount }</span> : ''
        ];
    }

    private buildNotWarningItems() {
        // this.props.subItemsData.linksCount = 10;
        return [
            this.props.subItemsData.commentsCount > 0 ? <span className={`subitem-tag`} data-sel={`comments`}><UI.Icon icon20={Icon20.Comments} /> { this.props.subItemsData.commentsCount }</span> : '',
            this.props.subItemsData.attachmentsCount > 0 ? <span className={`subitem-tag`} data-sel={`attachments`}><UI.Icon icon20={Icon20.Files} /> { this.props.subItemsData.attachmentsCount }</span> : '',
            this.props.subItemsData.linksCount > 0 ? <span className={`subitem-tag`} data-sel={`links`}><UI.Icon icon20={Icon20.Relationship} /> { this.props.subItemsData.linksCount }</span> : ''
        ];
    }
}
