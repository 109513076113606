import React from 'react';
import { UI } from 'src/engrator-core';
import {PropertyDefinition} from "../artifacts";
import {ConfiguredProperty} from "../artifacts/data-artifact";
import {DropdownOption} from "../../engrator-core/ui";

type OtherArtifactPropertyPickerProps = {
    artifacts: Array<any>,
    otherArtifactPickerChangeHandler: any
}

type State = {
    selectedArtifactId: string;
}

export default class OtherArtifactPropertyPicker extends React.Component<OtherArtifactPropertyPickerProps, State> {
    schema: any;
    private artifactsOptions: DropdownOption[];
    private properties: DropdownOption[];

    constructor(props: OtherArtifactPropertyPickerProps) {
        super(props);
        this.artifactsOptions = props.artifacts.map((artifact: any) => ({
            value: artifact.id,
            label: artifact.id
        } as DropdownOption));
        this.properties = [];
        this.state = { selectedArtifactId: '' };
        const properties = (oldValue: any, newValue: any) => {
            if (newValue === '') {
                return [];
            }
            const propertyDefinitions: Array<ConfiguredProperty> = props.artifacts.filter((artifact: any) => artifact.id === newValue)[0].propertyDefinitions;
            const options = propertyDefinitions.map((propertyDefinition: ConfiguredProperty) => ({
                value: propertyDefinition.property.id,
                label: propertyDefinition.property.name
            }));
            return {propertyId: {element: 'dropdown', optionsSource: () => Promise.resolve(options), label: ''}};
        };
        // this.schema = new UI.FormSchema({
        //     artifactId: {element: 'dropdown', optionsSource: artifactsSource, label: '', subElements: properties},    
        // });
    }

    onFormChangeHandler(elementThatChanged: any, formData: any) {
        // if (formData.artifactId && formData.propertyId) {
            this.props.otherArtifactPickerChangeHandler(formData.artifactId, formData.propertyId);
        // }
    }

    render() {
        return <div>
            <UI.Dropdown
                label=""
                options={ this.artifactsOptions }
                onChange={ this.onArtifactSelected.bind(this) }
            />
            { this.state.selectedArtifactId && <UI.Dropdown
                label=""
                options={ this.properties }
                onChange={ this.onPropertySelected.bind(this) }
            /> }
            {/* <UI.Form onFormChange={ this.onFormChangeHandler.bind(this) } schema={ this.schema } /> */}
        </div>
    }

    private onArtifactSelected(artifactId: string) {
        const propertyDefinitions = this.props.artifacts.filter((artifact: any) => artifact.id === artifactId)[0].propertyDefinitions;
        this.properties = propertyDefinitions.map((propertyDefinition: ConfiguredProperty) => ({
            value: propertyDefinition.property.id,
            label: propertyDefinition.property.name
        }));
        this.props.otherArtifactPickerChangeHandler(artifactId, '');
        this.setState({ selectedArtifactId: artifactId });
    }

    private onPropertySelected(propertyId: string) {
        this.props.otherArtifactPickerChangeHandler(this.state.selectedArtifactId, propertyId);
    }
}