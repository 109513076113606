import React from 'react';
import {NewPipelineStepComponentProps} from "src/generic";
import {UI} from 'src/engrator-core';
import {AzureSearchItemsConfiguration} from "./azure-search-items-configuration";
import {IntegrationStepConfiguration} from "src/generic/software/step";
import {AzureProjectItemTypePicker, AzureProjectItemTypePickerData} from "../common";
import {ArtifactDirection} from "../../../../generic/artifacts";
import {AzureProjectItemTypeDisplay} from "../common/azure-project-item-type-display";

type Props = {
} & NewPipelineStepComponentProps;
type State = {
    formError: { [key: string]: string },
    canConfigureArtifact: boolean,
    isLoadingJql: boolean,
    isAdding: boolean;
    isSetUp: boolean;
};

export class AzureSearchItemsNewStep extends React.Component<Props, State> {
    private configuration: AzureSearchItemsConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as AzureSearchItemsConfiguration;
        this.configuration.onlyNewChanges = true;
        this.state = {
            isSetUp: false,
            isLoadingJql: false,
            formError: {},
            canConfigureArtifact: false,
            isAdding: false
        };
    }

    onFormChange(elementThatChanged: 'customWIQL', newValue: string) {
        this.configuration[elementThatChanged] = newValue;
    }

    private onConfigurationChanged(data: AzureProjectItemTypePickerData) {
        this.configuration.project = data.project!;
        this.configuration.itemType = data.itemType!;
        if (this.configuration.project && this.configuration.itemType) {
            this.setState({ isSetUp: true }, () => {
                this.props.showArtifactBuilder(ArtifactDirection.Out);
            });
        }
    }

    render() {
        return <div className={`connectors-azure-search-items-step`}>
            { this.state.isSetUp && <AzureProjectItemTypeDisplay context={ this.configuration } /> }
            { !this.state.isSetUp && <AzureProjectItemTypePicker connectionId={ this.props.context.connectionId}
                                        onDataChanged={ this.onConfigurationChanged.bind(this) }
                                        formError={ this.props.formError} /> }
            <UI.FormGroup
                label={`Changed from last integration`}
                description={`If checked, only items that changed since last integration will be picked up.`}
            >
                <UI.Checkbox defaultValue={"true"} onChange={ this.onlyNewChanged.bind(this) } checkedValue={ "true" } uncheckedValue={ "false "}/>
            </UI.FormGroup>
        </div>
    }

    onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }
};
