import React from 'react';

import {EditPipelineStepComponentProps} from "src/generic";
import {UI} from "src/engrator-core";
import {ArtifactDirection} from "src/generic/artifacts";
import {InthubNewErrorConfiguration} from "./inthub-new-error-configuration";

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class IntHubNewErrorEditStep extends React.Component<Props, State> {
    private configuration: InthubNewErrorConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as InthubNewErrorConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
        </React.Fragment>;
    }
};