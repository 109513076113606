import React from 'react';
import {getUsageInfo} from './rest-api';
import {MigrationUsageInfoResponse, MigrationUsageState} from './migration-usage-info-response';
import {UI} from 'src/engrator-core';

type Props = {};

type State = {
    response?: MigrationUsageInfoResponse;
};

const ContactSupport = () => <span>Migration is a premium feature. Contact us via chat or email <a
    href={`mailto:support@getint.io`}>support@getint.io</a></span>

export class MigrationUsageInfoBox extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
        getUsageInfo().then((response) => {
            this.setState({ response });
        });
    }

    render() {
        if (!this.state.response || this.state.response.state === MigrationUsageState.FeatureEnabled) {
            return <React.Fragment/>
        }
        if (this.state.response.state === MigrationUsageState.HasRunsLeft) {
            return <UI.Message appearance={'warning'}>
                {this.state.response?.message}. <ContactSupport/>
            </UI.Message>
        } else if (this.state.response.state === MigrationUsageState.Invalid) {
            return <UI.Message appearance={'error'}>
                {this.state.response?.message}. <ContactSupport/>
            </UI.Message>
        }
        return <React.Fragment>

        </React.Fragment>;
    }
}
