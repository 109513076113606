import { Software } from 'src/generic';
import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { createGenericConnectionFactory } from 'src/generic/connections';
import { SoftwareName } from '../software-name';
import { AuthMethod } from 'src/generic/connections/auth-method.type';
import { PipelineGenericStep } from 'src/generic/pipeline-step';

import { AzurePipelineSteps } from './azure-pipeline-steps';
import { AzureSmartintsSupport } from './smart-ints';

export class AzureSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.Azure, [AuthMethod.BasicAuth])
    }

    getName(): string {
        return 'Azure';
    }

    getID() {
        return 'Azure';
    }
    getAvailableSteps(): object[] {
        return AzurePipelineSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }
    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new AzureSmartintsSupport();
    }
}
