import React, {Component, KeyboardEvent} from 'react';

import {FieldError, isInIframeApp, UI} from 'src/engrator-core';
import {LoginData, signIn} from './rest-api';
import {AuthMainFrame} from "./auth-main-frame";
import {acquireT, fetchMsGraph, msalApp} from "./azureAd";

type SignInPageState = {
    error?: FieldError,
    isLoading: boolean,
    workspaces?: string[];
};

export class SignInPage extends Component<{}, SignInPageState> {
    state: SignInPageState = {
        error: undefined,
        isLoading: false,
    };
    loginData: LoginData = {
        username: '',
        password: ''
    };

    changeProperty(propertyName: 'username' | 'password', newValue: string) {
        this.loginData[propertyName] = newValue;
        this.forceUpdate();
    }

    async componentDidMount() {
        document.getElementsByTagName('html')[0].className = 'sign-in-module';
        if (msalApp.getAccount()) {
            const t = await acquireT(msalApp.getAccount());
            console.log(msalApp.getAccount());
            // console.log(t?.idToken.);
            await fetchMsGraph('https://graph.microsoft.com/v1.0/me', t!.idToken.rawIdToken);
        }
    }

    onSignInClick() {
        this.setState({isLoading: true, error: undefined}, () => {
            signIn(this.loginData)
                .then((workspaces) => {
                    this.setState({isLoading: false, error: undefined, workspaces});
                })
                .catch((error: any) => {
                    this.setState({isLoading: false, error});
                });
        });
    }

    handleSignInOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        if(!this.loginData.password || !this.loginData.username) {
            return;
        };
        if(event.key === 'Enter') {
            this.onSignInClick();
        }
    }

    render() {
        if (isInIframeApp()) {
            // return <div className={`in-iframe-apps-need-refresh`}>
            //     <p>Your session has expired. Please refresh the page to sign in again.</p>
            // </div>
            return <div className={`in-iframe-apps-need-refresh`}>
                <UI.Message appearance={"warning"}>Your session has expired. Please refresh the page to sign in again.</UI.Message>
            </div>;
        }
        return (
            <AuthMainFrame>
                <UI.Form errors={[]} error={this.state.error?.message}>
                    <UI.FormGroup>
                        <UI.Input defaultValue={this.loginData.username}
                                  label="" placeholder="Username"
                                  isRequired={true}
                                  onChange={(value: string) => this.changeProperty('username', value)}
                                  onKeyDown={(event) => this.handleSignInOnKeyDown(event)}/>
                    </UI.FormGroup>
                    <UI.FormGroup>
                        <UI.Input
                            type={`password`}
                            isRequired={true}
                            defaultValue={this.loginData.password}
                            label="" placeholder="Password"
                            onKeyDown={(event) => this.handleSignInOnKeyDown(event)}
                            onChange={(value: string) => this.changeProperty('password', value)}/>
                    </UI.FormGroup>
                    <UI.Button
                        className={`sign-in-btn`}
                        disabled={!this.loginData.username || !this.loginData.password}
                        isLoading={this.state.isLoading}
                        text="Sign In"
                        appearance={'elementary'}
                        onClick={() => this.onSignInClick()}/>
                    {/*<UI.Button text={`Microsoft SSO`} onClick={() => this.sa()}/>*/}
                </UI.Form>
            </AuthMainFrame>
        )
    }

    private sa() {

    }
}
