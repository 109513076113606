import React, {Component, useEffect} from 'react';
import { FieldError, UI } from 'src/engrator-core';
import { FormError } from '../../../engrator-core/form-error.type';
import { Footer } from '../../footer';

import {LogsViewer} from "../../../engrator-core/ui";
import {fetchSystemLogs} from "./rest-api";
import {useGlobalState} from "../../global-store";
import {getSystemVerticalMenu} from "../system-vertical-menu";
import {MainMenuItem} from "../../global-store/create-global-state";

type State = {
    isLoading: boolean;
    error?: FormError;
    logs: string;
};

class SystemLogsPageCmp extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: true,
            logs: ''
        };
    }

    componentDidMount(): void {
        fetchSystemLogs().then(logs => {
            this.setState({ logs, isLoading: false });
        }).catch((err) => {
            this.setState({ logs: '', isLoading: false });
        });
    }

    render() {
        return (
            <UI.Page>
                { this.state.isLoading && <UI.Loader visible={true} appearance={'darkgray'}/>}
                { !this.state.isLoading && <React.Fragment>
                    <LogsViewer logs={ this.state.logs } />
                </React.Fragment> }
                <Footer/>
            </UI.Page>
        );
    }
}

export function SystemLogsPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        setMainMenuItem(MainMenuItem.SystemSettings);
        setMenu(getSystemVerticalMenu());
    }, []);
    return <SystemLogsPageCmp />
}