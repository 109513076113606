import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";

import {AzureStepKey} from "../azure-step-key.type";
import {AzureStatusTransitionEditStep} from "./azure-status-transition-edit-step";
import {AzureStatusTransitionNewStep} from "./azure-status-transition-new-step";
import {AzureStatusTransitionConfiguration} from "./azure-status-transition-configuration";

export const AzureStatusTransitionPipelineStep: PipelineStep<AzureStatusTransitionConfiguration> = {
    addStepComponentClass: AzureStatusTransitionNewStep,
    editStepComponentClass: AzureStatusTransitionEditStep,
    specification: {
        action: "Change item status",
        description: "Move Azure item from one status to another",
        key: AzureStepKey.AzureStatusTransition,
        software: SoftwareName.Azure,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AzureStatusTransitionConfiguration;
        return `Change item status`;
    }
}