import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { SoftwareName } from '../../software-name';
import { MondayStepContext, MondayStepContextData } from '../steps/common';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class MondaySmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.Monday,
            artifactName: '',
            className: '',
            configuration: {
                board: undefined,
                query: '',
            },
            connectionId: this.props.connectionId,
        };
    }

    render() {
        return <React.Fragment>
            {!this.smartIntTrigger.configuration.project && <MondayStepContext
				connectionId={this.props.connectionId}
				onDataChanged={this.onDataChanged.bind(this)}
			/>}
            {this.smartIntTrigger.configuration.board && <UI.FormGroup>
				<UI.LabelValue
					label={`Board`}
					value={this.smartIntTrigger.configuration.board.name}
				/>
			</UI.FormGroup>
            }
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private onDataChanged(data: MondayStepContextData) {
        if (data.board) {
            this.smartIntTrigger.configuration.board = data.board;
            this.smartIntTrigger.artifactName = 'task';
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }
}
