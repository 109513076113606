import React from 'react';
import {Icon, Icon20} from "./icon";

type Props = {
    className?: string;
    message: string;
    position?: 'left' | 'right';
    icon?: Icon20;
};
type State = {
    show: boolean;
}

export class Tooltip extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {show: false};
    }

    render() {
        let additionalClassName = (this.props.className) ? this.props.className : '';
        if (this.props.position) {
            additionalClassName += ' position-' + this.props.position;
        }
        return <div className={`engrator-ui-tooltip ${additionalClassName}`}
        >
            <div
                className={`hover-trigger`}
                onMouseOver={ () => this.switchVisibility(true) }
                onMouseOut={ () => this.switchVisibility(false) }
            >
                { !this.props.icon && <Icon icon20={ Icon20.Info } /> }
                { this.props.icon && <Icon icon20={ this.props.icon } /> }
                { this.state.show && <div className={`msg`}>
                    { this.props.message }
                </div> }
            </div>
        </div>;
    }

    private switchVisibility(show: boolean): void {
        this.setState({ show });
    }
}