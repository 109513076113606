import React from 'react';
import { UI } from 'src/engrator-core';

type Props = {};
type State = {};

export class InvalidLicenseWarningBar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return <div className={`invalid-license-warning-bar`}>
            <UI.WarningBar appearance={'danger'}>
                Invalid license was detected for this integration. Sync may stop due to license issues.
            </UI.WarningBar>
        </div>;
    }
}
