import React from 'react';

import {AsanaFindItemConfiguration} from "./asana-find-item-configuration";
import {EditPipelineStepComponentProps} from "src/generic/pipeline-step";
import {UI} from "../../../../engrator-core";
import {AsanaWorkspaceProjectDisplay} from "../common";
import {ArtifactDirection} from "../../../../generic/artifacts";

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class AsanaFindItemEditStep extends React.Component<Props, State> {
    private configuration: AsanaFindItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as AsanaFindItemConfiguration;
    }

    componentDidMount() {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <AsanaWorkspaceProjectDisplay configuration={ this.configuration } />
            <UI.LabelValue label={`Item Key`} value={ this.configuration.id } />
        </React.Fragment>;
    }
};