import {VerticalMenu, VerticalMenuItem} from "src/app/global-store/create-global-state";
import {Icon20} from "../../../../engrator-core/ui";

export function getIntegrationVerticalMenu(integrationId: number): VerticalMenu {
    return {
        header: 'Workflows',
        items: populateVerticalMenu(integrationId)
    }
}

function populateVerticalMenu(integrationId: number) {
    let items: VerticalMenuItem[] = [
        {
            name: 'Editor',
            path: `/app/integration/smart-ints/${integrationId}/edit`,
            icon: Icon20.Edit,
        },
        {
            name: 'Changes history',
            path: `/app/integration/smart-ints/${integrationId}/changes`,
            icon: Icon20.Archiver,
        },
        {
            name: 'Migrate data',
            path: `/app/integration/smart-ints/${integrationId}/migration`,
            icon: Icon20.Upload,
        },
        {
            name: 'Latest synced items',
            path: `/app/integration/smart-ints/${integrationId}/synced-items`,
        },
        {
            name: 'Latest runs',
            path: `/app/integration/smart-ints/${integrationId}/runs`,
        },
    ]
    return items;
}