import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from './button';

type TabButtonProps = {
    text: string,
    path?: string,
    onClick?: () => void;
    icon?: any;
    isSelected: boolean
};

export class TabButton extends React.Component<TabButtonProps> {
    render() {
        const className = (this.props.isSelected) ? 'selected' : '';
        const dataSelValue = this.getDataSelValue(this.props.text);
        return <div data-sel={dataSelValue} className={`engrator-tab-button ${className}`}>
            {this.props.path && <NavLink to={this.props.path}>{this.props.text}</NavLink>}
            {this.props.onClick &&
			<Button icon={this.props.icon} appearance={'tab'} onClick={this.props.onClick} text={this.props.text}/>}
        </div>;
    }

    getDataSelValue(text: string): string {
        return text.toLowerCase()
            .replace(new RegExp(' ', 'gi'), '-');
    }
}
