import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";

import {JiraStepKey} from "../jira-step-key.type";
import {JiraUpdateIssueEditStep} from "./jira-update-issue-edit-step";
import {JiraUpdateIssueNewStep} from "./jira-update-issue-new-step";
import {JiraUpdateIssueConfiguration} from "./jira-update-issue-configuration";

export const JiraUpdateIssuePipelineStep: PipelineStep<JiraUpdateIssueConfiguration> = {
    addStepComponentClass: JiraUpdateIssueNewStep,
    editStepComponentClass: JiraUpdateIssueEditStep,
    specification: {
        action: "Update issue",
        description: "Update issue",
        key: JiraStepKey.JiraUpdateItem,
        software: SoftwareName.Jira,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `updatedIssue`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as JiraUpdateIssueConfiguration;
        return `Update ${stepConfiguration.issueType.name} in ${stepConfiguration.project.name}`;
    }
}