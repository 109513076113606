import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {ZendeskStepKey} from "../zendesk-step-key.type";
import {ZendeskCreateCcommentsNewStep} from "./zendesk-create-comments-new-step";
import {ZendeskCreateCommentsEditStep} from "./zendesk-create-comments-edit-step";
import {ZendeskCreateCommentsConfiguration} from "./zendesk-create-comments-configuration";


export const ZendeskCreateCommentsPipelineStep: PipelineStep<ZendeskCreateCommentsConfiguration> = {
    addStepComponentClass: ZendeskCreateCcommentsNewStep,
    editStepComponentClass: ZendeskCreateCommentsEditStep,
    specification: {
        action: "Create Zendesk Comment(s)",
        description: "Create comment(s) for selected ticket",
        key: ZendeskStepKey.ZendeskCreateComments,
        software: SoftwareName.Zendesk,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `ZendeskCard`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ZendeskCreateCommentsConfiguration;
        return `Create comment(s) for ticket '${stepConfiguration.targetArtifact}'`;
    }
};