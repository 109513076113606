import { Software } from 'src/generic';

import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { createGenericConnectionFactory } from 'src/generic/connections';
import { SoftwareName } from '../software-name';
import { AuthMethod } from 'src/generic/connections/auth-method.type';
import { PipelineGenericStep } from 'src/generic/pipeline-step';

import { AsanaPipelineSteps } from './asana-pipeline-steps';
import { AsanaSmartintsSupport } from './smart-ints';

export class AsanaSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.Asana, [AuthMethod.BearerToken])
    }

    getName(): string {
        return 'Asana';
    }

    getID() {
        return 'Asana';
    }
    getAvailableSteps(): object[] {
        return AsanaPipelineSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }
    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new AsanaSmartintsSupport();
    }
}
