import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {AzureStepKey} from "../azure-step-key.type";
import {AzureCreateCcommentsNewStep} from "./azure-create-comments-new-step";
import {AzureCreateCommentsEditStep} from "./azure-create-comments-edit-step";
import {AzureCreateCommentsConfiguration} from "./azure-create-comments-configuration";


export const AzureCreateCommentsPipelineStep: PipelineStep<AzureCreateCommentsConfiguration> = {
    addStepComponentClass: AzureCreateCcommentsNewStep,
    editStepComponentClass: AzureCreateCommentsEditStep,
    specification: {
        action: "Create Azure Item Comment(s)",
        description: "Create comment(s) for selected item",
        key: AzureStepKey.AzureCreateComments,
        software: SoftwareName.Azure,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `AzureCard`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AzureCreateCommentsConfiguration;
        return `Create comment(s) for item '${stepConfiguration.targetArtifact}'`;
    }
};