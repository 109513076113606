import {AuthResponse, InteractionRequiredAuthError, UserAgentApplication} from 'msal';

export const requiresInteraction = (errorMessage: any) => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf('consent_required') > -1 ||
        errorMessage.indexOf('interaction_required') > -1 ||
        errorMessage.indexOf('login_required') > -1
    );
};

export const fetchMsGraph = async (url: any, accessToken: any) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    return response.json();
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ') > -1;
    const msie11 = ua.indexOf('Trident/') > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const AUTH_SCOPES = {
    OPENID: 'openid',
    OFFLINE_ACCESS: 'offline_access',
    PROFILE: 'profile'
};

export const AUTH_REQUESTS = {
    LOGIN: {
        scopes: [AUTH_SCOPES.PROFILE],
    },
    EMAIL: {
        scopes: [],
    },
    REFRESH_TOKEN: {
        scopes: [process.env.REACT_APP_CLIENT_ID],
    },
};

export const msalApp = new UserAgentApplication({
    auth: {
        clientId: 'd34c1c56-c2a3-467a-8715-4721cc9f6913',
        authority: 'https://login.microsoftonline.com/fac2453b-bcb4-43d6-b580-021f625bb33b',
        validateAuthority: process.env.REACT_APP_VALIDATE_AUTHORITY === 'true',
        redirectUri: window.location.href,
        postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
        navigateToLoginRequestUrl: process.env.REACT_APP_NAVIGATE_TO_LOGIN_REQUEST_URL === 'true',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: isIE(),
    },
    system: {
        navigateFrameWait: 0,
    },
});

export async function acquireT(account: any): Promise<AuthResponse | undefined> {
    const request = {
        scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE],
        account
    };
    try {
        return await msalApp.acquireTokenSilent(request);
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            msalApp.acquireTokenRedirect(request);
        }
        console.log(error);
        return undefined;
    }
}

export async function azureAdSignIn(redirect?: any) {
    if (redirect) {
        return msalApp.loginRedirect({
            scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE],
            extraQueryParameters: {
                ui_locales: localStorage.getItem('language') ?? 'sv',
            },
        });
    }

    await msalApp.loginRedirect({
        scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE],
        extraQueryParameters: {
            ui_locales: localStorage.getItem('language') ?? 'sv',
        },
    });

    console.log(msalApp.getAccount());

    // if (loginResponse) {
    //     this.setState({
    //         account: loginResponse.account,
    //         isAuthenticated: true,
    //         error: null,
    //     });
    // }
}
