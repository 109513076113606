import React, { Component } from 'react';

import { FieldError, UI } from 'src/engrator-core';
import { fetchOptions, handleUpdateSharedMapping, SharedMapping, SharedMappingListItem, UpdateSharedMapping } from '../rest-api';
import { AddValueMapping } from '../../smartints/visual/components/value-mappings/add-value-mapping';
import { ValueMappingsGroupContainer } from '../../smartints/visual/components/value-mappings/value-mappings-group-container';
import { DropdownOption, Icon20 } from 'src/engrator-core/ui';
import {
    areSomeMappingsCopied,
    copyMappedOptions,
    reuseCopiedOptions
} from '../../smartints/configuration/sharing/mappings-copy';
import { ValueMappingsGroup } from '../../smartints/visual/components/value-mappings/value-mappings-group.type';
import {
    AddMappingOptions,
    getEmptyAdditionalData,
    PropertyValue,
    ValueMapping
} from '../../smartints/visual/components/value-mappings';
import {
    createValueMappingsGroups,
    getValueMappingForAnotherOption
} from '../../smartints/visual/components/value-mappings/value-mappings-group-factory';

import { EmptyHandler } from '../../smartints/visual/components/value-mappings/empty';
import { DefaultsPropertyMapping, PropertyMapping } from '../../smartints/visual/property-mapping.type';
import { DefaultMapping, DefaultMappingMode } from '../../smartints/visual/components/value-mappings/default-mappping.type';
import { SmartIntTrigger } from '../../smartints/definition/smart-int-trigger.type';
import { AppsSupport } from '../../smartints/visual/visual-integration-designer';
import { isPropertySelectable } from 'src/generic/artifacts';
import { fetchValueMappingsOptions } from '../../smartints/visual/components/value-mappings/value-mappings-options-fetcher';
import { SmartIntDefinitionTypeMapping } from '../../smartints/definition/smart-int-definition--type-mapping.type';
import { SharingAutoMapping } from '../../smartints/configuration/sharing';
import { AutoMappedOptions } from '../../smartints/configuration/sharing/automap-options';
import { Route, RouteComponentProps } from 'react-router-dom';
import {SharedMappingsAutoCreationConfiguration} from "./shared-mappings-auto-creation-configuration";

type Props = {
    closeHandler: (refresh: boolean) => void;
    onMappingsChangedHandler: (groups: ValueMappingsGroup[], defaultMappings?: DefaultsPropertyMapping) => void;
    valueConfigurationHandler?: (group: ValueMappingsGroup) => void;
    sharedMappingToEdit: SharedMappingListItem;
    groups: ValueMappingsGroup[];
    appsSupport: AppsSupport;
    mapping: PropertyMapping;
    defaults: { left: DefaultMapping; right: DefaultMapping };
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
    empty: { left: string; right: string };
    typeMapping: SmartIntDefinitionTypeMapping;
    depth: number;
};
type State = {
    error?: FieldError;
    selectedSharedMapping: SharedMapping;
    groups: ValueMappingsGroup[];
    isLoadingOptions: boolean;
    areSomeOptionsCopied: boolean;
    addOptionToSide: '' | 'left' | 'right';
    defaults: DefaultsPropertyMapping;
    showAutoMapping: boolean;
    isMappingNeeded: boolean;
    name: string;
    showMapAvailableOptions: boolean;
};

export class SharedMappingsEditPage extends Component<Props, State> {
    private leftOptions: DropdownOption[] = [];
    private rightOptions: DropdownOption[] = [];
    private addMappingsOptions: AddMappingOptions = {
        left: [],
        right: []
    };
    private propertyValueToAdd?: PropertyValue;

    constructor(props: Props) {
        super(props);
        const selectedSharedMapping = { ...this.props.sharedMappingToEdit };
        if (!selectedSharedMapping.configuration.autoCreationResolvers) {
            selectedSharedMapping.configuration.autoCreationResolvers = {left: undefined, right: undefined};
        }
        this.state = {
            selectedSharedMapping,
            groups: this.props.groups || [],
            isMappingNeeded: true,
            isLoadingOptions: false,
            areSomeOptionsCopied: areSomeMappingsCopied(),
            addOptionToSide: '',
            defaults: this.props.defaults,
            showAutoMapping: false,
            name: this.props.sharedMappingToEdit.name || '',
            showMapAvailableOptions: false
        };
    }

    private async fetchData(): Promise<void> {
        const areBothPropertiesSelectable = isPropertySelectable(this.props.mapping.left) && isPropertySelectable(this.props.mapping.right);
        if (!areBothPropertiesSelectable) {
            await this.setState({ isMappingNeeded: false });
            return;
        }
        await this.setState({ isLoadingOptions: true });
        try {
            const result = await fetchOptions(this.state.selectedSharedMapping);
            this.leftOptions = result.left;
            this.rightOptions = result.right;
            const valueMappings: ValueMapping[] = [];
            this.props.groups.forEach(group => {
                group.valueMappings.forEach(value => valueMappings.push(value));
            });
            const groups = createValueMappingsGroups(valueMappings);
            this.leaveOnlyUnPickedOptions(groups);
            await this.setState({ isLoadingOptions: false });
        } catch (error) {
            await this.setState({ error: error as FieldError, isLoadingOptions: false });
        }
    }

    private async handleShowMapAvailableOptions() {
        await this.fetchData();
        this.setState({ showMapAvailableOptions: true });
    }

    render() {
        const { isLoadingOptions, showMapAvailableOptions } = this.state;
        return (
            <UI.FullScreenModal
                primaryBtnHandler={() => this.doneClicked()}
                closeBtnHandler={() => this.closeClicked(false)}
                error={this.state.error?.message}
                primaryBtnText={`Apply`}
                header={`Edit Shared Mapping`}
                className={`maximized`}
            >
                {
                    <>
                        {
                            <>
                                <div className={'row-two-cols mapped-options-header right-buttons'}>
                                    <div className={`shared-mapping-name-container`}>
                                        <UI.FormGroup>
                                            <UI.Input
                                                isRequired={false}
                                                label={`Shared Mapping Name`}
                                                placeholder={`Shared Mapping Name`}
                                                defaultValue={this.state.name}
                                                onChange={(value: string) => this.handleChangeName(value)}
                                            />
                                        </UI.FormGroup>
                                    </div>
                                    {this.state.selectedSharedMapping.integrations.length > 0 && <div className="used-by-integrations">
                                        <h2>Used by following integrations</h2>
                                        <ul>
                                            {this.state.selectedSharedMapping.integrations.map(el => <li><Route render={({ history }: RouteComponentProps) => <div onClick={ () => history.push(`/app/integration/smart-ints/${el.id}/edit`) }>{el.name}</div>}/></li>)}
                                        </ul>
                                    </div>}
                                    <SharedMappingsAutoCreationConfiguration
                                        sharedMapping={ this.state.selectedSharedMapping }
                                    />
                                    <div className={`left`}>
                                        <h2>Mapped options</h2>
                                    </div>
                                    <div className={`right`}>
                                        {!isLoadingOptions && (
                                            <UI.ButtonsBar
                                                classes={`${this.state.areSomeOptionsCopied ? 'w-650' : ''}`}
                                                secondary={[
                                                    this.state.areSomeOptionsCopied && (
                                                        <UI.Button
                                                            appearance={'secondary'}
                                                            onClickConfirmationText={`Pasted!`}
                                                            onClick={() => this.useCopiedOptions()}
                                                            text={`Paste`}
                                                            icon={
                                                                <UI.Icon
                                                                    icon={'paste'}
                                                                    // onClick={ () => this.useCopiedOptions() }
                                                                />
                                                            }
                                                        />
                                                    ),
                                                    <UI.Button
                                                        onClickConfirmationText={`Copied!`}
                                                        appearance={'secondary'}
                                                        onClick={() =>
                                                            copyMappedOptions(this.state.groups, this.props.defaults)
                                                        }
                                                        text={`Copy`}
                                                        icon={<UI.Icon icon={'copy'} />}
                                                    />
                                                ]}
                                            />
                                        )}
                                    </div>
                                </div>
                                {this.state.groups.map((group, index) => (
                                    <ValueMappingsGroupContainer
                                        key={index}
                                        group={group}
                                        addPropertyValue={this.addPropertyValue.bind(this)}
                                        addMultipleToSide={this.state.addOptionToSide}
                                        valueConfigurationHandler={this.props.valueConfigurationHandler}
                                        onDeleteHandler={mapping => this.deleteMapping(group, mapping)}
                                    />
                                ))}
                                {isLoadingOptions && (
                                    <p>
                                        <UI.Loader visible={true} appearance={'darkgray'} /> Loading options
                                    </p>
                                )}
                                {this.state.error && (
                                    <UI.Message
                                        appearance={'error-message'}
                                        message={
                                            'Failed to load fields options for mapping. ' + this.state.error.message
                                        }
                                    />
                                )}
                            </>
                        }
                        {(!this.state.selectedSharedMapping.integrations || (this.state.selectedSharedMapping.integrations && this.state.selectedSharedMapping.integrations.length === 0)) && 
                            <UI.Message
                                appearance={'warning'}
                                message={`Mapping options are unavailable due to the lack of integrations utilizing this shared mapping.`}
                            />}
                        {!showMapAvailableOptions && !isLoadingOptions && this.state.selectedSharedMapping.integrations && this.state.selectedSharedMapping.integrations.length > 0 && 
                            <div className="map-options-button">
                                <UI.Button text='Map available options' onClick={() => this.handleShowMapAvailableOptions() }/>
                            </div>
                        }
                        {!isLoadingOptions && showMapAvailableOptions && (
                            <>
                                {!this.state.error && (
                                    <AddValueMapping
                                        appsSupport={this.props.appsSupport}
                                        optionsQueryChangeHandler={this.optionsQueryChangeHandler.bind(this)}
                                        mapping={this.props.mapping}
                                        onValueOptionSelected={this.onValueOptionSelected.bind(this)}
                                        options={this.addMappingsOptions}
                                        addMappingHandler={this.addMappingHandler.bind(this)}
                                        triggers={{ left: this.props.leftTrigger, right: this.props.rightTrigger }}
                                        onClickDropdown={() => this.scrollDown()}
                                    />
                                )}

                                {(this.props.empty.left || this.props.empty.right) && (
                                    <UI.FormSection label={`Empty value handling (optional)`}>
                                        <EmptyHandler empty={this.props.empty} />
                                    </UI.FormSection>
                                )}
                            </>
                        )}
                    </>
                }
                {this.state.showAutoMapping && (
                    <SharingAutoMapping
                        triggers={{ left: this.props.leftTrigger, right: this.props.rightTrigger }}
                        propertyMapping={this.props.mapping}
                        typeMapping={this.props.typeMapping}
                        options={this.addMappingsOptions}
                        depth={this.props.depth + 1}
                        closeHandler={(options?: AutoMappedOptions[], clear?: boolean) =>
                            this.onAutoMappingClose(options, clear)
                        }
                    />
                )}
            </UI.FullScreenModal>
        );
    }

    private async onAutoMappingClose(options?: AutoMappedOptions[], clear?: boolean): Promise<void> {
        if (options) {
            if (clear) {
                await this.setState({ groups: [] });
            }
            for (const autoMappedOption of options) {
                if (autoMappedOption.approved) {
                    await this.addMappingHandler({
                        left: {
                            name: autoMappedOption.left.label,
                            value: autoMappedOption.left.value,
                            additionalData: getEmptyAdditionalData()
                        },
                        right: {
                            name: autoMappedOption.right.label,
                            value: autoMappedOption.right.value,
                            additionalData: getEmptyAdditionalData()
                        },
                        isDefault: false,
                        defaultValueMapping: false,
                    });
                }
            }
        }
        this.setState({ showAutoMapping: false });
    }

    private handleChangeName(value: string) {
        this.setState({ name: value });
    }

    private useCopiedOptions() {
        const groupsAndDefaultMappings = reuseCopiedOptions();
        const groups = groupsAndDefaultMappings.groups;
        this.leaveOnlyUnPickedOptions(groups);
        this.setState({ groups, defaults: groupsAndDefaultMappings.defaults }, () => {
            this.props.onMappingsChangedHandler(this.state.groups, groupsAndDefaultMappings.defaults);
        });
    }

    private optionsQueryChangeHandler(side: 'left' | 'right', queryValue: string) {}

    private onValueOptionSelected(side: 'left' | 'right', propertyValue: PropertyValue) {
        if (!propertyValue) {
            this.propertyValueToAdd = undefined;
            this.setState({ addOptionToSide: '' });
        } else {
            this.propertyValueToAdd = propertyValue;
            this.setState({ addOptionToSide: side });
        }
    }

    private addPropertyValue(side: 'left' | 'right', group: ValueMappingsGroup) {
        if (this.propertyValueToAdd) {
            const valueMapping = getValueMappingForAnotherOption(side, group, this.propertyValueToAdd);
            if (valueMapping) {
                this.addMappingHandler(valueMapping, group);
            }
        }
    }

    private addMappingHandler(mapping: ValueMapping, group?: ValueMappingsGroup): Promise<void> {
        return new Promise(resolve => {
            const valueMappings: ValueMapping[] = [];
            this.state.groups.forEach(group => {
                group.valueMappings.forEach(value => valueMappings.push(value));
            });
            valueMappings.push(mapping);
            const groups = createValueMappingsGroups(valueMappings);
            this.leaveOnlyUnPickedOptions(groups);
            this.propertyValueToAdd = undefined;
            this.setState({ addOptionToSide: '', groups }, () => {
                this.props.onMappingsChangedHandler(this.state.groups);
                resolve();
            });
        });
    }

    private deleteMapping(group: ValueMappingsGroup, mapping: ValueMapping) {
        const index = group.valueMappings.indexOf(mapping);
        group.valueMappings.splice(index, 1);
        const valueMappings: ValueMapping[] = [];
        this.state.groups.forEach(group => {
            group.valueMappings.forEach(value => valueMappings.push(value));
        });
        const groups = createValueMappingsGroups(valueMappings);
        this.leaveOnlyUnPickedOptions(groups);
        this.setState({ groups }, () => {
            this.props.onMappingsChangedHandler(this.state.groups);
        });
    }

    private autoMappingClicked() {
        this.setState({ showAutoMapping: true });
    }

    private leaveOnlyUnPickedOptions(groups: ValueMappingsGroup[]) {
        this.addMappingsOptions = { left: [], right: [] };
        this.leftOptions.forEach(option => {
            let exists = false;
            groups.forEach(group => {
                group.valueMappings.forEach(mapping => {
                    if (option.value === mapping.left.value) {
                        exists = true;
                    }
                });
            });
            if (!exists) {
                this.addMappingsOptions.left.push(option);
            }
        });
        this.rightOptions.forEach(option => {
            let exists = false;
            groups.forEach(group => {
                group.valueMappings.forEach(mapping => {
                    if (option.value === mapping.right.value) {
                        exists = true;
                    }
                });
            });
            if (!exists) {
                this.addMappingsOptions.right.push(option);
            }
        });
    }

    private goToNotifications() {
        (this.props as any).history.push(`/app/integration/notifications`);
    }

    doneClicked(): Promise<boolean> {
        return new Promise(resolve => {
            try {
                const obj: UpdateSharedMapping = {
                    name: this.state.name,
                    configuration: {
                        groups: this.state.groups,
                        azureEmailIsJiraUsername: this.props.mapping.additional.azureEmailIsJiraUsername,
                        leftTriggerConfiguration: this.props.leftTrigger.configuration,
                        rightTriggerConfiguration: this.props.rightTrigger.configuration,
                        mappingDirection: this.props.mapping.direction,
                        mappingIsNewlyCreated: this.props.mapping.isNewlyCreated || false,
                        mappingOptions: this.props.mapping.options,
                        typeMappingLeft: this.props.typeMapping.left,
                        typeMappingRight: this.props.typeMapping.right,
                        autoCreationResolvers: this.state.selectedSharedMapping.configuration.autoCreationResolvers
                    }
                }
                handleUpdateSharedMapping(this.state.selectedSharedMapping.id, obj).then(res => {
                    resolve(true);
                    this.closeClicked(true);

                })
            } catch (error) {

            }
        });
    }

    scrollDown() {
        setTimeout(() => {
            const box = document.querySelector('.box');
            if (box) {
                box.scrollBy({top: 500, behavior: 'smooth'})
            }
        }, 250)
    }

    closeClicked(value: boolean) {
        this.props.closeHandler(value);
    }
}
