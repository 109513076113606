import React from "react";
import {AddFilter} from "./add-filter";
import {PropertyDefinition} from "../../../../../generic/artifacts";
import {createEmptySet, FILTER_SET_NAME, FiltersSet, ItemFilter} from "./rest-api";
import {DisplayFilter} from "./display-filter";
import {UI} from "src/engrator-core";
import {SoftwareName} from "../../../../../software";

type State = {
    isLoadingFields: boolean;
    filters: FiltersSet;
    fields?: PropertyDefinition[];
    error?: any;
};

type Props = {
    app: SoftwareName;
    side: string;
    fieldsResolver: () => Promise<PropertyDefinition[]>;
    closeHandler: (filters?: FiltersSet) => void;
    filters?: FiltersSet
}

export class ItemFiltering extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if ((this.props.filters as any)?.length) {
            this.state = {
                isLoadingFields: false,
                filters: (this.props.filters) ? {
                    [FILTER_SET_NAME.ALL]: JSON.parse(JSON.stringify(this.props.filters)),
                    [FILTER_SET_NAME.UPDATED]: [],
                    [FILTER_SET_NAME.NEW]: []
                } : createEmptySet()
            };
        } else {
            this.state = {
                isLoadingFields: false,
                filters: (this.props.filters) ? JSON.parse(JSON.stringify(this.props.filters)) : createEmptySet()
            };
        }
    }

    async componentDidMount() {
        await this.setState({isLoadingFields: true});
        try {
            const fields = await this.props.fieldsResolver();
            const finalFields: any[] = [];
            const collectedFieldsIds: string[] = [];
            fields.forEach(f => {
                if (collectedFieldsIds.indexOf(f.id) === -1) {
                    collectedFieldsIds.push(f.id);
                    finalFields.push(f);
                }
            });
            await this.setState({fields: finalFields, isLoadingFields: false});
        } catch (error) {
            await this.setState({isLoadingFields: false, error: error});
        }
    }

    private addFilter(filter: ItemFilter, setName: FILTER_SET_NAME) {
        const filters = this.state.filters;
        filters[setName].push(filter);
        this.setState({filters});
    }

    private removeFilterHandler(index: number, setName: FILTER_SET_NAME): void {
        const filters = this.state.filters;
        filters[setName].splice(index, 1);
        this.setState({filters});
    }

    private closeClicked(): Promise<boolean> {
        this.props.closeHandler();
        return Promise.resolve(true);
    }

    private applyClicked(): Promise<boolean> {
        this.props.closeHandler(this.state.filters);
        return Promise.resolve(true);
    }

    render() {
        return <UI.FullScreenModal
            header={`Filter Items ${this.props.app} (${this.props.side})`}
            primaryBtnHandler={() => this.applyClicked()}
            showPrimaryBtn={true}
            primaryBtnText={`Apply`}
            closeBtnHandler={() => this.closeClicked()}
        >
            <div className={`item-filtering`}>
                <UI.Message
                    appearance={"info"}
                >
                    Filtering items involves creating a list of filtering criteria. Before selecting items for
                    synchronization, each item is compared against the rules you establish. Only those items that meet
                    the criteria will undergo further processing. <UI.DocumentationLink text={`Read more`}
                                                                                        url={`https://docs.getint.io/getting-started-with-the-platform/how-to-use-getint/integration/items-filtering`}/>
                </UI.Message>
                { this.state.error && <UI.Message appearance={'error-message'} message={this.state.error} /> }
                <h2>ALL items filters</h2>
                <div>
                    {this.state.filters[FILTER_SET_NAME.ALL]?.map((filter, index) => <DisplayFilter
                        deleteHandler={() => this.removeFilterHandler(index, FILTER_SET_NAME.ALL)}
                        filter={filter}
                        key={index}
                    />)}
                    {this.state.isLoadingFields && <UI.Loader
                        visible={true}
                        appearance={"darkgray"}
                    />}
                    {this.state.fields && <AddFilter
                        fields={this.state.fields}
                        addFilterHandler={(filter) => this.addFilter(filter, FILTER_SET_NAME.ALL)}
                    />}
                </div>
                {/* NEW items only */}
                <h2>NEW items filters</h2>
                <div>
                    {this.state.filters[FILTER_SET_NAME.NEW]?.map((filter, index) => <DisplayFilter
                        deleteHandler={() => this.removeFilterHandler(index, FILTER_SET_NAME.NEW)}
                        filter={filter}
                        key={index}
                    />)}
                    {this.state.isLoadingFields && <UI.Loader
                        visible={true}
                        appearance={"darkgray"}
                    />}
                    {this.state.fields && <AddFilter
                        fields={this.state.fields}
                        addFilterHandler={(filter) => this.addFilter(filter, FILTER_SET_NAME.NEW)}
                    />}
                </div>
                {/* SYNCED items only */}
                <h2>SYNCED items filters</h2>
                <div>
                    {this.state.filters[FILTER_SET_NAME.UPDATED]?.map((filter, index) => <DisplayFilter
                        deleteHandler={() => this.removeFilterHandler(index, FILTER_SET_NAME.UPDATED)}
                        filter={filter}
                        key={index}
                    />)}
                    {this.state.isLoadingFields && <UI.Loader
                        visible={true}
                        appearance={"darkgray"}
                    />}
                    {this.state.fields && <AddFilter
                        fields={this.state.fields}
                        addFilterHandler={(filter) => this.addFilter(filter, FILTER_SET_NAME.UPDATED)}
                    />}
                </div>
            </div>
        </UI.FullScreenModal>
    }
}