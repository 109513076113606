import { PipelineStep } from 'src/generic';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';
import { MondayStepKey } from '../freshdesk-step-key.type';
import { MondayFindItemConfiguration } from './monday-find-item-configuration';
import { MondayFindItemEditStep } from './monday-find-item-edit-step';
import { MondayFindItemNewStep } from './monday-find-item-new-step';


export const MondayFindItemPipelineStep: PipelineStep<MondayFindItemConfiguration> = {
    addStepComponentClass: MondayFindItemNewStep,
    editStepComponentClass: MondayFindItemEditStep,
    specification: {
        action: 'Find issue',
        description: 'Find issue with specific id',
        key: MondayStepKey.FreshdeskFindItem,
        software: SoftwareName.Monday,
        stepType: StepType.Action,
    },
    preferredNameForArtifact: `MondayItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as MondayFindItemConfiguration;
        return `Find issue with id='${stepConfiguration.id}'`;
    },
};
