export enum DesignerEventType {
    StepsChangedEvent,
    ShowSettings
}
export type EventHandler = (notificationData: EventNotificationData) => void;
export type EventNotificationData = {};

export class EventsBus {
    subscribers: Map<DesignerEventType, Array<EventHandler>>;

    constructor() {
        this.subscribers = new Map();
    }

    subscribe(eventType: DesignerEventType, designerEventHandler: EventHandler): void {
        if (!this.subscribers.has(eventType)) {
            this.subscribers.set(eventType, new Array());
        }
        this.subscribers.get(eventType)?.push(designerEventHandler);
    }

    notify(eventType: DesignerEventType, notificationData: EventNotificationData): void {
        if (!this.subscribers.has(eventType)) {
            return;
        }
        this.subscribers.get(eventType)?.forEach((subscriber: EventHandler) => {
            subscriber(notificationData);
        });
    }

    clearSubscribers(): void {

    }
}