import React from 'react';
import {UI} from '..';

type Props = {
    header: string,
    fullReportUrl?: string,
    description: string
};

export class DashboardTile extends React.Component<Props> {
    render() {
        return <div className={`engrator-ui-dashboard-tile`}>
            <UI.Tile className={`dashboard-chart-tile`}>
                <h3>{this.props.header}</h3>
                <div className={`content`}>{this.props.children}</div>
                <div className={`footer`}>
                    <div className={`description`}>
                        {this.props.description}
                    </div>
                    {this.props.fullReportUrl && <div>
                        <UI.NavLink path={this.props.fullReportUrl}>{`View full report`}</UI.NavLink>
                    </div>}
                </div>
            </UI.Tile>
        </div>;
    }
}