import React from 'react';

import './RightPanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default class RightPanel extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="RightPanel">
            <span className="close-btn"><FontAwesomeIcon onClick={() => this.props.close() } icon={faTimes} /></span>
            <div className="content">
                { this.props.children }
            </div>
        </div>
    }
}