import React from "react";

import {IntegrationError} from "./integration-error.type";
import {TableGridData} from "../../../engrator-core/ui";
import {UI} from "../../../engrator-core";

export function createIntegrationErrorsTableGridData(errors: IntegrationError[]): TableGridData {
    return {
        data: errors.map(error => [
            // <UI.NavLink path={`/app/reporting/suite-run/${error.id}/flow/${error.id}`}>#{error.id}</UI.NavLink>,
            `${error.id}`,
            error.errorGroup,
            error.firstOccurredAt,
            error.lastOccurredAt,
            error.numberOfOccurrences,
            error.details,
        ]),
        headers: ['ID', 'Group', 'First Occurr.', 'Last Occurr.', '# Occurr.', 'Details'],
    };
}