import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {AzureStepKey} from "../azure-step-key.type";
import {AzureFindItemNewStep} from "./azure-find-item-new-step";
import {AzureFindItemEditStep} from "./azure-find-item-edit-step";
import {AzureFindItemConfiguration} from "./azure-find-item-configuration";


export const AzureFindItemPipelineStep: PipelineStep<AzureFindItemConfiguration> = {
    addStepComponentClass: AzureFindItemNewStep,
    editStepComponentClass: AzureFindItemEditStep,
    specification: {
        action: "Find Item",
        description: "Find Azure item in specific project",
        key: AzureStepKey.AzureFindItem,
        software: SoftwareName.Azure,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `AzureItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AzureFindItemConfiguration;
        return `Find ${stepConfiguration.itemType.name} item in ${stepConfiguration.project.name} with id='${stepConfiguration.id}'`;
    }
};