import React, {SyntheticEvent} from 'react';
import { UI } from 'src/engrator-core';
import { PropertyMapping } from '../visual/property-mapping.type';
import { DirectionPicker } from '../visual/components/direction-picker';
import { SmartIntDirection } from '../definition/smart-int-direction.type';
import { PropertyMappingOptionsSelector } from '../visual/type-mapping-configuration';
import { WarningFlag } from './warning-flag';
import { getPropertyMappingWarning } from './configuration-warning-checker';
import { Icon20 } from 'src/engrator-core/ui';
import {SharedMappingFlag} from "./sahred-mapping-flag";

type Props = {
    propertyMapping: PropertyMapping;
    onDeleteHandler: (event?: SyntheticEvent) => void;
    onValuesMappingHandler: () => void;
    className?: string;
};
type State = {
    className?: string;
};

export class MappingDisplay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            className: props.className
        };
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.className !== prevProps.className) {
            await this.setState({ className: this.props.className });
            if (this.state.className === 'anim-fade-in') {
                setTimeout(() => {
                    this.setState({ className: undefined });
                }, 3000);
            }
        }
    }

    render() {
        const warning = getPropertyMappingWarning(this.props.propertyMapping);
        return <div
            key={ this.getDisplayName('left', this.props.propertyMapping) + '-' + this.getDisplayName('right', this.props.propertyMapping) }
            onClick={ () => this.mappingClicked() }
            className={`mapping-display row-two-cols field-mapping-item ${ (this.state.className) ? this.state.className : '' }`}>
            { warning && !this.props.propertyMapping.sharedMappingId && <WarningFlag
                warning={ warning }
            /> }
            { (this.props.propertyMapping.sharedMappingId || 0) > 0 && <SharedMappingFlag /> }
            <div className={`left center`}>
                <span className={`value`}>{this.getDisplayName('left', this.props.propertyMapping)}</span>
                <PropertyMappingOptionsSelector
                    options={this.props.propertyMapping.options.left}
                    readonly={ this.props.propertyMapping.left.options?.readonly }
                />
            </div>
            <DirectionPicker
                default={ this.props.propertyMapping.direction }
                onChange={ (newDirection: SmartIntDirection) => this.changeMappingDirection(newDirection) } />
            <div className={`right center`}>
                <span className={`value`}>{this.getDisplayName('right', this.props.propertyMapping)}</span>
                <PropertyMappingOptionsSelector
                    options={this.props.propertyMapping.options.right}
                    readonly={ this.props.propertyMapping.right.options?.readonly }
                />
                <div className={'delete hidden'} onClick={(event) => this.props.onDeleteHandler(event)}><UI.Icon icon={'trash'}/>
                </div>
            </div>
            {this.isConfigurable() && <React.Fragment>
                <UI.Icon
                    onClick={() => this.props.onValuesMappingHandler()}
                    icon20={ Icon20.Settings}
                />
                { !this.props.propertyMapping.sharedMappingId && <div className={`options value-mappings`}>
                    <span
                        className={`label ${(this.props.propertyMapping.groups?.length > 0) ? 'active' : ''}`}>{this.props.propertyMapping.groups?.length} Options Mapped</span>
                </div> }
            </React.Fragment>}
        </div>;
    }

    private getDisplayName(side: 'left' | 'right', propertyMapping: PropertyMapping) {
        if (propertyMapping.options[side].predefinedValue) {
            if (propertyMapping.options[side].predefinedValueLabel) {
                return `"${propertyMapping.options[side].predefinedValueLabel}"`;
            }
            return `"${propertyMapping.options[side].predefinedValue}"`;
        }
        return propertyMapping[side].name;
    }

    private isConfigurable(): boolean {
        return true; // !!(this.props.propertyMapping.left.options?.selectable || this.props.propertyMapping.right.options?.selectable);
    }

    private changeMappingDirection(newDirection: SmartIntDirection) {
        this.props.propertyMapping.direction = newDirection;
    }

    private async mappingClicked(): Promise<void> {
        if (this.isConfigurable()) {
            this.props.onValuesMappingHandler();
        }
    }
}
