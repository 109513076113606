import { PipelineStep } from 'src/generic';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';

import { GetintArchiverStepKey } from '../archiver-step-key.type';
import { GetintArchiverUpdateItemConfiguration } from './monday-update-item-configuration';
import { GetintArchiverUpdateItemEditStep } from './monday-update-item-edit-step';
import { GetintArchiverUpdateItemNewStep } from './monday-update-item-new-step';

export const GetintArchiverUpdateItemPipelineStep: PipelineStep<GetintArchiverUpdateItemConfiguration> = {
    addStepComponentClass: GetintArchiverUpdateItemNewStep,
    editStepComponentClass: GetintArchiverUpdateItemEditStep,
    specification: {
        action: 'Update issue',
        description: 'Update selected issue',
        key: GetintArchiverStepKey.GetintArchiverUpdateItem,
        software: SoftwareName.GetintArchiver,
        stepType: StepType.Action,
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GetintArchiverUpdateItemConfiguration;
        return `Update issue '${stepConfiguration.targetArtifact}'`;
    },
};
