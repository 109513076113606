import React, {useEffect} from 'react';

import {FieldError, UI} from 'src/engrator-core';
import {Footer} from '../footer';
import {
    CreateIntegrationGuide,
    registerUserActivityAction,
    shouldShowCreateInteagrationGuide,
    UserActivityAction,
} from '../system/user-activity';
import {getSystemInfo} from "../top-navigation/rest-api";
import {SystemInfo} from "../top-navigation/threads-summary.type";
import {useGlobalState} from "../global-store";
import {homeVerticalMenu} from "./home-vertical-menu";
import {MainMenuItem} from "../global-store/create-global-state";


type State = {
    errors: Array<FieldError>,
    showCreateIntegrationGuide?: boolean;
    isLoading: boolean;
    time: number;
    error?: FieldError;
    tenantInfo?: SystemInfo;
};

export class GettintStartedPageCmp extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            errors: [],
            isLoading: false,
            time: new Date().getTime(),
        };
    }

    async componentDidMount() {
        registerUserActivityAction(UserActivityAction.WasOnDashboard);
        try {
            const showCreateIntegrationGuide = await shouldShowCreateInteagrationGuide();
            const tenantInfo = await getSystemInfo();
            this.setState({showCreateIntegrationGuide, tenantInfo });
        } catch (e) {
            this.setState({error: e});
        }
    }

    render() {
        if (this.state.error) {
            return <UI.Message appearance={'error'}>{this.state.error?.message}</UI.Message>;
        }
        if (this.state.showCreateIntegrationGuide === undefined) {
            return <React.Fragment/>;
        }
        if (this.state.showCreateIntegrationGuide) {
            return <UI.Page className="dashboard-page"><CreateIntegrationGuide/></UI.Page>;
        }
        return (
            <UI.Page className="dashboard-page">
                <CreateIntegrationGuide/>
                <Footer/>
            </UI.Page>
        );
    }
}

export function GettintStartedPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMenu(homeVerticalMenu);
        setMainMenuItem(MainMenuItem.Home);
    }, []);
    return <GettintStartedPageCmp />
}
