import React from 'react';
import { FieldError, UI } from '../../../../engrator-core';
import { NotificationAlert } from '../notification-alert.type';
import { deleteAlert } from '../rest-api';

type Props = {
    closeHandler: () => void;
    notification: NotificationAlert;
    onDeleteHandler: () => void;
};

type State = {
    error?: FieldError;
    isLoading: boolean;
};

export class DeleteNotificationModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoading: false };
    }

    async onDeleteClicked() {
        this.setState({ isLoading: true }, async () => {
            try {
                await deleteAlert(this.props.notification);
                this.setState({ isLoading: false }, () => {
                    this.props.closeHandler();
                    this.props.onDeleteHandler();
                });
            } catch (error) {
                this.setState({ error: error as FieldError, isLoading: false });
            }
        });
    }

    isLoading(): boolean {
        return false;
    }

    render() {
        return (
            <React.Fragment>
                <UI.Modal header={`Delete notification '${this.props.notification.name}'`} isLeftAligned={true}>
                    Please confirm you are sure to delete "{this.props.notification.name}" notification
                    {this.state.error && (
                        <UI.Message appearance={'error'}>
                            <p>Failed to delete notification: {this.state.error.message}</p>
                        </UI.Message>
                    )}
                    <UI.ButtonsBar
                        primary={
                            <UI.Button
                                text="Delete"
                                isLoading={this.state.isLoading}
                                disabled={this.state.isLoading}
                                appearance="danger"
                                onClick={() => this.onDeleteClicked()}
                            />
                        }
                        secondary={[
                            <UI.Button
                                text="Close"
                                appearance="link-inline"
                                onClick={() => this.props.closeHandler()}
                            />
                        ]}
                    />
                </UI.Modal>
            </React.Fragment>
        );
    }
}
