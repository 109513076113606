import React from 'react';
import { switchBodyScroll } from '../index';
import {ButtonsBar} from "./buttons-bar";
import {Button} from "./button";


type Props ={
    btnAppearance?: 'danger' | 'elementary';
    isLeftAligned?: boolean;
    header?: string;
    highestIndex?: boolean;
    isWide?: boolean;
    isFullScreen?: boolean;
    closeHandler: () => void;
    confirmHandler: () => void;
}

export class ConfirmationModal extends React.Component<Props> {
    render() {
        return <div className={`modal persistence-loader confirmation-modal`}>
            <div className={`box`}>
                <h3>{ this.props.header || 'Please confirm' }</h3>
                { this.props.children }
                <ButtonsBar
                    primary={ <Button
                        appearance={ this.props.btnAppearance || 'elementary' }
                        onClick={ () => this.props.confirmHandler() }
                        text={`Confirm`}
                        />
                    }
                    secondary={ [
                        <Button
                            appearance={`secondary`}
                            onClick={ () => this.props.closeHandler() }
                            text={`Cancel`}
                        />
                    ] }
                />
            </div>
        </div>;
    }
}

