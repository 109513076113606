import React from 'react';
import {DropdownFilter, InputFilter} from "../../../reporting/filters";
import {DropdownOption} from "../../../reporting/filters/dropdown-option.type";
import { UI } from 'src/engrator-core';
import { SoftwareName } from 'src/software';
import { ConnectionsFilters, createEmptyFilters, isAnyFilterUsed } from '../rest-api';

type Props = {
    onFiltersChanged: (newFiltersValues: ConnectionsFilters) => void;
    defaultFilters: ConnectionsFilters;
    owners: string[];
};

type State = {
    owners: string[];
    filters: ConnectionsFilters;
};

const APPS: DropdownOption[] = (Object.keys(SoftwareName) as Array<keyof typeof SoftwareName>).map(key => ({
    label: key,
    value: key,
    isSelected: false
}));

export class ConnectionsListFiltering extends React.Component<Props, State> {
    private nameTimeout?: NodeJS.Timeout;
    constructor(props: Props) {
        super(props);
        this.state = {
            filters: this.props.defaultFilters,
            owners: this.props.owners
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(this.props.defaultFilters) !== JSON.stringify(prevProps.defaultFilters)) {
            this.setState({ filters: this.props.defaultFilters });
        }
    }

    render() {
        return <React.Fragment>
            <InputFilter
                filterChangeHandler={ (filter) => this.onNameFilterChanged(filter['query']) }
                title={`Search by name or id`}
                placeholder={`Search by name or id`}
                defaultValue={ this.state.filters.query }
            />
            <DropdownFilter
                isSingleSelect={true}
                defaultValues={{ selectedValues: this.state.filters.selectedOwner }}
                title={`Owner`}
                options={ this.state.owners.map(o => ({
                    value: o,
                    label: o,
                    isSelected: false
                }))}
                listenForChanges={ true }
                filterChangeHandler={(filter) => this.onSelectedHandler(filter['selectedValues'])}
            />
            <DropdownFilter
                isSingleSelect={ false }
                filterChangeHandler={(filter) => this.onAppsFilterChanged(filter['selectedValues'])}
                title={`Apps`}
                options={ APPS }
                listenForChanges={ true }
                defaultValues={{ selectedValues: (this.state.filters.selectedApps) ? this.state.filters.selectedApps.split(',') : [] }}
                onClearHandler={() => this.onAppsFilterChanged([])}
            />
            { isAnyFilterUsed(this.state.filters) && <UI.Button
                text={`Clear`}
                appearance={"link-inline"}
                onClick={ () => this.clearFilters() }
            /> }
        </React.Fragment>
    }

    private async clearFilters() {
        await this.setState({ filters: createEmptyFilters() });
        this.props.onFiltersChanged(this.state.filters);
    }

    private async onSelectedHandler(owner: string) {
        const filters = this.state.filters;
        filters.selectedOwner = owner;
        await this.setState({ filters })
        this.props.onFiltersChanged(filters);
    }

    private async onAppsFilterChanged(newApp?: SoftwareName[]) {
        const filters = this.state.filters;
        filters.selectedApps = newApp?.join(',');
        await this.setState({ filters })
        this.props.onFiltersChanged(filters);
    }

    private async onNameFilterChanged(query: string) {
        if (this.nameTimeout) {
            clearInterval(this.nameTimeout);
        }
        this.nameTimeout = setTimeout(() => {
            const filters = this.state.filters;
            filters.query = query;
            this.setState({ filters }, () => {
                this.props.onFiltersChanged(filters);
            });
        }, 1000);
    }
}
