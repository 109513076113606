import React from 'react';

import {ServiceNowFindItemConfiguration} from "./servicenow-find-item-configuration";
import {EditPipelineStepComponentProps} from "src/generic/pipeline-step";
import {UI} from "../../../../engrator-core";
import {ServicenowStepContextDisplay} from "../common/servicenow-step-context-display";

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class ServiceNowFindItemEditStep extends React.Component<Props, State> {
    private configuration: ServiceNowFindItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as ServiceNowFindItemConfiguration;
    }

    componentDidMount(): void {
    }

    render() {
        return <React.Fragment>
            <ServicenowStepContextDisplay configuration={ this.configuration.context } />
            <UI.LabelValue label={`Item Key`} value={ this.configuration.id } />
        </React.Fragment>;
    }
};