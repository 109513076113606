import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Loader } from './loader';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Icon, Icon20} from "./icon";

type ButtonProps = {
    onClick?: any,
    text: string,
    className?: string,
    btnClassName?: string,
    icon?: any,
    appearance?: 'secondary' | 'elementary' | 'inline' | 'danger' | 'link-inline' | 'tab' | 'success',
    isLoading?: boolean,
    disabled?: boolean,
    alignment?: 'right';
    dataSel?: string;
    path?: string;
    onClickConfirmationText?: string;
} & RouteComponentProps;
type State = {
    text: string;
    disabled: boolean;
    confirmationState: boolean;
};

const Button = withRouter(class Button extends React.Component<ButtonProps, State> {
    constructor(props: Readonly<ButtonProps>) {
        super(props);
        this.state = {
            disabled: false,
            text: this.props.text,
            confirmationState: false
        };
    }

    componentDidUpdate(prevProps: ButtonProps) {
        if ((this.props.isLoading && this.props.isLoading !== prevProps.isLoading)
        || (this.props.disabled && this.props.disabled !== prevProps.disabled)) {
            this.forceUpdate();
        }
    }

    getClassName() {
        const classes = ['button-container'];
        if (this.props.className) {
            classes.push(this.props.className);
        }
        if (this.props.appearance) {
            classes.push(this.props.appearance);
        } else {
            classes.push('elementary');
        }
        if (this.props.alignment) {
            classes.push('alignment-' + this.props.alignment);
        }
        if (this.props.isLoading) {
            classes.push('is-loading');
        }
        if (!this.props.text) {
            classes.push('short')
        }
        return classes.join(' ');
    }

    private onClick(): void {
        if (this.props.path) {
            (this.props.history as any).push(this.props.path);
        } else if (this.props.onClickConfirmationText) {
            this.setState({ confirmationState: true, disabled: true, text: this.props.onClickConfirmationText }, () => {
                this.props.onClick();
                setTimeout(() => {
                    this.setState({ confirmationState: false, disabled: false, text: this.props.text });
                }, 1000);
            })
        } else {
            this.props.onClick();
        }
    }

    render() {
        const attributes: any = [];
        if (this.props.isLoading === true || this.props.disabled === true || this.state.disabled) {
            attributes['disabled'] = 'disabled';
        }
        if (this.props.dataSel) {
            attributes['data-sel'] = this.props.dataSel;
        }
        attributes['class'] = '';
        if (this.props.btnClassName) {
            attributes['class'] = this.props.btnClassName;
        }
        if (this.state.confirmationState) {
            attributes['class'] += ' confirmation-text';
        }
        return <div className={ this.getClassName() }>
            <button type="button" onClick={ () => this.onClick() } { ...attributes } >
                { this.props.isLoading === true && <Loader visible={ true } appearance={ this.getLoaderAppearance() }/> }
                <React.Fragment>
                    { this.props.icon && !this.props.isLoading && <React.Fragment>{ this.props.icon } </React.Fragment> }
                    { this.state.text }
                </React.Fragment>
            </button>
        </div>;
    }

    private getLoaderAppearance(): 'darkgray' | 'white' | 'blue' {
        if (this.props.appearance === 'secondary') {
            return 'darkgray';
        } else if (this.props.appearance === 'elementary') {
            return 'white';
        }
        return 'blue';
    }
})

export function RemoveButton(props: { onClick: () => void }) {
    return <Button
        appearance={'secondary'}
        icon={ <Icon icon20={ Icon20.Trash } /> }
        text={``}
        onClick={ () => props.onClick() }
    />;
}

export function SettingsButton(props: { onClick: () => void }) {
    return <Button
        appearance={'secondary'}
        icon={ <Icon icon20={ Icon20.Settings } /> }
        text={``}
        onClick={ () => props.onClick() }
    />;
}

// export class Button = withRouter(Button2);
export {
    Button
}