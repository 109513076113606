import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";

import {AsanaStepKey} from "../asana-step-key.type";
import {AsanaUpdateItemEditStep} from "./asana-update-item-edit-step";
import {AsanaUpdateItemNewStep} from "./asana-update-item-new-step";
import {AsanaUpdateItemConfiguration} from "./asana-update-item-configuration";

export const AsanaUpdateItemPipelineStep: PipelineStep<AsanaUpdateItemConfiguration> = {
    addStepComponentClass: AsanaUpdateItemNewStep,
    editStepComponentClass: AsanaUpdateItemEditStep,
    specification: {
        action: "Update Task",
        description: "Update Asana Task",
        key: AsanaStepKey.AsanaUpdateItem,
        software: SoftwareName.Asana,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `updatedAsanaItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AsanaUpdateItemConfiguration;
        return `Update task '${stepConfiguration.targetArtifact}'`;
    }
}