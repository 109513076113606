import React from 'react';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { UI } from 'src/engrator-core';
import { SoftwareName } from '../../software-name';
import { AzureProjectItemTypePicker, AzureProjectItemTypePickerData } from '../steps/common';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class AzureSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.Azure,
            artifactName: '',
            className: '',
            configuration: {
                project: undefined,
                query: ''
            },
            connectionId: this.props.connectionId
        };
    }

    render() {
        return <React.Fragment>
            {!this.smartIntTrigger.configuration.project && <AzureProjectItemTypePicker
                connectionId={this.props.connectionId}
                onDataChanged={this.onDataChanged.bind(this)}
            />}
            {this.smartIntTrigger.configuration.project && <UI.FormGroup
                dataSel={`project`}
            >
                <UI.LabelValue
                    label={`Project`}
                    value={this.smartIntTrigger.configuration.project.name}
                />
            </UI.FormGroup>
            }
            {/*<UI.FormGroup*/}
            {/*    dataSel={`query`}*/}
            {/*    label={`Additional Query`}*/}
            {/*    description={`You can provide additional query params which will be appended to the url generated when searching for work items in Azure (e.g. [System.AreaPath] UNDER "Main\\demo")`}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={this.onJqlInputChanged.bind(this)} placeholder={`e.g. [System.AreaPath] UNDER "Main\\demo"`}/>*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private onDataChanged(data: AzureProjectItemTypePickerData) {
        if (data.project) {
            this.smartIntTrigger.configuration.project = data.project;
            if (data.azureCollection) {
                this.smartIntTrigger.configuration.azureCollection = data.azureCollection;
            }
            this.smartIntTrigger.artifactName = 'issue';
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }
}
