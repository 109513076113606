import React from 'react';
import { EditPipelineStepComponentProps } from 'src/generic/pipeline-step';
import { AzureCreateCommentsConfiguration } from '../../../azure/steps/create-comments/azure-create-comments-configuration';
import { EditCommentsStep } from '../../../generic/create-comments-step';

import { GetintArchiverCreateCommentsConfiguration } from './monday-create-comments-configuration';

type Props = {} & EditPipelineStepComponentProps;
type State = {};

export class GetintArchiverCreateCommentsEditStep extends React.Component<Props, State> {
    private configuration: GetintArchiverCreateCommentsConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as GetintArchiverCreateCommentsConfiguration;
    }

    render() {
        return <EditCommentsStep
            configuration={this.configuration}
            formError={this.props.formError}
            onConfigurationChanged={this.onConfigurationChanged.bind(this)}/>;
    }

    private onConfigurationChanged(newConfiguration: AzureCreateCommentsConfiguration) {
        this.configuration = Object.assign(this.configuration, newConfiguration);
    }
};
