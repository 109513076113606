import React from 'react';
import {UI} from 'src/engrator-core';
import {Icon20} from "../../engrator-core/ui";

type Props = {
    optionSelectedHandler: (option: MoreOptions) => void;
}

type State = {
    isOpened: boolean;
}

export enum MoreOptions {
    'Settings' = 'Settings',
    'Advanced' = 'Advanced',
    'Share' = 'Share',
}

export class MoreBtn extends React.Component<Props, State> {
    private wrapperRef: any;

    constructor(props: Props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.state = {
            isOpened: false
        };
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.documentClick.bind(this));
        document.addEventListener('touchstart', this.documentClick.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.documentClick);
        document.removeEventListener('touchstart', this.documentClick);
    }

    documentClick(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpened) {
            this.setState({isOpened: false}, () => {
                // this.props.onCloseHandler();
            });
        }
    }

    private switchPopup() {
        this.setState({isOpened: !this.state.isOpened});
    }

    render() {
        return <div className={`more-btn`} ref={this.setWrapperRef}>
            <UI.Button
                icon={ <UI.Icon icon={"ellipsis-v"} /> }
                text={`More`}
                appearance="secondary"
                onClick={() => this.setState({ isOpened: !this.state.isOpened })}
            />
            { this.state.isOpened && <div className={`popup`}>
                <ul>
                    <li data-sel="settings" onClick={ () => this.optionClicked(MoreOptions.Settings)}>
                        <UI.Icon icon20={Icon20.Settings} /> Settings
                    </li>
                    <li onClick={ () => this.optionClicked(MoreOptions.Advanced)}>
                        <UI.Icon icon20={Icon20.Advanced} /> Advanced
                    </li>
                    <li onClick={ () => this.optionClicked(MoreOptions.Share)}>
                        <UI.Icon icon20={Icon20.Share} /> Share
                    </li>
                </ul>
            </div> }
        </div>
    }

    private optionClicked(option: MoreOptions): void {
        this.props.optionSelectedHandler(option);
        this.setState({ isOpened: false });
    }
}
