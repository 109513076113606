export function convertSearchParamsToFiltersData(entries: string[][]): any {
    const filtersData: any = {};
    entries.forEach(entry => {
        const filterKeyName = entry[0];
        const regex = /(.*)\[(.*)\]/gm;
        const  objectFilter: any = regex.exec(filterKeyName);
        if (objectFilter) {
            const filterName = objectFilter[1];
            if (!filtersData[filterName]) {
                filtersData[filterName] = {};
            }
            filtersData[filterName][objectFilter[2]] = entry[1];
        } else {
            filtersData[entry[0]] = entry[1];
        }
    });
    return filtersData;
}