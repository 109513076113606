import { axiosWrapper } from 'src/engrator-core';
import { SystemInfo, ThreadsSummary } from './threads-summary.type';

let systemInfo: SystemInfo;

export enum FeatureFlag {
    FF_ENABLE_JIRA_DB_SYNC = 'FF_ENABLE_JIRA_DB_SYNC',
    SHOW_BOOK_DEMO = 'SHOW_BOOK_DEMO',
    IMPROVED_MAPPINGS = 'IMPROVED_MAPPINGS',
    ALERTS_GETINT_CLOUD = 'ALERTS_GETINT_CLOUD',
    FF_LOGO = 'FF_LOGO',
    FF_NEW_FIELD_MAPPING = 'FF_NEW_FIELD_MAPPING',
    MIN_INTERVAL_IN_SECONDS = 'MIN_INTERVAL_IN_SECONDS',
    IS_SAAS = 'IS_SAAS'
}

export const fetchIntegrationThreadsSumary = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`/analysis/threads/summary`)
                .then((data: any) => {
                    resolve(data as ThreadsSummary);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchSystemInfo = (): Promise<SystemInfo> => {
    console.error(new Error().stack);
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`/system/info`)
                .then((data: any) => {
                    systemInfo = (data as SystemInfo);
                    resolve(data as SystemInfo);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export async function getSystemInfo(): Promise<SystemInfo | undefined> {
    if (!systemInfo) {
        await fetchSystemInfo();
    }
    return systemInfo;
}

export async function isFeatureFlagEnabled(flag: FeatureFlag): Promise<boolean> {
    return (await getSystemInfo())?.featureFlags[flag] === 'true';
}