import { axiosWrapper } from 'src/engrator-core';

const ENDPOINT_URL = '/system/api/license';

export type SystemLicenseInfo = {
    trial: boolean;
    expirationDate: string;
    urls: string;
};

export const fetchLicenseInfo = (): Promise<SystemLicenseInfo> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const setNewLicense = (license: string): Promise<SystemLicenseInfo> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}`, { license })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
