import { axiosWrapper } from 'src/engrator-core';

export type DebugResult = {
    data?: string;
    error?: string;
};

const ENDPOINT_URL = '/integration/connections';

export function deleteConnection(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.delete(ENDPOINT_URL + '/' + id)
            .then((data: any) => {
                resolve();
            })
            .catch((error: any) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
