import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { SoftwareName } from '../../software-name';
import { fetchBases, fetchTables } from "./rest-api";
import {AirtableBaseDTO, AirtableTableDTO} from "./airtable-smart-ints-trigger-configuration";
import {DropdownOption} from "../../../engrator-core/ui";

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {
    isLoading: boolean;
    bases?: DropdownOption[];
    tables?: DropdownOption[];
};

export class AirtableSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false
        };
        this.smartIntTrigger = {
            app: SoftwareName.Airtable,
            artifactName: '',
            className: '',
            configuration: {
                query: '',
            },
            connectionId: this.props.connectionId,
        };
    }

    async componentDidMount(): Promise<void> {
        await this.setState({ isLoading: true });
        const results = await fetchBases(this.smartIntTrigger.connectionId);
        const databaseOptions: DropdownOption[] = results.map(s => ({
            value: s.id,
            label: s.name,
            object: s
        }));
        this.setState({ isLoading :false, bases: databaseOptions });
    }

    render() {
        return <React.Fragment>
            { this.state.bases && <UI.FormGroup
                label={`Space`}
                isRequired={ true }
            >
                <UI.Dropdown
                    options={ this.state.bases }
                    onChange={ (value: string, obj: AirtableBaseDTO) => this.setBase(obj) }
                />
            </UI.FormGroup> }
            { this.state.tables && <UI.FormGroup
                label={`Table`}
                isRequired={ true }
            >
                <UI.Dropdown
                    options={ this.state.tables }
                    onChange={ (value: string, obj: AirtableTableDTO) => this.setTable(obj) }
                />
            </UI.FormGroup> }
            { this.state.isLoading && <UI.Loader visible={ true } appearance={"darkgray"} /> }
            {/*<UI.FormGroup*/}
            {/*    label={`Additional query`}*/}
            {/*    description={``}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={ this.onJqlInputChanged.bind(this)} />*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private setBase(item: AirtableBaseDTO) {
        this.smartIntTrigger.configuration.base = item;
        this.smartIntTrigger.artifactName = 'task';
        // this.props.onConfigured(this.smartIntTrigger!);
        this.setState({ isLoading: true }, () => {
            fetchTables(this.props.connectionId, item)
                .then(tables => {
                    this.setState({ isLoading: false,
                        tables: tables.map(s => ({
                            value: s.id,
                            label: s.name,
                            object: s
                        }))
                    });
                });
        });
    }

    private setTable(item: AirtableTableDTO) {
        this.smartIntTrigger.configuration.table = item;
        // this.smartIntTrigger.artifactName = 'task';
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}
