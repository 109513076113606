import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { Software } from 'src/generic';
import { createGenericConnectionFactory } from 'src/generic/connections';
import { AuthMethod } from 'src/generic/connections/auth-method.type';
import { PipelineGenericStep } from 'src/generic/pipeline-step';
import { SoftwareName } from '../software-name';

import { FreshdeskPipelineSteps } from './freshdesk-pipeline-steps';
import { FreshdeskSmartintsSupport } from './smart-ints';


export class FreshdeskSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.Freshdesk, [AuthMethod.BasicAuth]);
    }

    getName(): string {
        return 'Freshdesk';
    }

    getID() {
        return 'Freshdesk';
    }

    getAvailableSteps(): object[] {
        return FreshdeskPipelineSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }

    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new FreshdeskSmartintsSupport();
    }
}
