import React from 'react';

import {EditPipelineStepComponentProps} from "src/generic";
import {UI} from "src/engrator-core";
import {ArtifactDirection} from "src/generic/artifacts";

import {ZendeskUpdateItemConfiguration} from "./zendesk-update-item-configuration";

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class ZendeskUpdateItemEditStep extends React.Component<Props, State> {
    private configuration: ZendeskUpdateItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as ZendeskUpdateItemConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
        this.props.showArtifactBuilder(ArtifactDirection.In);
    }

    render() {
        return <React.Fragment>
        </React.Fragment>;
    }
};