import React from 'react';
import { UI } from 'src/engrator-core';
import { ContextMenuItem, OnContextMenuItemSelected } from 'src/engrator-core/ui';

type Props = {
    onSelected: OnContextMenuItemSelected;
    actions?: string[];
};

type State = {
};

export function getAvailableContextItems(actions?: string[]): ContextMenuItem[] {
    const items = [
        {
            id: 'Edit',
            name: 'Edit',
        },
        {
            id: 'Export',
            name: 'Export',
        },
        {
            id: 'Debug',
            name: 'Debug Requests',
        },
        {
            id: 'Delete',
            name: 'Delete',
        }
    ];
    if (actions) {
        return items.filter(it => actions.indexOf(it.id) >= 0);
    }
    return items;
}

export class ConnectionListItemContextMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div className={`integrations-list-context-menu`}>
            <UI.ContextMenu
                getItems={ () => getAvailableContextItems(this.props.actions) }
                onSelected={ this.onSelectedHandler.bind(this) }
            />
        </div>
    }

    private onSelectedHandler(item: ContextMenuItem) {
        this.props.onSelected(item);
    }
}
