import React from 'react';

import { UI } from 'src/engrator-core';
import { DropdownOption } from '../../../../engrator-core/ui';
import { UpdateStrategyMode, UpdateStrategySettings, UpdateStrategySubMode } from './update-strategy-settings.type';

type Props = {
    settings: UpdateStrategySettings;
};

type State = {
    settings: UpdateStrategySettings;
};

export class IntegrationUpdateStrategy extends React.Component<Props, State> {
    private modeOptions: DropdownOption[] = [
        {
            label: 'Use data of latest updated artifact - only one artifact will be updated',
            value: UpdateStrategyMode.UseLatestUpdatedData
        },
        { label: 'Merge changes - both artifacts will be updated', value: UpdateStrategyMode.MergeChangedFromBoth },
    ];
    private subModeOptions: DropdownOption[] = [
        { label: 'Prefer value from left app', value: UpdateStrategySubMode.PreferLeftAppData },
        { label: 'Prefer value from right app', value: UpdateStrategySubMode.PreferRightAppData },
    ];

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            settings: this.props.settings
        };
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                label={`Update strategy`}
                description={`Define how the data update should work when changes are detected in both linked artifacts`}
            >
                <UI.Dropdown
                    defaultValue={this.state.settings.updateStrategyMode}
                    disableEmptyOption={true}
                    options={this.modeOptions}
                    onChange={this.modeChanged.bind(this)}/>
                {this.state.settings.updateStrategyMode === UpdateStrategyMode.MergeChangedFromBoth && <React.Fragment>
                    <p>Which value to use when mapped fields are updated on both sides</p>
                    <UI.Dropdown
                        label={``}
                        defaultValue={this.state.settings.updateStrategySubMode}
                        disableEmptyOption={true}
                        options={this.subModeOptions}
                        onChange={this.subModeChanged.bind(this)}/>
                </React.Fragment>
                }
            </UI.FormGroup>
        </React.Fragment>;
    }

    private modeChanged(value: UpdateStrategyMode) {
        const settings = this.state.settings;
        settings.updateStrategyMode = value;
        settings.updateStrategySubMode = UpdateStrategySubMode.PreferLeftAppData;
        this.setState({ settings });
    }

    private subModeChanged(value: UpdateStrategySubMode) {
        const settings = this.state.settings;
        settings.updateStrategySubMode = value;
        this.setState({ settings });
    }
}
