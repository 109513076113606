import React from "react";
import {UI} from "src/engrator-core";
import {DropdownOption} from "../../../../../engrator-core/ui";
import {SoftwareLogotype} from "../../../../../designer/step-creator/software-logotype";
import {AppsSupport} from "../visual-integration-designer";
import {getOppositeSide, Side} from "../../../../../generic";
import {PredefinedPropertyDefinition, PropertyDataType, PropertyDefinition} from "../../../../../generic/artifacts";
import {
    createDefaultPropertyMappingOptions,
    getDirectionForNewMapping,
    PropertyMapping
} from "../property-mapping.type";
import {getDefaults} from "../components/value-mappings/default-mappping.type";
import {fetchPropertiesNew, fetchPropertiesOptions} from "../rest-api";
import {SoftwareName} from "../../../../../software";
import {SmartIntDefinition} from "../../definition";
import {SmartIntDefinitionTypeMapping} from "../../definition/smart-int-definition--type-mapping.type";
import {SmartIntTriggersPair} from "../../definition/smart-int-trigger.type";
import {convertToDropDownOptions} from "../components/value-mappings/value-mappings-options-fetcher";
import {ValueMappingsGroup} from "../components/value-mappings/value-mappings-group.type";
import {PropertiesContext} from "../../configuration";

enum BuilderStages {
    Source,
    SourceField,
    Destination,
    DestinationField,
    DestinationFixedValueString,
    DestinationFixedValueOption,
    Complete
}

type FieldMappingConfig = {
    fixedValue: string;
    fixedValueLabel: string;
    sourceSide?: Side;
    sourceValue?: string;
    destinationValue?: string;
    currentStage: BuilderStages;
    passedStages: BuilderStages[];
    sourceField?: PropertyDefinition;
    destinationField?: PropertyDefinition;
};

type Props = {
    addMappingHandler: (mapping: PropertyMapping) => Promise<void>;
    options: { left: DropdownOption[]; right: DropdownOption[] };
    appsSupport: AppsSupport;
    triggers: SmartIntTriggersPair;
    typeMapping: SmartIntDefinitionTypeMapping;
    propertiesContext: PropertiesContext;
    valueGroup?: ValueMappingsGroup;
    mappings: PropertyMapping[];
};

type State = {
    sourceApp?: SoftwareName;
    shouldShowOptionsIfFixedValue: boolean;
    config: FieldMappingConfig;
    sourceOptions: DropdownOption[];
    sourceFields: DropdownOption[];
    destinationOptions: DropdownOption[];
    destinationFields: DropdownOption[];
    didAddMapping: boolean;
    selectFieldOptions: DropdownOption[];
    error?: any;
    loading: {
        sourceFields: boolean;
        destinationFields: boolean;
        sourceSelectFieldOptions: boolean;
    },
    isReadOnlySelected?: boolean;
};

export class FieldMapping extends React.Component<Props, State> {
    private fields: {
        [Side.Left]: DropdownOption[],
        [Side.Right]: DropdownOption[]
    } = {
        [Side.Left]: [],
        [Side.Right]: []
    };

    constructor(props: Props, context: any) {
        super(props, context);
        this.state = {
            selectFieldOptions: [],
            didAddMapping: false,
            shouldShowOptionsIfFixedValue: false,
            config: {
                fixedValueLabel: '',
                fixedValue: '',
                currentStage: BuilderStages.Source,
                passedStages: []
            },
            loading: {
                sourceFields: false,
                destinationFields: false,
                sourceSelectFieldOptions: false
            },
            destinationOptions: [],
            sourceFields: [],
            destinationFields: [],
            sourceOptions: [
                { label: this.props.appsSupport.leftApp + ' (' + Side.Left.toLowerCase() + ')', value: Side.Left + '-' + this.props.appsSupport.leftApp, element: <React.Fragment><SoftwareLogotype showName={false} softwareName={ this.props.appsSupport.leftApp }/> { this.props.appsSupport.leftApp}  (left)</React.Fragment> },
                { label: this.props.appsSupport.rightApp + ' (' + Side.Right.toLowerCase() + ')', value: Side.Right + '-' + this.props.appsSupport.rightApp, element: <React.Fragment><SoftwareLogotype showName={false} softwareName={this.props.appsSupport.rightApp}/> { this.props.appsSupport.rightApp }  (right)</React.Fragment> }
            ]
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)) {
            this.forceUpdate();
        }
        if (JSON.stringify(this.props.mappings) !== JSON.stringify(prevProps.mappings)) {
            // Mappings list has changed, need to leave only unpicked properties
            this.forceUpdate();
        }
    }

    render() {
        if (this.state.error) {
            return <React.Fragment><UI.Message appearance={`error-message`}>
                Fields list could not be loaded. Please contact support.<br/>Details: { this.state.error.message || this.state.error }. <UI.DocumentationLink text={`Read more here`} url={`https://docs.getint.io/getintio-platform/connections/permissions-issue-status-code-500`} />
            </UI.Message><br/></React.Fragment>;
        }
        return <div className={`map-fields-container`}>
            <p>Build field mapping with a step-by-step configurator. You can <UI.DocumentationLink text={`read more here`} url={`https://docs.getint.io/getting-started-with-the-platform/integration-synchronization/how-to-map-fields`} /></p>
            <div className={`map-fields`}>
            Synchronize
            { this.shouldDisplayStage(BuilderStages.Source) && <UI.Dropdown
                defaultValue={ this.state.config.sourceValue }
                disableSearch={ true }
                placeholder={`App`}
                sortOptions={ false }
                showOptions={ (this.state.config.currentStage === BuilderStages.Source && this.state.didAddMapping === false) }
                className={ ((this.state.config.currentStage === BuilderStages.Source) ? 'current' : '') + ' source' }
                options={ this.state.sourceOptions }
                onChange={ (value: any) => this.sourceSelected(value) }
            /> }
            <React.Fragment>
                { this.state.loading.sourceFields && <UI.Loader visible={ true } appearance={'darkgray'} /> }
                { !this.state.loading.sourceFields && <UI.Dropdown
                    placeholder={`Field`}
                    defaultValue={ this.state.config.sourceField?.id }
                    className={ ((this.state.config.currentStage === BuilderStages.SourceField) ? 'current' : '') + ' source-field' }
                    options={ this.state.sourceFields }
                    showOptions={ (this.state.config.currentStage === BuilderStages.SourceField) }
                    onChange={ (value: any, object: any) => this.fieldSelected(BuilderStages.SourceField, object) }
                /> }
            </React.Fragment>
            <React.Fragment>
                {/*<div className={`break`}/> */}
                <div className={`destination-option`}>
                with
                <UI.Dropdown
                    className={ ((this.state.config.currentStage === BuilderStages.Destination) ? 'current' : '') + ' destination-choice' }
                    disableSearch={ true }
                    sortOptions={false}
                    defaultValue={ this.state.config.destinationValue}
                    placeholder={`App Field / Fixed Value`}
                    options={ this.state.destinationOptions }
                    showOptions={ (this.state.config.currentStage === BuilderStages.Destination) }
                    onChange={ (value: any) => this.destinationSelected(value) }
                />
            </div></React.Fragment>
            { this.shouldDisplayStage(BuilderStages.DestinationField) && <React.Fragment>
                { this.state.loading.destinationFields && <UI.Loader visible={ true } appearance={'darkgray'} /> }
                { !this.state.loading.destinationFields &&
                <UI.Dropdown
                    placeholder={`Field`}
                    defaultValue={ this.state.config.destinationField?.id }
                    className={ ((this.state.config.currentStage === BuilderStages.DestinationField) ? 'current' : '')  + ' destination-field'}
                    options={ this.state.destinationFields }
                    showOptions={ (this.state.config.currentStage === BuilderStages.DestinationField) }
                    onChange={ (value: any, object: any) => this.fieldSelected(BuilderStages.DestinationField, object) }
                /> }
                {/*field*/}
            </React.Fragment> }
            { this.shouldDisplayStage(BuilderStages.DestinationFixedValueString) && <React.Fragment>
                <UI.Input
                    placeholder={`Type fixed value`}
                    defaultValue={ this.state.config.fixedValue }
                    onChange={ (value: string) => this.setFixedValue(value) }
                />
            </React.Fragment> }
            { this.shouldDisplayStage(BuilderStages.DestinationFixedValueOption) && <React.Fragment>
                { this.state.loading.sourceSelectFieldOptions && <UI.Loader visible={ true } appearance={'darkgray'} /> }
                { !this.state.loading.sourceSelectFieldOptions && <UI.Dropdown
                        disableSearch={ false }
                        placeholder={`Select option`}
                        defaultValue={ this.state.config.fixedValue }
                        className={ ((this.state.config.currentStage === BuilderStages.DestinationFixedValueOption) ? 'current' : '') + ' destination-fixed-option' }
                        options={ this.state.selectFieldOptions }
                        showOptions={ (this.state.config.currentStage === BuilderStages.DestinationFixedValueOption) }
                        onChange={ (value: string, obj: any) => this.setFixedOptionValue(value, obj) }
                    /> }
                </React.Fragment> }
                <React.Fragment>
                    <div className={`break`}/>
                    <UI.Button
                        disabled={ !this.shouldDisplayStage(BuilderStages.Complete) }
                        text={`Add`}
                        // icon={ <UI.Icon icon20={Icon20.CirclePlusWhite} /> }
                        onClick={ () => this.addClicked() }
                    />
                </React.Fragment>
            </div>
        </div>
    }

    private async addClicked(): Promise<void> {
        const leftProperty: PropertyDefinition = (this.state.config.sourceSide === Side.Left)
            ? this.state.config.sourceField! : this.state.config.destinationField!;
        const rightProperty: PropertyDefinition = (this.state.config.sourceSide === Side.Right)
            ? this.state.config.sourceField! : this.state.config.destinationField!;
        const leftPredefinedValue = (leftProperty.id === PredefinedPropertyDefinition.id)
            ? this.state.config.fixedValue : '';
        const leftPredefinedLabel = (leftProperty.id === PredefinedPropertyDefinition.id)
            ? this.state.config.fixedValueLabel : '';
        const rightPredefinedValue = (rightProperty.id === PredefinedPropertyDefinition.id)
            ? this.state.config.fixedValue : '';
        const rightPredefinedLabel = (rightProperty.id === PredefinedPropertyDefinition.id)
            ? this.state.config.fixedValueLabel : '';
        await this.props.addMappingHandler({
            left: leftProperty,
            right: rightProperty,
            direction: getDirectionForNewMapping(leftProperty, rightProperty),
            options: {
                left: {
                    onCreate: true,
                    onUpdate: true,
                    postCreate: this.shouldPostCreateBeAutoChecked(leftProperty, rightProperty),
                    filterOptionsQuery: '',
                    predefinedValue: leftPredefinedValue,
                    predefinedValueLabel: leftPredefinedLabel
                },
                right: {
                    onCreate: true,
                    onUpdate: true,
                    postCreate: this.shouldPostCreateBeAutoChecked(rightProperty, leftProperty),
                    filterOptionsQuery: '',
                    predefinedValue: rightPredefinedValue,
                    predefinedValueLabel: rightPredefinedLabel
                }
            },
            groups: [],
            defaults: getDefaults(leftProperty, rightProperty),
            empty: {
                left: '',
                right: ''
            },
            additional: {
                azureEmailIsJiraUsername: false
            }
        });
        const config: FieldMappingConfig = {
            currentStage: BuilderStages.Source,
            passedStages: [],
            fixedValue: '',
            fixedValueLabel: ''
        };
        this.setState({ config, didAddMapping: true, sourceFields: [], destinationOptions: [], isReadOnlySelected: false });
    }

    private async setFixedOptionValue(value: string, obj: any): Promise<void> {
        const config = this.state.config;
        config.fixedValue = value;
        config.fixedValueLabel = obj.name || '';
        await this.setState({ config });
        await this.markStageAsPassed(BuilderStages.DestinationFixedValueOption, BuilderStages.Complete);
        await this.addClicked();
    }

    private async setFixedValue(value: string): Promise<void> {
        const config = this.state.config;
        config.fixedValue = value;
        await this.setState({ config });
        await this.markStageAsPassed(BuilderStages.DestinationFixedValueString, BuilderStages.Complete);
    }

    private shouldPostCreateBeAutoChecked(target: PropertyDefinition, source: PropertyDefinition) {
        return (!target.options?.readonly) && (source.id === 'id' || source.id === '_url');

    }

    private async fieldSelected(stage: BuilderStages, field: PropertyDefinition): Promise<void> {
        if (stage === BuilderStages.SourceField) {
            const config = this.state.config;
            config.sourceField = field;
            const destinationFields = this.state.destinationFields;
            const destinationOptions: DropdownOption[] = [];
            const oppositeSide = getOppositeSide(config.sourceSide!);
            const otherSideApp = (oppositeSide === Side.Left) ? this.props.appsSupport.leftApp : this.props.appsSupport.rightApp;
            destinationOptions.push({ label: otherSideApp + ` (${ oppositeSide.toLowerCase()})`, value: otherSideApp });
            destinationOptions.push({ label: 'Fixed value', value: 'predefinedValue' });
            for (const destinationField of destinationFields) {
                const destinationProperty = (destinationField.object) as PropertyDefinition;
                destinationField.hidden = false;
                destinationField.available = true;
                // If source field is status field, let it to be synced only with string fields
                if (field.id === 'status' || field.id === 'System.State') {
                    destinationField.available = (destinationProperty.dataType === PropertyDataType.String && !destinationProperty.options?.readonly);
                }
            }
            const shouldShowOptionsIfFixedValue = field.options?.selectable === true; /*|| field.dataType === PropertyDataType.IdLabel*/
            await this.setState({ destinationOptions, destinationFields, config, shouldShowOptionsIfFixedValue, isReadOnlySelected: field.options?.readonly });
            this.leaveOnlyNotPicked(oppositeSide, destinationFields);
            await this.markStageAsPassed(BuilderStages.SourceField, BuilderStages.Destination)
        } else if (stage === BuilderStages.DestinationField) {
            const config = this.state.config;
            config.destinationField = field;
            await this.setState({ config });
            await this.markStageAsPassed(BuilderStages.DestinationField, BuilderStages.Complete)
            await this.addClicked();
        }
    }

    private async destinationSelected(value: any): Promise<void> {
        const config = this.state.config;
        config.destinationValue = value;
        this.setState({ config });
        if (value === 'predefinedValue') {
            const config = this.state.config;
            config.destinationField = PredefinedPropertyDefinition;
            await this.setState({ config });
            // if predefined selected, lets see if it is a id label (dorpdown) field. If so we should show option to select
            if (this.state.shouldShowOptionsIfFixedValue) {
                const loading = this.state.loading;
                loading.sourceSelectFieldOptions = true;
                await this.setState({loading});
                await this.markStageAsPassed(BuilderStages.Destination, BuilderStages.DestinationFixedValueOption);
                const trigger = this.props.triggers[this.state.config.sourceSide!];
                const smartIntType = (this.state.config.sourceSide === Side.Left) ? this.props.typeMapping.left : this.props.typeMapping.right;
                try {
                    const options = await fetchPropertiesOptions(this.state.sourceApp!, this.state.config.sourceField!, trigger, smartIntType, createDefaultPropertyMappingOptions());
                    const selectFieldOptions = convertToDropDownOptions(options);
                    loading.sourceSelectFieldOptions = false
                    await this.setState({loading, selectFieldOptions});
                } catch (error) {
                    await this.setState({ error });
                }
            } else {
                await this.markStageAsPassed(BuilderStages.Destination, BuilderStages.DestinationFixedValueString);
            }
        } else {
            await this.markStageAsPassed(BuilderStages.Destination, BuilderStages.DestinationField);
        }
        const input = document.querySelector('input[placeholder="Type fixed value"]') as HTMLInputElement;
        if (input) {
            input.focus();
        }
    }

    private async sourceSelected(value: any): Promise<void> {
        const tab = value.split('-');
        const side: Side = tab[0];
        const sourceApp: SoftwareName = tab[1];
        const oppositeSide = getOppositeSide(side);
        const otherSideApp = (oppositeSide === Side.Left) ? this.props.appsSupport.leftApp : this.props.appsSupport.rightApp;
        const destinationOptions: DropdownOption[] = [];
        const sourceFields = (side === Side.Left) ? this.props.options.left : this.props.options.right;
        const destinationFields = (oppositeSide === Side.Left) ? this.props.options.left : this.props.options.right;
        const config = this.state.config;
        config.sourceSide = side;
        config.sourceValue = value;
        config.destinationValue = '';
        config.fixedValue = '';
        const loading = this.state.loading;
        loading.sourceFields = true;
        await this.setState({ loading, sourceApp, config, destinationOptions, sourceFields, destinationFields });
        await this.markStageAsPassed(BuilderStages.Source, BuilderStages.SourceField);
        try {
            // Fetch source fields
            {
                const smartIntType = (this.state.config.sourceSide === Side.Left) ? this.props.typeMapping.left : this.props.typeMapping.right;
                const trigger = this.props.triggers[this.state.config.sourceSide!];
                const value = (this.state.config.sourceSide === Side.Left) ? this.props.valueGroup?.leftValue : this.props.valueGroup?.rightValue;
                let sourceFields;
                if (this.fields[side].length > 0) {
                    sourceFields = this.fields[side];
                } else {
                    sourceFields = await fetchPropertiesNew(sourceApp, trigger, smartIntType, [], this.props.propertiesContext, value);
                    this.fields[side] = sourceFields;
                }
                this.leaveOnlyNotPicked(side, sourceFields);
                const loading = this.state.loading;
                loading.sourceFields = false;
                await this.setState({loading, sourceFields});
            }
            // Fetch destination fields in async way
            {
                const smartIntType = (oppositeSide === Side.Left) ? this.props.typeMapping.left : this.props.typeMapping.right;
                const trigger = this.props.triggers[oppositeSide!];
                const value = (oppositeSide === Side.Left) ? this.props.valueGroup?.leftValue : this.props.valueGroup?.rightValue;
                let loading = this.state.loading;
                loading.destinationFields = true;
                let destinationFields;
                if (this.fields[oppositeSide].length > 0) {
                    destinationFields = this.fields[oppositeSide];
                } else {
                    destinationFields = await fetchPropertiesNew(otherSideApp, trigger, smartIntType, [], this.props.propertiesContext, value);
                    this.fields[oppositeSide] = destinationFields;
                }
                this.leaveOnlyNotPicked(oppositeSide, destinationFields);
                // fetchPropertiesNew(otherSideApp, trigger, smartIntType, [], this.props.propertiesContext, value)
                //     .then((destinationFields) => {
                        loading = this.state.loading;
                        loading.destinationFields = false;
                        this.setState({loading, destinationFields});
                    // }).catch((error) => {/**/
                    //     this.setState({ error });
                // });
            }
        } catch (error) {
            await this.setState({ error });
        }
    }

    /**
     * Leave only not picked properties/fields
     * @param side
     * @param fields
     * @private
     */
    private leaveOnlyNotPicked(side: Side, fields: DropdownOption[]): void {
        const sideString: 'left' | 'right' = side.toLowerCase() as 'left' | 'right';
        const pickedIds = this.props.mappings.map(m => m[sideString].id);
        for (const option of fields) {
            const isReadOnlyCheck = this.state.isReadOnlySelected && (option.object as PropertyDefinition).options?.readonly;
            option.available = pickedIds.indexOf(option.value) === -1 && !isReadOnlyCheck;
        }
    }

    private async markStageAsPassed(stage: BuilderStages, nextStage: BuilderStages): Promise<void> {
        const config = this.state.config;
        config.passedStages = config.passedStages.filter(s => s < stage);
        config.passedStages.push(stage);
        config.currentStage = nextStage;
        this.setState({ config });
    }

    private shouldDisplayStage(stage: BuilderStages): boolean {
        return this.state.config.currentStage === stage
        || this.state.config.passedStages.indexOf(stage) >= 0;
    }
}