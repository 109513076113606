import React from 'react';
import {FieldError, UI} from "../../engrator-core";
import {ComponentContext, PipelineStep, PipelineStepArtifact, PipelineStepConfiguration} from "./pipeline-step";
import {ArtifactDirection} from "../artifacts";
import {ArtifactBuilderScene, DataArtifactBuilder} from "../artifact-builder";
import {ArtifactBuilder} from "../index";
import {StepAction, validateStep} from "./rest-api";
import {SoftwareName} from "../../software";
import {IntegrationStepConfiguration} from "../software/step";
import {FormError} from "../../engrator-core/form-error.type";
import {PipelineStepRules} from "./rules";
import {PipelineStepRule} from "./rules/pipeline-step-rule.type";
import {StepRightPanelFooter} from "./step-right-panel-footer";
import { SoftwareLogo } from 'src/designer/step-creator/software-logo';

type Props = {
    context: ComponentContext,
    componentClass: any,
    configuration: PipelineStepConfiguration;
    pipelineStep: PipelineStep<any>,
    isEdit: boolean,
    deleteStepHandler?: () => void,
    addStepHandler: (configuration: IntegrationStepConfiguration) => void
};
type State = {
    formError?: FormError;
    showOutArtifact: boolean;
    showInArtifact: boolean;
    isRequestPending: boolean;
};

export class StepRightPanelComponent extends React.Component<Props, State> {
    private outDataArtifactBuilder?: DataArtifactBuilder;
    private configuration: PipelineStepConfiguration = {};
    private inDataArtifactBuilder?: DataArtifactBuilder;
    private inDataArtifact?: any;
    private outDataArtifact?: any;
    private rules: PipelineStepRule[] = [];

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = props.configuration;
        this.state = {showOutArtifact: false, showInArtifact: false, isRequestPending: false };
    }

    render() {
        const Component = this.props.componentClass;
        const Footer = <StepRightPanelFooter
            isRequestPending={this.state.isRequestPending}
            deleteStepHandler={(this.props.deleteStepHandler)}
            validateStepBeforeAdd={this.validateStepBeforeAdd.bind(this)}
            isEdit={this.props.isEdit}/>
        return <UI.RightPanelContent
            header={ <React.Fragment>
                <SoftwareLogo softwareName={ this.props.pipelineStep.specification.software } />
                { this.props.pipelineStep.specification.action }
            </React.Fragment>
        }
            footer={Footer}>
            <UI.Form
                errors={[]}
            >
                <PipelineStepRules onRulesChanged={this.onRulesChanged.bind(this)}/>
                <UI.FormSection
                    label={`Step Configuration`}
                >
                    <Component
                        context={this.props.context}
                        addStepHandler={this.props.addStepHandler}
                        configuration={this.configuration}
                        formError={this.state.formError}
                        showArtifactBuilder={this.showArtifactBuilder.bind(this)}
                    />
                </UI.FormSection>
                {this.state.showInArtifact && <React.Fragment>
                    <UI.FormSection
                        label={`Configure InArtifact`}
                    >
                    <UI.FormGroup
                        error={this.state.formError?.inDataArtifact}
                        label=""
                        isRequired={true}
                        description="Artifact that will be persisted by this step">
                        <ArtifactBuilderScene
                            stepConfigurationFetcher={this.getStepConfigurationFetcher.bind(this)}
                            connectionId={this.props.context.connectionId}
                            dataArtifactBuilder={this.inDataArtifactBuilder!}
                            softwareName={this.props.context.stepSpecification.software as SoftwareName}
                        />
                    </UI.FormGroup>
                    </UI.FormSection>
                </React.Fragment>}

                {this.state.showOutArtifact && <React.Fragment>
                    <UI.FormSection
                        label={`Configure OutArtifact`}
                    >
                        <UI.FormGroup
                            error={this.state.formError?.outDataArtifact}
                            label=""
                            isRequired={true}
                            description="Artifact that will be produced by this step">
                            <ArtifactBuilderScene
                                stepConfigurationFetcher={this.getStepConfigurationFetcher.bind(this)}
                                dataArtifactBuilder={this.outDataArtifactBuilder!}
                                connectionId={this.props.context.connectionId}
                                softwareName={this.props.context.stepSpecification.software as SoftwareName}
                            />
                        </UI.FormGroup>
                    </UI.FormSection>
                </React.Fragment>}
            </UI.Form>
        </UI.RightPanelContent>
    }

    private validateStepBeforeAdd() {
        const action = (this.props.isEdit) ? StepAction.BeforeUpdate : StepAction.BeforeAdd;
        this.setState({ isRequestPending: true }, () => {
            validateStep(
                this.props.pipelineStep.specification.software as SoftwareName,
                this.props.pipelineStep.specification.key,
                action,
                this.getStepConfiguration())
                .then(() => {
                    this.props.addStepHandler(this.getStepConfiguration())
                })
                .catch((fieldError: FieldError) => {
                    const formError = {[fieldError.fieldName]: fieldError.message};
                    this.setState({formError, isRequestPending: false });
                });
        });
    }

    private onRulesChanged(rules: PipelineStepRule[]) {
        this.rules = rules;
    }

    private getStepConfiguration(): any {
        if (this.props.isEdit) {
            return this.configuration;
        }
        const stepConfiguration: IntegrationStepConfiguration = {
            inDataArtifact: this.inDataArtifact,
            outDataArtifact: this.outDataArtifact,
            connectionId: this.props.context.connectionId,
            data: this.configuration,
            rules: this.rules,
            stepSpecification: this.props.pipelineStep.specification
        };
        return stepConfiguration;
    }

    private showArtifactBuilder(direction: ArtifactDirection) {
        const newState : { showOutArtifact?: boolean, showInArtifact?: boolean } = {};
        if (direction === ArtifactDirection.Out && !this.outDataArtifactBuilder) {
            const artifactName = this.getOutArtifactName();
            const artifactToBuild = new PipelineStepArtifact(
                ArtifactDirection.Out,
                artifactName,
                this.getStepConfiguration(),
                (this.configuration as any).outDataArtifact?.properties
            );
            this.outDataArtifactBuilder = new ArtifactBuilder.DataArtifactBuilder(
                direction,
                artifactToBuild,
                this.props.context.artifactsFactory,
                (properties: Array<any>, persistedArtifactBuilder: any) => {
                    this.outDataArtifact = persistedArtifactBuilder;
                }
            );
            newState.showOutArtifact = true;
            this.setState({showOutArtifact: true});
        }
        if (direction === ArtifactDirection.In && !this.inDataArtifactBuilder) {
            const artifactToBuild = new PipelineStepArtifact(
                ArtifactDirection.In,
                '',
                this.getStepConfiguration(),
                (this.configuration as any).inDataArtifact?.properties
            );
            this.inDataArtifactBuilder = new ArtifactBuilder.DataArtifactBuilder(
                direction,
                artifactToBuild,
                this.props.context.artifactsFactory,
                (properties: Array<any>, persistedArtifactBuilder: any) => {
                    this.inDataArtifact = persistedArtifactBuilder;
                }
            );
            this.setState({showInArtifact: true});
        }
    }

    private getStepConfigurationFetcher() {
        return this.configuration;
    }

    private getOutArtifactName(): string {
        if (!this.props.isEdit) {
            return this.props.pipelineStep.preferredNameForArtifact;
        }
        return ((this.configuration as any)?.outDataArtifact) ? (this.configuration as any)?.outDataArtifact.name : '';
    }
}
