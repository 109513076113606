import React from "react";

import { UI } from 'src/engrator-core';


type Props = {
    selectedTabIndex: number;
    onTabSelected: (tabIndex: number) => void;
};
type State = {
};

export class NavBar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <UI.TabBar>
                {/* <UI.TabButton text="General Info" onClick={ () => this.props.onTabSelected(0) } isSelected={ this.props.selectedTabIndex === 0 } /> */}
                <UI.TabButton text="Configuration" onClick={ () => this.props.onTabSelected(1) } isSelected={ this.props.selectedTabIndex === 1 } />
                <UI.TabButton text="Logs" onClick={ () => this.props.onTabSelected(2) } isSelected={ this.props.selectedTabIndex === 2 } />
            </UI.TabBar>
    }
}