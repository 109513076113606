import React from 'react';

import {isJiraServerNativeApp, UI} from 'src/engrator-core';

type ReportsTaBbarProps = {
    selectedItem: 'RunsErrors' | 'Integration Threads' | 'Performance' | 'Logs' | 'SyncErrors'
};
export class AnalysisNavBar extends React.Component<ReportsTaBbarProps> {
    render() {
        return <React.Fragment>
            <div className={`analysis-nav-bar`}>
                <UI.TabBar>
                    {/*<UI.TabButton text={`Performance`} path={`/app/analysis/threads`} isSelected={ this.props.selectedItem === 'Performance' } />*/}
                    { !isJiraServerNativeApp && <UI.TabButton text={`Integration Threads`} path={`/app/analysis/threads`} isSelected={ this.props.selectedItem === 'Integration Threads' } /> }
                    <UI.TabButton text={`Runs Errors`} path={`/app/analysis/errors`} isSelected={ this.props.selectedItem === 'RunsErrors' } />
                    <UI.TabButton text={`Sync Errors`} path={`/app/analysis/syncerrors`} isSelected={ this.props.selectedItem === 'SyncErrors' } />
                    {/*<UI.TabButton text={`Logs`} path={`/app/analysis/logs`} isSelected={ this.props.selectedItem === 'Logs' } />*/}
                </UI.TabBar>
            </div>
        </React.Fragment>
    }
}