import React, {Component} from 'react';
import {OnContextMenuItemSelected} from 'src/engrator-core/ui';
import {ConnectionListItemContextMenu} from './connections-list-item-context-menu';
import './connections-list-page.css';
import {IntegrationAccessLevel} from "../../integration-suites/details/integration-details-model";


type State = {};
type Props = {
    handler: OnContextMenuItemSelected;
    accessLevel: IntegrationAccessLevel;
};


export class ConnectionsListActions extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.accessLevel === IntegrationAccessLevel.CanDelete) {
            return <ConnectionListItemContextMenu
                actions={['Delete']}
                onSelected={(item) => this.props.handler(item)}
            />
        }
        return <ConnectionListItemContextMenu
            onSelected={(item) => this.props.handler(item)}
        />
    }
}