import React from 'react';
import { UI } from 'src/engrator-core';
import { TimeDisplay } from 'src/engrator-core/ui';
import { disableTenant, fetchClusterTenants } from './../rest-api';
import { getTenantHealth } from './health';
import { TenantPropertiesApplier } from './properties/tenant-properties-applier';
import { TenantLinks } from './tenant-links';
import { TenantsCreate } from './tenants-create';
import { ListFilters, TenantsListFilters } from './tenants-list-filters';

type Props = {};
type State = {
    showCreateTenantPopUp: boolean;
    setTenantProperties: string;
};

export type JiraLicense = {
    paid: boolean;
    jiraLicenseInactiveSince: string;
    active: boolean;
    errorMessage: string;
    lastCheckTs?: string;
};

export type TenantInfo = {
    visible: boolean;
    url: string;
    createdAt: string;
    state: string;
    jiraUrl: string;
    tenantName: string;
    preferredApps: string;
    uniqueId: string;
    activity?: {
        lastActivityDateTime: string;
        integrationsCount: number;
        persistedItemsCount: number;
        connectionsCount: number;
        failedRunsCount: number;
        failedFlowsCount: number;
        runsCount: number;
        jiraLicenses: JiraLicense[];
        lastRuns?: {
            failed: number;
            successful: number;
        };
        lastFlows?: {
            failed: number;
            successful: number;
        };
    }
};

export class TenantsList extends React.Component<Props, State> {
    private tenants: TenantInfo[] = [];

    constructor(props: Props) {
        super(props);
        this.load();
        this.state = {
            setTenantProperties: '',
            showCreateTenantPopUp: false,
        };
    }

    render() {
        return <div>
            {this.tenants && <React.Fragment>
                {this.state.setTenantProperties && <TenantPropertiesApplier
					close={() => this.closePopUps()}
					tenantName={this.state.setTenantProperties}
				/>}

                {this.state.showCreateTenantPopUp && <TenantsCreate
					close={() => this.closePopUps()}
					onTenantCreated={() => this.onTenantCreated()}
				/>}
				<UI.Header
					title={'Workspaces'}
					buttons={[<UI.Button
                        onClick={() => this.setState({showCreateTenantPopUp: true})}
                        text="Create Workspace"
                    />]}
				/>
				<TenantsListFilters search={this.filterList.bind(this)}/>
				<table className="styled-table" style={{ width: '100%' }}>
					<thead>
					<tr>
						<th>Name</th>
						<th>Created / Last act.</th>
						<th>State</th>
						<th>Int.</th>
						<th>Conn.</th>
						<th>Pers.<br/>Items</th>
						<th>Runs</th>
						<th>Last<br/>Runs</th>
						<th>Failed<br/>Flows</th>
						<th>Last<br/>Flows</th>
						<th>Health</th>
						<th>Actions</th>
					</tr>
					</thead>
                    {this.tenants.filter(tenant => tenant.visible)
                        .map((tenant, index) => <tr key={index}>
                            <td>
                                <p><a href={tenant.url} target="_blank">{tenant.tenantName}</a></p>
                                <p>{tenant.jiraUrl} | {tenant.preferredApps} | {tenant.uniqueId} </p>
                                <TenantLinks jiraUrl={tenant.jiraUrl}/>
                            </td>
                            <td>
                                <TimeDisplay dateTime={tenant.createdAt} /><br/>
                                <TimeDisplay dateTime={tenant.activity?.lastActivityDateTime} />
                            </td>
                            <td>{tenant.state}</td>
                            <td>{tenant.activity?.integrationsCount}</td>
                            <td>{tenant.activity?.connectionsCount}</td>
                            <td><span
                                className={(tenant.activity && tenant.activity.persistedItemsCount > 0) ? `count-ok` : ``}>{tenant.activity?.persistedItemsCount}</span>
                            </td>
                            <td>
                                <span
                                    className={(tenant.activity && tenant.activity.failedRunsCount > 0) ? `count-failed` : ``}>{tenant.activity?.failedRunsCount}</span> | {tenant.activity?.runsCount}
                            </td>
                            <td><span
                                className={(tenant.activity && tenant.activity.lastRuns && tenant.activity.lastRuns?.failed > 0) ? `count-failed` : ``}>{tenant.activity?.lastRuns?.failed}</span> | {tenant.activity?.lastRuns?.successful}
                            </td>
                            <td>{tenant.activity?.failedFlowsCount}</td>
                            <td><span
                                className={(tenant.activity?.lastFlows && tenant.activity.lastFlows?.failed > 0) ? `count-failed` : ``}>{tenant.activity?.lastFlows?.failed}</span> | {tenant.activity?.lastFlows?.successful}
                            </td>
                            <td className={getTenantHealth(tenant)}>{getTenantHealth(tenant)}</td>
                            <td style={{ width: '140px' }} className={`actions`}>
                                {tenant.state === 'Enabled' &&
								<UI.Button appearance="secondary" onClick={() => this.disable(tenant)} text="Disable"/>}
                                <UI.Button appearance="secondary" onClick={() => this.setTenantProperty(tenant)}
                                           text="Properties"/>
                            </td>
                        </tr>)}
				</table>
			</React.Fragment>}
        </div>;
    }

    private filterList(filters: ListFilters) {
        this.tenants.forEach((tenant) => {
            tenant.visible = true;
            if (filters.query && !(tenant.jiraUrl.toLowerCase()
                .indexOf(filters.query.toLowerCase()) > -1 || tenant.url.toLowerCase()
                .indexOf(filters.query.toLowerCase()) > -1)) {
                tenant.visible = false;
            }
            if (!filters.showDisabled && tenant.state === 'Disabled') {
                tenant.visible = false;
            }
        });
        this.forceUpdate();
    }

    private load() {
        const headers: any = {};
        const additionalRequestData = {
            headers,
            timeout: 300 * 1000,
        };
        return fetchClusterTenants()
            .then(response => {
                this.tenants = response;
                this.tenants.forEach((tenant) => {
                    tenant.visible = true;
                    tenant.jiraUrl = tenant.jiraUrl || '';
                });
                this.filterList({
                    query: '',
                    showDisabled: false,
                });
            })
            .catch(error => {
                console.log(error);
                alert(JSON.stringify(error));
            });
    }

    private closePopUps() {
        this.setState({setTenantProperties: '', showCreateTenantPopUp: false});
    }

    private onTenantCreated() {
        this.load();
    }

    private setTenantProperty(tenant: TenantInfo): void {
        this.setState({setTenantProperties: tenant.tenantName});
    }

    private disable(tenant: TenantInfo) {
        if (!window.confirm('Are you sure you want to disable this tenant?')) {
            return;
        }
        disableTenant(
            tenant.tenantName,
        )
            .then((result) => {
                if (result) {
                    tenant.state = 'Disabled';
                    this.forceUpdate();
                }
            });
    }
}
