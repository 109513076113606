import React, {useEffect} from "react";

import {FlowsNavBarItem} from "./flows-nav-bar";
import {useGlobalState} from "../../global-store";
import {getSyncVerticalMenu} from "./sync-vertical-menu";
import {FlowPageCmp} from "./flow.page";
import {MainMenuItem} from "../../global-store/create-global-state";

export function FlowLogsPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Reporting);
        getSyncVerticalMenu(props.match.params.id, props.match.params.flowId).then(setMenu);
    }, []);
    return <FlowPageCmp
        flowId={ props.match.params.flowId}
        selectedNavItem={ FlowsNavBarItem.Logs }
    />
}