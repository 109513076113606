import React from 'react';
import { UI } from 'src/engrator-core';
import { ContextMenuItem, OnContextMenuItemSelected } from 'src/engrator-core/ui';

type Props = {
    onSelected: OnContextMenuItemSelected;
    actions?: string[];
};

export function getAvailableContextItems(actions?: string[]): ContextMenuItem[] {
    const items = [
        {
            id: 'Preview',
            name: 'Preview',
        }
    ];
    if (actions) {
        return items.filter(it => actions.includes(it.id));
    }
    return items;
}

export class IntegrationChangesItemContextMenu extends React.Component<Props> {
    render() {
        return (
            <div className='integrations-list-context-menu'>
                <UI.ContextMenu
                    getItems={() => getAvailableContextItems(this.props.actions)}
                    onSelected={this.onSelectedHandler.bind(this)}
                />
            </div>
        );
    }

    private onSelectedHandler(item: ContextMenuItem) {
        this.props.onSelected(item);
    }
}
