import { SuiteType } from '../../../generic/integrations/suite-type';

export type RunDetails = {
    canShareSupportAccess: boolean,
    isSharedWithSupport: boolean,
    id: number,
    suiteId: number,
    suiteName: string,
    startTime: string,
    formattedStartTime: string,
    endTime: string,
    pipelineName: string,
    formattedExecutionTime: string,
    suiteType: SuiteType;
    status: 'Success' | 'Failure',
    suiteConfiguration: any,
    foundTriggers: number,
    persistedArtifacts: number,
    performedFlows: number,
    failedFlows: number;
    failureReason?: string;
    runMode: 'Migration' | 'Sync';
}

export function createRunDetails(apiData: any): RunDetails {
    return {
        isSharedWithSupport: apiData.sharedWithSupport,
        canShareSupportAccess: apiData.canShareSupportAccess,
        suiteName: apiData.suiteName,
        id: apiData.id,
        suiteType: apiData.suiteType,
        suiteId: apiData.suiteId,
        pipelineName: apiData.suiteName,
        startTime: apiData.startTime,
        formattedStartTime: apiData.formattedStartTime,
        formattedExecutionTime: apiData.formattedExecutionTime,
        endTime: apiData.endTime,
        status: apiData.status,
        suiteConfiguration: apiData.suiteConfiguration,
        foundTriggers: apiData.foundTriggers,
        performedFlows: apiData.performedFlows,
        failedFlows: apiData.erroredFlows,
        persistedArtifacts: apiData.persistedArtifacts,
        failureReason: apiData.failureReason,
        runMode: apiData.runMode,
    }
}
