import {ISoftwareFactory, SoftwareName} from "../../../../../software";
import React from "react";
import {SoftwareLogotype} from "../../../../../designer/step-creator/software-logotype";
import {UI} from "src/engrator-core";
import unknown from './unknown.png';

type State = {};

type Props = {
    softwareFactory: ISoftwareFactory;
    optionSelected: (softwareName: SoftwareName) => void;
}

export class SelectAppStep extends React.Component<Props, State> {
    private appsNames: SoftwareName[];

    constructor(props: Props) {
        super(props);
        this.state = {};
        this.appsNames = this.props.softwareFactory.getAppsSupportingSmartInts()
            .map(software => software.getName() as SoftwareName)
            .sort();
    }

    render() {
        return <div className={`select-app-step`}>
            <UI.Message appearance={"info"}>
                Select the app that you want to integrate
            </UI.Message>
            <ul>
                {this.appsNames.map((app, index) => <li
                    data-sel={app}
                    onClick={() => this.props.optionSelected(app)}
                    key={index}>
                    <SoftwareLogotype softwareName={app}/>
                </li>)}
                <li>
                    <a className={`missing-app`} href={'https://getintio.atlassian.net/servicedesk/customer/portals'} target={'_blank'}>
                        <img src={ unknown } height={ 50 }/>
                        <span>Request New App?</span>
                    </a>
                </li>
            </ul>
        </div>
    }
}