import { Formatter } from "./formatter.type";

export function concatFormatters(selectedFormatters: Formatter[], availableFormatters: Formatter[]): Formatter[] {
    const formatters: Formatter[] = [];
    const ids: string[] = [];
    selectedFormatters.forEach(formatter => {
        formatters.push(Object.assign({}, formatter, { checked: true }));
        ids.push(formatter.id);
    });
    availableFormatters.forEach(formatter => {
        if (ids.indexOf(formatter.id) === -1) {
            formatters.push(Object.assign({}, formatter));
        }
    });
    return formatters;
}