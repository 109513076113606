import React, {Component} from 'react';

import {
    AuthToken,
    CLUSTER_ADMIN_WORKSPACE,
    CLUSTER_ADMIN_WORKSPACE_LANDING_PAGE, GENERAL_WORKSPACE_LANDING_PAGE,
    GetintUserWorkspace,
    GetintWorkspace,
    UI
} from 'src/engrator-core';
import getintNewLogo from 'src/getint_new_logo.svg';
import signInSmallLogo from './sign_in_small_logo.png';
import signInBgGraphic from './sign_in_bg.png';
import {Footer} from "../footer";
import {FeatureFlag, fetchSystemInfo, isFeatureFlagEnabled} from "../top-navigation/rest-api";
import {SystemInfo} from "../top-navigation/threads-summary.type";

type Props = {
    closeHandler: () => void;
};
type State = {
    workspaces: GetintUserWorkspace[];
    customLogo?: string;
    workspaceToChange?: GetintUserWorkspace;
}

export class WorkspaceSelection extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            workspaces: AuthToken.getWorkspaces()
        };
    }

    setWorkspace(workspace: GetintUserWorkspace): void {
        this.setState({ workspaceToChange: workspace });
    }

    render() {
        return (
            <UI.Modal
                header={`Switch workspace`}
            >
                <UI.Dropdown
                    options={ this.state.workspaces.map(w => ({label: w.workspaceName, value: w.workspaceId, object: w })) }
                    onChange={ (workspaceId: string, workspace: GetintUserWorkspace) => this.setWorkspace(workspace) }
                />
                <UI.ButtonsBar
                    primary={<UI.Button
                        text={`Switch`}
                        disabled={ !this.state.workspaceToChange }
                        onClick={ () => this.changeWorkspace() }
                    />}
                    secondary={[<UI.Button
                        text={`Close`}
                        appearance={`secondary`}
                        onClick={ () => this.props.closeHandler() }
                    />]}
                />
            </UI.Modal>
        )
    }

    changeWorkspace() {
        if (this.state.workspaceToChange) {
            AuthToken.setWorkspace( this.state.workspaceToChange );
            if (this.state.workspaceToChange.workspaceId === CLUSTER_ADMIN_WORKSPACE.workspaceId) {
                window.location.href = CLUSTER_ADMIN_WORKSPACE_LANDING_PAGE;
            } else {
                window.location.href = GENERAL_WORKSPACE_LANDING_PAGE;
            }
        }
    }
}
