import React from 'react';

import {EntityFormHelper, UI} from "../../engrator-core";
import {Auth, NewConnectionData} from "../../connections";
import {FormError} from "../../engrator-core/form-error.type";
import {OauthFlow} from "./oauth/oauth-flow";
import {OAuthData, OAuthSupport} from "./oauth/oauth-support";
import {SoftwareName} from "../../software";

type Props = {
    auth: Auth,
    formError: FormError;
    app: SoftwareName;
    oAuthSupport: OAuthSupport;
    newConnectionData: NewConnectionData;
    onAuthorize: (data: OAuthData) => void;
};
type State = {
    formError: { [key: string]: string }
};

export class OAuth extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private auth: any;
    private oAuthFlow: OauthFlow;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = { formError: {} };
        this.auth = this.props.auth;
        // this.props.newConnectionData.url = this.props.oAuthSupport.getApiUrl();
        this.oAuthFlow = new OauthFlow(this.props.oAuthSupport, (tokenData: any) => {
            console.log(tokenData);
            if (tokenData.token) {
                this.auth.token = tokenData;
                if (tokenData) {
                    this.props.onAuthorize(tokenData);
                }
            }
            this.forceUpdate();
        });
        this.entityFormHelper = new EntityFormHelper(() => {});
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.formError !== prevProps.formError) {
            this.forceUpdate();
        }
    }

    render() {
        return <React.Fragment>
            <p>Using OAuth authentication method you will be asked in a new window to sign in
            with your app credentials and grant Getint needed permissions. Click button below to authorize Getint.</p>
            <UI.Button disabled={ !!this.auth.token } text={`Authorize GetInt`} onClick={ this.startFlow.bind(this) } />
            { this.auth.token && <UI.Message appearance={"success"}>Token captured! Click add button to save connection.</UI.Message> }
        </React.Fragment>
    }

    private startFlow() {
        this.oAuthFlow.start(this.props.newConnectionData.url, this.props.app);
    }
}