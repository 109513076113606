import React from 'react';

type Props = {
    name: string | JSX.Element;
    transparentBg?: boolean;
};

export class Tag extends React.Component<Props> {
    render() {
        return <span className={`engrator-ui-tag ${ (this.props.transparentBg) ? 'transparent-bg' : '' } `}>
            { this.props.name}
        </span>;
    }
}
