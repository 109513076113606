import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { MondaySmartIntsTriggerConfiguration } from './monday-smart-ints-trigger-configuration';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class MondaySmartIntsEditTrigger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const configuration = (this.props.trigger.configuration as MondaySmartIntsTriggerConfiguration);
        return <React.Fragment>
            <UI.FormGroup
                dataSel={`board`}
            >
                <UI.LabelValue
                    label={`Board`}
                    value={configuration.board.name}
                />
            </UI.FormGroup>
        </React.Fragment>;
    }
}
