import React from 'react';

type LabelValueProps = {
    label: string,
    value: any,
    subvalue?: any,
    isInline?: boolean,
    className?: string
};

export class LabelValue extends React.Component<LabelValueProps> {
    render() {
        let classNames = ' ui-label-value ';
        classNames += (this.props.className) ? this.props.className : '';
        if (this.props.isInline) {
            classNames += ' inline ';
        }
        return <div className={ classNames }>
            <label>{ this.props.label }</label>
            <span>{ this.props.value }</span>
            { this.props.subvalue && <p className={`subvalue`}>({ this.props.subvalue })</p> }
        </div>;
    }
};
