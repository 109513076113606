import { PipelineStep } from 'src/generic';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';
import { MondayStepKey } from '../freshdesk-step-key.type';
import { MondayCreateCommentsConfiguration } from './monday-create-comments-configuration';
import { MondayCreateCommentsEditStep } from './monday-create-comments-edit-step';
import { MondayCreateCcommentsNewStep } from './monday-create-comments-new-step';


export const MondayCreateCommentsPipelineStep: PipelineStep<MondayCreateCommentsConfiguration> = {
    addStepComponentClass: MondayCreateCcommentsNewStep,
    editStepComponentClass: MondayCreateCommentsEditStep,
    specification: {
        action: 'Create issue comment(s)',
        description: 'Create comment(s) for selected issue',
        key: MondayStepKey.FreshdeskCreateComments,
        software: SoftwareName.Monday,
        stepType: StepType.Action,
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `MondayCard`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as MondayCreateCommentsConfiguration;
        return `Create comment(s) for issue '${stepConfiguration.targetArtifact}'`;
    },
};
