import React from 'react';

import { EntityFormHelper, UI } from '../../engrator-core';
import { FormError } from '../../engrator-core/form-error.type';
import { BasicAuthData } from '../../generic/connections/basic-auth-data.type';
import { NewConnectionData } from '../../connections';

type Props = {
    auth: any,
    formError: FormError;
    connectionData: NewConnectionData;
};
type State = {
    formError: { [key: string]: string }
};

export class FreshdeskBasicAuth extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private auth: any;
    private basicAuthData: BasicAuthData = {
        login: 'x', password: ''
    };

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = { formError: {} };
        this.auth = this.props.auth;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'login' | 'password', newValue: string) => {
            this.basicAuthData[propertyName] = newValue;
            this.auth.login = this.basicAuthData.login;
            this.auth.password = this.basicAuthData.password;
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.formError !== prevProps.formError) {
            this.forceUpdate();
        }
        if (this.props.connectionData && this.props.connectionData.url !== prevProps.connectionData.url) {
            console.log('update');
        }
    }

    render() {
        console.log(this.props.connectionData.url);
        return <React.Fragment>
            <UI.FormGroup
                dataSel={`password`}
                label={`API Key`}
                error={this.props.formError['password']}
                isRequired={true}>
                <UI.Input type="password" onChange={this.entityFormHelper.onChangeHandler('password')}/>
            </UI.FormGroup>
        </React.Fragment>
    }
}
