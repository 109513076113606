import React, { Component } from 'react';

import { UI } from 'src/engrator-core';
import { LogsViewer } from '../../../engrator-core/ui';
import { fetchFlowLogs } from './rest-api';
import {FormError} from "../../../engrator-core/form-error.type";
import {downloadRunLogs} from "../run-results/rest-api";

type Props = {
  runId: number;
  flowId: number;
};
type State = {
  logs: any;
  error?: FormError;
};

export class FlowLogs extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            logs: undefined
        };
    }

    componentDidMount(): void {
      fetchFlowLogs(this.props.flowId)
        .then((logs: string) => {
          this.setState({logs});
        }).catch(error => {
            console.log(error);
            this.setState({ error })
      });
    }

    render() {
        if (this.state.error) {
            return <React.Fragment>
                <br/><UI.Message appearance={"error"}>Failed to load logs. { this.state.error.message }</UI.Message>
            </React.Fragment>
        }
        if (this.state.logs === undefined) {
            return <UI.CenteredLoader text={`Loading logs`} />;
        }
        return (
            <UI.Page className={"no-padding-page"}>
                <React.Fragment>
                    <div className="run-results-logs">
                        <LogsViewer
                            logs={ this.state.logs! }
                            downloadLogsHandler={ () => this.downloadClicked() }
                        />
                    </div>
                </React.Fragment>
            </UI.Page>
        )
    }

    private async downloadClicked() {
        await downloadRunLogs(this.props.runId);
    }
}
