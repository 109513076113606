import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { Software } from 'src/generic';
import { createGenericConnectionFactory } from 'src/generic/connections';
import { AuthMethod } from 'src/generic/connections/auth-method.type';
import { PipelineGenericStep } from 'src/generic/pipeline-step';
import { SoftwareName } from '../software-name';

import { MondayPipelineSteps } from './monday-pipeline-steps';
import { MondaySmartintsSupport } from './smart-ints';


export class MondaySoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.Monday, [AuthMethod.BearerToken]);
    }

    getName(): string {
        return 'Monday';
    }

    getID() {
        return 'Monday';
    }

    getAvailableSteps(): object[] {
        return MondayPipelineSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }

    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new MondaySmartintsSupport();
    }
}
