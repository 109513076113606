import React from 'react';

import PropertyMapping from './PropertyMapping';
import PropertyMappingCreator from './PropertyMappingCreator';
import {DataArtifactBuilder} from './data-artifact-builder';
import {ConfiguredProperty} from '../artifacts/data-artifact';
import {
    createPropertySource,
} from "../artifacts/property-source";
import {ArtifactDirection} from "../artifacts";
import { UI } from 'src/engrator-core';
import {SoftwareName} from "../../software";
import {StepConfigurationFetcher} from "./step-configuration-fetcher.type";

type ArtifactBuilderSceneProps = {
    dataArtifactBuilder: DataArtifactBuilder;
    softwareName: SoftwareName;
    connectionId: number;
    stepConfigurationFetcher: StepConfigurationFetcher;
};

export class ArtifactBuilderScene extends React.Component<ArtifactBuilderSceneProps, {}> {
    private showArtifactNameField: boolean;

    constructor(props: ArtifactBuilderSceneProps) {
        super(props);
        this.showArtifactNameField = (
            this.props.dataArtifactBuilder.getDirection() === ArtifactDirection.Out
            && this.props.dataArtifactBuilder.getConfiguredProperties().length === 0);
        this.state = { propertyMappings: [] };
    }

    componentDidUpdate(prevProps: ArtifactBuilderSceneProps) {
        if (prevProps && prevProps.dataArtifactBuilder.getBuildTime() !== this.props.dataArtifactBuilder.getBuildTime()) {
            this.forceUpdate();
        }
    }

    addPropertyMapping(propertyMapping: any) {
        if (this.props.dataArtifactBuilder) {
            const propertySource = createPropertySource(propertyMapping, this.props.dataArtifactBuilder.getDirection());
            this.props.dataArtifactBuilder.setUpProperty(propertyMapping.property, propertySource);
            this.forceUpdate();
        }
    }

    deletePropertyMapping(configuredProperty: ConfiguredProperty) {
        this.props.dataArtifactBuilder.removeProperty(configuredProperty);
        this.forceUpdate();
    }

    render() {
        return <div className="artifact-builder">
            { this.showArtifactNameField && <UI.FormGroup>
                <UI.Input
                    defaultValue={ this.props.dataArtifactBuilder.getArtifactName() }
                    label="Artifact name"
                    onChange={(name: string) => { this.props.dataArtifactBuilder.setArtifactName(name) }}
                />
            </UI.FormGroup> }
            { !this.showArtifactNameField && <UI.LabelValue
                label="Artifact name"
                value={ this.props.dataArtifactBuilder.getArtifactName() }
            /> }
            <PropertyMappingCreator
                stepConfigurationFetcher={ this.props.stepConfigurationFetcher }
                connectionId={ this.props.connectionId }
                direction={ this.props.dataArtifactBuilder.getDirection()   }
                addPropertyMapping={ this.addPropertyMapping.bind(this) }
                propertiesToMap={ this.props.dataArtifactBuilder.getAvailableProperties() }
                availableArtifacts={ this.props.dataArtifactBuilder.getAvailableArtifactsWithProperties() }
                softwareName={ this.props.softwareName }
                configuredProperties={ this.props.dataArtifactBuilder.getConfiguredProperties() }
            />
            { this.props.dataArtifactBuilder.getConfiguredProperties().map((configuredProperty: ConfiguredProperty, index: number) =>
                <PropertyMapping 
                    key={ index } 
                    configuredProperty={ configuredProperty } 
                    deleteHandler={ () => this.deletePropertyMapping(configuredProperty) }
                />
            )}
        </div>;
    }
};