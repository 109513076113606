import {axiosWrapper, FieldError} from "../../../engrator-core";
import { NotionDatabaseDTO } from "./notion-smart-ints-trigger-configuration";

export const ENDPOINT_URL = '/connectors/notion';

export function fetchDatabases(connectionId: number): Promise<NotionDatabaseDTO[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/databases')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
