export enum DebuggingState {
    Idle,
    Running,
    Stopped
};

export enum DebuggingResultStatus {
    Success,
    Error
};

export type DebuggingResult = {
    status: DebuggingResultStatus
};

export enum StepDebuggingStatus {
    Idle,
    InProgress,
    Success,
    Error
}

export type StartDebuggingHandler = (firstStepConfiguration: any) => void;

export type StepDebuggingState = {
    stepIndex: number,
    status: StepDebuggingStatus,
    output: any
};

export type OnStepDebugged = (result: StepDebuggingState) => Promise<void>;