import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";

import {ZendeskStepKey} from "../zendesk-step-key.type";
import {ZendeskUpdateItemEditStep} from "./zendesk-update-item-edit-step";
import {ZendeskUpdateItemNewStep} from "./zendesk-update-item-new-step";
import {ZendeskUpdateItemConfiguration} from "./zendesk-update-item-configuration";

export const ZendeskUpdateItemPipelineStep: PipelineStep<ZendeskUpdateItemConfiguration> = {
    addStepComponentClass: ZendeskUpdateItemNewStep,
    editStepComponentClass: ZendeskUpdateItemEditStep,
    specification: {
        action: "Update Ticket",
        description: "Update Zendesk ticket",
        key: ZendeskStepKey.ZendeskUpdateItem,
        software: SoftwareName.Zendesk,
        stepType: StepType.Action
    },
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ZendeskUpdateItemConfiguration;
        return `Update ticket '${stepConfiguration.targetArtifact}'`;
    }
}