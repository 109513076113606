import React, { Component } from 'react';
import { OnContextMenuItemSelected } from 'src/engrator-core/ui';
import { IntegrationChangesItemContextMenu } from './integration-changes-item-context-menu';

type Props = {
    onContextMenuSelectHandler: OnContextMenuItemSelected;
};

export class IntegrationChangesActions extends Component<Props> {
    render() {
        return (
            <IntegrationChangesItemContextMenu
                actions={['Preview']}
                onSelected={(item) => this.props.onContextMenuSelectHandler(item)}
            />
        );
    }
}
