import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './right-panel.css';

type RightPanelProps = {
    onCloseHandler: () => void;
    title: string
};

export class RightPanel extends React.Component<RightPanelProps> {
    render() {
        return <div className={`engrator-right-panel`}>
            <div className="header">
                {this.props.title}
            </div>
            <span className="close-btn"><FontAwesomeIcon onClick={() => this.props.onCloseHandler() } icon={faTimes} /></span>
            <div className="content">
                { this.props.children }
            </div>
        </div>;
    }
}