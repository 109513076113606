import React, {Component, useEffect} from "react";

import {copyObject, FieldError, UI} from 'src/engrator-core';

import {ContextMenuItem, Icon20, TableGridData} from "../../../engrator-core/ui";
import {useGlobalState} from "../../global-store";
import {getWorkflowsVerticalMenu} from "../vertical-menu";
import {MainMenuItem} from "../../global-store/create-global-state";
import { fetchSharedMappings, handleSharedMappingStatus, isAnyFilterUsed, restoreFiltersFromStorage, SharedMapping, SharedMappingListItem, SharedMappingsFilters, SharedMappingStatus } from "./rest-api";
import { SoftwareLogo } from "src/designer/step-creator/software-logo";
import { DELETE_ITEM, DISABLE_ITEM, EDIT_ITEM, ENABLE_ITEM, SharedMappingsListItemContextMenu } from "./shared-mappings-list-item-context-menu";
import { DeleteSharedMappingModal } from "./delete-shared-mapping-modal";
import { SharedMappingsEditPage } from "./edit/shared-mappings-edit-page";
import { withRouter } from "react-router-dom";
import { RouteComponentProps, StaticContext } from "react-router";
import { IntegrationModuleTabBar } from "../integration-module-tab-bar";
import { SharedMappingsListFiltering } from "./shared-mappings-list-filtering";
import { OwnerTag } from "../smartints/owner-tag";

type Props = {} & RouteComponentProps;
type State = {
    error?: FieldError,
    isLoading: boolean,
    allSharedMappings: SharedMappingListItem[];
    sharedMappingToEdit?: SharedMappingListItem;
    shouldReloadTable: boolean;
    sharedMappingToDelete?: SharedMappingListItem;
    filters: SharedMappingsFilters;
    owners: string[];
};

export class SharedMappingsListPageCmp extends Component<Props, State> {
    private tableGridData?: TableGridData = undefined;
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            allSharedMappings: [],
            shouldReloadTable: false,
            filters: restoreFiltersFromStorage(),
            owners: []
        };
    }

    componentDidMount(): void {
        this.loadData(this.state.filters);
    }

   componentDidUpdate(prevProps: Readonly<RouteComponentProps<{}, StaticContext, unknown>>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.shouldReloadTable !== this.state.shouldReloadTable) {
        this.loadData(this.state.filters);
    }
   }

    private loadData(filters: SharedMappingsFilters): void {
        this.setState({ isLoading: true}, () => {
            fetchSharedMappings(filters)
                .then(sharedMappings => {
                    this.tableGridData = this.createData(sharedMappings.data);
                    this.setState({ isLoading: false, allSharedMappings: sharedMappings.data, owners: sharedMappings.owners });
                    this.checkUrlParams();
                })
                .catch(error => this.setState({ isLoading: false, error}));
        });
    }

    private checkUrlParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if (id) {
            const found = this.state.allSharedMappings.find(el => el.id === +id);
            this.setState({ sharedMappingToEdit: found });
        }
    }

    private refreshList() {
        this.setState({allSharedMappings: [], shouldReloadTable: !this.state.shouldReloadTable});
    }

    render() {
        return (
            <UI.Page className={`shared-mappings-list-page`}>
                <UI.Message appearance={'info'}>
                    To find out more about Shared Mapping concept, you can <UI.DocumentationLink text={`check our docs`} url={`https://docs.getint.io/getintio-platform/workflows/shared-mappings-in-getint-centralized-mapping-for-efficient-integrations`} />.
                </UI.Message>
                { this.state.owners.length > 0 && <IntegrationModuleTabBar selectedIndex={0}>
                        <SharedMappingsListFiltering defaultFilters={this.state.filters} onFiltersChanged={(newFilters) => this.onFiltersChanged(newFilters)} owners={this.state.owners}/>
                    </IntegrationModuleTabBar>
                }
                { isAnyFilterUsed(this.state.filters) && <UI.Message appearance={'warning'}>
                    Displaying a filtered subset of shared mappings. Select 'Clear' to reset.
                </UI.Message> }
                { this.state.sharedMappingToDelete && <DeleteSharedMappingModal
					sharedMapping={ this.state.sharedMappingToDelete }
					closeHandler={ () => this.setState({ sharedMappingToDelete: undefined }) }
                    onDeleteHandler={ () => this.refreshList() }
				/> }
                { this.state.error && <UI.Message appearance={"error"} message={ this.state.error?.message} /> }
                { this.state.isLoading && <UI.Loader visible={ true } appearance={"darkgray"}/> }
                { this.state.sharedMappingToEdit && <SharedMappingsEditPage
                    depth={1}
                    typeMapping={this.state.sharedMappingToEdit.typeMapping}
                    groups={this.state.sharedMappingToEdit.groups}
                    appsSupport={this.state.sharedMappingToEdit.appsSupport}
                    defaults={this.state.sharedMappingToEdit.mapping.defaults}
                    empty={this.state.sharedMappingToEdit.mapping.empty}
                    leftTrigger={this.state.sharedMappingToEdit.leftTrigger}
                    mapping={this.state.sharedMappingToEdit.mapping}
                    onMappingsChangedHandler={() => {}}
                    rightTrigger={this.state.sharedMappingToEdit.rightTrigger}
                    closeHandler={ (shouldRefresh) => this.closeSharedMappingModal(shouldRefresh) }
                    sharedMappingToEdit={this.state.sharedMappingToEdit}
                    {...this.props}
                /> }
                { !this.state.isLoading && this.tableGridData && <React.Fragment>
                    <UI.TableGrid
                        data={ this.tableGridData.data }
                        headers={ this.tableGridData.headers }
                    />
                </React.Fragment> }
            </UI.Page>
        )
    }

    private handleEdit(value: SharedMappingListItem) {
        this.setState({ sharedMappingToEdit: copyObject(value)});
    }

    private contextMenuItemSelected(item: ContextMenuItem, sharedMapping: SharedMappingListItem) {
        if (item.id === DELETE_ITEM.id) {
            this.setState({ sharedMappingToDelete: sharedMapping })
        } else if (item.id === EDIT_ITEM.id) {
            this.handleEdit(sharedMapping);
        } else if (item.id === DISABLE_ITEM.id) {
            handleSharedMappingStatus(sharedMapping, SharedMappingStatus.DISABLE).then(() => {
                this.refreshList();
            })
        } else if (item.id === ENABLE_ITEM.id) {
            handleSharedMappingStatus(sharedMapping, SharedMappingStatus.ENABLE).then(() => {
                this.refreshList();
            })
        }
    }

    private closeSharedMappingModal(shouldRefresh: boolean) {
        this.setState({
            sharedMappingToEdit: undefined,
            shouldReloadTable: shouldRefresh ? !this.state.shouldReloadTable : this.state.shouldReloadTable
        });
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if (id) {
            window.history.replaceState(null, '', window.location.href.split('?id')[0]);
        }
    }

    private createData(sharedMappings: SharedMappingListItem[]): TableGridData {
        return {
            data: sharedMappings.map(sharedMapping => [
                <UI.Button appearance="link-inline" text={`#${sharedMapping.id}`} onClick={ () => this.handleEdit(sharedMapping) }/>,
                <UI.Button appearance="link-inline" text={sharedMapping.name} onClick={ () => this.handleEdit(sharedMapping) }/>,
                `${sharedMapping.propertiesData.left.name} - ${sharedMapping.propertiesData.right.name}`,
                <div className={`connected-apps`}>
                    <SoftwareLogo softwareName={ sharedMapping.apps.left } />
                    <UI.Icon icon20={ Icon20.Getint} />
                    <SoftwareLogo softwareName={ sharedMapping.apps.right } />
                </div>,
                sharedMapping.integrations.length,
                <UI.EntityStatus status={sharedMapping.status}/>,
                <OwnerTag
                    maxLength={ 10 }
                    ownerId={ sharedMapping.ownerId }
                    owner={ sharedMapping.owner }
                />,
                <SharedMappingsListItemContextMenu 
                    onSelected={(item) => this.contextMenuItemSelected(item, sharedMapping)}
                    listItem={ sharedMapping }
                />
            ]),
            headers: ['ID', 'Name', 'Fields', 'Apps', 'Integrations', 'Status', 'Owner', 'Actions'],
        };
    }

    private onFiltersChanged(filters: SharedMappingsFilters) {
        this.setState({ filters }, () => {
            this.refreshList();
        });
    }
}

const SharedMappingsListPageWithRouter = withRouter(SharedMappingsListPageCmp);


export function SharedMappingsListPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        setMainMenuItem(MainMenuItem.Workflows);
        getWorkflowsVerticalMenu()
            .then(workflowsVerticalMenu => {
                setMenu(workflowsVerticalMenu);
                setMainMenuItem(MainMenuItem.Workflows);
            });
    }, []);
    return <SharedMappingsListPageWithRouter />
}