import {SoftwareName} from "../../../../../software";
import {copyObject} from "../../../../../engrator-core";
import {AppsSupport} from "../../visual/visual-integration-designer";

enum PluginKind {
    OnValueSet = 'OnValueSet'
}

export type SmartIntPlugin = {
    id: string;
    name: string;
    description?: string;
    apps?: SoftwareName[];
    data: { [key in string]: string };
    kind: PluginKind,
    settings: {
        code: boolean;
        custom: string[];
    }
};

const availablePlugins: SmartIntPlugin[] = [
    // {
    //     id: 'jiraAzureUserByEmailFinder',
    //     name: 'Match users by email',
    //     apps: [SoftwareName.Jira, SoftwareName.Azure],
    //     kind: PluginKind.OnMappedValueSet,
    //     data: {},
    //     settings: {
    //         code: false,
    //         custom: ['fields']
    //     }
    // },
    {
        id: 'onValueSet',
        name: 'On value set',
        description: 'Customize field value according to your requirements. For example, you can concatenate two field values or assign a value retrieved from a REST API.',
        data: {
            'code': "function onValueSet(api, context) {\n\n}"
        },
        kind: PluginKind.OnValueSet,
        settings: {
            code: true,
            custom: []
        }
    }
];

export function getDefaultDebugConfig(appSupport: AppsSupport): object {
    return {
        "source": {
            "property": null,
            "app": appSupport.leftApp,
            "side": "Left"
        },
        "target": {
            "property": {
                "dataType": "IdLabel",
                "name": "Assigned To",
                "id": "System.AssignedTo"
            },
            "app": appSupport.rightApp,
            "side": "Right"
        }
    };
}

export function getAvailablePlugins(leftApp: SoftwareName, rightApp: SoftwareName): SmartIntPlugin[] {
    return copyObject(availablePlugins.filter(p => !p.apps || (p.apps.indexOf(leftApp) >= 0 && p.apps.indexOf(rightApp, p.apps.indexOf(leftApp) + 1) >= 0)));
}