import React from 'react';

import './form-group.css';

export class FormGroup extends React.Component<FormGroupProps, FormGroupState> {
    constructor(props: Readonly<FormGroupProps>) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: FormGroupProps) {
        if (this.props.error !== prevProps.error) {
            this.setState({error: this.props.error });
        }
    }

    render() {
        const errorClassName = (this.state.error) ? 'error' : '';
        const dataSel = { 'data-sel': this.getDataSel() }
        return <div className={ `engrator-form-group ${errorClassName}` } { ...dataSel }>
            { this.props.label && <label>{ this.props.label } { this.props.isRequired === true && <span className={`required`}>*</span>}</label> }
            { this.props.description && <p className="description">{ this.props.description }</p> }
            { this.props.children }
            { this.state.error && <span className="error">{ this.state.error }</span> }
        </div>;
    }

    private getDataSel() {
        if (this.props.dataSel) {
            return this.props.dataSel;
        }
        if (this.props.label && typeof this.props.label === 'string') {
            return this.props.label.toLowerCase();
        }
        return '';
    }
};

type FormGroupProps = {
    label?: any,
    description?: any,
    error?: string,
    isRequired?: boolean;
    dataSel?: string;
};

type FormGroupState = {
    error?: string;
};