import React from 'react';

import {WrikeCreateCommentsConfiguration} from "./wrike-create-comments-configuration";
import {EditPipelineStepComponentProps} from "src/generic/pipeline-step";
import {EditCommentsStep} from "../../../generic/create-comments-step";
import {AzureCreateCommentsConfiguration} from "../../../azure/steps/create-comments/azure-create-comments-configuration";

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class WrikeCreateCommentsEditStep extends React.Component<Props, State> {
    private configuration: WrikeCreateCommentsConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as WrikeCreateCommentsConfiguration;
    }

    render() {
        return <EditCommentsStep
            configuration={ this.configuration }
            formError={ this.props.formError }
            onConfigurationChanged={ this.onConfigurationChanged.bind(this) } />;
    }

    private onConfigurationChanged(newConfiguration: AzureCreateCommentsConfiguration) {
        this.configuration = Object.assign(this.configuration, newConfiguration);
    }
};