import { axiosWrapper } from 'src/engrator-core';
import { MigrationConfiguration } from './migration-configuration.type';
import { MigrationUsageInfoResponse } from './migration-usage-info-response';

const ENDPOINT_URL = '/integration/migration';

export function validateMigrationSettings(configuration: MigrationConfiguration): Promise<MigrationConfiguration> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + '/validate', configuration)
                .then((data: any) => {
                    resolve(data as MigrationConfiguration);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function getUsageInfo(): Promise<MigrationUsageInfoResponse> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/usage-info')
                .then((data: any) => {
                    resolve(data as MigrationUsageInfoResponse);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
