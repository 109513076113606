import React from 'react';

import { EditPipelineStepComponentProps } from 'src/generic';
import { ArtifactDirection } from 'src/generic/artifacts';
import { GetintArchiverStatusTransitionConfiguration } from './monday-status-transition-configuration';

type Props = {} & EditPipelineStepComponentProps;
type State = {};

export class GetintArchiverStatusTransitionEditStep extends React.Component<Props, State> {
    private configuration: GetintArchiverStatusTransitionConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as GetintArchiverStatusTransitionConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
        this.props.showArtifactBuilder(ArtifactDirection.In);
    }

    render() {
        return <React.Fragment>
        </React.Fragment>;
    }
};
