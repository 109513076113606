import {SoftwareName} from "../../../../../software";
import {SmartIntDefinitionTypeMapping} from "../../definition/smart-int-definition--type-mapping.type";

export function isAttachmentsSyncSupported(leftApp: SoftwareName, rightApp: SoftwareName): boolean {
    const listOfAppsDoNotSupport: SoftwareName[]  = [
        SoftwareName.Notion,
        // SoftwareName.Salesforce,
        SoftwareName.Airtable,
        SoftwareName.Hubspot,
        SoftwareName.IntHub,
        SoftwareName.Trello
    ];
    if (listOfAppsDoNotSupport.indexOf(leftApp) >= 0 || listOfAppsDoNotSupport.indexOf(rightApp) >= 0) {
        return false;
    }
    return true;
}

export function isStatusSyncSupported(mapping: SmartIntDefinitionTypeMapping, leftApp: SoftwareName, rightApp: SoftwareName): boolean {
    const salesforceTypes = ['Account', 'Contact'];
    if (leftApp === SoftwareName.Salesforce && salesforceTypes.indexOf(mapping.left.id) >= 0) {
        return false;
    } else if (rightApp === SoftwareName.Salesforce && salesforceTypes.indexOf(mapping.right.id) >= 0) {
        return false;
    }
    return true;
}

export function isHierarchySyncSupported(leftApp: SoftwareName, rightApp: SoftwareName): boolean {
    const listOfAppsDoNotSupport: SoftwareName[]  = [
        SoftwareName.Zendesk,
        SoftwareName.Notion,
        SoftwareName.Freshdesk,
        SoftwareName.Salesforce,
        SoftwareName.Trello,
        SoftwareName.Airtable,
        SoftwareName.Hubspot,
        SoftwareName.ClickUp,
        SoftwareName.Wrike,
        SoftwareName.GetintArchiver,
        SoftwareName.GitHub,
        SoftwareName.GitLab,
        SoftwareName.IntHub,
    ];
    if (listOfAppsDoNotSupport.indexOf(leftApp) >= 0 || listOfAppsDoNotSupport.indexOf(rightApp) >= 0) {
        return false;
    }
    return true;
}