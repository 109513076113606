import React, {SyntheticEvent} from 'react';
import { UI } from 'src/engrator-core';
import {PropertyMappingOptions} from "../property-mapping.type";

type Props = {
    options: PropertyMappingOptions;
    readonly?: boolean;
};
type State = {
};

export class PropertyMappingOptionsSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <div className={`options`}>
            <span onClick={ (event) => this.switchFlag('onCreate', event) } className={`label ${ this.getClassName(this.props.options.onCreate) }`}>
                <UI.Icon icon="check" />
                On Create
            </span>
            <span onClick={ (event) => this.switchFlag('onUpdate', event) } className={`label ${this.getClassName(this.props.options.onUpdate)}`}>
                <UI.Icon icon="check" />
                On Update
            </span>
            { this.props.readonly && <span className={`label active not-clickable`}>
                Readonly
            </span> }
        </div>
    }

    private getClassName(flag: boolean): string {
        return (flag) ? 'active' : '';
    }

    private switchFlag(flagName: 'onCreate' | 'onUpdate', event: SyntheticEvent) {
        event.stopPropagation();
        this.props.options[flagName] = !this.props.options[flagName];
        this.forceUpdate();
    }
}
