import { axiosWrapper } from './../../engrator-core';


const ENDPOINT_URL = '/integration/suites';

export const save = (integrationId: number | undefined, integrationData: any): Promise<number> => {
    return new Promise((resolve, reject) => {
        try {
            const promise = (integrationId)
                ? axiosWrapper.put(ENDPOINT_URL + '/' + integrationId, integrationData)
                : axiosWrapper.post(ENDPOINT_URL, integrationData)
            promise.then((data: any) => {
                resolve(data)
            })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const deleteIntegration = (integrationId: number): Promise<any> => {
    return axiosWrapper.delete(ENDPOINT_URL + '/' + integrationId);
};
