import React from 'react';

import { EntityFormHelper, UI } from '../../engrator-core';
import { FormError } from '../../engrator-core/form-error.type';
import { BearerTokenData } from './bearer-token-data.type';

type Props = {
    auth: any,
    formError: FormError
};
type State = {
    formError: { [key: string]: string }
};

export class BearerToken extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private auth: any;
    private bearerTokenData: BearerTokenData = {
        token: '',
        url: ''
    };

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = { formError: {} };
        this.auth = this.props.auth;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'token' | 'url', newValue: string) => {
            this.bearerTokenData[propertyName] = newValue;
            this.auth.token = this.bearerTokenData.token;
            this.auth.url = this.bearerTokenData.url;
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.formError !== prevProps.formError) {
            this.forceUpdate();
        }
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                dataSel={`password`}
                label={`Token`}
                error={this.props.formError['token']}
                isRequired={true}>
                <UI.Input type="password" onChange={this.entityFormHelper.onChangeHandler('token')}/>
            </UI.FormGroup>
            <UI.FormGroup
                label={`Url (Optional)`}
                description={`Optionally you can provide url instead of the standard API endpoint to which requests will be sent.`}
                error={this.props.formError['url']}
                isRequired={false}>
                <UI.Input type="text" onChange={this.entityFormHelper.onChangeHandler('url')}/>
            </UI.FormGroup>
        </React.Fragment>
    }
}
