import {ReportData} from "../../../../reporting/report-data.type";

export const FETCHING_INTERVAL_IN_SECONDS = 10 * 1000;

export async function fetchNewEntries(dataFetch: () => Promise<ReportData>, currentReportData?: ReportData): Promise<boolean | undefined> {
    if (!currentReportData) {
        return;
    }
    const newData = await dataFetch();
    const entries: any = [];
    for (let i=0; i<newData.data.length; i++) {
        const entry = newData.data[newData.data.length - 1 - i];
        // If there is no data yet in the report || id is higher than the previous one
        if (!currentReportData.data[0] || entry['id'] > currentReportData.data[0].id) {
            entry['newEntry'] = true;
            entries.push(entry);
        }
    }
    let modified = false;
    for (const entry of newData.data) {
        for (const currentEntry of currentReportData.data) {
            if (currentEntry.id === entry.id) {
                if (currentEntry.status !== entry.status) {
                    currentEntry.status = entry.status;
                    modified = true;
                }
                if (currentEntry.formattedExecutionTime !== entry.formattedExecutionTime) {
                    currentEntry.formattedExecutionTime = entry.formattedExecutionTime;
                    modified = true;
                }
                if (currentEntry.formattedStartTime !== entry.formattedStartTime) {
                    currentEntry.formattedStartTime = entry.formattedStartTime;
                    modified = true;
                }
                if (currentEntry.performedFlows !== entry.performedFlows) {
                    currentEntry.performedFlows = entry.performedFlows;
                    modified = true;
                }
                if (currentEntry.foundTriggers !== entry.foundTriggers) {
                    currentEntry.foundTriggers = entry.foundTriggers;
                    modified = true;
                }
                if (currentEntry.persistedArtifacts !== entry.persistedArtifacts) {
                    currentEntry.persistedArtifacts = entry.persistedArtifacts;
                    modified = true;
                }
                if (currentEntry.executionTime !== entry.executionTime) {
                    currentEntry.executionTime = entry.executionTime;
                    modified = true;
                }
                if (currentEntry.syncedWithItemId !== entry.syncedWithItemId) {
                    currentEntry.syncedWithItemId = entry.syncedWithItemId;
                    modified = true;
                }
                if (currentEntry.executionTime !== entry.executionTime) {
                    currentEntry.executionTime = entry.executionTime;
                    modified = true;
                } else if (currentEntry.warningLogsCount !== entry.warningLogsCount) {
                    currentEntry.warningLogsCount = entry.warningLogsCount;
                    modified = true;
                }
                if (JSON.stringify(currentEntry.subItemsData) !== JSON.stringify(entry.subItemsData)) {
                    currentEntry.subItemsData = entry.subItemsData;
                    modified = true;
                }
            }
        }
    }
    if (entries.length === 0) {
        return modified;
    }
    currentReportData.data.unshift(...entries);
    if (currentReportData.data.length > 20) {
        currentReportData.data.splice(20, currentReportData.data.length - 20);
    }
    return true;
}