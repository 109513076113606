import React, {Component, useEffect} from 'react';
import { UI } from 'src/engrator-core';
import { FormError } from '../../../engrator-core/form-error.type';
import { Footer } from '../../footer';
import { getUpgradeProgress, StepName, stopUpgrade, upgradeFromFile, upgradeVersion } from './rest-api';
import { UpgradeVersionProgress } from './upgrade-version-progress';
import {useGlobalState} from "../../global-store";
import {getSystemVerticalMenu} from "../system-vertical-menu";
import {MainMenuItem} from "../../global-store/create-global-state";

type State = {
    isLoading: boolean;
    url: string;
    file: any;
    error?: FormError;
};

class GeneralSystemPageCmp extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: false,
            url: '',
            file: undefined
        };
    }

    componentDidMount(): void {
        getUpgradeProgress()
            .then((result) => {
                if (result.currentStep !== StepName.Idle) {
                    this.setState({ isLoading: true });
                }
            })
    }

    render() {
        return (
            <UI.Page>
                <UI.Tile>
                    <UI.Form useMaxWidthClass={true}>
                        {/*<UI.LabelValue*/}
                        {/*    label={`Version: `}*/}
                        {/*    value={`${this.state.url}`}*/}
                        {/*/>*/}
                        <UI.FormGroup
                            label={`Upgrade to new getint.io version`}
                            description={`You can change current version of getint.io. Once package will be downloaded, all integration threads will be stopped and reboot of application will be performed.`}
                        >
                            { !this.state.isLoading && <UI.Input
                                label={`Url to new version package`}
                                placeholder={`https://...`}
                                onChange={(newValue) => this.setNewUrl(newValue)}
                            /> }
                        </UI.FormGroup>
                        { !this.state.isLoading && <UI.FormGroup
                            label={`or upload new version`}
                        >
                            <input
                                onChange={ this.onFileSelected.bind(this) }
                                type={`file`}
                            />
                        </UI.FormGroup> }
                        {this.state.isLoading && <UpgradeVersionProgress/>}
                        {this.state.error && <UI.Message appearance={'error'}>{this.state.error.message}</UI.Message>}
                        <UI.ButtonsBar
                            primary={<UI.Button
                                isLoading={this.state.isLoading}
                                disabled={!this.state.url && !this.state.file}
                                onClick={this.upgradeVersionClicked.bind(this)}
                                text={`Upgrade`}
                            />}
                            secondary={[
                                (this.state.isLoading) ? <UI.Button
                                    onClick={this.stopUpgradeClicked.bind(this)}
                                    appearance={'secondary'}
                                    text={`Stop`}
                                /> : <React.Fragment />,
                            ]}
                        />
                    </UI.Form>
                </UI.Tile>
                <Footer/>
            </UI.Page>
        );
    }

    setNewUrl(newValue: string): void {
        this.setState({url: newValue});
    }

    stopUpgradeClicked(): void {
        stopUpgrade()
            .then((result) => {
                this.setState({isLoading: false, url: '', file: undefined});
            })
            .catch((error) => {
                this.setState({error});
            });
    }

    upgradeVersionClicked(): void {
        this.setState({isLoading: true}, () => {
            if (this.state.file) {
                upgradeFromFile(this.state.file)
                    .then((result) => {
                        // this.setState({isLoading: false});
                    })
                    .catch((error) => {
                        this.setState({error, isLoading: false});
                    });
            } else {
                upgradeVersion(this.state.url)
                    .then((result) => {
                        // this.setState({isLoading: false});
                    })
                    .catch((error) => {
                        this.setState({error, isLoading: false});
                    });
            }
        });
    }

    private onFileSelected(event: any) {
        if (event.target?.files && event.target.files[0]) {
            this.setState({ file: event.target.files[0] });
        }
    }
}

export function GeneralSystemPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.SystemSettings);
        setMenu(getSystemVerticalMenu());
    }, []);
    return <GeneralSystemPageCmp />
}