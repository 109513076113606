import React, {Component, useEffect} from "react";
import {UI} from "src/engrator-core";
import {getSetting, JiraServerSetting, saveSetting} from "./rest-api";
import {FormError} from "../../../engrator-core/form-error.type";
import {Footer} from "../../footer";
import {useGlobalState} from "../../global-store";
import {jiraServerSettingsVerticalMenu} from "./jiraserver-settings-vertical-menu";
import {MainMenuItem} from "../../global-store/create-global-state";
import {DataStoragePage, DataStoragePageCmp} from "../../system";
import {getSystemVerticalMenu} from "../../system/system-vertical-menu";
import {SettingsGeneralPageCmp} from "../settings-general";

export function JiraServerGeneralSettingsPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMenu(jiraServerSettingsVerticalMenu);
        setMainMenuItem(MainMenuItem.Settings);
    }, []);
    return <SettingsGeneralPageCmp />
}