import React from 'react';
import { UI } from '../../../engrator-core';
import { TableGridData, TimeDisplay } from '../../../engrator-core/ui';

import { SyncError } from './sync-error.type';

export function createSyncErrorsTableGridData(errors: SyncError[]): TableGridData {
    return {
        data: errors.map(error => [
            // <UI.NavLink path={`/app/reporting/suite-run/${error.id}/flow/${error.id}`}>#{error.id}</UI.NavLink>,
            <UI.NavLink path={`/app/reporting/suite-run/${error.runId}/flow/${error.id}/details`}>#{error.id}</UI.NavLink>,
            <TimeDisplay dateTime={error.formattedStartTime}/>,
            error.failureReason,
            <UI.NavLink path={`/app/reporting/suite-run/${error.runId}/flow/${error.id}/details`}>Details</UI.NavLink>,
        ]),
        headers: ['#Sync ID', 'Occurred at', 'Message', 'Actions'],
    };
}
