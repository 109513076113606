import React, { MouseEvent } from 'react';
import {Icon} from "./icon";

type Props = {
    text: string,
    url: string,
    onClickHandler?: () => void
}

type State = {
}

export class DocumentationLink extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    handleClick(e: MouseEvent<HTMLAnchorElement>) {
        if (!this.props.onClickHandler) return;
        e.preventDefault();
        this.props.onClickHandler();
    }

    render() {
        return <a
            className={`engrator-ui-documentation-link`}
            target={"_blank"}
            href={ this.props.url }
            onClick={ (e: MouseEvent<HTMLAnchorElement>) => this.handleClick(e) }>
                { this.props.text } <Icon icon={"external-link"} />
        </a>;
    }
}