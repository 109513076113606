import React from 'react';

import {Route} from 'react-router-dom';
import {AuthToken, FieldError, isInIframeApp, setIframeToken, UI} from 'src/engrator-core';
import {AnalysisPage, IntegrationErrorsPage, IntegrationThreadsPage, LogsPage} from './analysis';
import {SyncErrorsPage} from './analysis/sync-errors';
import {SignInPage} from './auth';
import {ClusterTenantsLicensesPage} from './cluster/cluster-tenants-licenses-page';
import {ClusterTenantsPage} from './cluster/cluster-tenants-page';
import {DashboardPage, GettintStartedPage} from './dashboard';
import {AcceptTerms} from './engagement';
import {OnboardingProgress} from './engagement/onboarding';
import {IntegrationSyncsPage, PipelinesGroupsPage} from './integration';
import {ConnectionsListPage, OauthRedirectPage} from './integration/connections';
import {IntegrationCreatePage, IntegrationDetailsPage, IntegrationsListPage} from './integration/integration-suites';
import {SmartIntsDetailsPage} from './integration/smartints';
import {SmartIntsCreatePage} from './integration/smartints/pages/create';
import {SmartIntsListPage} from './integration/smartints/pages/list/smart-ints-list-page';
import {FlowPage, ReportingPage, RunResultsLogsPage, RunResultsPage} from './reporting';
import {
    DataStoragePage,
    GeneralSystemPage,
    SystemApiKeysPage,
    SystemCustomPropertiesPage,
    SystemIntegrationThreadsPage,
    SystemLicensePage,
    SystemLogsPage,
    SystemUserPasswordPage, SystemUsersPage,
} from './system';
import {TopNavigation} from './top-navigation';
import {ArchivedItemsConfigurationPage, ArchivedItemsPage} from "./archiver";
import {NotificationsListPage} from "./integration/notifications";
import {fetchSystemInfo} from "./top-navigation/rest-api";
import {DynamicRouter} from "./dynamic-router";
import {JiraServerSettingsMigrationLicensePage, JiraServerSettingsPage} from "./settings/jiraserver";
import {CloudSystemLicensePage} from "./settings/cloud";
import TopHorizontalMenu from "./top-navigation/top-horizontal-menu";
import {MainVerticalMenu} from "./top-navigation/main-vertical-menu";
import {FlowLogsPage} from "./reporting/flows/flow-logs.page";
import {IntegrationMigrationPage} from "./integration/smartints/pages/integration-migration-page";
import {IntegrationRunsPage} from "./integration/smartints/pages/reports/integration-runs-page";
import {Loader} from "../engrator-core/ui";
import getintIoIcon from './top-navigation/getintio-icon.png';
import {CloudSettingsDataStoragePage} from "./settings/cloud/cloud-settings-data-storage-page";
import {ClusterUsersPage} from "./cluster/users";
import {AccountAccessPage} from "./settings";
import {JiraServerGeneralSettingsPage} from "./settings/jiraserver/jira-server-general-settings-page";
import {IntegrationChangesPage} from "./integration/smartints/pages/changes/integration-changes-page";
import { SharedMappingsListPage } from './integration/shared-mappings/shared-mappings-list-page';

type State = {
    tokenCheckStatus: 'init' | 'no-token' | 'token-exists' | 'fetching-token';
    showOverlay: boolean;
    fetchInfoError?: any;
    needsSignIn: boolean;
}

export class AppRenderer extends React.Component<{}, State> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            tokenCheckStatus: 'init',
            showOverlay: true,
            needsSignIn: false
        }
    }

    async needsToSignIn(): Promise<void> {
        const query = new URLSearchParams(window.location.search);
        if (query.get('usePostMessage') || isInIframeApp()) {
            window.onmessage = (e: MessageEvent) => {
                if (e.data.indexOf('getintToken')) {
                    const token = JSON.parse(e.data).token;
                    setIframeToken(token);
                    this.fetchInfo();
                }
            }
            window.parent.postMessage('getToken', '*')
            return;
        } else {
            try {
                await fetchSystemInfo();
            } catch(error) {
                const err = error as FieldError;
                if(err.status === 401) {
                    this.setState({ showOverlay: false, needsSignIn: true });
                    return;
                }
            }
            // const jiraBearerToken = query.get('jbt');
            // if (jiraBearerToken) {
            //     document.body.setAttribute('jbt', jiraBearerToken);
            // }
            // const origin = query.get('origin');
            // if (origin) {
            //     document.body.setAttribute('origin', origin);
            // }
            // this.fetchInfo();
            // return;
        }
        if (AuthToken.exists()) {
            this.setState({showOverlay: false, needsSignIn: false});
        } else {
            this.setState({showOverlay: false, needsSignIn: true});
        }
    }

    private fetchInfo() {
        fetchSystemInfo().then(info => {
            this.setState({showOverlay: false});
        }).catch(error => {
            this.setState({fetchInfoError: error});
        });
    }

    async componentDidMount() {
        setTimeout(async () => {
            await this.needsToSignIn()
            // if (this.needsToSignIn()) {
            // } else {
            //     // fetchSystemInfo().then(info => {
            //     //     this.setState({showOverlay: false});
            //     // }).catch(error => {
            //     //     this.setState({fetchInfoError: error});
            //     // });
            // }
        }, 3 * 1000);
    }

    render() {
        if (this.state.showOverlay) {
            return <AppLoader
                errorMsg={ this.state.fetchInfoError?.message }
            />;
        }
        if (this.state.needsSignIn) {
            return <DynamicRouter>
                <SignInPage/>
            </DynamicRouter>
        }
        return <DynamicRouter>
            <div>
                <AcceptTerms/>
                <TopNavigation/>
                <TopHorizontalMenu />
                <MainVerticalMenu />
                <div className="content">
                    <Route path="/" exact component={DashboardPage}/>
                    <Route path="/app/dashboard" exact component={DashboardPage}/>
                    <Route path="/app/getting-started" exact component={ GettintStartedPage }/>
                    <Route path="/app/integration/create" exact component={IntegrationCreatePage}/>
                    <Route path="/app/integration/shared-mappings" exact component={SharedMappingsListPage}/>
                    <Route path="/app/integration/connections" exact component={ConnectionsListPage}/>
                    <Route path="/app/integration/notifications" exact component={NotificationsListPage}/>
                    <Route path="/app/integration/groups" exact component={PipelinesGroupsPage}/>
                    <Route path="/app/integration/pipelines" exact component={IntegrationsListPage}/>
                    <Route path="/app/integration/smart-ints" exact component={SmartIntsListPage}/>
                    <Route path="/app/integration/smart-ints/create" exact component={SmartIntsCreatePage}/>
                    <Route path="/app/integration/pipelines/:id/edit" component={IntegrationDetailsPage}/>

                    <Route path="/app/integration/smart-ints/:id/edit" component={SmartIntsDetailsPage}/>
                    <Route path="/app/integration/smart-ints/:id/changes" component={IntegrationChangesPage}/>
                    <Route path="/app/integration/smart-ints/:id/migration" component={IntegrationMigrationPage}/>
                    <Route path="/app/integration/smart-ints/:id/synced-items" component={IntegrationSyncsPage}/>
                    <Route path="/app/integration/smart-ints/:id/runs" component={IntegrationRunsPage}/>

                    <Route path="/app/archiver" exact component={ArchivedItemsPage}/>
                    <Route path="/app/archiver/configuration" exact component={ArchivedItemsConfigurationPage}/>

                    <Route path="/app/reporting" exact component={ReportingPage}/>
                    <Route path="/app/reporting/suite-run/:id/details" exact component={RunResultsPage}/>
                    <Route path="/app/reporting/suite-run/:id/logs" component={RunResultsLogsPage}/>
                    <Route path="/app/reporting/suite-run/:id/flow/:flowId" exact component={FlowPage}/>
                    <Route path="/app/reporting/suite-run/:id/flow/:flowId/details" exact component={FlowPage}/>
                    <Route path="/app/reporting/suite-run/:id/flow/:flowId/logs" exact component={FlowLogsPage}/>

                    <Route path="/app/analysis" exact component={AnalysisPage}/>
                    <Route path="/app/analysis/threads" exact component={IntegrationThreadsPage}/>
                    <Route path="/app/analysis/logs" exact component={LogsPage}/>
                    <Route path="/app/analysis/errors" exact component={IntegrationErrorsPage}/>
                    <Route path="/app/analysis/syncerrors" exact component={SyncErrorsPage}/>
                    <Route path="/app/system/api-keys" exact component={SystemApiKeysPage}/>
                    <Route path="/app/system/license" exact component={SystemLicensePage}/>
                    <Route path="/app/system/general" exact component={GeneralSystemPage}/>
                    <Route path="/app/system/data-storage" exact component={ DataStoragePage }/>
                    {/*<Route path="/app/system/users" exact component={ SystemUsersPage }/>*/}
                    <Route path="/app/system/custom-properties" exact component={SystemCustomPropertiesPage}/>
                    <Route path="/app/system/integration-threads" exact component={SystemIntegrationThreadsPage}/>
                    <Route path="/app/system/logs" exact component={SystemLogsPage}/>
                    <Route path="/app/system/password" exact component={SystemUserPasswordPage}/>
                    <Route path="/app/oauth/redirect" exact component={OauthRedirectPage}/>

                    <Route path="/app/jira-server/settings/data-storage" exact component={ JiraServerSettingsPage }/>
                    <Route path="/app/jira-server/settings/general" exact component={ JiraServerGeneralSettingsPage }/>
                    <Route path="/app/jira-server/settings/migration-license" exact component={ JiraServerSettingsMigrationLicensePage }/>
                    <Route path="/app/jira-server/settings/custom-properties" exact component={SystemCustomPropertiesPage}/>

                    <Route path="/app/settings/cloud/license" exact component={ CloudSystemLicensePage }/>
                    <Route path="/app/settings/cloud/data-storage" exact component={ CloudSettingsDataStoragePage }/>
                    <Route path="/app/settings/cloud/account-access" exact component={ AccountAccessPage }/>

                    <Route path="/app/cluster/tenants/list" exact component={ClusterTenantsPage}/>
                    <Route path="/app/cluster/users/list" exact component={ClusterUsersPage}/>
                    <Route path="/app/cluster/tenants/licenses" exact component={ClusterTenantsLicensesPage}/>
                </div>
                {!AuthToken.isClusterAdmin() && <OnboardingProgress/>}
            </div>
        </DynamicRouter>
    }
}

type AppLoaderProps = {
    errorMsg?: string;
}
type AppLoaderState = {
    showLoader: boolean;
};
class AppLoader extends React.Component<AppLoaderProps, AppLoaderState> {
    constructor(props: Readonly<AppLoaderProps>) {
        super(props);
        this.state = { showLoader: false };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showLoader: true });
        }, 1500);
    }

    componentDidUpdate(prevProps: Readonly<AppLoaderProps>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.errorMsg !== prevProps.errorMsg) {
            this.forceUpdate();
        }
    }

    render() {
        return <div className={`loading-overlay`}>
            <div>
                {/*<AppSVGLogo app={ SoftwareName.Airtable } />*/}
                {/*<AppSVGLogo app={ SoftwareName.ServiceNow } />*/}
                {/*<AppSVGLogo app={ SoftwareName.Asana } />*/}
                {/*<AppSVGLogo app={ SoftwareName.GitLab } />*/}
                {/*<AppSVGLogo app={ SoftwareName.ClickUp } />*/}
                {/*<AppSVGLogo app={ SoftwareName.Jira } />*/}
                {/*<AppSVGLogo app={ SoftwareName.Zendesk } />*/}
                { !this.state.showLoader && <img src={getintIoIcon} height={30}/> }
                { this.state.showLoader && <p><Loader visible={true} appearance={"darkgray"} /><br/>Getint is loading...</p> }
                { this.props.errorMsg && <React.Fragment>
                    <UI.Message appearance={"error"} message={this.props.errorMsg} />
                    {/*<Footer></Footer>*/}
                </React.Fragment> }
            </div>
        </div>;
    }
}