import React from 'react';
import { UI } from 'src/engrator-core';
import { hasUserCompleted, registerUserActivityAction, UserActivityAction } from '../system/user-activity';

type Props = {};
type State = {
    isChatAvailable: boolean;
    shouldBeDisplayed: boolean;
};

export class WelcomeWizard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isChatAvailable: false,
            shouldBeDisplayed: false
        };
    }

    async componentDidMount(): Promise<void> {
        const shouldBeDisplayed = await hasUserCompleted(UserActivityAction.ConfirmedWelcomeWizard);
        this.setState({ shouldBeDisplayed }, () => {
            if (this.state.shouldBeDisplayed) {
                setTimeout(() => {
                    if ((window as any).Tawk_API !== undefined && (window as any).Tawk_API.maximize) {
                        this.setState({ isChatAvailable: true });
                        (window as any).Tawk_API.maximize();
                    }
                }, 2000);
            }
        });
    }

    render() {
        if (!this.state.shouldBeDisplayed) {
            return <React.Fragment/>;
        }
        return <div className={`persistence-loader engagement-popup`}>
            <div className={`box`}>
                <h1>Welcome in getint.io</h1>
                <p>We are very happy that you decided to give a try our Integration Platform.</p><br/>
                <p>Click <b>Create Integration</b> button to configure integration according to you workflow and needs.
                    It should take you just <b>few minutes</b> to design basic integration.</p>
                <p>Once created, remember that to trigger integraion you need to add or edit issues/tasks/items in
                    synced apps/projects.</p>
                {/*{this.state.isChatAvailable && <UI.Message appearance={'info'}>*/}
                {/*    In case of issues write to us on chat (bottom left window corner)*/}
                {/*</UI.Message>}*/}
                <br/>
                <UI.Button
                    onClick={() => this.done()}
                    text={`OK`}
                />
            </div>
        </div>;
    }

    private done() {
        registerUserActivityAction(UserActivityAction.ConfirmedWelcomeWizard);
        this.setState({ shouldBeDisplayed: false });
    }
}
