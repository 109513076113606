import React from 'react';
import { SelectAppStep } from './select-app-step';
import { SelectConnectionDetailsStep } from './select-connection-details-step';
import { ISoftwareFactory, SoftwareName } from '../../../../../software';
import { SmartIntTrigger } from '../../definition/smart-int-trigger.type';
import { registerUserActivityAction, UserActivityAction } from '../../../../system/user-activity';
import { BreadcrumbStep, ConnectionStep } from 'src/engrator-core/ui/breadcrumbs';

type State = {
    softwareName?: SoftwareName;
};

type Props = {
    currentStep: BreadcrumbStep;
    close: () => void;
    softwareFactory: ISoftwareFactory;
    onAppSelected: (appName: SoftwareName, connectionId: number, trigger: SmartIntTrigger) => void;
    onChangeStepHandler: (step: ConnectionStep) => void;
}

export class AppSelectionWizard extends React.Component<Props, State> {
    render() {
        const { currentStep } = this.props;
        return <React.Fragment>
            <div className={`app-selection-wizard`}>
                {currentStep.index === ConnectionStep.SelectApp && <SelectAppStep
                    softwareFactory={this.props.softwareFactory}
                    optionSelected={this.appSelectedFromList.bind(this)}
                />}
                {(currentStep.index === ConnectionStep.ConnectApp || currentStep.index === ConnectionStep.Configure || currentStep.index === ConnectionStep.CreateConnection) && <SelectConnectionDetailsStep
                    softwareFactory={this.props.softwareFactory}
                    onConnectionSetUp={this.onConnectionSetUp.bind(this)}
                    softwareName={this.state.softwareName}
                    onChangeStepHandler={(step: ConnectionStep) => this.props.onChangeStepHandler(step)}
                    currentStep={currentStep}
                />}
            </div>
        </React.Fragment>
    }

    private appSelectedFromList(softwareName: SoftwareName) {
        registerUserActivityAction(UserActivityAction.SelectedSoftware);
        this.setState({ softwareName });
        this.props.onChangeStepHandler(ConnectionStep.ConnectApp);
    }

    private onConnectionSetUp(connectionId: number, trigger: SmartIntTrigger) {
        this.props.onAppSelected(
            this.state.softwareName as SoftwareName,
            connectionId,
            trigger
        );
    }
}
