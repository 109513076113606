import React from 'react';
import { UI } from 'src/engrator-core';
import { getTrailData, TrialData } from './../rest-api';
import { ContactSales } from './contact-sales';

type Props = {};
type State = {
    isClosed: boolean;
    trialData?: TrialData;
    contactSales: boolean;
};

export class TrialWarningBar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isClosed: false,
            contactSales: false
        };
    }

    componentDidMount() {
        const data: TrialData = {
            contactSales: false, daysLeft: 20, isSaas: false, linkToBuy: "dsadsa", onTrial: true, saas: false
        };
        getTrailData().then((trialData) => {
            this.setState({ trialData });
        })
        .catch((error) => {

        });
    }

    render() {
        if (this.state.isClosed || !this.state.trialData) {
            return <React.Fragment/>;
        }
        if (this.state.trialData && !this.state.trialData.onTrial) {
            return <React.Fragment/>;
        }
        if (this.state.trialData.daysLeft <= 0) {
            return <React.Fragment/>;
        }
        return <div className={`trial-warning-bar`}>
            { this.state.contactSales && <ContactSales 
                closeHandler={ () => this.closeContactSales() }
            /> }
            <UI.WarningBar>
                {this.state.trialData.daysLeft > 0 && <span>{this.state.trialData.daysLeft} days left. </span>}
                {!(this.state.trialData.daysLeft > 0) && <span>Trial version. </span>}
                {this.state.trialData.linkToBuy && <a href={this.state.trialData.linkToBuy} target={'_blank'}>BUY</a>}
                {!this.state.trialData.linkToBuy && <span> Buy</span>} commercial license.
                { this.state.trialData.contactSales &&
                    <React.Fragment> Or <UI.Button className={`contact-sales`} 
                        onClick={this.contactSales.bind(this)} 
                        appearance="link-inline"
                        text={`CONTACT SALES`}
                    />
                    </React.Fragment>
                }
                {/*<UI.Icon icon="close" onClick={() => this.closeClicked()}/>*/}
            </UI.WarningBar>
        </div>;
    }

    private closeContactSales() {
        this.setState({ contactSales: false });
    }

    private contactSales() {
        this.setState({ contactSales: true });
    }

    private closeClicked() {
        this.setState({ isClosed: true });
    }
}
