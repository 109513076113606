import { PipelineStep } from 'src/generic';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';

import { MondayStepKey } from '../freshdesk-step-key.type';
import { MondayUpdateItemConfiguration } from './monday-update-item-configuration';
import { MondayUpdateItemEditStep } from './monday-update-item-edit-step';
import { MondayUpdateItemNewStep } from './monday-update-item-new-step';

export const MondayUpdateItemPipelineStep: PipelineStep<MondayUpdateItemConfiguration> = {
    addStepComponentClass: MondayUpdateItemNewStep,
    editStepComponentClass: MondayUpdateItemEditStep,
    specification: {
        action: 'Update issue',
        description: 'Update selected issue',
        key: MondayStepKey.FreshdeskUpdateItem,
        software: SoftwareName.Monday,
        stepType: StepType.Action,
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as MondayUpdateItemConfiguration;
        return `Update issue '${stepConfiguration.targetArtifact}'`;
    },
};
