export enum UpdateStrategyMode {
    UseLatestUpdatedData = 'UseLatestUpdatedData',
    MergeChangedFromBoth = 'MergeChangedFromBoth'
}

export enum UpdateStrategySubMode {
    PreferLeftAppData = 'PreferLeftAppData',
    PreferRightAppData = 'PreferRightAppData'
}

export type UpdateStrategySettings = {
    updateStrategyMode: UpdateStrategyMode;
    updateStrategySubMode: UpdateStrategySubMode;
}

export function getDefaultUpdateStrategySettings(): UpdateStrategySettings {
    return {
        updateStrategyMode: UpdateStrategyMode.MergeChangedFromBoth,
        updateStrategySubMode: UpdateStrategySubMode.PreferLeftAppData
    };
}
