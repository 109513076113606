import React from 'react';


import {UI} from 'src/engrator-core';
import {SmartIntDefinitionTypeMapping} from '../../definition/smart-int-definition--type-mapping.type';
import {SmartIntAdvancedSyncConfiguration} from '../../definition/smart-int-definition-configuration.type';
import {AppsSupport} from '../visual-integration-designer';
import {ReadMore} from '../../../../../engrator-core/ui/read-more';

type Props = {
    appsSupport: AppsSupport;
    mapping: SmartIntDefinitionTypeMapping;
    syncConfiguration: SmartIntAdvancedSyncConfiguration;
};
type State = {
    syncConfiguration: SmartIntAdvancedSyncConfiguration;
}

export class AdvancedSync extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        console.log(this.props.syncConfiguration);
        this.state = {
            syncConfiguration: JSON.parse(JSON.stringify(this.props.syncConfiguration))
        };
    }

    render() {
        return <div className={`advanced-sync`}>
            <UI.FormSection
                label={`Customize create / update actions`}
            >
                <UI.Checkbox
                    defaultValue={`${this.state.syncConfiguration.enabled}`}
                    checkedValue={`true`}
                    uncheckedValue={`false`}
                    onChange={() => this.switchEnableFlag()}
                    label={`Enable`}
                />
                <UI.FormGroup
                    description={
                        <div>
                            Decide if items should be created and updated in each app or not. Useful for setting up
                            one-way integration, <UI.DocumentationLink
                                text={`read more here`}
                                url={`https://docs.getint.io/getintio-platform/workflows/configuring-your-data-sync-bidirectional-and-unidirectional-options`}/>
                        </div>
                    }
                >
                    {this.state.syncConfiguration.enabled && <React.Fragment>
                        <br/>
                        <ul className={`advanced-sync-options`}>
                            <li>
                            </li>
                            <li>
                            </li>

                        </ul>
                        <div className={`flex row two-columns`}>
                            <div className={``}>
                                <h4>{this.props.appsSupport.leftApp} (left) → {this.props.appsSupport.rightApp} (right)</h4>
                                {/*<UI.Checkbox*/}
                                {/*    defaultValue={`${this.state.syncConfiguration.left.create}`}*/}
                                {/*    checkedValue={`true`}*/}
                                {/*    uncheckedValue={`false`}*/}
                                {/*    onChange={() => this.switchActionFlag('left', 'create')}*/}
                                {/*    label={`Create ${this.props.mapping.left.name}(s)`}*/}
                                {/*/>*/}
                                {/*<UI.Checkbox*/}
                                {/*    defaultValue={`${this.state.syncConfiguration.left.update}`}*/}
                                {/*    checkedValue={`true`}*/}
                                {/*    uncheckedValue={`false`}*/}
                                {/*    onChange={() => this.switchActionFlag('left', 'update')}*/}
                                {/*    label={`Update  ${ this.props.mapping.left.name}(s)`}*/}
                                {/*/>*/}
                                <UI.Checkbox
                                    defaultValue={`${this.state.syncConfiguration.right.create}`}
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                    onChange={() => this.switchActionFlag('right', 'create')}
                                    label={
                                        <span>Sync <b>NEW</b> {this.props.mapping.left.name}(s) from {this.props.appsSupport.leftApp} (left) → {this.props.appsSupport.rightApp} (right)</span>}
                                />

                                <UI.Checkbox
                                    defaultValue={`${this.state.syncConfiguration.right.update}`}
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                    onChange={() => this.switchActionFlag('right', 'update')}
                                    label={
                                        <span>Sync <b>UPDATED</b> {this.props.mapping.left.name}(s) {this.props.appsSupport.leftApp} (left) → {this.props.appsSupport.rightApp} (right)</span>}
                                />
                            </div>
                            <div className={``}>
                                <h4>{ this.props.appsSupport.rightApp } (right) → {this.props.appsSupport.leftApp} (left)</h4>
                                <UI.Checkbox
                                    defaultValue={`${this.state.syncConfiguration.left.create}`}
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                    onChange={() => this.switchActionFlag('left', 'create')}
                                    label={
                                        <span>Sync <b>NEW</b> {this.props.mapping.right.name}(s) from {this.props.appsSupport.rightApp} (right) → {this.props.appsSupport.leftApp} (left)</span>}
                                />
                                <UI.Checkbox
                                    defaultValue={`${this.state.syncConfiguration.left.update}`}
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                    onChange={() => this.switchActionFlag('left', 'update')}
                                    label={
                                        <span>Sync <b>UPDATED</b> {this.props.mapping.right.name}(s) from {this.props.appsSupport.rightApp} (right) → {this.props.appsSupport.leftApp} (left)</span>}
                                />


                                {/*<UI.Checkbox*/}
                                {/*    defaultValue={`${this.state.syncConfiguration.right.create}`}*/}
                                {/*    checkedValue={`true`}*/}
                                {/*    uncheckedValue={`false`}*/}
                                {/*    onChange={() => this.switchActionFlag('right', 'create')}*/}
                                {/*    label={`Create  ${ this.props.mapping.right.name}(s)`}*/}
                                {/*/>*/}
                                {/*<UI.Checkbox*/}
                                {/*    defaultValue={`${this.state.syncConfiguration.right.update}`}*/}
                                {/*    checkedValue={`true`}*/}
                                {/*    uncheckedValue={`false`}*/}
                                {/*    onChange={() => this.switchActionFlag('right', 'update')}*/}
                                {/*    label={`Update  ${this.props.mapping.right.name}(s)`}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </React.Fragment>}
                </UI.FormGroup>
            </UI.FormSection>
        </div>;
    }

    private switchActionFlag(side: 'left' | 'right', action: 'create' | 'update') {
        const syncConfiguration = this.state.syncConfiguration;
        syncConfiguration[side][action] = !syncConfiguration[side][action];
        Object.assign(this.props.syncConfiguration, syncConfiguration);
        this.setState({syncConfiguration});
    }

    private switchEnableFlag() {
        const syncConfiguration = this.state.syncConfiguration;
        syncConfiguration.enabled = !syncConfiguration.enabled;
        Object.assign(this.props.syncConfiguration, syncConfiguration);
        this.setState({syncConfiguration});
    }

    private changeCondition(side: 'left' | 'right', newValue: string) {

    }
}
