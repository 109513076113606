import React from 'react';
import {NewPipelineStepComponentProps} from "src/generic";
import {WrikeCreateCommentsConfiguration} from "./wrike-create-comments-configuration";
import {CreateCommentsStep} from "src/software/generic/create-comments-step/create-comments-step";
import {UI} from 'src/engrator-core';
import {StepArtifact} from "src/generic/software/step";
import {SoftwareName} from "../../../software-name";

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class WrikeCreateCcommentsNewStep extends React.Component<Props, State> {
    private configuration: WrikeCreateCommentsConfiguration;
    private availableArtifacts: StepArtifact[];

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration as WrikeCreateCommentsConfiguration;
        this.availableArtifacts = this.props.context.artifactsFactory.getAvailableOutArtifactsForSoftware(SoftwareName.Wrike);
    }

    render() {
        return <React.Fragment>
            { this.props.formError?.general && <UI.Message
                appearance={`error`}
                >{ this.props.formError.general}</UI.Message>
            }
            <CreateCommentsStep
                onConfigurationChanged={ this.onConfigurationChanged.bind(this) }
                availableArtifacts={ this.availableArtifacts }
                formError={ this.props.formError }
            />
        </React.Fragment>;
    }

    private onConfigurationChanged(newConfiguration: WrikeCreateCommentsConfiguration) {
        this.configuration = Object.assign(this.configuration, newConfiguration);
    }
}