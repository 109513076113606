import React, {useEffect} from 'react';

import {FieldError, UI} from 'src/engrator-core';
import {Footer} from '../footer';
import {
    CreateIntegrationGuide,
    registerUserActivityAction,
    shouldShowCreateInteagrationGuide,
    UserActivityAction,
} from '../system/user-activity';
import {Chart} from './chart';
import {DashboardReportType} from './dashboard-report.type';
import {ListChart} from './list-chart';
import {clearDashboardCaches} from './rest-api';
import {StartGuide} from "../engagement/start-guide";
import {getSystemInfo} from "../top-navigation/rest-api";
import {SystemInfo} from "../top-navigation/threads-summary.type";
import {useGlobalState} from "../global-store";
import {MainMenuItem} from "../global-store/create-global-state";
import {homeVerticalMenu} from "./home-vertical-menu";


type State = {
    errors: Array<FieldError>,
    showCreateIntegrationGuide?: boolean;
    isLoading: boolean;
    time: number;
    error?: FieldError;
    tenantInfo?: SystemInfo;
};

export class DashboardPageCmp extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            errors: [],
            isLoading: false,
            time: new Date().getTime(),
        };
    }

    async componentDidMount() {
        registerUserActivityAction(UserActivityAction.WasOnDashboard);
        try {
            const showCreateIntegrationGuide = await shouldShowCreateInteagrationGuide();
            const tenantInfo = await getSystemInfo();
            this.setState({showCreateIntegrationGuide, tenantInfo });
        } catch (e) {
            this.setState({error: e});
        }
    }

    render() {
        if (this.state.error) {
            return <UI.Message appearance={'error'}>{this.state.error?.message}</UI.Message>;
        }
        if (this.state.showCreateIntegrationGuide === undefined) {
            return <React.Fragment/>;
        }
        if (this.state.showCreateIntegrationGuide) {
            return <UI.Page className="dashboard-page"><CreateIntegrationGuide/></UI.Page>;
        }
        return (
            <UI.Page className="dashboard-page">
                <UI.Header
                    title={``}
                    buttons={[
                        <UI.Button
                            onClick={this.clearCache.bind(this)}
                            appearance="secondary"
                            text={`Refresh`}
                        />,
                    ]}
                />
                { !!this.state.tenantInfo?.usingH2Database && <React.Fragment>
                    <UI.Message appearance={"warning"}>You are using H2 database which is dedicated only for a trial purposes and is not designed for production use. Using it may cause instability and loss of data. Before using Getint in production mode make sure to switch to a relational database like PostgreSQL, Oracle, MSSQL. You can change JDBC connection settings in <b>getint.env</b> file.</UI.Message>
                    <br/>
                </React.Fragment> }
                <div className={`content`}>
                    <UI.DashboardTile
                        header={`Synced Items`}
                        description={`Number of synced items`}
                        fullReportUrl={`/app/reporting?report=${DashboardReportType.PerformedFlows}`}
                    >
                        <Chart time={this.state.time} reportType={DashboardReportType.PersistedArtifacts}/>
                    </UI.DashboardTile>
                    {/*<StartGuide />*/}
                    {/*<UI.DashboardTile*/}
                    {/*    header={`Start guide`}*/}
                    {/*    description={`dsa`}*/}
                    {/*>*/}
                    {/*    <StartGuide />*/}
                    {/*</UI.DashboardTile>*/}
                    <UI.DashboardTile
                        header={`Performed Syncs`}
                        description={`Number of all and failed syncs`}
                        fullReportUrl={`/app/reporting?report=${DashboardReportType.PerformedFlows}`}
                    >
                        <Chart
                            time={this.state.time}
                            reportType={DashboardReportType.PerformedFlows}/>
                    </UI.DashboardTile>

                    <UI.DashboardTile
                        header={`Runs`}
                        description={`All and failed runs`}
                        fullReportUrl={`/app/reporting?report=${DashboardReportType.PipelinesRuns}`}
                    >
                        <Chart time={this.state.time} reportType={DashboardReportType.FailedRuns}/>
                    </UI.DashboardTile>

                    <UI.DashboardTile
                        header={`Latest errors`}
                        description={`Latest errors occurred during synchronization`}
                        fullReportUrl={`/app/analysis/syncerrors`}
                    >
                        <ListChart time={this.state.time} reportType={DashboardReportType.LatestErrors}/>
                    </UI.DashboardTile>
                </div>
                <Footer/>
            </UI.Page>
        );
    }

    private clearCache() {
        clearDashboardCaches()
            .then(() => {
                this.setState({time: new Date().getTime()});
            });
    }
}

export function DashboardPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMenu(homeVerticalMenu);
        setMainMenuItem(MainMenuItem.Home);
    }, []);
    return <DashboardPageCmp />
}
