import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { AzureSmartIntsTriggerConfiguration } from './azure-smart-ints-trigger-configuration';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class AzureSmartIntsEditTrigger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const configuration = (this.props.trigger.configuration as AzureSmartIntsTriggerConfiguration);
        return <React.Fragment>
            <UI.FormGroup>
                <UI.LabelValue
                    label={`Project`}
                    value={configuration.project.name}
                />
            </UI.FormGroup>
            <UI.FormGroup
                label={`Additional Query`}
                description={`You can provide additional query params which will be appended to the url generated when searching for work items in Azure (e.g. [System.AreaPath] UNDER "Main\\demo")`}
            >
                <UI.Input defaultValue={configuration.query} label={``} onChange={this.onJqlInputChanged.bind(this)} placeholder={`e.g. [System.AreaPath] UNDER "Main\\demo"`}/>
            </UI.FormGroup>
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.props.trigger.configuration.query = newValue;
    }
}
