import { SceneComponentType } from './scene-component-type';
import { SceneTransitionType, SceneTransition, SceneTransitionData } from './';
import { EventsBus } from 'src/generic';

export class SceneManager {
    components : {[componentType: number]: any} = {
        [SceneComponentType.RightPanel]: null,
        [SceneComponentType.MainScene]: null
    }
    sceneTransition: SceneTransition;
    updateSceneComponentsHandler: (sceneElementType: SceneComponentType, element: any) => void;

    constructor(updateSceneComponentHandler: (sceneElementType: SceneComponentType, element: any) => void, eventsBus: EventsBus) {
        this.updateSceneComponentsHandler = updateSceneComponentHandler;
        this.sceneTransition = new SceneTransition(this.setSceneComponent.bind(this), eventsBus);
    }

    private setSceneComponent(sceneElementType: SceneComponentType, element: any) {
        this.updateSceneComponentsHandler(sceneElementType, element);
    }

    transition(transitionType: SceneTransitionType, data: SceneTransitionData) {
        this.sceneTransition.performTransition(transitionType, data);
    }

    clearScene() {
        this.setSceneComponent(SceneComponentType.RightPanel, null);
        this.setSceneComponent(SceneComponentType.MainScene, null);
    }
}