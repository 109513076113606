import React from 'react';
import {NewPipelineStepComponentProps} from "src/generic";
import {UI} from 'src/engrator-core';
import {GitHubSearchItemsConfiguration} from "./github-search-items-configuration";
import {GitHubStepContext, GitHubStepContextData} from "../common";
import {ArtifactDirection} from "../../../../generic/artifacts";

type Props = {
} & NewPipelineStepComponentProps;
type State = {
    formError: { [key: string]: string },
    canConfigureArtifact: boolean,
    isLoadingJql: boolean,
    isAdding: boolean
};

export class GitHubSearchItemsNewStep extends React.Component<Props, State> {
    private configuration: GitHubSearchItemsConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as GitHubSearchItemsConfiguration;
        this.configuration.onlyNewChanges = true;
        this.configuration.context = {};
        this.state = { isLoadingJql: false, formError: {}, canConfigureArtifact: false, isAdding: false };
    }

    private onConfigurationChanged(data: GitHubStepContextData) {
        this.configuration.context.repository = data.repository;
        if (this.configuration.context.repository) {
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }

    render() {
        return <div className={`connectors-github-search-items-step`}>
            <GitHubStepContext connectionId={ this.props.context.connectionId } onDataChanged={ this.onConfigurationChanged.bind(this) } />
            <UI.FormGroup
                label={`Changed from last integration`}
                description={`If checked, only items that changed since last integration will be picked up.`}
            >
                <UI.Checkbox defaultValue={"true"} onChange={ this.onlyNewChanged.bind(this) } checkedValue={ "true" } uncheckedValue={ "false "}/>
            </UI.FormGroup>
        </div>
    }

    onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }
};
