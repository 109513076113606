import React from 'react';
import { UI } from 'src/engrator-core';
import { hasUserCompleted, registerUserActivityAction, UserActivityAction } from '../system/user-activity';

type Props = {};
type State = {
    isChatAvailable: boolean;
    shouldBeDisplayed: boolean;
};

export class IntegrationCreatedCongrats extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isChatAvailable: true,
            shouldBeDisplayed: false
        };
    }

    async componentDidMount(): Promise<void> {
        const completedByUser = await hasUserCompleted(UserActivityAction.IntegrationCreatedCongrats);
        this.setState({ shouldBeDisplayed: !completedByUser }, () => {
            if (this.state.shouldBeDisplayed) {
                setTimeout(() => {
                    if ((window as any).Tawk_API !== undefined && (window as any).Tawk_API.maximize) {
                        this.setState({ isChatAvailable: true });
                        //(window as any).Tawk_API.maximize();
                    }
                }, 2000);
            }
        });
    }

    render() {
        if (!this.state.shouldBeDisplayed) {
            return <React.Fragment/>;
        }
        return <div className={`persistence-loader engagement-popup`}>
            <div className={`box`}>
                {/*<img src={star}/><br/><br/>*/}
                <h1>Congratulations! You created your first Integration</h1>
                <p>
                    Getint will constantly listen for changes, and will propagate updates to the synced apps.<br/><br/>
                    Integration will pick up newly created issues/tasks/incidents. To trigger this, proceed to the connected apps, and create new entries.<br/><br/>
                    In case of issues, open a support ticket.
                </p>
                {/*{this.state.isChatAvailable && <UI.Message appearance={'info'}>*/}
                {/*    In case of issues write to us on chat (bottom left window corner)*/}
                {/*</UI.Message>}*/}
                <UI.Button
                    onClick={() => this.done()}
                    text={`OK`}
                />
            </div>
        </div>;
    }

    private done() {
        registerUserActivityAction(UserActivityAction.IntegrationCreatedCongrats);
        this.setState({ shouldBeDisplayed: false });
    }
}
