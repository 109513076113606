import React from 'react';
import { FieldError, UI } from '../../../../../../engrator-core';
import { SharedMapping } from 'src/app/integration/shared-mappings/rest-api';

type Props = {
    closeHandler: () => void;
    sharedMapping: SharedMapping;
    convertHandler: () => void;
};

type State = {
    error?: FieldError;
    isLoading: boolean;
};

export class SharedMappingModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoading: false };
    }

    async onConvertClicked() {
        this.setState({ isLoading: true }, async () => {
            try {
                this.setState({ isLoading: false }, () => {
                    this.props.convertHandler();
                    this.props.closeHandler();
                });
            } catch (error) {
                this.setState({ error: error as FieldError, isLoading: false });
            }
        });
    }

    isLoading(): boolean {
        return false;
    }

    render() {
        return (
            <React.Fragment>
                <UI.Modal header={`Convert '${this.props.sharedMapping.name}' to standard mapping`} isLeftAligned={true}>
                    Please confirm you are sure to convert "{this.props.sharedMapping.name}" shared mapping to standard mapping
                    {this.state.error && (
                        <UI.Message appearance={'error'}>
                            <p>Failed to convert shared mapping: {this.state.error.message}</p>
                        </UI.Message>
                    )}
                    <UI.ButtonsBar
                        primary={
                            <UI.Button
                                text="Convert"
                                isLoading={this.state.isLoading}
                                disabled={this.state.isLoading}
                                appearance="danger"
                                onClick={() => this.onConvertClicked()}
                            />
                        }
                        secondary={[
                            <UI.Button
                                text="Close"
                                appearance="link-inline"
                                onClick={() => this.props.closeHandler()}
                            />
                        ]}
                    />
                </UI.Modal>
            </React.Fragment>
        );
    }
}
