import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {ZendeskStepKey} from "../zendesk-step-key.type";
import {ZendeskFindItemNewStep} from "./zendesk-find-item-new-step";
import {ZendeskFindItemEditStep} from "./zendesk-find-item-edit-step";
import {ZendeskFindItemConfiguration} from "./zendesk-find-item-configuration";


export const ZendeskFindItemPipelineStep: PipelineStep<ZendeskFindItemConfiguration> = {
    addStepComponentClass: ZendeskFindItemNewStep,
    editStepComponentClass: ZendeskFindItemEditStep,
    specification: {
        action: "Find Ticket",
        description: "Find Zendesk ticket",
        key: ZendeskStepKey.ZendeskFindItem,
        software: SoftwareName.Zendesk,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `ZendeskItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ZendeskFindItemConfiguration;
        return `Find ticket with id='${stepConfiguration.id}'`;
    }
};