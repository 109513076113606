import { PipelineStep } from 'src/generic';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';
import { GetintArchiverStepKey } from '../archiver-step-key.type';
import { GetintArchiverCreateItemConfiguration } from './monday-create-item-configuration';
import { GetintArchiverCreateItemEditStep } from './monday-create-item-edit-step';
import { GetintArchiverCreateItemNewStep } from './monday-create-item-new-step';

export const GetintArchiverCreateItemPipelineStep: PipelineStep<GetintArchiverCreateItemConfiguration> = {
    addStepComponentClass: GetintArchiverCreateItemNewStep,
    editStepComponentClass: GetintArchiverCreateItemEditStep,
    specification: {
        action: 'Create issue',
        description: 'Create issue in specified board',
        key: GetintArchiverStepKey.GetintArchiverCreateItem,
        software: SoftwareName.GetintArchiver,
        stepType: StepType.Action,
    },
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GetintArchiverCreateItemConfiguration;
        return `Create issue in ${stepConfiguration.context.board?.name}`;
    },
};
