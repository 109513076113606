import { PipelineStep } from 'src/generic';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';

import { MondayStepKey } from '../freshdesk-step-key.type';
import { MondayStatusTransitionConfiguration } from './monday-status-transition-configuration';
import { MondayStatusTransitionEditStep } from './monday-status-transition-edit-step';
import { MondayStatusTransitionNewStep } from './monday-status-transition-new-step';

export const MondayStatusTransitionPipelineStep: PipelineStep<MondayStatusTransitionConfiguration> = {
    addStepComponentClass: MondayStatusTransitionNewStep,
    editStepComponentClass: MondayStatusTransitionEditStep,
    specification: {
        action: 'Change Item Status',
        description: 'Move Monday Item from one status to another',
        key: MondayStepKey.FreshdeskStatusTransition,
        software: SoftwareName.Monday,
        stepType: StepType.Action,
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as MondayStatusTransitionConfiguration;
        return `Change item status`;
    },
};
