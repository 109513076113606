import React, { Component } from 'react';
import { UI } from 'src/engrator-core';
import { FormError } from 'src/engrator-core/form-error.type';
import { BulkEditOperation } from './bulkd-edit-operation.type';
import { bulkEditIntegrations } from './rest-api';


type State = {
    selectedOperation?: BulkEditOperation;
    isLoading: boolean;
    error?: FormError;
}
type Props = {
    refreshHandler: () => void;
    selectedIds: number[];
}

export class BulkEditButtons extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    render() {
        return <React.Fragment>
            <UI.ButtonsBar
                primary={ <React.Fragment /> }
                secondary={[
                    <UI.Button dataSel={`disable`} text="Disable" appearance="secondary"
                               onClick={() => this.selectOperation(BulkEditOperation.Disable)}/>,
                    <UI.Button text="Enable" appearance="secondary"
                               onClick={() => this.selectOperation(BulkEditOperation.Enable)}/>,
                    <UI.Button text="Delete" appearance="danger" onClick={() => this.selectOperation(BulkEditOperation.Delete)}/>,
                ]} />
            { this.state.selectedOperation && <UI.ConfirmationModal
                closeHandler={ () => this.closeClicked() }
                confirmHandler={ () => this.confirm() }
            >
                { this.state.error && <div>
                    <UI.Message appearance={'error'}>
                        <p>Something went wrong: { this.state.error.message }</p>
                    </UI.Message>
                </div> }
                { this.state.isLoading && <React.Fragment>
                        <h3>Working...</h3>
                        <div className={`loader`}><UI.Loader appearance={"darkgray"} visible={true}/></div>
                    </React.Fragment>
                }
                { !this.state.isLoading && <React.Fragment>
					<p>Are you sure to <span className={`operation-name`}>{this.state.selectedOperation}</span> integration(s) ?</p>
                </React.Fragment> }
            </UI.ConfirmationModal>}
        </React.Fragment>;
    }

    private confirm() {
        this.setState({ isLoading: true }, () => {
            bulkEditIntegrations(this.props.selectedIds, this.state.selectedOperation!)
                .then(() => {
                    this.props.refreshHandler();
                })
                .catch((error) => {
                    this.setState({ isLoading: false, error });
                });
        })
    }

    private closeClicked() {
        this.setState({ selectedOperation: undefined, error: undefined });
    }

    private selectOperation(operation: BulkEditOperation) {
        this.setState({ selectedOperation: operation });
    }
}
