import {FormError} from "../../../../engrator-core/form-error.type";
import React from "react";
import {ServicenowStepContextData} from "./servicenow-step-context";
import { UI } from "src/engrator-core";

type Props = {
    configuration: ServicenowStepContextData;
}

type State = {
}

export class ServicenowStepContextDisplay extends React.Component<Props, State> {
    render() {
        return <React.Fragment>
            <UI.LabelValue
                label={'Table'}
                value={ this.props.configuration.table!.label }
            />
        </React.Fragment>
    }
}