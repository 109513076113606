import {axiosWrapper} from 'src/engrator-core';
import {
    createIntegrationChangesModel,
    IntegrationChangeDto,
    IntegrationChangesModel
} from './integration-changes-model';

const ENDPOINT_URL = '/integration/suites';

export function fetchIntegrationChanges(id: number): Promise<IntegrationChangesModel[]> {
    return axiosWrapper.get(`${ENDPOINT_URL}/${id}/changes`)
        .then((response: any) => {
            const changes = response.changes;
            if (changes.length > 0) {
                return changes.map((change: IntegrationChangeDto) =>
                    createIntegrationChangesModel(change)
                );
            }
            return [];
        })
        .catch((error) => {
            throw new Error(`Error fetching integration changes: ${error.message || error}`);
        });
}
