import React from 'react';
import {EntityFormHelper, FieldError, UI} from "src/engrator-core";
import {FormError} from "src/engrator-core/form-error.type";
import {DropdownOption} from "src/engrator-core/ui";
import { fetchSpaces, fetchFolders } from "./../rest-api";
import {WrikeSpace} from "./wrike-space.type";
import {WrikeFolder} from "./wrike-folder.type";

export type WrikeStepContextData = {
    space?: WrikeSpace;
    folder?: WrikeFolder;
};

type Props = {
    connectionId: number;
    onDataChanged: (data: WrikeStepContextData, isValid: boolean) => void;
    formError?: FormError;
}

type State = {
    formError: FormError;
    isLoadingSpaces: boolean;
    isLoadingFolders: boolean;
}

export class WrikeStepContext extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private spaceOptions: DropdownOption[] = [];
    private folderOptions: DropdownOption[] = [];
    private contextData : WrikeStepContextData = {};

    constructor(props: Props) {
        super(props);
        this.state = {formError: {}, isLoadingSpaces: true, isLoadingFolders: false };
        this.entityFormHelper = new EntityFormHelper((propertyName: 'space' | 'folder', newValue, additionalArgument?: any) => {
            if (!additionalArgument) {
                return;
            }
            if (propertyName === 'space') {
                this.contextData.space = additionalArgument as WrikeSpace;
                this.fetchFolders();
            } else if (propertyName === 'folder') {
                this.contextData.folder = additionalArgument as WrikeFolder;
            }
            const isValid = (this.contextData.folder !== undefined && this.contextData.space !== undefined);
            this.props.onDataChanged(this.contextData, isValid);
            this.forceUpdate();
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.forceUpdate();
        }
    }

    componentDidMount(): void {
        fetchSpaces(this.props.connectionId)
            .then((organizations: WrikeSpace[]) => {
                this.spaceOptions = organizations.map(space => ({
                    label: space.name,
                    value: space.id,
                    object: space
                }));
                this.setState({isLoadingSpaces: false});
            })
            .catch((error: FieldError) => {
                this.setState({ isLoadingSpaces: false, formError: { ['pickerError']: error.message  } });
            });
    }

    render() {
        return <React.Fragment>
            { this.state.formError.pickerError && <UI.Message appearance={"error"}>{ this.state.formError.pickerError}</UI.Message> }
            <UI.FormGroup
                label={`Space`}
                error={ this.props.formError?.space }
                description={``}
                isRequired={true}>
                { this.state.isLoadingSpaces && <UI.Loader visible={true} appearance={"darkgray"} /> }
                { !this.state.isLoadingSpaces && <UI.Dropdown options={ this.spaceOptions } onChange={ this.entityFormHelper.onChangeHandler('space')   }/> }
            </UI.FormGroup>
            { this.contextData.space && <UI.FormGroup
                label={`Folder`}
                error={ this.props.formError?.folder }
                description={``}
                isRequired={true}>
                { this.state.isLoadingFolders && <UI.Loader visible={true} appearance={"darkgray"} /> }
                { !this.state.isLoadingFolders && <UI.Dropdown options={ this.folderOptions } onChange={ this.entityFormHelper.onChangeHandler('folder')   }/> }
            </UI.FormGroup> }
        </React.Fragment>;
    }

    fetchFolders() {
        if (!this.contextData.space) {
            return;
        }
        this.setState({ isLoadingFolders: true }, () => {
            fetchFolders(this.props.connectionId, this.contextData.space!.id)
                .then((issueTypes) => {
                    this.folderOptions = issueTypes.map(folder => ({
                        label: folder.name,
                        value: String(folder.id),
                        object: folder
                    }));
                    this.setState({isLoadingFolders: false});
                })
                .catch((error: FieldError) => {
                    this.setState({ isLoadingFolders: false, formError: {[`pickerError`]: error.message}});
                });
        });
    }
};