import { axiosWrapper } from 'src/engrator-core';

import { SyncError } from './sync-error.type';

const ENDPOINT_URL = '/analysis/sync-errors';

type SyncErrorsResponse = {
    flows: SyncError[],
    pages: number,
    currentPage: number
};

export const fetchSyncErrors = (pageToFetch: number): Promise<SyncErrorsResponse> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}?page=${pageToFetch}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

