import { PipelineGenericStep, PipelineIntegrationStep, PipelineStep } from "src/generic";
import { ConfigurationComponent, GenericSoftwareStep, IntegrationStep, StepSpecification, StepType } from "src/generic/software/step";

export function createCommonGenericSoftwareStep(stepSpecificiation: StepSpecification): CommonGenericSoftwareStep | undefined {
    if (stepSpecificiation.key.indexOf('SyncAttachments') > 0 
    || stepSpecificiation.key.indexOf('SyncComments') > 0
    || stepSpecificiation.key.indexOf('ArtifactsComparator') > 0
    || stepSpecificiation.key.indexOf('SyncHierarchy') > 0
    || stepSpecificiation.key.indexOf('SmartIntStatus') > 0) {
        return new PipelineGenericStep(createPipelineStep(stepSpecificiation));
    }
    return undefined;
}

export class CommonGenericSoftwareStep implements GenericSoftwareStep {
    getStepSpecification(): StepSpecification {
        throw new Error("Method not implemented.");
    }
    getFlowStepSnippet(configuration: any) {
        throw new Error("Method not implemented.");
    }
    getConfigurationComponent(connectionId: number, artifactsFactory: any, addStepHandler: (integrationStep: IntegrationStep) => void, stepSpecification: StepSpecification): ConfigurationComponent {
        throw new Error("Method not implemented.");
    }
    getIntegrationStep(configuration: any): PipelineIntegrationStep {
        throw new Error("Method not implemented.");
    }

}

function createPipelineStep(stepSpecification: StepSpecification): PipelineStep<{}> {
    return {
        addStepComponentClass: undefined,
        editStepComponentClass: undefined,
        specification: stepSpecification,
        preferredNameForArtifact: `jiraIssue`,
        getSnippetTitle(configuration: {}): string {
            if (stepSpecification.key.indexOf('SyncAttachments') > 0) {
                return 'Synchronize attachments';
            } else if (stepSpecification.key.indexOf('SyncComments')> 0) {
                return 'Synchronize comments';
            } else if (stepSpecification.key.indexOf('ArtifactsComparator')> 0) {
                return 'Synchronize fields values';
            } else if (stepSpecification.key.indexOf('SmartIntStatus')> 0) {
                return 'Status transition';
            } else if (stepSpecification.key.indexOf('SyncHierarchy')> 0) {
                return 'Synchronize hierarchy';
            }
            return stepSpecification.action;
        }
    };
}