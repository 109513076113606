import React, {SyntheticEvent} from 'react';
import {UI} from 'src/engrator-core';
import {DropdownOption, Icon, Icon20} from '../../../../engrator-core/ui';
import {SmartIntDefinitionTypeMapping} from '../definition/smart-int-definition--type-mapping.type';
import {SmartIntTrigger} from '../definition/smart-int-trigger.type';
import {ValueMappingsGroup} from '../visual/components/value-mappings/value-mappings-group.type';
import {DefaultsPropertyMapping, PropertyMapping} from '../visual/property-mapping.type';
import {AppsSupport} from '../visual/visual-integration-designer';
import {AddMapping} from './add-mapping';

import {MappingDisplay} from './mapping-display';
import ValuesMappingsPicker from "../visual/components/value-mappings/values-mappings-picker";
import {FormError} from "../../../../engrator-core/form-error.type";
import {Side} from "../../../../generic";
import {MapFieldsBtn} from "./map-fields-btn";
import {MatchFieldsWindow} from "../visual/autobuilder/match-fields-window";
import {mergeFieldsMappings} from "./fields-mappings-mergerer";

export enum PropertiesContext {
    Standard = 'Standard',
    Transition = 'Transition'
}

type Props = {
    isMangerView?: boolean;
    properties: PropertyMapping[];
    onMappingsChangedHandler: (mappings: PropertyMapping[]) => void;
    appsSupport: AppsSupport;
    mapping: SmartIntDefinitionTypeMapping;
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
    addMappingHeader?: string;
    multiFieldsUsage: boolean;
    propertiesContext: PropertiesContext;
    valueGroup?: ValueMappingsGroup;
    shouldDisableAutoMap?: boolean;
    disableAddFieldBtn?: boolean;
};
type State = {
    isAutoMapping: boolean;
    error?: FormError;
    isLoadingOptions: boolean;
    showMapFields: boolean;
    showValuesMapping: boolean;
    mappings: PropertyMapping[];
    showAutoMapping: boolean;
};

export class PropertyMappingsPicker extends React.Component<Props, State> {
    private leftOptions: DropdownOption[] = [];
    private rightOptions: DropdownOption[] = [];
    private addMappingsOptions: { left: DropdownOption[]; right: DropdownOption[] } = {
        left: [],
        right: []
    };
    private currentlyEditedPropertyMapping?: PropertyMapping;

    constructor(props: Props) {
        super(props);
        this.state = {
            showAutoMapping: false,
            isAutoMapping: false,
            mappings: this.props.properties,
            showMapFields: false,
            isLoadingOptions: true,
            showValuesMapping: false
        };
    }

    private putIdForExistingProperties(options: DropdownOption[]): void {
        const names = options.map(o => o.label);
        for (const option of options) {
            let counter = 0;
            // count how many options are with the same name
            for (const name of names) {
                if (name === option.label) {
                    counter++;
                }
            }
            // If more than 1, add to name of the option the ID
            if (counter > 1) {
                option.label = option.label + ` (${option.value}) `;
            }
        }
    }

    async componentDidMount(): Promise<void> {
        // if (await isFeatureFlagEnabled(FeatureFlag.FF_NEW_FIELD_MAPPING)) {
        await this.setState({isLoadingOptions: false});
        // } else {
        //     fetchProperties(this.props.appsSupport.leftApp, this.props.leftTrigger, this.props.mapping.left, this.props.propertiesContext, this.props.valueGroup?.leftValue)
        //         .then((options) => {
        //             this.leftOptions = options.map((option) => ({
        //                 value: option.id,
        //                 label: option.name,
        //                 object: option
        //             }));
        //             this.putIdForExistingProperties(this.leftOptions);
        //
        //             fetchProperties(this.props.appsSupport.rightApp, this.props.rightTrigger, this.props.mapping.right, this.props.propertiesContext, this.props.valueGroup?.rightValue)
        //                 .then((options) => {
        //                     this.rightOptions = options.map((option) => ({
        //                         value: option.id,
        //                         label: option.name,
        //                         object: option
        //                     }));
        //                     this.putIdForExistingProperties(this.rightOptions);
        //                     this.leaveOnlyUnPickedOptions(this.state.mappings).then(() => {
        //                         this.setState({isLoadingOptions: false});
        //                     })
        //                 })
        //                 .catch((error) => {
        //                     this.setState({isLoadingOptions: false, error});
        //                 });
        //         })
        //         .catch((error) => {
        //             this.setState({isLoadingOptions: false, error});
        //         });
        // }
    }

    render() {
        const showMapFields =
            (this.props.disableAddFieldBtn !== undefined)
                ? false
                : (!this.state.showMapFields && this.props.isMangerView) || (!this.state.showMapFields);
        const isLoadingOptions = (this.state.isLoadingOptions);
        return <React.Fragment>
            {this.props.children}
            <div className={`section-with-help`}>
                <div className={`left`}>
                    {/* Add mapping button and map fields component*/}
                    {(showMapFields && this.state.mappings.length > 3) &&
                        <MapFieldsBtn
                            shouldDisableAutoMap={ this.props.shouldDisableAutoMap }
                            isAutoMapping={ this.state.isAutoMapping }
                            addMappingClicked={() => this.addMappingClicked()}
                            autoMapFieldsClicked={() => this.autoMapFieldsClicked()}
                        />}
                    {this.state.showMapFields && <React.Fragment>
                        {!isLoadingOptions && !this.state.error && <AddMapping
                            propertiesContext={this.props.propertiesContext}
                            valueGroup={this.props.valueGroup}
                            appsSupport={this.props.appsSupport}
                            options={this.addMappingsOptions}
                            addMappingHeader={this.props.addMappingHeader}
                            addMappingHandler={this.addMappingHandler.bind(this)}
                            typeMapping={this.props.mapping}
                            mappings={this.state.mappings}
                            triggers={{[Side.Left]: this.props.leftTrigger, [Side.Right]: this.props.rightTrigger}}
                        />}
                        {!isLoadingOptions && this.state.error &&
                            <React.Fragment><UI.Message appearance={"error-message"}>Failed to fetch fields. Please
                                contact support. Error: {this.state.error.message} </UI.Message><br/></React.Fragment>}
                        {isLoadingOptions && <p>Loading fields <UI.Loader visible={true} appearance={"darkgray"}/></p>}
                    </React.Fragment>}
                    {this.state.mappings.length > 0 && <div className="clear-mappings">
                        <UI.Button appearance='danger' text="Clear Mappings" onClick={() => this.clearMappings()}/>
                    </div>}
                    {/* Mapped options */}
                    {this.state.mappings.map((propertyMapping, index) =>
                            // <React.Fragment key={index}>
                            <MappingDisplay
                                // className={ (propertyMapping.isNewlyCreated) ? 'anim-fade-in' : '' }
                                propertyMapping={propertyMapping}
                                onValuesMappingHandler={() => this.onValuesMappingHandler(propertyMapping)}
                                onDeleteHandler={(event) => this.deleteMapping(index, event)} key={index}
                            />
                        // </React.Fragment>
                    )}
                    {(showMapFields && this.state.mappings.length <= 3) &&
                        <MapFieldsBtn
                            shouldDisableAutoMap={ this.props.shouldDisableAutoMap }
                            isAutoMapping={ this.state.isAutoMapping }
                            addMappingClicked={() => this.addMappingClicked()}
                            autoMapFieldsClicked={() => this.autoMapFieldsClicked()}
                        />}
                </div>
                { this.state.showAutoMapping && <MatchFieldsWindow
                    closeHandler={() => this.setState({showAutoMapping: false})}
                    applyHandler={(mappings, mode) => this.matchFieldsHandler(mappings, mode) }
                    appSupport={ this.props.appsSupport}
                    leftTrigger={ this.props.leftTrigger}
                    rightTrigger={ this.props.rightTrigger}
                    mapping={ this.props.mapping }
                    multiFieldsUsage={false}
                    /> }
                {!!!this.props.isMangerView && <div className={`right`}>
                    <h2>How field mapping works</h2>
                    <p>
                        Map similar fields across applications, even if they have different names (e.g., 'Assignee' in Jira and 'Assigned To' in Azure DevOps). Some mappings might require setting up the corresponding fields in another system first. Essentially, field mapping translates data from one format to another.
                    </p>
                    <h2><UI.Icon icon20={Icon20.Files}/> Resources</h2>
                    <ul>
                        <li><a target={`_blank`} href={"https://docs.getint.io/guides/integration-synchronization/how-to-map-fields"}>How fields mapping works</a></li>
                        <li><a target={`_blank`} href={"https://docs.getint.io/getintio-platform/workflows/assignees-mapping"}>Mapping users/assignees</a></li>
                        {/*<li><a href={""}>How to map users and assignees</a></li>*/}
                        {/*<li><a href={""}>How to do multi-mapping</a></li>*/}
                        {/*<li><a href={""}>I do not see field on a list</a></li>*/}
                        <li><a target={`_blank`} href={"https://docs.getint.io/getintio-platform/workflows/jira-integrations-storing-reference-id-url-of-counterpart"}>Storing reference ID / URL of counterpart</a></li>
                        <li><a target={`_blank`} href={"https://docs.getint.io/getintio-platform/workflows/storing-counterpart-link-in-the-task-comment"}>Storing counterpart link in the task comment</a></li>
                        <li><a target={`_blank`} href={"https://docs.getint.io/getintio-platform/workflows/configuring-your-data-sync-bidirectional-and-unidirectional-options"}>Configuring Your Data Sync: Bidirectional and Unidirectional Options</a></li>
                    </ul>
                </div>}
            </div>

            {this.state.showValuesMapping && <UI.FullScreenModal
                depth={1}
                header={`Field mapping configuration`}
                maximized={true}
                showPrimaryBtn={false}
                closeBtnHandler={() => this.closeValueMappingsPicker()}
                primaryBtnHandler={() => Promise.resolve(true)}
            ><ValuesMappingsPicker
                depth={1}
                // valueMappings={this.currentlyEditedPropertyMapping!.values}
                groups={this.currentlyEditedPropertyMapping!.groups}
                defaults={this.currentlyEditedPropertyMapping!.defaults}
                empty={this.currentlyEditedPropertyMapping!.empty}
                mapping={this.currentlyEditedPropertyMapping!}
                leftTrigger={this.props.leftTrigger}
                rightTrigger={this.props.rightTrigger}
                typeMapping={this.props.mapping}
                appsSupport={this.props.appsSupport}
                closeHandler={this.closeValueMappingsPicker.bind(this)}
                onMappingsChangedHandler={this.onValueMappingsChanged.bind(this)}
                showNavBar={true}
                properties={this.props.properties}
                sharedMappingId={this.currentlyEditedPropertyMapping!.sharedMappingId}
            /></UI.FullScreenModal>
            }
        </React.Fragment>;
    }

    private async autoMapFieldsClicked() {
        this.setState({ showAutoMapping: true });
    }

    private addMappingClicked() {
        this.setState({showMapFields: true});
    }

    private onValueMappingsChanged(groups: ValueMappingsGroup[], defaultMappings?: DefaultsPropertyMapping, sharedMappingId?: number) {
        this.currentlyEditedPropertyMapping!.groups = groups;
        if (defaultMappings) {
            this.currentlyEditedPropertyMapping!.defaults = defaultMappings;
        }
        this.currentlyEditedPropertyMapping!.sharedMappingId = sharedMappingId;
    }

    private async addMappingHandler(mapping: PropertyMapping): Promise<void> {
        const mappings = this.state.mappings;
        mapping.isNewlyCreated = true;
        mappings.unshift(mapping);
        await this.leaveOnlyUnPickedOptions(mappings);
        this.props.onMappingsChangedHandler(this.state.mappings);
    }

    private onValuesMappingHandler(propertyMapping: PropertyMapping) {
        this.currentlyEditedPropertyMapping = propertyMapping;
        this.setState({showValuesMapping: true});
    }

    private closeValueMappingsPicker() {
        this.currentlyEditedPropertyMapping = undefined;
        this.setState({showValuesMapping: false});
    }

    private async deleteMapping(index: number, event?: SyntheticEvent) {
        if (event) {
            event.stopPropagation();
        }
        const mappings = this.state.mappings;
        mappings.splice(index, 1);
        await this.leaveOnlyUnPickedOptions(mappings);
        this.props.onMappingsChangedHandler(this.state.mappings);
    }

    private async leaveOnlyUnPickedOptions(mappings: PropertyMapping[]) {
        this.addMappingsOptions = {left: [], right: []};
        this.leftOptions.forEach(option => {
            let exists = false;
            if (!this.props.multiFieldsUsage) {
                mappings.forEach(mapping => {
                    if (option.value === mapping.left.id) {
                        exists = true;
                    }
                });
            }
            if (!exists) {
                this.addMappingsOptions.left.push(option);
            }
        });
        this.rightOptions.forEach(option => {
            let exists = false;
            if (!this.props.multiFieldsUsage) {
                mappings.forEach(mapping => {
                    if (option.value === mapping.right.id) {
                        exists = true;
                    }
                });
            }
            if (!exists) {
                this.addMappingsOptions.right.push(option);
            }
        });
        await this.setState({mappings});
    }

    private async matchFieldsHandler(mappings: PropertyMapping[], mode: "merge" | "replace"): Promise<void> {
        if (mode === 'replace') {
            await this.leaveOnlyUnPickedOptions(mappings);
            this.props.onMappingsChangedHandler(this.state.mappings);
        } else {
            const mergedMappings = mergeFieldsMappings(this.state.mappings, mappings);
            await this.leaveOnlyUnPickedOptions(mergedMappings);
            this.props.onMappingsChangedHandler(this.state.mappings);
        }
    }

    private clearMappings() {
        this.setState({ mappings: [] })
    }
}
