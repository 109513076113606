import React from 'react';
import { UI } from 'src/engrator-core';
import {IntegrationListItem} from "../integration-list-item";
import {getScriptForExport} from "./rest-api";
import {FormError} from "../../../../../../engrator-core/form-error.type";

type Props = {
    integration: IntegrationListItem;
    closeHandler: () => void;
};

type State = {
    isLoading: boolean;
    script?: string;
    error?: FormError;
};

export class IntegrationExport extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        getScriptForExport(this.props.integration.id)
            .then(script => {
                this.setState({ isLoading: false, script });
            })
            .catch((error) => {
                this.setState({ isLoading: false, error });
            })
    }

    render() {
        return <UI.FullScreenModal
            showPrimaryBtn={ false }
            header={`Export integration`}
            primaryBtnHandler={ () => Promise.resolve(true) }
            closeBtnHandler={ () => this.closeClicked() }
        >
            { this.state.isLoading && <UI.Loader appearance={'darkgray'} visible={true} /> }
            { this.state.error && <UI.Message appearance={'error'}>
                    <p>Something went wrong: { this.state.error.message }</p>
                </UI.Message> }
            { this.state.script && <div className="script-container">
                <p>Please copy below internal script</p>
                <textarea>{ JSON.stringify(this.state.script, undefined, 2) }</textarea>
            </div> }
        </UI.FullScreenModal>
    }

    private closeClicked() {
        this.props.closeHandler();
    }
}
