import React from "react";
import {SoftwareName} from "src/software";
import {DropdownOption} from "../../../../../engrator-core/ui";
import softwareFactory from "../../../../../software/software-factory";
import {fetchMappings} from "../rest-api";
import {SmartIntTrigger} from "../../definition/smart-int-trigger.type";
import {FieldError, UI} from "src/engrator-core";
import {AppSmartIntsSupport} from "../../app-smart-ints-support";

type State = {
    options: DropdownOption[];
    isLoading: boolean;
    error?: FieldError;
};

type Props = {
    softwareName: SoftwareName;
    trigger: SmartIntTrigger;
    optionSelected: (option?: DropdownOption) => void;
}

export class TypeSelectionWizard extends React.Component<Props, State> {
    private smartIntSupport: AppSmartIntsSupport | null;
    constructor(props: Props) {
        super(props);
        this.state = { options: [], isLoading: true };
        this.smartIntSupport = softwareFactory.getSoftwareByName(this.props.softwareName)!.getSmartIntsSupport();
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            fetchMappings(this.props.softwareName, this.props.trigger, 'types').then(
                (options) => {
                    this.setState({options, isLoading: false });
                }
            ).catch((error: any) => {
                this.setState({ isLoading: false, error });
            });
        });
    }

    render() {
        return <div className={`type-selection-wizard`}>
            { this.state.error && <UI.Message appearance={"error"}>{ this.state.error?.message }</UI.Message> }
            { this.state.isLoading && <UI.Loader visible={ true } appearance={"darkgray"} /> }
            { this.state.options.length > 0 && <UI.Dropdown
                label={`Select ${ this.props.softwareName } type`}
                options={ this.state.options }
                isRequired={ true }
                onChange={ (option: DropdownOption, object: any) => { console.log('d', option); this.props.optionSelected(object)} }
            /> }
        </div>
    }
}
