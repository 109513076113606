import React from 'react';
import {UI} from '../../../../../engrator-core';
import {PropertiesContext, PropertyMappingsPicker} from '../../configuration';

import {SmartIntDefinitionTypeMapping} from '../../definition/smart-int-definition--type-mapping.type';
import {SmartIntTrigger} from '../../definition/smart-int-trigger.type';
import {PropertyMapping} from '../property-mapping.type';
import {AppsSupport} from '../visual-integration-designer';

type Props = {
    mapping: SmartIntDefinitionTypeMapping;
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
    appsSupport: AppsSupport;
    multiFieldsUsage: boolean;
    isMangerView?: boolean;
    shouldDisableAutoMap?: boolean;
    disableAddFieldBtn?: boolean;
};

export class FieldsMappings extends React.Component<Props> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            { !!!this.props.isMangerView && <UI.Message appearance={"info"}>
                Execute the field mapping, according to your business requirements. Specify direction for fields mappings if other than bi-directional.
            </UI.Message> }
            <PropertyMappingsPicker
                disableAddFieldBtn={this.props.disableAddFieldBtn}
                shouldDisableAutoMap={ this.props.shouldDisableAutoMap }
                properties={this.props.mapping.propertiesConfiguration.properties}
                appsSupport={this.props.appsSupport}
                mapping={this.props.mapping}
                multiFieldsUsage={this.props.multiFieldsUsage}
                leftTrigger={this.props.leftTrigger}
                rightTrigger={this.props.rightTrigger}
                propertiesContext={PropertiesContext.Standard}
                isMangerView={ this.props.isMangerView }
                onMappingsChangedHandler={(mappings: PropertyMapping[]) => this.updateFieldsMappings(mappings)}
            />
        </React.Fragment>
    }

    private updateFieldsMappings(propertyMappings: PropertyMapping[]) {
        this.props.mapping.propertiesConfiguration.properties = propertyMappings;
    }
}
