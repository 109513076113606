import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";

import {InthubNewErrorConfiguration} from "./inthub-new-error-configuration";
import {IntHubNewErrorNewStep} from "./inthub-new-error-new-step";
import {IntHubNewErrorEditStep} from "./inthub-new-error-edit-step";
import {IntHubStepKey} from "../inthub-step-key.type";


export const IntHubNewErrorPipelineStep: PipelineStep<InthubNewErrorConfiguration> = {
    addStepComponentClass: IntHubNewErrorNewStep,
    editStepComponentClass: IntHubNewErrorEditStep,
    specification: {
        action: "New Integration Error Occured",
        description: "Triggered when new error occures during integration",
        key: IntHubStepKey.NewError,
        software: SoftwareName.IntHub,
        stepType: StepType.Trigger
    },
    preferredNameForArtifact: `intHubError`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        return 'When New Integration Error occures';
    }
}