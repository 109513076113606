import React, {Component} from "react";
import {PipelineModeEnum} from "./IntegrationCreatePage";
import {UI} from "src/engrator-core";


export class PipelineMode extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={`pipeline-mode`}>
                <div className={`content`}>
                    <div className={`header`}>
                        <h1>Select The Way</h1>
                        <p>Select the way you want to create Integration Pipeline(s).</p>
                    </div>
                    <div className={`flex row`}>
                        <div className={`flex-equal tile`} onClick={ () => this.props.modeSelectedHandler(PipelineModeEnum.SmartInt) }>
                            <UI.Tile>
                                <h3>SmartInt</h3>
                            </UI.Tile>
                        </div>
                        <div className={`flex-equal tile`} onClick={ () => this.props.modeSelectedHandler(PipelineModeEnum.Advanced) }>
                            <UI.Tile>
                                <h3>Advanced</h3>
                            </UI.Tile>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

type Props = {
    modeSelectedHandler: (mode: PipelineModeEnum) => void;
};
type State = {};