import React from 'react';
import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { SmartIntTrigger } from 'src/app/integration/smartints/definition/smart-int-trigger.type';
import { DropdownOption } from 'src/engrator-core/ui';
import { JiraSmartIntsEditTrigger } from './jira-smart-ints-edit-trigger';

import { JiraSmartIntsTrigger } from './jira-smart-ints-trigger';
import { fetchEpicRelationshipOptions, fetchSubtasksRelationshipOptions } from './rest-api';

export class JiraSmartIntsSupport extends AppSmartIntsSupport {
  getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void): any {
    return <JiraSmartIntsTrigger
      connectionId={connectionId}
      onConfigured={onConfigured}
    />;
  }

  getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <JiraSmartIntsEditTrigger
            connectionId={ connectionId }
            onConfigured={ onConfigured }
            trigger={ trigger }
        />;
    }


  hasTriggerConfigurationComponent(): boolean {
    return true;
  }

  isSupportingHierarchyEpics() {
    return true;
  }

  isSupportingHierarchySubtasks(typeName: string): boolean {
    if (typeName.toLowerCase() === 'epic') {
      return false;
    }
    return super.isSupportingHierarchySubtasks(typeName);
  }

  getEpicRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
    return fetchEpicRelationshipOptions(trigger);
  }

  getSubtasksRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
    return fetchSubtasksRelationshipOptions(trigger);
  }
}
