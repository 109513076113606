import {PipelineStep} from "src/generic/pipeline-step";
import {SoftwareName} from "src/software/software-name";
import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";

import {AsanaStepKey} from "../asana-step-key.type";
import {AsanaSearchItemsConfiguration} from "./asana-search-items-configuration";
import {AsanaSearchItemsNewStep} from "./asana-search-items-new-step";
import {AsanaSearchItemsEditStep} from "./asana-search-items-edit-step";


export const AsanaSearchItemsPipelineStep :  PipelineStep<AsanaSearchItemsConfiguration> = {
    addStepComponentClass: AsanaSearchItemsNewStep,
    editStepComponentClass: AsanaSearchItemsEditStep,
    specification: {
        action: "Search Asana Tasks",
        description: "Search tasks in specified project",
        key: AsanaStepKey.AsanaSearchItems,
        software: SoftwareName.Asana,
        stepType: StepType.Trigger
    },
    preferredNameForArtifact: `AsanaItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AsanaSearchItemsConfiguration;
        return `Search for tasks in ${stepConfiguration.workspace.name}, ${stepConfiguration.project.name}`;
    }
}