import React from 'react';
import {EntityFormHelper, FieldError, UI} from "src/engrator-core";
import {FormError} from "src/engrator-core/form-error.type";
import {DropdownOption} from "src/engrator-core/ui";
import { fetchTables } from "./../rest-api";
import {GitLabProject} from "./gitlab-project.type";
import {GitLabStepContextData} from "./gitlab-step-context";

type Props = {
    configuration: GitLabStepContextData;
}

type State = {
}

export class GitLabStepContextDisplay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <UI.LabelValue label={`Project`} value={ this.props.configuration.project!.name} />
        </React.Fragment>;
    }
};