import React from 'react';
import { DropdownOption } from '../../../../../../engrator-core/ui';
import { UI } from 'src/engrator-core';
import { DefaultMapping, DefaultMappingMode } from './default-mappping.type';
import { DefaultMappingDisplay } from './default-mapping-display';

type Props = {
    options: DropdownOption[];
    mapping: DefaultMapping;
    onChangeHandler: () => void;
};

type State = {
    isEditMode: boolean;
};

export class DefaultMappingPicker extends React.Component<Props, State> {
    private editedMapping: DefaultMapping;
    private defaultModes: DropdownOption[] = [
        { value: 'Skip', label: 'Skip' },
        { value: DefaultMappingMode.Value, label: 'Value' },
        { value: DefaultMappingMode.Predefined, label: 'Predefined' },
        { value: DefaultMappingMode.ValueFromOtherSide, label: 'Use value from other side' },
        { value: DefaultMappingMode.LabelFromOtherSide, label: 'Use label from other side' } 
    ];
    private defaultMode: DropdownOption;

    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = { isEditMode: false };
        this.editedMapping = this.props.mapping;
        this.defaultMode = this.defaultModes[0];
    }

    render() {
        return <div>
            {!this.state.isEditMode && <React.Fragment>
                <DefaultMappingDisplay defaultMapping={this.props.mapping}/>
                <UI.Button
                    onClick={this.editClicked.bind(this)}
                    text={`Edit`}
                    appearance={'link-inline'}/>
            </React.Fragment>}
            {this.state.isEditMode && <div className={`edit-default-mapping`}>
                <UI.Dropdown
                    disableEmptyOption={true}
                    label={`Select default mode`}
                    options={this.defaultModes}
                    onChange={this.modeChanged.bind(this)}
                    defaultValue={this.editedMapping.mode}
                />
                {this.editedMapping.mode === DefaultMappingMode.Value && <UI.Dropdown
                    disableEmptyOption={false}
                    label={`Select value which will be used as default option`}
                    options={this.props.options}
                    defaultValue={this.editedMapping.value}
                    onChange={this.valueOptionChanged.bind(this)}
                />}
                {this.editedMapping.mode === DefaultMappingMode.Predefined && <UI.Input
                    label={`or provide predefined value`}
                    defaultValue={this.editedMapping.value}
                    onChange={this.predefinedValueChanged.bind(this)}
                />}
                <UI.Button disabled={this.isDoneButtonDisabled()} onClick={this.doneClicked.bind(this)} text={`Done`}
                           appearance={'inline'}/>
            </div>}
        </div>;
    }

    private shouldProvideValue() {
        return this.editedMapping.mode === DefaultMappingMode.Value ||
            this.editedMapping.mode == DefaultMappingMode.Predefined;
    }

    private isDoneButtonDisabled() {
        if (!this.shouldProvideValue()) {
            return false;
        }
        return !this.editedMapping.value;
    }

    private editClicked() {
        this.editedMapping = Object.assign({}, this.props.mapping);
        this.setState({ isEditMode: true });
    }

    private doneClicked() {
        Object.assign(this.props.mapping, this.editedMapping);
        this.setState({ isEditMode: false }, () => {
            this.props.onChangeHandler();
        });
    }

    private modeChanged(newValue: string) {
        this.editedMapping.mode = newValue as DefaultMappingMode;
        if (this.editedMapping.mode !== DefaultMappingMode.Value) {
            this.editedMapping.value = '';
            this.editedMapping.label = '';
        }
        this.forceUpdate();
    }

    private valueOptionChanged(newValue: string) {
        const option = this.props.options.filter(option => option.value === newValue)[0];
        this.editedMapping.value = option?.value || '';
        this.editedMapping.label = option?.label || '';
        this.forceUpdate();
    }

    private predefinedValueChanged(newValue: string) {
        this.editedMapping.value = newValue;
        this.editedMapping.label = newValue;
        this.forceUpdate();
    }
}
