import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { axiosWrapper, FieldError } from '../../../engrator-core';
import { DropdownOption } from '../../../engrator-core/ui';
import { JiraSmartIntTriggerConfiguration } from './jira-smartint-trigger-configuration.type';

export const ENDPOINT_URL = '/connectors/jira';

export const fetchEpicRelationshipOptions = (smartIntsTrigger: SmartIntTrigger): Promise<DropdownOption[]> => {
  return new Promise((resolve, reject) => {
    const triggerConfig = smartIntsTrigger.configuration as JiraSmartIntTriggerConfiguration;
    try {
      const url = ENDPOINT_URL + '/meta/' + smartIntsTrigger.connectionId + '/epics-relationships/options/' + triggerConfig.project.key;
      axiosWrapper.get(url)
        .then((data: any) => {
          const options = data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }));
          resolve(options);
        })
        .catch((error: FieldError) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchSubtasksRelationshipOptions = (smartIntsTrigger: SmartIntTrigger): Promise<DropdownOption[]> => {
  return new Promise((resolve, reject) => {
    const triggerConfig = smartIntsTrigger.configuration as JiraSmartIntTriggerConfiguration;
    try {
      const url = ENDPOINT_URL + '/meta/' + smartIntsTrigger.connectionId + '/subtasks-relationships/options/' + triggerConfig.project.key;
      axiosWrapper.get(url)
        .then((data: any) => {
          const options = data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }));
          resolve(options);
        })
        .catch((error: FieldError) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
};
