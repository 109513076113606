import { Software } from 'src/generic';
import { ConnectionFactory } from "./../connections/connection-factory";
import { softwareListToRegister } from './software-list-to-register';

export type ISoftwareFactory = {
    registerSoftware(software: any, connectionFactory: ConnectionFactory): void;
    getSoftwareList(): Array<Software>;
    getAppsSupportingSmartInts(): Software[];
    getSoftwareByName(softwareName: string): Software | undefined;
};

class SoftwareFactory implements ISoftwareFactory {
    softwareList: Array<Software> = [];

    registerSoftware(software: Software): void {
        this.softwareList.push(software);
    }
    
    getSoftwareList(): Software[] {
        return this.softwareList;
    }

    getAppsSupportingSmartInts(): Software[] {
        return this.softwareList.filter(app => app.getSmartIntsSupport() !== null);
    }

    getSoftwareByName(softwareName: string) : Software | undefined {
        return this.softwareList.filter(software => software.getName() === softwareName)[0];
    }
}

const softwareFactory = new SoftwareFactory();

softwareListToRegister.forEach(software => {
    softwareFactory.registerSoftware(software);
});

export default softwareFactory;
