import { PipelineStep } from 'src/generic/pipeline-step';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';

import { MondayStepKey } from '../freshdesk-step-key.type';
import { MondaySearchItemsConfiguration } from './monday-search-items-configuration';
import { MondaySearchItemsEditStep } from './monday-search-items-edit-step';
import { MondaySearchItemsNewStep } from './monday-search-items-new-step';


export const MondaySearchItemsPipelineStep: PipelineStep<MondaySearchItemsConfiguration> = {
    addStepComponentClass: MondaySearchItemsNewStep,
    editStepComponentClass: MondaySearchItemsEditStep,
    specification: {
        action: 'Search Monday Issues',
        description: 'Search issues in specified project/repository',
        key: MondayStepKey.FreshdeskSearchItems,
        software: SoftwareName.Monday,
        stepType: StepType.Trigger,
    },
    preferredNameForArtifact: `MondayItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as MondaySearchItemsConfiguration;
        return `Search for issues in ${stepConfiguration.context.board?.name}`;
    },
};
