import React from 'react';

import { faCogs } from '@fortawesome/free-solid-svg-icons'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Artifact } from './Artifact';
import { StepType } from './../software/step';
import { ArtifactDefinition } from './../artifacts';
import { StepDebuggingState, StepDebuggingStatus } from './../debugging';
import { SoftwareName } from 'src/software';

type StepSnippetProps = {
    software: any,
    softwareName: SoftwareName,
    title: String,
    stepType: StepType,
    debuggingState: StepDebuggingState | null,
    inArtifactDefinition: ArtifactDefinition | null,
    outArtifactDefinition: string | null
};

export class StepSnippet extends React.Component<StepSnippetProps> {
    state: any;

    constructor(props: StepSnippetProps) {
        super(props);
        this.state = {
            isValid: true
        };
    }

    setIsValid(isValid: boolean) {
        this.setState({ isValid });
    }

    getSnippetIcon() {
        if (this.props.stepType === StepType.Action) {
            return faCogs;
        }
        return faCloudDownloadAlt;
    }

    getDebuggingCssClassName(): string {
        if (this.props.debuggingState?.status === StepDebuggingStatus.InProgress) {
            return 'debugging-in-progress';
        } else if (this.props.debuggingState?.status === StepDebuggingStatus.Success) {
            return 'debugging-success';
        } else if (this.props.debuggingState?.status === StepDebuggingStatus.Error) {
            return 'debugging-error';
        }
        return 'debugging-idle';
    }

    render() {
        let mainClass = this.state.isValid ? 'snippet valid' : 'snippet invalid';
        if (this.props.debuggingState !== null) {
            mainClass += ' ' + this.getDebuggingCssClassName();
        }
        return <div>
            <div className={ mainClass }>
            <p className="software">
                { this.props.software }
                <span>{ this.props.softwareName }</span>
            </p>
            <p className="title">{ this.props.title }</p>
            <div className="inside">{ this.props.children }</div>
            {/*{ this.props.inArtifactDefinition && <Artifact definition={ this.props.inArtifactDefinition }/> }*/}
        </div>
            { this.props.outArtifactDefinition && <Artifact name={ this.props.outArtifactDefinition }/> }
        </div>
    }
}