import React from 'react';
import { UI } from 'src/engrator-core';
import { FormError } from 'src/engrator-core/form-error.type';
import { contactSales } from '../rest-api';

type Props = {
    closeHandler: () => void;
};
type State = {
    isLoading: boolean;
    email: string;
    message: string;
    error?: FormError;
    success: boolean;
};

export class ContactSales extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            email: '',
            message: '',
            success: false
        };
    }

    render() {
        if (this.state.success) {
            return <UI.Modal>
                <UI.Message appearance={'success'}>
                    <p>Your request has been sent to sales team. We will contact you on provided email address.</p>
                </UI.Message>
                <UI.Button 
                    text="Close" 
                    appearance="link-inline"
                    onClick={() => this.props.closeHandler() }
                />
            </UI.Modal>
        }
        return <UI.Modal 
                header={`Commercial license request`}
                isLeftAligned={ true } >
            <UI.Form>
                { this.state.error && <div>
                    <UI.Message appearance={'error'}>
                        <p>Something went wrong: { this.state.error.message }</p>
                    </UI.Message>
                </div> }
                <UI.Input
                    label={`Email`}
                    isRequired={ true }
                    onChange={ (value) => this.setState({ email: value }) }
                />
                <UI.Textarea
                    label={`Message (optional)`}
                    onChange={ (value) => this.setState({ message: value }) }
                    rows={ 4 }
                />
                <UI.ButtonsBar
                    primary={
                        <UI.Button 
                            text="Send" 
                            isLoading={ this.isLoading() }
                            disabled={ this.isLoading() || this.isFormFilled() === false }
                            appearance="elementary"
                            onClick={() => this.onSendClicked() }
                        />
                    }
                    secondary={[
                        <UI.Button 
                            text="Cancel" 
                            appearance="link-inline"
                            onClick={() => this.props.closeHandler() }
                        />
                    ]} 
                />
            </UI.Form>
        </UI.Modal>
    }

    private onSendClicked() {
        this.setState({ isLoading: true }, () => {
            contactSales(this.state.email, this.state.message)
            .then(() => {
                this.setState({ error: undefined, success: true, isLoading: false });
            })
            .catch((error) => {
                this.setState({ error, success: false, isLoading: false });
            });
        })
    }

    private isLoading(): boolean {
        return this.state.isLoading;
    }

    private isFormFilled(): boolean {
        return !!this.state.email && this.state.email.indexOf('@') > 0;
    }
}
