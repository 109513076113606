import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {AsanaStepKey} from "../asana-step-key.type";
import {AsanaCreateCcommentsNewStep} from "./asana-create-comments-new-step";
import {AsanaCreateCommentsEditStep} from "./asana-create-comments-edit-step";
import {AsanaCreateCommentsConfiguration} from "./asana-create-comments-configuration";


export const AsanaCreateCommentsPipelineStep: PipelineStep<AsanaCreateCommentsConfiguration> = {
    addStepComponentClass: AsanaCreateCcommentsNewStep,
    editStepComponentClass: AsanaCreateCommentsEditStep,
    specification: {
        action: "Create Asana Task Comment(s)",
        description: "Create comment(s) for selected task",
        key: AsanaStepKey.AsanaCreateComments,
        software: SoftwareName.Asana,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `AsanaCard`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AsanaCreateCommentsConfiguration;
        return `Create comment(s) for task '${stepConfiguration.targetArtifact}'`;
    }
};