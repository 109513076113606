import { axiosWrapper, EntityStatusEnum } from 'src/engrator-core';

const ENDPOINT_URL = '/system/api/logs';

export function fetchSystemLogs(): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
