import React from 'react';
import { UI } from 'src/engrator-core';
import { ReSyncSettings } from './re-sync-settings.type';
import { getShouldResyncState, updateShouldResyncState } from './rest-api';

type Props = {
    artifactVersionId: number;
}
type State = {
    reSyncSettings: ReSyncSettings | undefined;
    isSaving: boolean;
    hasBeenSaved: boolean;
};

export class ReSync extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            reSyncSettings: undefined,
            isSaving: false,
            hasBeenSaved: false,
        };
    }

    componentDidMount() {
        getShouldResyncState(this.props.artifactVersionId)
            .then((reSyncSettings) => {
                this.setState({reSyncSettings});
            });
    }

    render() {
        if (this.state.reSyncSettings === undefined) {
            return <React.Fragment/>;
        }
        const savedElement = (this.state.hasBeenSaved) ? <span>Saved</span> : '';
        return <UI.Form>
            <UI.FormGroup>
                <UI.Message appearance={"info"}
                    message={`You can force resync of this item. It will be picked up on next integration run and resync all found changes. You can also type a list of fields which you would like to force resync.`}

                />
                <UI.Checkbox
                    defaultValue={`${this.state.reSyncSettings?.shouldResync}`}
                    checkedValue={`true`}
                    uncheckedValue={`true`}
                    onChange={() => this.switchReSyncFlag()}
                    label={`Resync on next integration run`}
                />
                {this.state.reSyncSettings?.shouldResync && <UI.Input
                    label={`Provide fields list to resync (separated with ",")`}
                    onChange={this.changeFields.bind(this)}
                    defaultValue={this.state.reSyncSettings?.resyncFields}
                />}
                <UI.ButtonsBar
                    primary={<UI.Button
                        isLoading={this.state.isSaving}
                        onClick={() => this.saveResyncSettings()}
                        text={`Save`}
                    />
                    }
                    secondary={[savedElement]}
                />
            </UI.FormGroup>
        </UI.Form>;
    }

    private switchReSyncFlag() {
        const reSyncSettings = this.state.reSyncSettings;
        if (reSyncSettings === undefined) {
            return;
        }
        reSyncSettings.shouldResync = !reSyncSettings?.shouldResync;
        this.setState({reSyncSettings, hasBeenSaved: false});
    }

    private changeFields(newValue: string) {
        const reSyncSettings = this.state.reSyncSettings;
        if (reSyncSettings === undefined) {
            return;
        }
        reSyncSettings.resyncFields = newValue;
        this.setState({reSyncSettings, hasBeenSaved: false});
    }

    private saveResyncSettings() {
        this.setState({isSaving: true, hasBeenSaved: false}, () => {
            updateShouldResyncState(this.props.artifactVersionId, this.state.reSyncSettings!)
                .then(() => {
                    this.setState({isSaving: false, hasBeenSaved: true});
                });
        });
    }
}
