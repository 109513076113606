import React from 'react';
import { UI } from 'src/engrator-core';
import { DefaultMapping } from './default-mappping.type';

type Props = {
    defaultMapping: DefaultMapping;
};

type State = {};

export class DefaultMappingDisplay extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
    }

    render() {
        return <div>
            <UI.LabelValue label={`Mode: `} value={this.props.defaultMapping.mode}/>
            {this.props.defaultMapping.value && <UI.LabelValue
                label={`Value: `}
                value={`${this.props.defaultMapping.label} (${this.props.defaultMapping.value})`}
            />}
        </div>;
    }
}
