import React, { Component } from 'react';
import { UI } from 'src/engrator-core';
import { IntegrationChangesModel } from '../../../changes/integration-changes-model';
import JsonViewCompare from 'react-json-view-compare';

type IntegrationChangesModalProps = {
    isOpen: boolean;
    selectedVersion?: IntegrationChangesModel;
    latestVersion?: IntegrationChangesModel;
    isConfigExpanded: boolean;
    singleChange: boolean;
    onClose: () => void;
    onToggleConfig: () => void;
};

export class IntegrationChangesModal extends Component<IntegrationChangesModalProps> {
    render() {
        const {
            isOpen,
            selectedVersion,
            latestVersion,
            isConfigExpanded,
            singleChange,
            onClose,
            onToggleConfig
        } = this.props;
        if (!isOpen || !selectedVersion || !latestVersion) {
            return null;
        }
        return (
            <UI.FullScreenModal
                closeBtnHandler={onClose}
                header='Integration Changes Details'
                showPrimaryBtn={false}
                maximized={true}
            >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <UI.Tile>
                            <h3>Selected Version Details</h3>
                            <div>
                                <UI.LabelValue
                                    className={selectedVersion.version !== latestVersion.version ? 'label-value changed' : 'label-value'}
                                    label='Version: '
                                    value={selectedVersion.version ? selectedVersion.version.toString() : 'N/A'}
                                />
                                <UI.LabelValue
                                    className={new Date(selectedVersion.modifiedAt).toLocaleString() !== new Date(latestVersion.modifiedAt).toLocaleString() ? 'label-value changed' : 'label-value'}
                                    label='Modified At: '
                                    value={new Date(selectedVersion.modifiedAt).toLocaleString()}
                                />
                                <UI.LabelValue
                                    className={selectedVersion.changeAuthorName !== latestVersion.changeAuthorName ? 'label-value changed' : 'label-value'}
                                    label='Author: '
                                    value={selectedVersion.changeAuthorName}
                                />
                            </div>
                        </UI.Tile>
                        {!singleChange && (
                            <UI.Tile>
                                <h3>Current Version Details</h3>
                                <div>
                                    <UI.LabelValue
                                        className='label-value'
                                        label='Version (Current): '
                                        value={latestVersion.version ? latestVersion.version.toString() : 'N/A'}
                                    />
                                    <UI.LabelValue
                                        className='label-value'
                                        label='Modified At: '
                                        value={new Date(latestVersion.modifiedAt).toLocaleString()}
                                    />
                                    <UI.LabelValue
                                        className='label-value'
                                        label='Author: '
                                        value={latestVersion.changeAuthorName}
                                    />
                                </div>
                            </UI.Tile>
                        )}
                    </div>
                    <br />
                    <UI.Button
                        text={`${isConfigExpanded ? 'Hide' : 'Show'} ${singleChange ? 'version details' : 'versions comparison'}`}
                        appearance='elementary'
                        onClick={onToggleConfig}
                    />
                    <br /><br />
                    {isConfigExpanded && (
                        <UI.Tile>
                            {singleChange ? (
                                <JsonViewCompare
                                    oldData={{}}
                                    newData={latestVersion.configurationAndOthers ? JSON.parse(latestVersion.configurationAndOthers) : {}}
                                    style={{ background: '#f0f0f0', padding: '10px' }}
                                />
                            ) : (
                                <JsonViewCompare
                                    oldData={selectedVersion.configurationAndOthers ? JSON.parse(selectedVersion.configurationAndOthers) : {}}
                                    newData={latestVersion.configurationAndOthers ? JSON.parse(latestVersion.configurationAndOthers) : {}}
                                    style={{ background: '#f0f0f0', padding: '10px' }}
                                />
                            )}
                        </UI.Tile>
                    )}
                </div>
            </UI.FullScreenModal>
        );
    }
}
