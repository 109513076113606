import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {AsanaStepKey} from "../asana-step-key.type";
import {AsanaCreateItemConfiguration} from "./asana-create-item-configuration";
import {AsanaCreateItemEditStep} from "./asana-create-item-edit-step";
import {AsanaCreateItemNewStep} from "./asana-create-item-new-step";

export const AsanaCreateItemPipelineStep: PipelineStep<AsanaCreateItemConfiguration> = {
    addStepComponentClass: AsanaCreateItemNewStep,
    editStepComponentClass: AsanaCreateItemEditStep,
    specification: {
        action: "Create Asana Task",
        description: "Create Asana task",
        key: AsanaStepKey.AsanaCreateItem,
        software: SoftwareName.Asana,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `createdAsanaItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AsanaCreateItemConfiguration;
        return `Create task in ${stepConfiguration.workspace.name}, ${stepConfiguration.project.name}`;
    }
}