import { PipelineStep } from 'src/generic';
import {
    GetintArchiverCreateCommentsPipelineStep,
    GetintArchiverCreateItemPipelineStep,
    GetintArchiverFindItemPipelineStep,
    GetintArchiverSearchItemsPipelineStep,
    GetintArchiverStatusTransitionPipelineStep,
    GetintArchiverUpdateItemPipelineStep,
} from './steps';

export const GetintArchiverPipelineSteps: PipelineStep<any>[] = [
    GetintArchiverSearchItemsPipelineStep,
    GetintArchiverCreateItemPipelineStep,
    GetintArchiverCreateCommentsPipelineStep,
    GetintArchiverUpdateItemPipelineStep,
    GetintArchiverFindItemPipelineStep,
    GetintArchiverStatusTransitionPipelineStep,
];
