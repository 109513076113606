import React, {useEffect} from 'react';

import {FieldError, UI} from 'src/engrator-core';
import {useGlobalState} from "../../../../global-store";
import {MainMenuItem, VerticalMenu} from "../../../../global-store/create-global-state";
import {getIntegrationVerticalMenu} from "../integration-vertical-menu";
import {Message, TableGridData} from "../../../../../engrator-core/ui";
import {ReportName} from "../../../../reporting/report-name.type";
import {fetchReportData} from "../../../../reporting/rest-api";
import {ReportData} from "../../../../reporting/report-data.type";
import {createTableGridData} from "../../../../reporting/report-row-factory";
import {InfoWithRefreshBtn} from "./info-with-refresh-btn";
import {FETCHING_INTERVAL_IN_SECONDS, fetchNewEntries} from "./new-entries-fetcher";

type State = {
    isLoading: boolean;
    error?: FieldError;
};
type Props = {
    history: History;
    integrationId: number;
};

class IntegrationSyncsPageCmp extends React.Component<Props, State> {
    private tableGridData?: TableGridData;
    private reportName: ReportName = ReportName.PerformedFlows;
    private currentReportData?: ReportData;
    private automaticFetchInterval?: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.loadPage();
        this.automaticFetchInterval = setInterval(() => {
            fetchNewEntries(
                () => fetchReportData(this.reportName!, 0, {integrationId: this.props.integrationId}),
                this.currentReportData
            ).then((modified?: boolean) => {
                if (modified) {
                    this.tableGridData = createTableGridData(this.reportName!, this.currentReportData!, (item: any) => {
                        (this.props as any).history.push(`/app/reporting/suite-run/${ item.runId }/flow/${ item.id }/details`);
                    });
                    this.forceUpdate();
                }
            });
        }, FETCHING_INTERVAL_IN_SECONDS);
    }

    componentWillUnmount() {
        if (this.automaticFetchInterval) {
            clearInterval(this.automaticFetchInterval);
        }
    }

    render() {
        if (this.state.isLoading) {
            return <UI.Page>
                <UI.Loader appearance={'darkgray'} visible={true} />
            </UI.Page>;
        }
        return (
            <UI.Page
                className="dashboard-page"
                title={`Latest ${ this.tableGridData?.data.length || '' } synced items by integration`}
            >
                <InfoWithRefreshBtn
                    integrationId={ this.props.integrationId }
                    refreshHandler={ () => this.refreshClicked() }
                />
                <br />
                { this.state.error && <Message appearance={"error-message"} message={ this.state.error } /> }
                { this.tableGridData && <UI.TableGrid
                    data={ this.tableGridData?.data }
                    headers={ this.tableGridData?.headers }
                /> }
            </UI.Page>
        );
    }

    private loadPage() {
        this.setState({ isLoading: true }, () => {
            fetchReportData(this.reportName!, 0, {integrationId: this.props.integrationId})
                .then((reportData: ReportData) => {
                    if (reportData) {
                        this.currentReportData = reportData;
                        this.tableGridData = createTableGridData(this.reportName!, reportData, (item: any) => {
                            (this.props as any).history.push(`/app/reporting/suite-run/${ item.runId }/flow/${ item.id }/details`);
                        });
                    }
                    this.setState({isLoading: false, error: undefined});
                })
                .catch(error => {
                    this.setState({isLoading: false, error});
                });
        });
    }

    private refreshClicked() {
        this.loadPage();
    }
}

export function IntegrationSyncsPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    const integrationId = props.match.params.id;
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Workflows);
        const menu: VerticalMenu = getIntegrationVerticalMenu(integrationId);
        setMenu(menu);
    }, []);
    return <IntegrationSyncsPageCmp
        integrationId={ integrationId }
        history={ props.history }
    />
}
