import React from 'react';
import { UI } from 'src/engrator-core';

type Props = {
    search: (filters: ListFilters) => void;
};
type State = {
    filters: ListFilters;
};
export type ListFilters = {
    showDisabled: boolean;
    query: string;
};

export class TenantsListFilters extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            filters: {
                query: '',
                showDisabled: false
            }
        };
    }

    render() {
        return <div className={`tenants-filters`}>
            <div className={`query`}>
                <UI.Input placeholder="Filter query" defaultValue={ this.state.filters.query } onChange={ (event) => this.setQuery(event) } />
            </div>
            <div className={`disabled`}>
                <UI.Checkbox
                    label="Show disabled"
                    checkedValue="true"
                    uncheckedValue="false"
                    onChange={ (event) => this.switchShowDisabled() } 
                />
            </div>
            <div className="btn">
                <UI.Button 
                    appearance="link-inline"
                    text="Clear"
                    onClick={ () => this.clear() }
                />
            </div>
            <div className="btn">
                <UI.Button 
                    text="Search"
                    onClick={ () => this.props.search(this.state.filters) }
                />
            </div>
        </div>;
    }

    private setQuery(event: any) {
        const filters = this.state.filters;
        filters.query = event;
        this.setState({ filters });
    }

    private switchShowDisabled() {
        const filters = this.state.filters;
        filters.showDisabled = !filters.showDisabled;
        this.setState({ filters });
    }

    private clear() {
        this.setState({ filters: {
            query: '',
            showDisabled: false
        }}, () => {
            this.props.search(this.state.filters);
        });
    }
}