export enum SoftwareName {
    Jira = 'Jira',
    ServiceNow = 'ServiceNow',
    Trello = 'Trello',
    IntHub = 'getint.io',
    Asana='Asana',
    Zendesk='Zendesk',
    Azure='Azure',
    Monday='Monday',
    GitHub='GitHub',
    ClickUp='ClickUp',
    GitLab='GitLab',
    Wrike='Wrike',
    Freshdesk='Freshdesk',
    Freshservice='Freshservice',
    GetintArchiver = 'GetintArchiver',
    Salesforce = 'Salesforce',
    Hubspot = 'Hubspot',
    Airtable = 'Airtable',
    Notion = 'Notion',
    EasyVista = 'EasyVista',
    Shotgrid = 'Shotgrid',
}

export function getSoftwareName(softwareName: string): string {
    if (softwareName === SoftwareName.GetintArchiver) {
        return 'GetInt Archiver';
    } else if (softwareName === SoftwareName.Azure) {
        return 'Azure DevOps';
    } else if (softwareName === SoftwareName.Freshdesk) {
        return 'Freshdesk';
    } else if (softwareName === SoftwareName.Freshservice) {
        return 'Freshservice';
    }
    return softwareName;
}