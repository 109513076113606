import React from 'react';

import {EditPipelineStepComponentProps} from "src/generic";
import {UI} from "src/engrator-core";
import {ArtifactDirection} from "src/generic/artifacts";
import {WrikeCreateItemConfiguration} from "./wrike-create-item-configuration";
import {AzureProjectItemTypeDisplay} from "../../../azure/steps/common/azure-project-item-type-display";
import {WrikeStepContextDisplay} from "../common";

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class WrikeCreateItemEditStep extends React.Component<Props, State> {
    private configuration: WrikeCreateItemConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as WrikeCreateItemConfiguration;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
        this.props.showArtifactBuilder(ArtifactDirection.In);
    }

    render() {
        return <React.Fragment>
            <WrikeStepContextDisplay context={ this.configuration.context} />
        </React.Fragment>;
    }
};