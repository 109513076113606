import {axiosWrapper} from 'src/engrator-core';
import {DropdownOption} from "../../engrator-core/ui";

const INTEGRATION_SUITES_ENDPOINT_URL = '/integration/suites';

export const isAdvancedConfigurationEnabled = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(INTEGRATION_SUITES_ENDPOINT_URL + `/advanced-configuration/enabled`)
                .then((isEnabled: any) => {
                    resolve(isEnabled);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function getUsersToShare(): Promise<DropdownOption[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(INTEGRATION_SUITES_ENDPOINT_URL + `/sharing/users`)
                .then((users: any) => {
                    const options: DropdownOption[] = users.map((u: any) => ({
                        label: u.name || u.identifier,
                        value: u.id
                    }));
                    resolve(options);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}