import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {GitHubStepKey} from "../github-step-key.type";
import {GitHubCreateItemConfiguration} from "./github-create-item-configuration";
import {GitHubCreateItemEditStep} from "./github-create-item-edit-step";
import {GitHubCreateItemNewStep} from "./github-create-item-new-step";

export const GitHubCreateItemPipelineStep: PipelineStep<GitHubCreateItemConfiguration> = {
    addStepComponentClass: GitHubCreateItemNewStep,
    editStepComponentClass: GitHubCreateItemEditStep,
    specification: {
        action: "Create issue",
        description: "Create issue in specified repository",
        key: GitHubStepKey.GitHubCreateItem,
        software: SoftwareName.GitHub,
        stepType: StepType.Action
    },
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitHubCreateItemConfiguration;
        return `Create issue in ${stepConfiguration.context.repository?.name}`;
    }
}