import React from 'react';
import {SoftwareName} from "../../software";
import {Connection, ConnectionFactory} from "../../connections";
import {GenericConnectionComponent} from "./generic-connection-component";
import {AuthMethod} from "./auth-method.type";
import {fetchConnectionsForSoftware} from "src/software/generic/connections-rest-api";
import {OAuthSupport} from "./oauth/oauth-support";
import {CommonConnectionComponent, ConnectionConfiguration} from "./common-connection-component";
import {ConnectionModel} from "../../app/integration/connections/connection-model";

export function createGenericConnectionFactory(softwareName: SoftwareName, supportedAuthMethods: AuthMethod[], oAuthSupport?: OAuthSupport, config?: ConnectionConfiguration): ConnectionFactory {
    return {
        fetchConnections(): Promise<Array<Connection>> {
            return fetchConnectionsForSoftware(softwareName);
        }, getConnectionComponent(onConnectionAddedHandler: () => void, connectionToEdit?: ConnectionModel, refreshHandler?: () => void): any {
            const connectionConfig = (config) ? config : {
                requireUrl: true
            };
            return <CommonConnectionComponent
                connectionToEdit={ connectionToEdit }
                config={ connectionConfig }
                softwareName={ softwareName }
                supportedAuthMethods={ supportedAuthMethods }
                onConnectionAddedHandler={ onConnectionAddedHandler }
                refreshHandler={refreshHandler}
            />
            return <GenericConnectionComponent
                supportedAuthMethods={ supportedAuthMethods }
                onConnectionAddedHandler={ onConnectionAddedHandler }
                softwareName={ softwareName }
                oAuthSupport={ oAuthSupport }/>;
        }
    }
}