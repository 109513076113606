import React from 'react';

import {FieldError, UI} from 'src/engrator-core';
import {DashboardReportType} from './dashboard-report.type';
import {getDashboardChartData} from './rest-api';

type ErrorListEntry = {
    flowId: number;
    runId: number;
    error: string;
};

type State = {
    errors: Array<FieldError>,
    isLoading: boolean,
    chartData?: object[],
    keys: string[],
    error?: FieldError
};

type Props = {
    time: number;
    reportType: DashboardReportType;
};

export class ListChart extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            error: undefined,
            errors: [],
            isLoading: true,
            keys: [],
        };
    }

    componentDidMount(): void {
        this.load();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.time !== prevProps.time) {
            this.load();
        }
    }

    private load() {
        this.setState({chartData: undefined}, () => {
            getDashboardChartData(this.props.reportType, 5)
                .then(response => {
                    const chartData = response.data as unknown as ErrorListEntry[];
                    this.setState({chartData, keys: response.keys});
                })
                .catch(error => {
                    this.setState({error});
                });
        });
    }

    render() {
        if (this.state.error) {
            return <UI.Message appearance={`error`}>{(this.state.error as unknown as FieldError).message}</UI.Message>;
        }
        if (!this.state.chartData) {
            return <React.Fragment>
                <UI.Loader visible={true} appearance={'darkgray'}/>
            </React.Fragment>;
        }
        if (this.state.chartData.length === 0) {
            return <p>No data to display.</p>;
        }
        return (
            <div className="list-chart">
                {this.state.chartData.map((data: any, index) => <div key={index} className="entry">
                    <UI.Message appearance="error">{(data.error).substring(0, 120)}... </UI.Message>
                    <UI.NavLink
                        path={`/app/reporting/suite-run/${data.runId}/flow/${data.flowId}/details`}>#{data.flowId}</UI.NavLink>
                </div>)}
            </div>
        );
    }
}
