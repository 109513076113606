import {VerticalMenu} from "src/app/global-store/create-global-state";
import {isJiraServerNativeApp} from "../../engrator-core";

export function getReportingVerticalMenu(): VerticalMenu {
    const menu = {
        header: 'Reporting',
        items: [{
            name: 'Performed syncs',
            path: '/app/reporting?report=PerformedFlows'
        }, {
            name: 'Runs',
            path: '/app/reporting?report=PipelinesRuns'
        }, {
            name: 'Synced items',
            path: '/app/reporting?report=SyncedArtifacts'
        }, {
            name: 'Run errors',
            path: '/app/analysis/errors'
        }, {
            name: 'Sync errors',
            path: '/app/analysis/syncerrors'
        }]
    };
    if (!isJiraServerNativeApp) {
        menu.items.push({
            name: 'Sync jobs',
            path: '/app/analysis/threads'
        });
    }
    return menu;
}