import {axiosWrapper, UI} from "src/engrator-core";
import {SoftwareName} from "src/software";
import {DropdownOption} from "../../../engrator-core/ui";

const ENDPOINT_URL = '/connectors';

function changeToDropdownOption(item: any): DropdownOption {
    return {
        label: item.name,
        value: item.id,
        object: {
            label: item.name,
            value: item.id
        }
    }
}

export const fetchStatuses = (softwareName: SoftwareName, connectionId: number, configurationData: any): Promise<UI.DropdownOption[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}/${softwareName.toLowerCase()}/meta/${connectionId}/statuses`, configurationData)
                .then((data: any) => {
                    resolve(data.map(changeToDropdownOption));
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};