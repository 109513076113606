export type PropertyValue = {
    name: string;
    value: string;
    additionalData: {
        data: object
    };
};

type EmptyPropertyValueAdditionalData = {
    data: {}
};

export function  getEmptyAdditionalData(): EmptyPropertyValueAdditionalData {
    return {
        data: {}
    }
}
