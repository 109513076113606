import React from 'react';
import {NewPipelineStepComponentProps} from "src/generic";
import {EntityFormHelper, FieldError, UI} from 'src/engrator-core';
import {JiraSearchIssuesConfiguration} from "./jira-search-issues-configuration";
import {JiraSearchIssuesJqlValidation} from "./components/jira-search-issues-jql-validation";
import {validateJql} from './rest-api';
import {ArtifactDirection} from "../../../../generic/artifacts";
import {IntegrationStepConfiguration} from "../../../../generic/software/step";
import {JiraProjectIssuetypePicker, JiraProjectIssuetypePickerData} from './../common';
type Props = {
} & NewPipelineStepComponentProps;
type State = {
    jqlValidationResult?: JiraSearchIssuesJqlValidation,
    formError: { [key: string]: string },
    canConfigureArtifact: boolean,
    isLoadingJql: boolean,
    isAdding: boolean
};

export class JiraSearchIssuesNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: JiraSearchIssuesConfiguration;
    private validateJqlTimerId?: NodeJS.Timeout;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as JiraSearchIssuesConfiguration;
        this.configuration.onlyNewChanges = true;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'boardId' | 'listId', newValue) => {
            // this.configuration[propertyName] = newValue;
            // this.checkIfArtifactCanBeCreated();
        });
        this.state = { isLoadingJql: false, formError: {}, canConfigureArtifact: false, isAdding: false };
    }

    onFormChange(elementThatChanged: 'customJQL', newValue: string) {
        this.configuration[elementThatChanged] = newValue;
    }

    private onProjectIssueTypeDataChanged(data: JiraProjectIssuetypePickerData) {
        this.configuration.project = data.project!;
        this.configuration.issueType = data.issueType!;
        this.validateJql(Object.assign({}, this.configuration), true);
    }

    render() {
        return <div className={`connectors-jira-search-issues-step`}>
            <JiraProjectIssuetypePicker
                formError={ this.props.formError }
                connectionId={ this.props.context.connectionId }
                onDataChanged={ this.onProjectIssueTypeDataChanged.bind(this) } />
            <UI.FormGroup>
                <UI.Input label="Customize JQL" onChange={ (newValue) => { this.onFormChange('customJQL', newValue) }   }/>
            </UI.FormGroup>
            <UI.FormGroup
                label={`Changed from last integration`}
                description={`If checked, only issues that changed since last integration will be picked up.`}
            >
                <UI.Checkbox defaultValue={"true"} onChange={ this.onlyNewChanged.bind(this) } checkedValue={ "true" } uncheckedValue={ "false "}/>
            </UI.FormGroup>
            { this.state.isLoadingJql && <UI.Loader visible={true} appearance={`darkgray`} /> }
            { this.state.jqlValidationResult && <UI.FormGroup
                label={`Final JQL`}
            >
                { this.state.jqlValidationResult?.jql && <pre className={`final-jql`}>{ `${this.state.jqlValidationResult?.jql}` }</pre> }
                { this.state.jqlValidationResult?.exceptionMessage && <pre className={`final-jql`}><span className={`error`}>Invalid JQL ${this.state.jqlValidationResult?.exceptionMessage}</span></pre> }
            </UI.FormGroup> }
        </div>
    }


    validateJql(configuration: JiraSearchIssuesConfiguration, projectOrIssueTypeChanged: boolean) {
        if (this.validateJqlTimerId) {
            clearTimeout(this.validateJqlTimerId);
        }
        const formError = this.state.formError;
        const canConfigureArtifact = !!(configuration.issueType && configuration.project);
        if (!canConfigureArtifact) {
            return;
        }
        this.setState({
            formError: {},
            isLoadingJql: true,
            canConfigureArtifact: this.state.canConfigureArtifact && !projectOrIssueTypeChanged,
            jqlValidationResult: undefined
        });
        this.validateJqlTimerId = setTimeout(() => {
            validateJql(this.getStepConfiguration(configuration))
                .then((jqlValidationResult) => {
                    if (!this.state.canConfigureArtifact && canConfigureArtifact) {
                        this.props.showArtifactBuilder(ArtifactDirection.Out);
                    }
                    this.setState({
                        jqlValidationResult,
                        canConfigureArtifact,
                        isLoadingJql: false
                    });
                })
                .catch((error: FieldError) => {
                    formError[error.fieldName] = error.message;
                    this.setState({ formError, isLoadingJql: false, jqlValidationResult: {exceptionMessage: error.message} });
                });
        }, 1000);
    }

    onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
        this.validateJql(Object.assign({}, this.configuration), false);
    }

    private getStepConfiguration(configuration: JiraSearchIssuesConfiguration): IntegrationStepConfiguration {
        return {
            data: configuration,
            connectionId: this.props.context.connectionId,
            stepSpecification: this.props.context.stepSpecification,
            inDataArtifact: null,
            outDataArtifact: null,
            rules: []
        };
    }
};
