import {isJiraServerNativeApp, UI} from "../../../../../engrator-core";
import React, {useEffect, useState} from "react";
import {Icon20} from "../../../../../engrator-core/ui";
import {SmartIntIntegration} from "../../../../../generic";
import {fetchIntegrationDetails} from "../../../integration-suites/details/rest-api";

export function InfoWithRefreshBtn(props: { integrationId: number, refreshHandler: () => void }) {
    const [integration,setIntegration] = useState<SmartIntIntegration>();
    useEffect(() => {
        fetchIntegrationDetails(props.integrationId)
            .then(detailsModel => {
                const integration = new SmartIntIntegration(
                    detailsModel.id,
                    detailsModel.name,
                    detailsModel.configuration,
                    detailsModel.settings,
                    detailsModel.migrationConfiguration,
                    detailsModel.accessLevel
                );
                setIntegration(integration);
            });
    }, []);
    if (!integration) {
        return <React.Fragment/>
    }
    return <div className={`info-with-refresh-btn`}>
        { integration?.settings.status === 'Disabled' && <UI.Message appearance={'warning'}>
            This integration is disabled. It won't run until enabled or set as migration. Activate it in the settings.
        </UI.Message> }
        { integration?.settings.status === 'Enabled' && <div className={`flex`}>
            <UI.Message appearance={'info'}>
                { !isJiraServerNativeApp && <span>This integration runs every {integration?.settings.intervalInSeconds} seconds. </span> }
                { isJiraServerNativeApp && <span>This integration runs every ~120 seconds. </span> }
                To modify the interval, adjust it in the settings. <UI.DocumentationLink text={`Read how integrations are executed`} url={`https://docs.getint.io/getintio-platform/how-integrations-are-executed-by-getint`} />
            </UI.Message>
            <UI.Button
                appearance={"secondary"}
                onClick={ () => props.refreshHandler() }
                icon={ <UI.Icon icon20={Icon20.Refresh} /> }
                text={`Refresh`}
            />
        </div> }
    </div>;
}