import React from 'react';
import { DocumentationLink } from './documentation-link';

type Props = {
    url: string
}

type State = {}

export class ReadMore extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <div className={`ui-read-more`}>
            <DocumentationLink text={`Read more here`} url={this.props.url}/>
        </div>
    }
}
