import React from 'react';
import { EntityFormHelper } from 'src/engrator-core';
import { NewPipelineStepComponentProps } from 'src/generic';
import { ArtifactDirection } from 'src/generic/artifacts';
import { GetintArchiverStepContext, GetintArchiverStepContextData, GetintArchiverStepContextDisplay } from '../common';

import { GetintArchiverCreateItemConfiguration } from './monday-create-item-configuration';

type Props = {} & NewPipelineStepComponentProps;
type State = {};

export class GetintArchiverCreateItemNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: GetintArchiverCreateItemConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as GetintArchiverCreateItemConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: '', newValue) => {
            // this.onConfigurationChanged();
        });
    }

    render() {
        return <React.Fragment>
            {!this.configuration.context && <GetintArchiverStepContext
				connectionId={this.props.context.connectionId}
				onDataChanged={this.stepContextDataChanged.bind(this)}
			/>}
            {this.configuration.context && <GetintArchiverStepContextDisplay context={this.configuration.context}/>}
        </React.Fragment>;
    }

    private stepContextDataChanged(contextData: GetintArchiverStepContextData, isValid: boolean) {
        if (isValid) {
            this.configuration.context = contextData;
            this.props.showArtifactBuilder(ArtifactDirection.In);
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }
};
