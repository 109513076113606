import React from 'react';
import { EntityFormHelper, UI } from 'src/engrator-core';

type State = {};
type Props = {
    additionalDescription?: string;
    isRequired: boolean;
    onConditionChanged: (newConditionValue: string) => void;
    title?: string;
};

export class Condition extends React.Component<Props, State> {
    private condition?: string;
    private entityFormHelper: EntityFormHelper;

    constructor(props: Props) {
        super(props);
        this.entityFormHelper = new EntityFormHelper((propertyName, newValue) => {
            this.condition = newValue;
            this.props.onConditionChanged(this.condition!);
        });
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                label={ (this.props.title !== undefined) ? this.props.title : `Condition` }
                description={
                    <div>{this.props.additionalDescription} Provide logical condition that will be evaluated to TRUE or
                        FALSE. You can use variables like artifacts properties e.g.
                        "$&lbrace;jiraBug.summary&rbrace;".</div>
                }
                isRequired={ this.props.isRequired }
            >
                <UI.Input onChange={ this.entityFormHelper.onChangeHandler('condition') }  />
            </UI.FormGroup>
        </React.Fragment>
    }
}
