import React from 'react';
import {UI} from "../../../../../engrator-core";
import {getDefaultDebugConfig, SmartIntPlugin} from "./plugins";
import {CodeEditor} from "../advanced/code-editor";
import {Icon20} from "../../../../../engrator-core/ui";
import {AppsSupport} from "../../visual/visual-integration-designer";
import {debugPlugin} from "./rest-api";
import {SmartIntTrigger} from "../../definition/smart-int-trigger.type";

type Props = {
    plugin: SmartIntPlugin;
    configureHandler: () => void;
    closeHandler: () => void;
    appSupport: AppsSupport;
    triggers: { left: SmartIntTrigger, right: SmartIntTrigger };
};
type State = {
    defaultDebug: object;
    loadingDebug: boolean;
    debugResult?: string;
};

export class PluginConfig extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            defaultDebug: getDefaultDebugConfig(this.props.appSupport),
            loadingDebug: false
        };
    }

    render() {
        return <UI.FullScreenModal
            maximized={true}
            header={`Configure plugin`}
            primaryBtnHandler={() => Promise.resolve(true)}
            closeBtnHandler={() => this.props.closeHandler()}
            depth={2}
        >
            <UI.Form>
                <div className={`configure-plugin`}>
                    <div>
                        {this.props.plugin.settings?.code && <UI.FormGroup
                            label={`Custom code`}>
                            <CodeEditor
                                onChange={(value) => this.setData('code', value)}
                                defaultValue={this.props.plugin.data?.code || ''}
                            />
                        </UI.FormGroup>}
                        {this.props.plugin.settings?.custom.map((settingsName, index) => <UI.FormGroup
                            label={`${settingsName}`}
                        >
                            <UI.Input
                                defaultValue={this.props.plugin.data[settingsName] || ''}
                                placeholder={settingsName}
                                onChange={(value) => this.props.plugin.data[settingsName] = value}
                            />
                        </UI.FormGroup>)}
                    </div>
                    <div>
                        <UI.FormGroup
                            label={`Debug plugin`}
                        >
                            <CodeEditor
                                language={'json'}
                                onChange={(value) => this.setDefaultDebug(value)}
                                defaultValue={JSON.stringify(this.state.defaultDebug, null, 2)}
                            />
                            <br/>
                            <UI.Button
                                icon={<UI.Icon icon20={Icon20.Play}/>}
                                onClick={() => this.debug()}
                                text={`Debug`}
                                isLoading={this.state.loadingDebug}
                            />
                        </UI.FormGroup>
                        { this.state.debugResult && <UI.FormGroup
                            label={`Result`}
                        >
                            <pre className={`debug-result`}>{ this.state.debugResult }</pre>
                        </UI.FormGroup> }
                    </div>
                </div>
            </UI.Form>
        </UI.FullScreenModal>;
    }

    private setDefaultDebug(value: string) {
        try {
            this.setState({defaultDebug: JSON.parse(value)})
        } catch (err) {
        }
    }

    private async debug() {
        await this.setState({loadingDebug: true});
        try {
            const pluginCode = this.props.plugin.data['code'];
            const debugContext = this.state.defaultDebug;
            const result = await debugPlugin(this.props.plugin, JSON.stringify(debugContext), this.props.triggers);
            await this.setState({loadingDebug: false, debugResult: JSON.stringify(result, undefined, 2) });
        } catch (e) {
            await this.setState({loadingDebug: false, debugResult: JSON.stringify(e) });
        }
    }

    private setData(configName: string, value: string) {
        if (!this.props.plugin.data) {
            this.props.plugin.data = {};
        }
        this.props.plugin.data[configName] = value;
    }

    private configurePluginClicked() {
    }
}
