import React, {useEffect} from "react";
import {useGlobalState} from "../../global-store";
import {MainMenuItem} from "../../global-store/create-global-state";
import {getCloudSettingsMenu} from "./cloud-settings-menu";
import {DataStoragePage} from "../../system";

export function CloudSettingsDataStoragePage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Settings);
        setMenu(getCloudSettingsMenu());
    }, []);
    return <DataStoragePage />
}

