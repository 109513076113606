import {
    SmartIntAdvancedConfiguration,
    SmartIntAttachmentsConfiguration,
    SmartIntCommentsConfiguration,
    SmartIntHierarchyConfiguration,
    SmartIntPropertiesMappingsConfiguration, SmartIntRelationshipConfiguration,
    SmartIntStatusTransitionConfiguration
} from './smart-int-definition-configuration.type';
import { SmartIntDirection } from './smart-int-direction.type';
import { SmartIntType } from './smart-int-type.type';

export type SmartIntDefinitionTypeMapping = {
    left: SmartIntType;
    right: SmartIntType;
    direction: SmartIntDirection;
    propertiesConfiguration: SmartIntPropertiesMappingsConfiguration;
    commentsConfiguration: SmartIntCommentsConfiguration;
    attachmentsConfiguration: SmartIntAttachmentsConfiguration;
    advancedConfiguration: SmartIntAdvancedConfiguration;
    hierarchyConfiguration: SmartIntHierarchyConfiguration;
    statusTransitionConfiguration: SmartIntStatusTransitionConfiguration;
    status?: 'Enabled' | 'Disabled'
};

export function createTypeMappingConfiguration(typeMapping: SmartIntDefinitionTypeMapping): SmartIntDefinitionTypeMapping {
    const cloned = JSON.parse(JSON.stringify(typeMapping)) as SmartIntDefinitionTypeMapping;
    return cloned;
}
