import { axiosWrapper } from 'src/engrator-core';

import {SystemApiKey} from "./system-api-key.type";
import {NewSystemApiKey} from "./new-system-api-key.type";
import {UpdatedSystemApiKey} from "./updated-system-api-key.type";

const ENDPOINT_URL = '/system/api/keys';

export const fetchSystemApiKeys = (): Promise<SystemApiKey[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const createSystemApiKey = (newSystemApiKey: NewSystemApiKey): Promise<SystemApiKey> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}`, newSystemApiKey)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const updateSystemApiKey = (apiKeyId: number, updatedSystemApiKey: UpdatedSystemApiKey): Promise<SystemApiKey> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.put(`${ENDPOINT_URL}/${apiKeyId}`, updatedSystemApiKey)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};