import React, {Component} from "react";

import {UI} from 'src/engrator-core';
import { fetchIntegrationThreadsStates } from './rest-api';
import {AnalysisNavBar} from "./analysis-nav-bar";

type State = {
};

export class AnalysisPage extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(): void {
        fetchIntegrationThreadsStates()
            .then(data => console.log(data));
    }

    render() {
        return (
            <UI.Page>
                <AnalysisNavBar selectedItem={"Integration Threads"}/>
            </UI.Page>
        )
    }
}