import React, { Component } from 'react';
import { UI } from 'src/engrator-core';
import { getUpgradeProgress, StepName, UpgradeProgress } from './rest-api';

type State = {
    progress?: UpgradeProgress;
};

export class UpgradeVersionProgress extends Component<{}, State> {
    private interval: any;

    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            getUpgradeProgress()
                .then((progress) => {
                    this.setState({progress});
                });
        }, 3000 * 1);
    }

    componentWillUnmount() {
        // if (this.interval) {
        //     clearInterval(this.interval);
        // }
    }

    render() {
        if (!this.state.progress) {
            return <React.Fragment/>;
        }
        return (
            <div className={`upgrade-version-progress`}>
                <UI.FormGroup>
                    <UI.Message appearance={"warning"}>Upgrade is in progress</UI.Message>
                    <ul>
                        <li className={this.getClassName(StepName.Downloading)}>
                            { this.state.progress.currentStep === StepName.Downloading && <UI.Loader visible={ true } appearance={"darkgray"} /> }
                            <UI.Icon icon={`check`}/> Downloading { this.state.progress.packageUrl }
                        </li>
                        <li className={this.getClassName(StepName.StoppingThreads)}>
                            { this.state.progress.currentStep === StepName.StoppingThreads && <UI.Loader visible={ true } appearance={"darkgray"} /> }
                            <UI.Icon icon={`check`}/> Stopping integration
                            threads
                        </li>
                        <li className={this.getClassName(StepName.PreparingToRebooting)}>
                            { this.state.progress.currentStep === StepName.PreparingToRebooting && <UI.Loader visible={ true } appearance={"darkgray"} /> }
                            <UI.Icon icon={`check`}/> Unpacking and preparing to reboot
                        </li>
                        <li className={this.getClassName(StepName.Rebooting)}>
                            { this.state.progress.currentStep === StepName.Rebooting && <UI.Loader visible={ true } appearance={"darkgray"} /> }
                            <UI.Icon icon={`check`}/> Rebooting
                        </li>
                    </ul>
                </UI.FormGroup>
            </div>
        );
    }

    private getClassName(stepName: StepName): string {
        if (this.state.progress?.currentStep === stepName) {
            return 'current';
        }
        if (this.state.progress && this.state.progress.doneSteps.indexOf(stepName) >= 0) {
            return 'done';
        }
        return '';
    }
}
