import React, {Component, useEffect} from "react";

import { UI } from 'src/engrator-core';
import {downloadRunLogs, fetchRunLogs} from './rest-api';
import {LogsViewer} from "../../../engrator-core/ui";
import {useGlobalState} from "../../global-store";
import {MainMenuItem, VerticalMenu} from "../../global-store/create-global-state";
import {getRunVerticalMenu} from "./run-vertical-menu";

type RunResultsLogsState = {
    runId: number;
    logs?: string;
    isLoading: boolean;
    error?: any;
};

type Props = {
    runId: number;
};

class RunResultsLogsPageCmp extends Component<Props, RunResultsLogsState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            runId: this.props.runId,
            isLoading: true,
            logs: undefined
        };
    }

    async componentDidMount() {
        try {
            const logs = await fetchRunLogs(this.props.runId);
            await this.setState({ isLoading: false, logs: logs});
        } catch (error) {
            await this.setState({ isLoading: false, error });
        }
    }

    render() {
        return (
            <UI.Page
                className={`run-details`}
            >
                { this.state.error && <UI.Message appearance={'error-message'} message={ this.state.error} /> }
                { this.state.logs && <React.Fragment>
                    {/*<div className="run-results-logs">*/}
                        <LogsViewer
                            logs={ this.state.logs! }
                            downloadLogsHandler={ () => this.downloadLogsClicked() }
                        />
                    {/*</div>*/}
                </React.Fragment> }
            </UI.Page>
        )
    }

    private async downloadLogsClicked() {
        await downloadRunLogs(this.state.runId);
    }
}

export function RunResultsLogsPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        const id = props.match.params.id;
        setMainMenuItem(MainMenuItem.Reporting);
        getRunVerticalMenu(props.match.params.id).then(menu => {
            setMenu(menu);
        });
    }, []);
    return <RunResultsLogsPageCmp
        runId={ props.match.params.id }
    />
}