import React from 'react';
import {NewPipelineStepComponentProps} from "src/generic";
import {GitLabFindItemConfiguration} from "./gitlab-find-item-configuration";
import {EntityFormHelper, UI} from 'src/engrator-core';
import {ArtifactDirection} from "../../../../generic/artifacts";
import {GitLabStepContext, GitLabStepContextData} from "../common";

type Props = {
} & NewPipelineStepComponentProps;
type State = {
    isContextSetUp: boolean;
};

export class GitLabFindItemNewStep extends React.Component<Props, State> {
    private configuration: GitLabFindItemConfiguration;
    private entityFormHelper: EntityFormHelper;

    constructor(props: Props) {
        super(props);
        this.state = { isContextSetUp: false };
        this.configuration = this.props.configuration as GitLabFindItemConfiguration;
        this.entityFormHelper = this.getEntityFormHelper();
    }

    render() {
        return <React.Fragment>
            { this.props.formError?.general && <UI.Message
                appearance={`error`}
                >{ this.props.formError.general}</UI.Message>
            }
            { !this.state.isContextSetUp && <GitLabStepContext
                connectionId={ this.props.context.connectionId }
                onDataChanged={ this.stepContextDataChanged.bind(this) }
            /> }
            { this.state.isContextSetUp && <React.Fragment>
                <UI.LabelValue label={ `Table`} value={ this.configuration.context.project!.name } />
                <UI.FormGroup
                    label={`Item Key`}
                    isRequired={ true }
                    description={`Provide key of the item that should be found. You can use variables e.g. properties from other Artifacts.`}
                    error={ this.props.formError?.id }>
                    <UI.Input onChange={ this.entityFormHelper.onChangeHandler('id') } />
                </UI.FormGroup> 
            </React.Fragment> }
        </React.Fragment>;
    }

    private stepContextDataChanged(contextData: GitLabStepContextData, isValid: boolean) {
        if (isValid) {
            this.configuration.context = contextData;
            this.props.showArtifactBuilder(ArtifactDirection.In);
            this.props.showArtifactBuilder(ArtifactDirection.Out);
            this.setState({ isContextSetUp: true });
        }
    }

    private getEntityFormHelper() {
        return new EntityFormHelper((propertyName: 'id', newValue) => {
            this.configuration[propertyName] = newValue;
            this.configurationChanged();
        });
    }

    private configurationChanged() {
        if (this.configuration.context && this.configuration.id) {
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }
}