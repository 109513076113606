import {PipelineStep} from "src/generic";
import {
    AzureCreateCommentsPipelineStep,
    AzureCreateItemPipelineStep,
    AzureSearchItemsPipelineStep,
    AzureUpdateItemPipelineStep,
    AzureFindItemPipelineStep,
    AzureStatusTransitionPipelineStep
} from "./steps";

export const AzurePipelineSteps: PipelineStep<any>[] = [
    AzureSearchItemsPipelineStep,
    AzureCreateItemPipelineStep,
    AzureCreateCommentsPipelineStep,
    AzureUpdateItemPipelineStep,
    AzureFindItemPipelineStep,
    AzureStatusTransitionPipelineStep
];