import React from "react";
import {FieldError, UI} from "src/engrator-core";
import {AppsSupport} from "../visual-integration-designer";
import {fetchLinksToMap, UILinkDTO} from "./rest-api";
import {DropdownOption, Icon20} from "../../../../../engrator-core/ui";
import {
    getDefaultHierarchy, RelationshipLinkMapping,
    SmartIntRelationshipConfiguration
} from "../../definition/smart-int-definition-configuration.type";

type State = {
    isLoading: boolean;
    loadingError?: any;
    error?: FieldError;
    leftAppLinks?: DropdownOption[];
    rightAppLinks?: DropdownOption[];
    selectedLink: { left?: UILinkDTO, right?: UILinkDTO };
};

type Props = {
    closeHandler: () => void;
    applyHandler: (newConfiguration: SmartIntRelationshipConfiguration) => void;
    appSupport: AppsSupport;
    connectionIds: { left: number; right: number };
    configuration?: SmartIntRelationshipConfiguration;
}

export class RelationshipConfigurationWindow extends React.Component<Props, State> {
    private modifiedConfiguration: SmartIntRelationshipConfiguration;
    private allLinks: {
        left: DropdownOption[];
        right: DropdownOption[];
    } = {
        left: [], right: []
    };

    constructor(props: Props) {
        super(props);
        this.state = {isLoading: true, selectedLink: {}};
        this.modifiedConfiguration = (this.props.configuration)
            ? JSON.parse(JSON.stringify(this.props.configuration))
            : {links: [], hierarchy: getDefaultHierarchy()};
        if (!this.modifiedConfiguration.hierarchy) {
            this.modifiedConfiguration.hierarchy = getDefaultHierarchy();
        }
    }

    async componentDidMount() {
        try {
            this.allLinks.left = await fetchLinksToMap(this.props.connectionIds.left, this.props.appSupport.leftApp);
            this.allLinks.right = await fetchLinksToMap(this.props.connectionIds.right, this.props.appSupport.rightApp);
            this.setState({
                leftAppLinks: this.getAvailableLinks(this.allLinks.left, 'left'),
                rightAppLinks: this.getAvailableLinks(this.allLinks.right, 'right'),
                isLoading: false
            });
        } catch (error) {
            this.setState({error, isLoading: false})
        }
    }

    render() {
        return <UI.FullScreenModal
            header={`Dependencies configuration`}
            primaryBtnHandler={() => this.applyClicked()}
            showPrimaryBtn={true}
            primaryBtnText={`Apply`}
            closeBtnHandler={() => this.closeClicked()}
        >
            <div className={`relationships-configuration`}>
                <UI.Message appearance={"info"}>Our 'Dependencies' feature is in Alpha, and we're eager to improve it. Share your feedback with us!</UI.Message>
                {this.state.error && <UI.Message appearance={"error-message"}>{this.state.error?.message}</UI.Message>}
                <div>
                    <h2>Links</h2>
                    {this.modifiedConfiguration.links.map((link, index) => <div className={`display-entry-row`}>
                        <div>
                            {/*<UI.Icon icon20={ Icon20.Reverse } onClick={ () => this.inverseLink('left', link) } />*/}
                            {link.left.name}
                        </div>
                        <div>
                            {/*<UI.Icon icon20={ Icon20.Reverse } onClick={ () => this.inverseLink('right', link) } />*/}
                            {link.right.name}
                        </div>
                        <div>
                            <UI.Icon
                                icon={"trash"}
                                onClick={() => this.deleteClicked(index)}
                            />
                        </div>
                    </div>)}
                    {this.state.isLoading && <UI.Loader visible={true} appearance={'darkgray'}/>}
                    {this.state.leftAppLinks && this.state.rightAppLinks && <div className={`add-entry-row`}>
                        <UI.Form>
                            <div className={`controls`}>
                                <UI.Dropdown
                                    label={`${this.props.appSupport.leftApp} (left) link`}
                                    options={this.state.leftAppLinks}
                                    isRequired={true}
                                    onChange={(option: DropdownOption, link: UILinkDTO) => this.setLink('left', link)}
                                />
                                <UI.Dropdown
                                    label={`${this.props.appSupport.rightApp} (right) link`}
                                    options={this.state.rightAppLinks}
                                    isRequired={true}
                                    onChange={(option: DropdownOption, link: UILinkDTO) => this.setLink('right', link)}
                                />
                                <UI.Button
                                    text={`Add`}
                                    disabled={!this.state.selectedLink.left || !this.state.selectedLink.right}
                                    onClick={() => this.mapLinksClicked()}
                                />
                            </div>
                        </UI.Form>
                    </div>}
                    {/*{ this.state.leftAppLinks && this.state.rightAppLinks && <HierarchyConfiguration*/}
                    {/*    jiraLinks={ this.allLinks.left }*/}
                    {/*    hierarchy={ this.modifiedConfiguration.hierarchy }*/}
                    {/*/> }*/}
                </div>
            </div>
        </UI.FullScreenModal>
    }

    private getAvailableLinks(availableLinks: DropdownOption[], side: 'left' | 'right'): DropdownOption[] {
        const linksIdsSelected = this.modifiedConfiguration.links.map(l => l[side].linksIds[side]);
        return availableLinks.filter(l => linksIdsSelected.indexOf((l.object as any as UILinkDTO).linksIds[side]) === -1);
    }

    private deleteClicked(index: number) {
        this.modifiedConfiguration.links.splice(index, 1);
        this.setState({
            leftAppLinks: this.getAvailableLinks(this.allLinks.left, 'left'),
            rightAppLinks: this.getAvailableLinks(this.allLinks.right, 'right'),
            isLoading: false
        });
    }

    private async applyClicked(): Promise<boolean> {
        this.props.applyHandler(this.modifiedConfiguration);
        this.props.closeHandler();
        return true;
    }

    private async closeClicked(): Promise<boolean> {
        this.props.closeHandler();
        return true;
    }

    private setLink(side: 'left' | 'right', link: UILinkDTO) {
        const selectedLink = this.state.selectedLink;
        selectedLink[side] = link as UILinkDTO;
        this.setState({selectedLink});
    }

    private inverseLink(side: 'left' | 'right', link: RelationshipLinkMapping): void {
        const split = link[side].name.split('/');
        if (split[1]) {
            // If its possible to inverse link (in and out link have different names)
            const right = link.left.linksIds.right;
            link[side].name = split[1] + ' / ' + split[0];
            link[side].linksIds = {
                left: right,
                right: link.left.linksIds.left
            };
            this.forceUpdate();
        }
    }

    private mapLinksClicked() {
        this.modifiedConfiguration.links.push({
            left: this.state.selectedLink.left!,
            right: this.state.selectedLink.right!
        });
        this.setState({
            selectedLink: {},
            leftAppLinks: this.getAvailableLinks(this.allLinks.left, 'left'),
            rightAppLinks: this.getAvailableLinks(this.allLinks.right, 'right'),
            isLoading: false
        });
    }
}