import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { axiosWrapper, FieldError } from '../../../engrator-core';
import { DropdownOption } from '../../../engrator-core/ui';
import { AsanaSmartIntsTriggerConfiguration } from './asana-smart-ints-trigger-configuration';

export const ENDPOINT_URL = '/connectors/asana';

export const fetchAsanaSubtasksRelationshipOptions = (smartIntsTrigger: SmartIntTrigger): Promise<DropdownOption[]> => {
    return new Promise((resolve, reject) => {
        const triggerConfig = smartIntsTrigger.configuration as AsanaSmartIntsTriggerConfiguration;
        try {
            const url = ENDPOINT_URL + '/meta/' + smartIntsTrigger.connectionId + '/subtasks-relationships/options/' + triggerConfig.project.id;
            axiosWrapper.get(url)
                .then((data: any) => {
                    const options = data.map((option: any) => ({
                        value: option.id,
                        label: option.name,
                    }));
                    resolve(options);
                })
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
