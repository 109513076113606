import {Software} from 'src/generic';
import {AppSmartIntsSupport} from "src/app/integration/smartints/app-smart-ints-support";
import {createGenericConnectionFactory} from "src/generic/connections";
import {SoftwareName} from "../software-name";
import {AuthMethod} from "src/generic/connections/auth-method.type";
import {PipelineGenericStep} from "src/generic/pipeline-step";

import {GitHubPipelineSteps} from "./github-pipeline-steps";
import { GitHubSmartintsSupport } from './smart-ints';


export class GitHubSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.GitHub, [AuthMethod.BearerToken])
    }
    getName(): string {
        return 'GitHub';
    }
    getID() {
        return 'GitHub';
    }
    getAvailableSteps(): object[] {
        return GitHubPipelineSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }
    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new GitHubSmartintsSupport();
    }
}
