import React from 'react';
import {UI} from 'src/engrator-core';
import {AttachmentsStorageConfiguration} from 'src/software/generic';

import {SmartIntDefinitionTypeMapping} from '../../definition/smart-int-definition--type-mapping.type';
import {
    createDefaultAttachemntsConfiguration,
    createEmptyPrivatePublicConfiguration,
    SmartIntAttachmentsConfiguration,
    SmartIntCommentsConfiguration,
} from '../../definition/smart-int-definition-configuration.type';
import {AppsSupport} from '../visual-integration-designer';
import {SmartIntDirection} from "../../definition/smart-int-direction.type";
import {DropdownOption} from "../../../../../engrator-core/ui";
import {CommentsPublicPrivateConfiguration} from "./comments/comments-public-private-configuration";
import {SoftwareName} from "../../../../../software";
import {
    AttachmentsPublicAttachmentsConfiguration
} from "../../../../../software/generic/attachments/attachments-public-attachments-configuration";
import {
    AttachmentsJiraInsertAsPublicComment
} from "../../../../../software/generic/attachments/attachments-jira-insert-attachments-as-public-comment";
import {isAttachmentsSyncSupported} from "./enabled-features";
import {CommentsFiltering} from "./comments/comments-filtering";

type Props = {
    mapping: SmartIntDefinitionTypeMapping;
    appsSupport: AppsSupport;
};

type State = {
    showCommentsLimitationInfo: boolean;
}

export class CommentsAttachments extends React.Component<Props, State> {
    private readonly directionOptions: DropdownOption[];
    private readonly showPublicPrivateConfiguration: boolean;

    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            showCommentsLimitationInfo: false
        }
        if (this.props.mapping.commentsConfiguration.asPrivate === undefined) {
            this.props.mapping.commentsConfiguration.asPrivate = {
                left: false, right: false
            };
        }
        if (!this.props.mapping.commentsConfiguration.direction) {
            this.props.mapping.commentsConfiguration.direction = SmartIntDirection.Both;
        }
        if (undefined === this.props.mapping.commentsConfiguration.insertItemId) {
            this.props.mapping.commentsConfiguration.insertItemId = true;
        }
        if (this.props.mapping.attachmentsConfiguration.left === undefined) {
            this.props.mapping.attachmentsConfiguration.left = createDefaultAttachemntsConfiguration();
        }
        if (this.props.mapping.attachmentsConfiguration.right === undefined) {
            this.props.mapping.attachmentsConfiguration.right = createDefaultAttachemntsConfiguration();
        }
        if (!this.props.mapping.commentsConfiguration.privatePublic) {
            this.props.mapping.commentsConfiguration.privatePublic = createEmptyPrivatePublicConfiguration();
        }
        if (!this.props.mapping.commentsConfiguration.filters) {
            this.props.mapping.commentsConfiguration.filters = {
                left: {
                    conditions: [],
                    mode: 'Pass'
                },
                right: {
                    conditions: [],
                    mode: 'Pass'
                }
            };
        }
        if (!this.props.mapping.attachmentsConfiguration.direction) {
            this.props.mapping.attachmentsConfiguration.direction = SmartIntDirection.Both;
        }
        this.directionOptions = [
            { label: 'Both ways', value: SmartIntDirection.Both},
            { label: `Only to ${ this.props.appsSupport.leftApp } (Left)`, value: SmartIntDirection.ToLeft},
            { label: `Only to ${ this.props.appsSupport.rightApp } (Right)`, value: SmartIntDirection.ToRight}
        ];
        const appsThatCanConfiurePrivatePublic = [SoftwareName.Jira, SoftwareName.ServiceNow, SoftwareName.Zendesk,  SoftwareName.Freshdesk];
        this.showPublicPrivateConfiguration =
            appsThatCanConfiurePrivatePublic.indexOf(this.props.appsSupport.leftApp) !== -1
            && appsThatCanConfiurePrivatePublic.indexOf(this.props.appsSupport.rightApp) !== -1;
    }

    private handleCommentsLimitationInfo(value: boolean) {
        this.setState({ showCommentsLimitationInfo: value});
    }

    render() {
        const isGitLab = (this.props.appsSupport.rightApp === 'GitLab' || this.props.appsSupport.leftApp === 'GitLab');
        const isMonday = (this.props.appsSupport.rightApp === 'Monday' || this.props.appsSupport.leftApp === 'Monday');
        const showInlineImagesSync = (this.props.appsSupport.rightApp === 'Jira' && this.props.appsSupport.leftApp === 'Azure')
            || (this.props.appsSupport.rightApp === 'Azure' && this.props.appsSupport.leftApp === 'Jira')
            || (this.props.appsSupport.rightApp === 'Azure' && this.props.appsSupport.leftApp === 'Azure');
        const showLogicalCondition = !!this.props.mapping.commentsConfiguration.conditions?.left || !!this.props.mapping.commentsConfiguration.conditions?.right;
        const isRightFilteringEnabled = (this.props.mapping.commentsConfiguration.direction === SmartIntDirection.ToRight || this.props.mapping.commentsConfiguration.direction === SmartIntDirection.Both);
        const isLeftFilteringEnabled = (this.props.mapping.commentsConfiguration.direction === SmartIntDirection.ToLeft || this.props.mapping.commentsConfiguration.direction === SmartIntDirection.Both);
        const gitLabWarningMsg = "Gitlab do not offer API for attachments downloading/uploading and we support only downloading with workaround by providing cookie header";
        const mondayWarningMsg = "Monday do not offer API for inline images synchronization";
        return <React.Fragment>
            {this.state.showCommentsLimitationInfo && <UI.FullScreenModal
            depth={ 2 }
            maximized={ true }
            closeBtnHandler={ () => this.handleCommentsLimitationInfo(false) }
            header={`Comments Limitation`}
            showPrimaryBtn={false}
        >
                <div className={`comments-limitation-info`}>
                    <p>When syncing comments between tools like Jira, Freshdesk, and Asana, you may experience formatting issues due to platform differences. Common issues include:</p>
                    <ul>
                        <li><span>Complex Formatting:</span> Font colors combined with bold, italic, or underline may not sync correctly.</li>
                        <li><span>Numbered Lists:</span> Lists with additional formatting elements may appear incorrectly.</li>
                        <li><span>Attachments and Inline Images:</span> These may not sync perfectly, resulting in missing or broken </li>
                        <li><span>Hierarchy Levels:</span> Only up to one level of hierarchy is supported in some tools.</li>
                    </ul>
                    <p>To minimize issues, use simpler formatting and plain text where possible. We continuously work with platform communities and support teams to address these limitations, but some issues are beyond our control. For assistance, contact us at <UI.DocumentationLink text={`https://getint.io/help-center`} url={`https://getint.io/help-center`}/></p>
                </div>
            </UI.FullScreenModal>}
            <div>
                <UI.Message appearance={"warning"} margin={'20px 0px 0px 0px'}>
                    When syncing comments between tools like Jira, Freshdesk, and Asana, you may experience formatting issues due to platform differences <UI.DocumentationLink text={`Read More`} url={``} onClickHandler={() => this.handleCommentsLimitationInfo(true)} />.
                </UI.Message>
                <UI.FormSection
                    label={`Synchronize comments`}
                >
                    <UI.Checkbox
                        defaultValue={`${this.props.mapping.commentsConfiguration.enabled}`}
                        onChange={() => this.switchFlag(this.props.mapping.commentsConfiguration, 'enabled')}
                        checkedValue={'true'} uncheckedValue={'false'} label={`Enable`}/>
                    {this.props.mapping.commentsConfiguration.enabled && <React.Fragment>
                        <UI.Dropdown
                            isRequired={ false }
                            disableEmptyOption={ true }
                            onChange={ (value: SmartIntDirection) => this.setCommentsDirection(value) }
                            options={ this.directionOptions }
                            defaultValue={ this.props.mapping.commentsConfiguration.direction }
                        />
                        <React.Fragment>
                            <UI.Message appearance={"info"}>
                                Filter comments by different properties like author, content. For more details, check out our <UI.DocumentationLink text={`documentation here`} url={`https://docs.getint.io/getintio-platform/workflows/items-filtering/how-to-filter-comments`} />.
                            </UI.Message>
                            <div className={`comments-filtering`}>
                                <div className={(!isLeftFilteringEnabled) ? 'disabled' : ''}>
                                    { (!isLeftFilteringEnabled) && <div className={`disabled-overlay`}>&nbsp;</div> }
                                    { <React.Fragment>
                                        <h3>Filter { this.props.appsSupport.leftApp } (left) comments</h3>
                                        <CommentsFiltering
                                            filters={ this.props.mapping.commentsConfiguration.filters.left}
                                        />
                                    </React.Fragment> }
                                </div>
                                <div className={(!isRightFilteringEnabled) ? 'disabled' : ''}>
                                    { (!isRightFilteringEnabled) && <div className={`disabled-overlay`}>&nbsp;</div> }
                                    { <React.Fragment>
                                        <h3>Filter { this.props.appsSupport.rightApp } (right) comments</h3>
                                        <CommentsFiltering
                                            filters={ this.props.mapping.commentsConfiguration.filters.right}
                                        />
                                    </React.Fragment> }
                                </div>
                            </div>
                        </React.Fragment>
                        { showLogicalCondition && <UI.FormGroup
                            description={<span>For each app you can provide logical condition which will be used to leave those comments that should be synced. In condition you can use $&#123;comment&#125; to reference to the validated comment artifact.</span>}
                        >
                            <div className={`flex row`}>
                                <div className={`flex-equal with-padding`}>
                                    <UI.Input
                                        defaultValue={this.props.mapping.commentsConfiguration.conditions?.left}
                                        placeholder={`Logical condition`}
                                        onChange={(newValue) => this.changeCondition('left', newValue)}/>
                                </div>
                                <div className={`flex-equal with-padding`}>
                                    <UI.Input
                                        defaultValue={this.props.mapping.commentsConfiguration.conditions?.right}
                                        placeholder={`Logical condition`}
                                        onChange={(newValue) => this.changeCondition('right', newValue)}/>
                                </div>
                            </div>
                        </UI.FormGroup> }
                        <UI.FormGroup>
                            <UI.Checkbox
                                defaultValue={`${!!this.props.mapping.commentsConfiguration.disablePairingComment}`}
                                label={`Automatically link comments to related items when created.`}
                                onChange={() => this.switchCommentsConfigurationFlag('disablePairingComment')}
                                checkedValue={`false`} uncheckedValue={`true`}
                            />
                        </UI.FormGroup>
                        <UI.FormGroup>
                            <UI.Checkbox
                                defaultValue={`${this.props.mapping.commentsConfiguration.insertOriginalAuthor}`}
                                label={`Maintain attribution to the original author of the comment.`}
                                onChange={() => this.switchCommentsConfigurationFlag('insertOriginalAuthor')}
                                checkedValue={`true`} uncheckedValue={`false`}/>
                        </UI.FormGroup>
                        <UI.FormGroup>
                            <UI.Checkbox
                                defaultValue={`${this.props.mapping.commentsConfiguration.insertItemId}`}
                                label={`Sync original item id`}
                                onChange={() => this.switchCommentsConfigurationFlag('insertItemId')}
                                checkedValue={`true`} uncheckedValue={`false`}/>
                        </UI.FormGroup>
                        <UI.FormGroup>
                            <UI.Checkbox
                                defaultValue={`${this.props.mapping.commentsConfiguration.insertOriginalCreationDate}`}
                                label={`Sync original comment creation date`}
                                onChange={() => this.switchCommentsConfigurationFlag('insertOriginalCreationDate')}
                                checkedValue={`true`} uncheckedValue={`false`}/>
                        </UI.FormGroup>
                        <UI.FormGroup
                            label={`Comments Visibility`}
                        >
                            <div className={`flex row`}>
                                <div className={`flex-equal with-padding`}>
                                    <UI.Checkbox
                                        defaultValue={`${this.props.mapping.commentsConfiguration.asPrivate.left}`}
                                        label={`Add comments as Private to ${this.props.appsSupport.leftApp}`}
                                        onChange={() => this.switchPrivateFlag('left')}
                                        checkedValue={`true`} uncheckedValue={`false`}/>
                                </div>
                                <div className={`flex-equal with-padding`}>
                                    <UI.Checkbox
                                        defaultValue={`${this.props.mapping.commentsConfiguration.asPrivate.right}`}
                                        label={`Add comments as Private to ${this.props.appsSupport.rightApp}`}
                                        onChange={() => this.switchPrivateFlag('right')}
                                        checkedValue={`true`} uncheckedValue={`false`}/>
                                </div>
                            </div>
						</UI.FormGroup>
                        { this.showPublicPrivateConfiguration && <CommentsPublicPrivateConfiguration
                            leftApp={ this.props.appsSupport.leftApp }
                            rightApp={ this.props.appsSupport.rightApp }
                            configuration={ this.props.mapping.commentsConfiguration.privatePublic }
                        /> }
					</React.Fragment>}
                </UI.FormSection>
                { isAttachmentsSyncSupported(this.props.appsSupport.leftApp, this.props.appsSupport.rightApp) && <UI.FormSection
                    dataSel={`attachments`}
                    label={`Synchronize attachments`}
                >
                    <UI.Checkbox
                        defaultValue={`${this.props.mapping.attachmentsConfiguration.enabled}`}
                        onChange={() => this.switchAttachmentsFlag(this.props.mapping.attachmentsConfiguration, 'enabled')}
                        checkedValue={'true'} uncheckedValue={'false'} label={`Enable`}/>
                    { this.props.mapping.attachmentsConfiguration.enabled && <React.Fragment>
                        {isGitLab && <div style={{margin: "20px 0px"}}>
                            <UI.Message appearance={"warning"}>
                                {gitLabWarningMsg} <UI.DocumentationLink text={`Read More`} url={`https://docs.getint.io/guides/integration-synchronization/jira-gitlab/attachments-sync-in-gitlab-integration`}/>.
                            </UI.Message>
                        </div>}
                        {isMonday && <div style={{margin: "20px 0px"}}>
                            <UI.Message appearance={"warning"}>
                                {mondayWarningMsg} <UI.DocumentationLink text={`Read More`} url={`https://docs.getint.io/guides/integration-synchronization/jira-monday-integration/synchronizing-attachments-in-a-monday.com-integration`}/>.
                            </UI.Message>
                        </div>}
                        <UI.Dropdown
                            label={ `Sync direction` }
                            isRequired={ false }
                            disableEmptyOption={ true }
                            onChange={ (value: SmartIntDirection) => this.setAttachmentsDirection(value) }
                            options={ this.directionOptions }
                            defaultValue={ this.props.mapping.attachmentsConfiguration.direction }
                        />
                        <UI.FormGroup
                            label={<UI.Checkbox
                                defaultValue={`${this.props.mapping.attachmentsConfiguration.deleteAttachments}`}
                                onChange={() => this.switchAttachmentsFlag(this.props.mapping.attachmentsConfiguration, 'deleteAttachments')}
                                checkedValue={'true'} uncheckedValue={'false'} label={`Delete attachments (experimental)`}/>}
                            description={`Attachments created by getint.io will be captured in a database. Once attachment is deleted, getint.io will delete it from the counterpart when the sync of the item happens.`}
                        >
                        </UI.FormGroup>
                        { showInlineImagesSync && <UI.FormGroup
                            label={<UI.Checkbox
                                defaultValue={`${this.props.mapping.attachmentsConfiguration.syncInlineImages}`}
                                onChange={() => this.switchAttachmentsFlag(this.props.mapping.attachmentsConfiguration, 'syncInlineImages')}
                                checkedValue={'true'} uncheckedValue={'false'} label={`Sync inline images in comments (experimental)`}/>}
                        /> }
                        { isGitLab && <UI.FormGroup
                            label={`GitLab Cookie header for fetching attachments`}
                            description={`GitLab do not provide direct API for downloading attachments. As a workaround, you can provide a Cookie header (that can be obtained from your browser after you sign in to your GitLab account) which we will use to download attachments`}
                        >
                            <UI.Input
                                defaultValue={ this.props.mapping.attachmentsConfiguration.gitlabCookieHeader}
                                onChange={ (newValue) => this.props.mapping.attachmentsConfiguration.gitlabCookieHeader = newValue }
                            />
                        </UI.FormGroup> }
                        { this.props.appsSupport.left.supportsAttachmentsStorage() && <AttachmentsStorageConfiguration
                            configuration={ this.props.mapping.attachmentsConfiguration.left }
                            appName={ this.props.appsSupport.leftApp }
                        /> }
                        { this.props.appsSupport.right.supportsAttachmentsStorage() && <AttachmentsStorageConfiguration
                            configuration={ this.props.mapping.attachmentsConfiguration.right }
                            appName={ this.props.appsSupport.rightApp }
                        /> }
                        { (this.props.appsSupport.leftApp === SoftwareName.Jira || this.props.appsSupport.rightApp === SoftwareName.Jira) && <AttachmentsPublicAttachmentsConfiguration
                            configuration={ this.props.mapping.attachmentsConfiguration }
                            appSupport={ this.props.appsSupport }
                        /> }
                        { (this.props.appsSupport.leftApp === SoftwareName.Jira || this.props.appsSupport.rightApp === SoftwareName.Jira) && <AttachmentsJiraInsertAsPublicComment
                            configuration={ this.props.mapping.attachmentsConfiguration }
                            appSupport={ this.props.appsSupport }
                        /> }
                        </React.Fragment>
                    }
                </UI.FormSection> }
            </div>
        </React.Fragment>
    }

    private setAttachmentsDirection(direction: SmartIntDirection) {
        this.props.mapping.attachmentsConfiguration.direction = direction;
    }

    private async setCommentsDirection(direction: SmartIntDirection) {
        this.props.mapping.commentsConfiguration.direction = direction;
        await this.forceUpdate();
    }

    private changeCondition(side: 'left' | 'right', newValue: string) {
        if (!this.props.mapping.commentsConfiguration.conditions) {
            this.props.mapping.commentsConfiguration.conditions = {left: '', right: ''};
        }
        this.props.mapping.commentsConfiguration.conditions[side] = newValue;
    }

    private switchFlag(configuration: SmartIntCommentsConfiguration | SmartIntAttachmentsConfiguration, flagName: 'enabled') {
        configuration[flagName] = !configuration[flagName];
        this.forceUpdate();
    }

    private switchAttachmentsFlag(configuration: SmartIntAttachmentsConfiguration, flagName: 'syncInlineImages' | 'enabled' | 'deleteAttachments') {
        configuration[flagName] = !configuration[flagName];
        this.forceUpdate();
    }

    private switchCommentsConfigurationFlag(flagName: 'enabled' | 'disablePairingComment' | 'insertOriginalCreationDate' | 'insertOriginalAuthor' | 'insertItemId') {
        this.props.mapping.commentsConfiguration[flagName] = !!!this.props.mapping.commentsConfiguration[flagName];
    }

    private switchPrivateFlag(side: 'left' | 'right') {
        this.props.mapping.commentsConfiguration.asPrivate[side] = !!!this.props.mapping.commentsConfiguration.asPrivate[side];
    }
}
