import React from 'react';

import './secondary-scene.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

type SecondarySceneProps = {
    onCloseHandler: () => void;
};

export default class SecondaryScene extends React.Component<SecondarySceneProps> {
    render() {
        return <div className="secondary-scene">
            <span className="close-btn"><FontAwesomeIcon onClick={() => this.props.onCloseHandler() } icon={faTimes} /></span>
            { this.props.children }
        </div>;
    }
}