export type AdvancedScripting = {
    onAfterItemFetched: {
        left: string;
        right: string;
    };
    onBeforeRequestObjectSent: {
        left: string;
        right: string;
    };
    onBeforeStatusTransition: {
        left: string;
        right: string;
    };
    onBeforeCommentCreated: {
        left: string;
        right: string;
    }
    onBeforeIntegrationRuns: string;
};

export type AdvancedCustomProperties = {
    serviceNowImageBaseUrl: string;
};

export type AdvancedConfiguration = {
    scripting: AdvancedScripting;
    customProperties: AdvancedCustomProperties;
};

export function createEmptyAdvancedConfiguration(): AdvancedConfiguration {
    return {
        scripting: {
            onBeforeStatusTransition: {
                left: '',
                right: ''
            },
            onBeforeRequestObjectSent: {
                left: '',
                right: ''
            },
            onBeforeCommentCreated: {
                left: '',
                right: ''
            },
            onAfterItemFetched: {
                left: '',
                right: ''
            },
            onBeforeIntegrationRuns: ''
        },
        customProperties: {
            serviceNowImageBaseUrl: ''
        }
    };
}
