import React from "react";
import { UI } from "src/engrator-core";
import { ZendeskOrganizationDTO } from ".";

type Props = {
    onProvided: (organization: ZendeskOrganizationDTO) => void;
};

type State = {
    organization: ZendeskOrganizationDTO;
};

export class ZendeskOrganizationManualWay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            organization: {
                id: '',
                name: ''
            }
        };
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                label={`Id`}
            >
                <UI.Input 
                    label={``} onChange={ (newValue) => this.onPropertyChanged('id', newValue) }
                />
            </UI.FormGroup>
            <UI.FormGroup
                label={`Name`}
            >
                <UI.Input 
                    label={``} onChange={ (newValue) => this.onPropertyChanged('name', newValue) }
                />
            </UI.FormGroup>
        </React.Fragment>;
    }

    onPropertyChanged(name: 'id' | 'name', newValue: string) {
        const organization = this.state.organization;
        organization[name] = newValue;
        this.setState({ organization }, () => {
            if (this.state.organization.id && this.state.organization.name) {
                this.props.onProvided(this.state.organization);
            }
        });
    }
}