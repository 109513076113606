import { Software} from 'src/generic';
import {AppSmartIntsSupport} from "src/app/integration/smartints/app-smart-ints-support";
import {IntHubConnectionFactory} from "./connection-factory/inthub-connection-factory";
import {SoftwareName} from "../software-name";
import {PipelineGenericStep} from "../../generic/pipeline-step";
import {IntHubPipelienSteps} from "./inthub-pipeline-steps";

export class IntHubSoftware implements Software {
    getConnectionFactory() {
        return new IntHubConnectionFactory();
    }
    getName(): string {
        return SoftwareName.IntHub;
    }
    getID() {
        return SoftwareName.IntHub;
    }
    getAvailableSteps(): object[] {
        return IntHubPipelienSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }
    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return null;
    }
}
