import {UI} from "../../engrator-core";
import {Footer} from "../footer";
import React, {Component} from "react";
import {SystemLicenseInfo} from "../system/license/rest-api";
import {FormError} from "../../engrator-core/form-error.type";

type Props = {
    fetchLicense: () => Promise<SystemLicenseInfo>;
    setLicense: (newLicense: string) => Promise<void>;
};
type State = {
    license?: SystemLicenseInfo;
    newLicense?: string;
    error?: FormError;
    showLicenseEdit: boolean;
    isLoading: boolean;
    isSaving: boolean;
};

export class DedicatedLicense extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            isLoading: true,
            isSaving: false,
            showLicenseEdit: false
        };
    }

    async componentDidMount() {
        try {
            const license = await this.props.fetchLicense();
            this.setState({license, isLoading: false});
        } catch (formError) {
            this.setState({error: formError, isLoading: false });
        }
    }

    render() {
        if (this.state.isLoading) {
            return <UI.Page>
                <UI.Loader visible={true} appearance={'darkgray'}/>
            </UI.Page>;
        }
        return <UI.Page>
            <UI.Tile>
                {!this.state.showLicenseEdit && <React.Fragment>
                    {!this.state.license && <React.Fragment>
                        <p>There is no dedicated license.</p>
                    </React.Fragment>}
                    {this.state.license && <UI.Form>
                        <UI.LabelValue
                            label={`Is trial: `}
                            value={`${this.state.license.trial}`}
                        />
                        <UI.LabelValue
                            label={`Expiration date:`}
                            value={`${this.state.license.expirationDate}`}
                        />
                        <UI.LabelValue
                            label={`Apps urls to sync:`}
                            value={`${this.state.license.urls}`}
                        />
                        <br/>
                    </UI.Form> }
                </React.Fragment>}
                {!this.state.showLicenseEdit &&
                <UI.Button onClick={this.showLicenseEditForm.bind(this)} text={`Edit`}/>}
                {this.state.showLicenseEdit && <UI.Form>
                    {this.state.error && <UI.Message appearance={"error"} message={this.state.error.message} />}
                    <UI.FormGroup
                        label={`New license`}
                        description={`Paste into below field new license received from getint.io`}
                    >
                        <UI.Textarea onChange={(newLicense) => this.setState({newLicense})} rows={10}/>
                    </UI.FormGroup>
                    <UI.ButtonsBar primary={
                        <UI.Button disabled={ !this.state.newLicense } onClick={() => this.submitNewLicense()} text={`Submit`}/>
                    } secondary={[<UI.Button appearance={'secondary'} onClick={() => this.cancelNewLicense()}
                                             text={`Cancel`}/>]}/>
                </UI.Form>}
            </UI.Tile>
            <Footer/>
        </UI.Page>;
    }

    cancelNewLicense(): void {
        this.setState({showLicenseEdit: false});
    }

    async submitNewLicense(): Promise<void> {
        if (this.state.newLicense) {
            try {
                await this.props.setLicense(this.state.newLicense);
                const license = await this.props.fetchLicense();
                await this.setState({newLicense: '', showLicenseEdit: false, license});
            } catch (formError) {
                this.setState({error: formError});
            }
        }
    }

    showLicenseEditForm() {
        this.setState({showLicenseEdit: true});
    }
}