import React from 'react';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { UI } from 'src/engrator-core';
import { AsanaSmartIntsTriggerConfiguration } from './asana-smart-ints-trigger-configuration';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class AsanaSmartIntsEditTrigger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const configuration = (this.props.trigger.configuration as AsanaSmartIntsTriggerConfiguration);
        console.log(configuration);
        return <React.Fragment>
            <UI.FormGroup>
                <UI.LabelValue
                    label={`Workspace`}
                    value={configuration.workspace.name}
                />
            </UI.FormGroup>
            <UI.FormGroup>
                <UI.LabelValue
                    label={`Project`}
                    value={configuration.project.name}
                />
            </UI.FormGroup>
            <UI.FormGroup>
                <UI.Checkbox
                    label={`Use Premium search endpoint`}
                    checkedValue={`true`}
                    uncheckedValue={`false`}
                    defaultValue={`${configuration.usePremiumSearch}`}
                    onChange={ this.switchPremiumSearchFlag.bind(this) }
                />
            </UI.FormGroup>
            <UI.FormGroup
                label={`Additional Query`}
                description={`You can provide additional query params which will be appended to the url generated when searching for issues in GitLab`}
            >
                <UI.Input defaultValue={configuration.query} label={``} onChange={this.onJqlInputChanged.bind(this)}/>
            </UI.FormGroup>
        </React.Fragment>
    }

    private switchPremiumSearchFlag(newValue: string) {
        this.props.trigger.configuration.usePremiumSearch = (newValue === 'true') ? true : false;
        this.forceUpdate();
    }

    private onJqlInputChanged(newValue: string) {
        this.props.trigger.configuration.query = newValue;
    }
}
