import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntDefinitionTypeMapping } from '../../../definition/smart-int-definition--type-mapping.type';
import { SmartIntHierarchyConfiguration } from '../../../definition/smart-int-definition-configuration.type';
import { SmartIntTrigger } from '../../../definition/smart-int-trigger.type';
import { AppsSupport } from '../../visual-integration-designer';
import { RelationshipPicker, RelationshipPickerType } from './relationship-picker';

type Props = {
  appsSupport: Readonly<AppsSupport>;
  mapping: Readonly<SmartIntDefinitionTypeMapping>;
  hierarchyConfiguration: SmartIntHierarchyConfiguration;
  leftTrigger: SmartIntTrigger;
  rightTrigger: SmartIntTrigger;
};

export class SubtasksSync extends React.Component<Props> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        if (!this.props.hierarchyConfiguration.subtasks) {
            this.props.hierarchyConfiguration.subtasks = {
                enabled: false,
                options: {
                    left: '',
                    right: ''
                }
            };
        }
    }

    render() {
        return <UI.Form errors={ [] }>
            <UI.FormSection
                label={`Synchronize Subtasks relationship`}
            >

                <UI.FormGroup
                    label={<UI.Checkbox
                        defaultValue={`${this.props.hierarchyConfiguration.subtasks?.enabled}`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                        onChange={ this.switchEnabledFlag.bind(this) }
                        label={`Enable`}
                    />}
                    description={`Enable synchronization of Subtasks relationships for the currently configured type mapping. If item on one side will be linked with to a parent using configured below relationship, getint.io will try to establish relevant linking for its counterpart item.`}
                >
                    { this.props.hierarchyConfiguration.subtasks?.enabled && <UI.FormGroup
                        label={`Configure relationship`}
                        isRequired={ true}
                        description={``}
                    >
                        <div className="flex row two-columns">
                            <div>
                                <RelationshipPicker
                                    pickerType={ RelationshipPickerType.Subtasks }
									appSupport={this.props.appsSupport.left}
									trigger={this.props.leftTrigger}
									label={`${this.props.appsSupport.leftApp} ${this.props.mapping.left.name} (left)`}
									onChange={ (option: any) => this.setOption('left', option) }
									defaultValue={ this.props.hierarchyConfiguration.subtasks.options.left }
                                />
                                <UI.Input
                                    label={`Set fixed parent`}
                                    placeholder={`ID of the parent which will be set always when item is created`}
                                    defaultValue={ this.props.hierarchyConfiguration.subtasks?.fixed?.left || '' }
                                    onChange={ (newValue) => this.setFixedParent('left', newValue) }
                                />
                            </div>
                            <div>
								<RelationshipPicker
                                    pickerType={ RelationshipPickerType.Subtasks }
									appSupport={this.props.appsSupport.right}
									trigger={this.props.rightTrigger}
									label={`${this.props.appsSupport.rightApp} ${this.props.mapping.right.name} (right)`}
									onChange={(option: any) => this.setOption('right', option)}
									defaultValue={this.props.hierarchyConfiguration.subtasks.options.right}
								/>
                                <UI.Input
                                    label={`Set fixed parent`}
                                    placeholder={`ID of the parent which will be set always when item is created`}
                                    defaultValue={ this.props.hierarchyConfiguration.subtasks?.fixed?.right || '' }
                                    onChange={ (newValue) => this.setFixedParent('right', newValue) }
                                />
                            </div>
                        </div>
                    </UI.FormGroup> }
                </UI.FormGroup>
            </UI.FormSection>
        </UI.Form>
    }

    private setFixedParent(side: 'left' | 'right', newValue: string) {
        if (!this.props.hierarchyConfiguration.subtasks!.fixed) {
            this.props.hierarchyConfiguration.subtasks!.fixed = {
                left: '', right: ''
            };
        }
        this.props.hierarchyConfiguration.subtasks!.fixed[side] = newValue;
    }


    private switchEnabledFlag() {
        this.props.hierarchyConfiguration.subtasks!.enabled = !this.props.hierarchyConfiguration.subtasks?.enabled;
        this.forceUpdate(); 
    }

    private setOption(side: 'left' | 'right', optionValue: string) {
        this.props.hierarchyConfiguration.subtasks!.options[side] = optionValue;
    }
}
