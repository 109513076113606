import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {GitLabStepKey} from "../gitlab-step-key.type";
import {GitLabCreateCcommentsNewStep} from "./gitlab-create-comments-new-step";
import {GitLabCreateCommentsEditStep} from "./gitlab-create-comments-edit-step";
import {GitLabCreateCommentsConfiguration} from "./gitlab-create-comments-configuration";


export const GitLabCreateCommentsPipelineStep: PipelineStep<GitLabCreateCommentsConfiguration> = {
    addStepComponentClass: GitLabCreateCcommentsNewStep,
    editStepComponentClass: GitLabCreateCommentsEditStep,
    specification: {
        action: "Create issue comment(s)",
        description: "Create comment(s) for selected issue",
        key: GitLabStepKey.GitLabCreateComments,
        software: SoftwareName.GitLab,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `GitLabCard`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitLabCreateCommentsConfiguration;
        return `Create comment(s) for issue '${stepConfiguration.targetArtifact}'`;
    }
};