import { Status } from '../../../../software/generic/status-transition/status.type';
import { DefaultMapping, getDefaults, getDefaultSkipMapping } from '../visual/components/value-mappings/default-mappping.type';
import { ValueMappingsGroup } from '../visual/components/value-mappings/value-mappings-group.type';
import { PropertyMapping } from '../visual/property-mapping.type';
import {SmartIntDirection} from "./smart-int-direction.type";
import {PropertyDefinition} from "../../../../generic/artifacts";
import {UILinkDTO} from "../visual/relationships/rest-api";
import {ItemFilter} from "../visual/filtering/rest-api";
import {CommentFilter} from "../visual/type-mapping-configuration/comments/comments-filtering"; 

export enum SmartIntConfigurationType {
    StatusTransition = 'StatusTransition',
    PropertyMappings = 'PropertyMappings',
    Comments = 'Comments',
    Attachments = 'Attachments',
    Advanced = 'Advanced',
    Hierarchy = 'Hierarchy',
    CI = 'CI'
}

export type SmartIntDefinitionConfiguration = {
    configurationType: SmartIntConfigurationType;
}

export type PrivatePublicConfiguration = {
    left: {
        publicComment: CommentAction,
        privateComment: CommentAction,
    },
    right: {
        publicComment: CommentAction,
        privateComment: CommentAction,
    }
};

export enum CommentAction {
    Public = 'Public',
    Private = 'Private',
    Skip = 'Skip',
    PublicNote = 'PublicNote'
}

export type SmartIntCommentsConfiguration = {
    configurationType: SmartIntConfigurationType.Comments;
    enabled: boolean;
    filters: { left: CommentFilter; right: CommentFilter };
    disablePairingComment: boolean;
    insertOriginalAuthor: boolean;
    insertOriginalCreationDate: boolean;
    insertItemId: boolean;
    direction?: SmartIntDirection;
    asPrivate: { left: boolean; right: boolean };
    conditions: { left: string; right: string };
    privatePublic?: PrivatePublicConfiguration;
} & SmartIntDefinitionConfiguration;

export type PullRequestsConfiguration = {
    enabled: boolean;
    captureRegExp: string;
    itemStatus?: Status;
};

export type SmartIntPropertiesMappingsConfiguration = {
    configurationType: SmartIntConfigurationType.PropertyMappings;
    properties: PropertyMapping[];
} & SmartIntDefinitionConfiguration;

export type SmartIntAttachmentsConfiguration = {
    configurationType: SmartIntConfigurationType.Attachments;
    enabled: boolean;
    deleteAttachments?: boolean;
    syncInlineImages?: boolean;
    direction?: SmartIntDirection;
    gitlabCookieHeader?: string;
    left: SmartIntAppAttachmentsConfiguration;
    right: SmartIntAppAttachmentsConfiguration;
    onlyPublicCommentsAttachments: {
        left: boolean;
        right: boolean;
    },
    insertInPublicComment: {
        left: boolean;
        right: boolean;
    }
} & SmartIntDefinitionConfiguration;

export type SmartIntAppAttachmentsConfiguration = {
    storage: AttachmentsStorageConfiguration;
}

export type AttachmentsStorageConfiguration = {
    s3: {
        key: string;
        secret: string;
        bucketName: string;
        region: string;
    }
};

export type SmartIntAdvancedSyncConfiguration = {
    enabled: boolean;
    left: {
        create: boolean;
        update: boolean;
    },
    right: {
        create: boolean;
        update: boolean;
    },
    breakFlowCondition?: string;
};

export type SmartIntAdvancedConfiguration = {
    configurationType: SmartIntConfigurationType.Advanced;
    syncConfiguration?: SmartIntAdvancedSyncConfiguration;
    postCreate: {
        left: string;
        right: string;
    };

} & SmartIntDefinitionConfiguration;

export type SmartIntHierarchyConfiguration = {
    configurationType: SmartIntConfigurationType.Hierarchy;
    epics: {
        enabled: boolean;
        options: {
            left: string;
            right: string;
        }
    },
    subtasks?: {
        enabled: boolean;
        options: {
            left: string;
            right: string;
        },
        fixed?: {
            left: string;
            right: string;
        }
    }
}

export type RelationshipLinkMapping = {
    left: UILinkDTO;
    right: UILinkDTO;
}

export type Hierarchy = {
    enabled: boolean;
    jiraLink?: UILinkDTO;
    useEpicLink: boolean;
};

export function getDefaultHierarchy(): Hierarchy {
    return { enabled: false, useEpicLink: false };
}

export type SmartIntRelationshipConfiguration = {
    links: RelationshipLinkMapping[];
    hierarchy: Hierarchy;

};

export type SmartIntStatusTransitionConfiguration = {
    configurationType: SmartIntConfigurationType.StatusTransition;
    groups: ValueMappingsGroup[];
    defaults: {
        left: DefaultMapping;
        right: DefaultMapping;
    };
    statusFieldsIds: {
        left: string;
        right: string;
    };
    mapping?: {
        left: PropertyDefinition;
        right: PropertyDefinition;
    }
    enabled: boolean;
} & SmartIntDefinitionConfiguration;

export function getDefaultStatusTransitionConfiguration(): SmartIntStatusTransitionConfiguration {
    return {
        configurationType: SmartIntConfigurationType.StatusTransition,
        defaults: getDefaults(),
        statusFieldsIds: {
            left: '',
            right: ''
        },
        enabled: true,
        groups: []
    };
}

export function getDefaultAdvacnedSyncConfiguration(): SmartIntAdvancedSyncConfiguration {
    return {
        enabled: false,
        left: { create: true, update: true },
        right: { create: true, update: true },
    };
}

export function getDefaultHierarchySyncConfiguration(): SmartIntHierarchyConfiguration {
    return {
        configurationType: SmartIntConfigurationType.Hierarchy,
        epics: {
            enabled: false,
            options: {
                left: '',
                right: ''
            }
        }
    };
}

export function createDefaultAttachemntsConfiguration(): SmartIntAppAttachmentsConfiguration {
    return {
        storage: {
            s3: {
                region: '',
                bucketName: '',
                key: '',
                secret: '',
            }
        }
    }
}

export function createEmptyPrivatePublicConfiguration(): PrivatePublicConfiguration {
    return {
        left: {
            privateComment: CommentAction.Private,
            publicComment: CommentAction.Public
        },
        right: {
            privateComment: CommentAction.Private,
            publicComment: CommentAction.Public
        }
    }
}