import React from 'react';
import { UI } from 'src/engrator-core';
import { DropdownOption } from '../../../../engrator-core/ui';
import { getTenantProperties, setTenantProperty } from '../../rest-api';
import { TenantProperty, TenantPropertyName } from './tenant-property';

type Props = {
    close: () => void;
    tenantName: string;
};
type State = {
    error?: any;
    isSetting: boolean;
    properties: TenantProperty[];
};

export class TenantPropertiesApplier extends React.Component<Props, State> {
    private data = {
        propertyName: TenantPropertyName,
        value: '',
        tenantName: '',
    };

    private options: DropdownOption[] = [
        {label: 'Enable migration', value: TenantPropertyName.EnabledMigration},
        {label: 'Migration runs left', value: TenantPropertyName.MigrationRunsLeft},
        {label: 'Maintenance end date', value: TenantPropertyName.MaintenanceEndDate},
        {label: 'Is on trial', value: TenantPropertyName.IsOnTrial},
        {label: 'Expiry date', value: TenantPropertyName.ExpiryDate},
    ];

    constructor(props: Props) {
        super(props);
        this.state = {
            isSetting: false,
            properties: [],
        };
        this.data.tenantName = this.props.tenantName;
    }

    componentDidMount() {
        getTenantProperties(this.props.tenantName)
            .then((properties) => {
                this.setState({properties});
            });
    }

    render() {
        return <div className="persistence-loader engagement-popup">
            <div className={`box wide less-padding left-aligned`}>
                <UI.Form
                    errors={[]}
                >
                    {this.state.properties.length > 0 && <UI.FormGroup
						label={`Properties`}
					>
                        {this.state.properties.map((property, index) => <UI.LabelValue label={`${property.propertyName}: `}
                                                                                       value={property.value}/>)}
					</UI.FormGroup>}

                    {this.state.error && <UI.Message appearance="error">
                        {this.state.error.message}
					</UI.Message>}

                    <UI.FormGroup
                        isRequired={true}
                        label="Property"
                        description={'Select property which should be updated'}
                    >
                        <UI.Dropdown
                            options={this.options}
                            onChange={(newValue: any) => {
                                this.data.propertyName = newValue;
                            }}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        isRequired={true}
                        label="Value"
                    >
                        <UI.Input placeholder="Value" onChange={(newValue) => {
                            this.data.value = newValue;
                        }}/>
                    </UI.FormGroup>
                    <UI.ButtonsBar
                        primary={<UI.Button isLoading={this.state.isSetting} text="Set" onClick={() => this.createClicked()}/>}
                        secondary={[
                            <UI.Button text="Close" appearance="secondary" onClick={() => this.close()}/>,
                        ]}
                    />
                </UI.Form>
            </div>
        </div>;
    }

    private close() {
        this.props.close();
    }

    private createClicked() {
        this.setState({isSetting: true}, () => {
            setTenantProperty(this.data)
                .then(() => {
                    this.setState({isSetting: false}, () => {
                        this.props.close();
                    });
                })
                .catch((error) => {
                    this.setState({isSetting: false, error});
                });
        });
    }
}
