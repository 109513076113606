import { axiosWrapper } from 'src/engrator-core';

const ENDPOINT_URL = '/system/users/password';

export type ChangePasswordRequest = {
    current: string;
    repeated: string;
    newPassword: string;
};

export const changePassword = (request: ChangePasswordRequest): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}`, request)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
