import React, { Component } from "react";

import { IntegrationDesignerContainer } from 'src/designer';
import { PipelineMode } from "./pipeline-mode";
import {SmartintsWizard} from "../../smartints";

type IntegrationCreateState = {
    mode?: PipelineModeEnum,
    useDesigner: boolean,
    initialIntegration: any
};

export enum PipelineModeEnum {
    Advanced,
    SmartInt
}
export class IntegrationCreatePage extends Component<{}, IntegrationCreateState> {
    constructor(props: any) {
        super(props);
        this.state = {
            useDesigner: true,
            initialIntegration: undefined,
            mode: PipelineModeEnum.Advanced
        };
    }

    render() {
        if (this.state.useDesigner) {
            return <IntegrationDesignerContainer integrationData={ this.state.initialIntegration } />;
        }
        return (
            <div>
                { !this.state.mode && <PipelineMode modeSelectedHandler={ this.modeSelectedHandler.bind(this) }/> }
                { this.state.mode === PipelineModeEnum.SmartInt && <SmartintsWizard onPipelinesBuildUp={ this.onPipelinesBuildUp.bind(this) }/> }
            </div>
        )
    }

    private modeSelectedHandler(mode: PipelineModeEnum) {
        if (mode === PipelineModeEnum.SmartInt) {
            this.setState({mode});
        } else {
            this.setState({ useDesigner: true });
        }
    }

    private onPipelinesBuildUp(initialIntegrationData: any) {
        this.setState({ useDesigner: true, initialIntegration: initialIntegrationData });
    }
}