import React from 'react';
import { UI } from 'src/engrator-core';
import { ArtifactVersionDataPreview } from './artifact-version-data-preview';
import {getItemVersion, ItemVersionLookUp} from './rest-api';
import {SoftwareName} from "../../../software";

type Props = {
    lookUpData: ItemVersionLookUp;
    appName?: string;
}
type State = {
    itemVersionData?: any;
};

export class ArtifactVersionPreviewLink extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        if (!this.props.lookUpData?.itemId) {
            return <span className={`item-id`}>-</span>;
        }
        return <div data-sel={'item-' + this.props.lookUpData.itemId} className={`artifact-version-preview-link`}>
            <span className={`item-id`} onClick={() => this.showItemVersion()}>{ this.getId() }</span>
            <a className={`preview`} onClick={() => this.showItemVersion()}>
                <UI.Icon icon="artifact"/>
            </a>
            {this.state.itemVersionData && <ArtifactVersionDataPreview
				artifactVersion={this.state.itemVersionData}
				close={() => this.closePreview()}
			/>}
        </div>;
    }

    closePreview() {
        this.setState({itemVersionData: undefined});
    }

    showItemVersion() {
        getItemVersion(this.props.lookUpData)
            .then((data) => {
                this.setState({itemVersionData: data});
            });
    }

    private getId() {
        if (this.props.appName === SoftwareName.GetintArchiver) {
            return 'ARCHIVER-' + this.props.lookUpData.itemId;
        }
        return this.props.lookUpData.itemId;
    }
}
