import React, {Component} from "react";

import {UI} from 'src/engrator-core';
import { IntegrationThreadRun } from "./integration-thread-run.type";

type Props = {
    run: IntegrationThreadRun
};

export class IntegrationThreadRunInfo extends Component<Props, {}> {
    render() {
        return <div className={`run-info`}>
            <UI.ExecutionStatus status={ this.props.run.status as unknown as string } />
            <UI.NavLink path={`/app/reporting/suite-run/${this.props.run.id}/details`}>
                #{this.props.run.id}
            </UI.NavLink>
            { this.props.run.performedFlows > 0 && <React.Fragment>
                &nbsp;(
                { this.props.run.performedFlows }
                { this.props.run.persistedArtifacts > 0 && <React.Fragment>
                    / { this.props.run.persistedArtifacts }
                </React.Fragment> }
                )
            </React.Fragment> }
        </div>
    }
}