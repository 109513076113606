import React, {Component} from 'react';
import {copyObject, UI} from 'src/engrator-core';
import {FormError} from "../../engrator-core/form-error.type";
import {
    DataStorageSettings,
    fetchSettings,
    GeneralSettings, getEmptyGeneralSettings,
    saveSettings,
    SettingsGroupName
} from "../system/data-storage/rest-api";
import {Footer} from "../footer";

type State = {
    isLoading: boolean;
    error?: FormError;
    isSaving: boolean;
    saved: boolean;
    settings?: GeneralSettings;
    hasChanged: boolean;
};

export class SettingsGeneralPageCmp extends Component<{}, State> {
    private originalSettings?: GeneralSettings;

    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: true,
            isSaving: false,
            hasChanged: false,
            saved: false
        };
    }

    async componentDidMount() {
        try {
            const settings = await fetchSettings(getEmptyGeneralSettings(), SettingsGroupName.General) as GeneralSettings;
            this.originalSettings = copyObject(settings);
            this.setState({isLoading: false, settings});
        } catch (error) {
            this.setState({isLoading: false, error})
        }
    }

    render() {
        return (
            <UI.Page>
                <UI.Tile>
                    {this.state.error && <UI.Message appearance={"error-message"} message={this.state.error.message}/>}
                    {this.state.isLoading && <UI.Loader visible={true} appearance={"darkgray"}/>}
                    {this.state.settings && <UI.Form useMaxWidthClass={true}>
                        <UI.FormGroup
                            label={`Max number of items to sync per run`}
                            isRequired={true}
                            description={`Define maximum number of the items that will be synchronized during one integration run`}
                        >
                            <UI.Input
                                defaultValue={`${this.state.settings.MaxNumberOfItemsPerSync}`}
                                type={'number'}
                                placeholder={`Provide number of days`}
                                onChange={(newValue: string) => this.changeSetting('MaxNumberOfItemsPerSync', newValue)}
                            />
                            {this.state.settings.Retention && parseInt(this.state.settings.Retention) < 3 &&
                                <UI.Message appearance={'warning'}>
                                    Please note that reducing the retention period may impact future troubleshooting efforts.
                                </UI.Message>}
                        </UI.FormGroup>
                        <UI.ButtonsBar
                            secondary={[(this.state.saved) ?
                                <UI.Message appearance={"success"}>Settings saved!</UI.Message> : <React.Fragment/>]}
                            primary={<UI.Button
                                // disabled={ !this.state.hasChanged } - removing this so user can save storage settings always which will make http requests logged for extended time
                                disabled={this.state.isSaving}
                                text={`Save`}
                                onClick={() => this.saveClicked()}
                                isLoading={this.state.isSaving}
                            />}
                        />
                    </UI.Form>}
                </UI.Tile>
                <Footer/>
            </UI.Page>
        );
    }

    private changeSetting(key: keyof DataStorageSettings, newValue: string) {
        if (this.state.settings && !this.state.isSaving) {
            const settings = this.state.settings;
            settings[key] = newValue;
            const hasChanged = JSON.stringify(settings) !== JSON.stringify(this.originalSettings);
            this.setState({settings, hasChanged});
        }
    }

    private async saveClicked() {
        try {
            await this.setState({saved: false, isSaving: true, error: undefined});
            await saveSettings(SettingsGroupName.General, this.state.settings!);
            this.originalSettings = copyObject(this.state.settings);
            await this.setState({hasChanged: false, saved: true, isSaving: false});
        } catch (error) {
            this.setState({error, isSaving: false});
        }
    }
}