import {axiosWrapper, FieldError} from "../../../engrator-core";

export const ENDPOINT_URL = '/connectors/shotgrid';

export type ShotgridProjectDTO = {
    id: number;
    name: string;
};

export function fetchProjects(connectionId: number): Promise<ShotgridProjectDTO[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/projects')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
