import React from "react";

import RightPanel from "src/designer/container/right-panel/RightPanel";
import {RightPanelContent} from "src/engrator-core/ui/right-panel-content";

import {FlowStep} from "./../flow-steps-factory";
import {NavBar} from "./nav-bar";
import {GeneralInfo} from "./general-info";
import {Logs} from "./logs";
import {Configuration} from "./configuration";
import { SoftwareLogo } from "src/designer/step-creator/software-logo";
import { UI } from "src/engrator-core";

type Props = {
    flowStep: FlowStep;
    close: () => void;
    stepIndex: number
};

type State = {
    selectedTabIndex: number;
};

export class FlowRightPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedTabIndex: 1
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.stepIndex !== prevProps.stepIndex) {
            this.setState({ selectedTabIndex: 1 });
        }
    }

    render() {
        const header = <React.Fragment>
            <SoftwareLogo softwareName={ this.props.flowStep.genericSoftwareStep.getStepSpecification().software } />
            Flow Execution Step (#{ this.props.stepIndex + 1 })
        </React.Fragment>;

        return <div className={`right-panel`}>
            <RightPanel 
                close={ () => this.props.close() }   
            >
                <RightPanelContent 
                    header={ header }
                    footer={ <UI.ButtonsBar
                        primary={ <UI.Button appearance="elementary" onClick={() => this.props.close() }  text="Close" /> }
                        ></UI.ButtonsBar>
                    }
                >
                    <NavBar selectedTabIndex={ this.state.selectedTabIndex } onTabSelected={ this.onTabSelected.bind(this) } />
                    {/* { this.state.selectedTabIndex === 0 && <GeneralInfo flowStep={ this.props.flowStep }/> } */}
                    { this.state.selectedTabIndex === 1 && <Configuration flowStep={ this.props.flowStep }/> }
                    { this.state.selectedTabIndex === 2 && <Logs flowStep={ this.props.flowStep }/> }
                </RightPanelContent>
            </RightPanel>
        </div>
    }

    private onTabSelected(tabIndex: number) {
        this.setState({ selectedTabIndex: tabIndex });
    }
}