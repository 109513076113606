import React, {Component, useEffect} from 'react';
import {copyObject, UI} from 'src/engrator-core';
import {FormError} from '../../../engrator-core/form-error.type';
import {Footer} from '../../footer';
import {useGlobalState} from "../../global-store";
import {getSystemVerticalMenu} from "../system-vertical-menu";
import {MainMenuItem} from "../../global-store/create-global-state";
import {
    DataStorageSettings,
    fetchSettings, getEmptyDataStorageSettings,
    getMinimumLogLevelOptions,
    saveSettings,
    SettingName,
    SettingsGroupName
} from "./rest-api";
import { FeatureFlag, isFeatureFlagEnabled } from 'src/app/top-navigation/rest-api';

type State = {
    isLoading: boolean;
    error?: FormError;
    isSaving: boolean;
    saved: boolean;
    settings?: DataStorageSettings;
    hasChanged: boolean;
    seconds: number;
    showTimer: boolean;
    timerDate: Date;
    logHttpRequestsExpiresAfterUtc?: Date;
    isSaas: boolean;
};

const TEN_MINUTES = 600000;

export class DataStoragePageCmp extends Component<{}, State> {
    private originalSettings?: DataStorageSettings;
    private timerInterval: any;
    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: true,
            isSaving: false,
            hasChanged: false,
            saved: false,
            seconds: 0,
            showTimer: false,
            timerDate: new Date(),
            isSaas: false
        };
    }

    async componentDidMount() {
        try {
            const isSaas = await isFeatureFlagEnabled(FeatureFlag.IS_SAAS);
            const settings = await fetchSettings(getEmptyDataStorageSettings(), SettingsGroupName.DataStorage) as DataStorageSettings;
            this.originalSettings = copyObject(settings);
            let logHttpRequestsExpiresAfterUtc;
            if (this.originalSettings && this.originalSettings.LogHttpRequests === 'true') {
                logHttpRequestsExpiresAfterUtc = new Date(this.originalSettings.LogHttpRequestsExpiresAfterUtc);
            }
            this.setState({isLoading: false, settings, logHttpRequestsExpiresAfterUtc, isSaas});
        } catch (error) {
            this.setState({ isLoading: false, error})
        }
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevState.showTimer != this.state.showTimer) {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
            }
        }
        if (prevState.showTimer === false && this.state.showTimer === true) {
            this.setState({seconds: new Date().getSeconds(), timerDate: new Date(new Date().getTime() + TEN_MINUTES)});
            this.timerInterval = setInterval(() => {
                if (this.state.seconds === 59) {
                    this.setState({ seconds: 0, timerDate: new Date(new Date().getTime() + TEN_MINUTES + 1000) })
                } else {
                    this.setState({ seconds: this.state.seconds + 1 })
                }
            }, 1000);
        }
    }

    componentWillUnmount(): void {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }

    render() {
        const logHttpRequestsExpiresAfterUtcMsg = this.state.logHttpRequestsExpiresAfterUtc && !this.state.isSaas && `We are logging HTTP Requests until it is turned off.`;
        const logHttpRequestsExpiresAfterUtcMsgSaaS = this.state.logHttpRequestsExpiresAfterUtc && this.state.isSaas && `We are logging HTTP Requests by ${this.state.logHttpRequestsExpiresAfterUtc.toISOString().replace(/T.*/,'')} ${this.state.logHttpRequestsExpiresAfterUtc.toLocaleTimeString()}`;
        return (
            <UI.Page>
                <UI.Tile>
                    { this.state.error && <UI.Message appearance={"error-message"} message={ this.state.error.message} /> }
                    { this.state.isLoading && <UI.Loader visible={ true } appearance={"darkgray"}/> }
                    { this.state.settings && <UI.Form useMaxWidthClass={true}>
                        <UI.FormGroup
                            label={`Enable logs storage`}
                            isRequired={ true }
                            description={`Decide whether to store records of integration activities. Enables troubleshooting and historical review when active.`}
                        >
                            <UI.Checkbox
                                defaultValue={`${ this.state.settings.Enabled }`}
                                onChange={ (newValue: string) => this.changeSetting('Enabled', newValue) }
                                checkedValue={`true`}
                                uncheckedValue={`false`}
                            />
                        </UI.FormGroup>
                        { this.state.settings.Enabled === 'true' && <React.Fragment>
                            <UI.FormGroup
                                label={`Logs retention period`}
                                isRequired={ true }
                                description={`Specify the number of days to retain integration logs. Logs older than the set duration will be automatically deleted.`}
                            >
                                <div className="retention-days-input"><UI.Input
                                    defaultValue={`${ this.state.settings.Retention }`}
                                    type={'number'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => this.changeSetting('Retention', newValue) }
                                /><div>days</div></div>
                                { this.state.settings.Retention && parseInt(this.state.settings.Retention) < 3 && <UI.Message appearance={'warning'}>
                                    Please note that reducing the retention period may impact future troubleshooting efforts.
                                </UI.Message> }
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Logs minimum level`}
                                isRequired={ true }
                                description={`Defines the threshold for the severity level of log messages that should be recorded.`}
                            >
                                <UI.Dropdown
                                    defaultValue={`${ this.state.settings.MinimumLogLevel }`}
                                    options={ getMinimumLogLevelOptions() }
                                    onChange={ (newValue: string) => this.changeSetting('MinimumLogLevel', newValue) }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Log HTTP requests`}
                                isRequired={ false }
                                description={`Activate HTTP request and response logging for enhanced debugging. ${this.state.isSaas ? `WARNING! Logging will remain active for only 10 minutes after saving. ${this.getTextTimer()}` : ''}`}
                            >
                                <UI.Checkbox
                                    defaultValue={`${ this.state.settings.LogHttpRequests }`}
                                    onChange={ (newValue: string) => this.changeSetting('LogHttpRequests', newValue) }
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                />
                                { logHttpRequestsExpiresAfterUtcMsgSaaS && <UI.Message appearance={'warning'} margin='10px 0px 0px 0px'>
                                    {logHttpRequestsExpiresAfterUtcMsgSaaS}
                                </UI.Message> }
                                { logHttpRequestsExpiresAfterUtcMsg && <UI.Message appearance={'warning'} margin='10px 0px 0px 0px'>
                                    {logHttpRequestsExpiresAfterUtcMsg}
                                </UI.Message> }
                            </UI.FormGroup>
                        </React.Fragment> }
                        <UI.FormGroup
                            label={`Items data encryption in logs files`}
                            isRequired={ true }
                            description={`Choose to encrypt sensitive data within logs. Enhances security by ensuring confidential information remains protected.`}
                        >
                            <UI.Checkbox
                                defaultValue={`${ this.state.settings.ItemsDataEncryptionInLogs }`}
                                onChange={ (newValue: string) => this.changeSetting('ItemsDataEncryptionInLogs', newValue) }
                                checkedValue={`true`}
                                uncheckedValue={`false`}
                            />
                        </UI.FormGroup>
                        <UI.ButtonsBar
                            secondary={ [(this.state.saved) ? <UI.Message appearance={"success"}>Settings saved!</UI.Message> : <React.Fragment />] }
                            primary={ <UI.Button
                                disabled={ this.state.isSaving || !this.state.hasChanged }
                                text={`Save`}
                                onClick={ () => this.saveClicked() }
                                isLoading={ this.state.isSaving }
                            /> }
                        />
                    </UI.Form> }
                </UI.Tile>
                <Footer/>
            </UI.Page>
        );
    }

    private changeSetting(key: keyof DataStorageSettings, newValue: string) {
        if (this.state.settings && !this.state.isSaving) {
            const settings = this.state.settings;
            settings[key] = newValue;
            let shouldShowTimer = false;
            if (key === SettingName.LogHttpRequests && newValue === 'true' && !this.state.logHttpRequestsExpiresAfterUtc) {
                shouldShowTimer = true;
            }
            const hasChanged = JSON.stringify(settings) !== JSON.stringify(this.originalSettings);
            this.setState({settings, hasChanged, showTimer: shouldShowTimer});
        }
    }

    private async saveClicked() {
        try {
            await this.setState({saved: false, isSaving: true, error: undefined});
            const fetchedSettings = await saveSettings(SettingsGroupName.DataStorage, this.state.settings!);
            this.originalSettings = copyObject(this.state.settings);
            let logHttpRequestsExpiresAfterUtc;
            if (this.state.settings?.LogHttpRequests === 'true') {
                const filteredLogHttpRequestsExpiresAfterUtc = fetchedSettings.filter(el => el.name === SettingName.LogHttpRequestsExpiresAfterUtc);
                logHttpRequestsExpiresAfterUtc = new Date(filteredLogHttpRequestsExpiresAfterUtc[filteredLogHttpRequestsExpiresAfterUtc.length-1].value);
            }
            await this.setState({hasChanged: false, saved: true, isSaving: false, showTimer: false, logHttpRequestsExpiresAfterUtc});
        } catch (error) {
            this.setState({ error, isSaving: false });
        }
    }

    private getTextTimer() {
        const hoursTimer = this.state.timerDate.getHours() < 10 ? `0${this.state.timerDate.getHours()}` : this.state.timerDate.getHours();
        const minutesTimer = this.state.timerDate.getMinutes() < 10 ? `0${this.state.timerDate.getMinutes()}` : this.state.timerDate.getMinutes();
        const secondsTimer = this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds;
        return `${this.state.showTimer ? `We will log HTTP Requests by ${this.state.timerDate.toISOString().replace(/T.*/,'')} ${hoursTimer}:${minutesTimer}:${secondsTimer}` : ''}`
    }
}

export function DataStoragePage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.SystemSettings);
        setMenu(getSystemVerticalMenu());
    }, []);
    return <DataStoragePageCmp />
}