import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";

import {GitHubStepKey} from "../github-step-key.type";
import {GitHubStatusTransitionEditStep} from "./github-status-transition-edit-step";
import {GitHubStatusTransitionNewStep} from "./github-status-transition-new-step";
import {GitHubStatusTransitionConfiguration} from "./github-status-transition-configuration";

export const GitHubStatusTransitionPipelineStep: PipelineStep<GitHubStatusTransitionConfiguration> = {
    addStepComponentClass: GitHubStatusTransitionNewStep,
    editStepComponentClass: GitHubStatusTransitionEditStep,
    specification: {
        action: "Change Item Status",
        description: "Move GitHub Item from one status to another",
        key: GitHubStepKey.GitHubStatusTransition,
        software: SoftwareName.GitHub,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitHubStatusTransitionConfiguration;
        return `Change item status`;
    }
}