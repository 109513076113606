export enum TenantPropertyName {
    EnabledMigration = 'EnabledMigration',
    MigrationRunsLeft = 'MigrationRunsLeft',
    MaintenanceEndDate = 'MaintenanceEndDate',
    IsOnTrial = 'IsOnTrial',
    ExpiryDate = 'ExpiryDate'
}

export type TenantProperty = {
    propertyName: TenantPropertyName;
    value: string;
};
