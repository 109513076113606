import { UI } from '../../engrator-core';
import React from 'react';
import { SuiteType } from '../../generic/integrations/suite-type';

type Props = {
    id: number;
    runId?: number;
    name: string;
    suiteType: SuiteType;
};

type State = {};

export class IntegrationEditLink extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
    }

    render() {
        if (this.props.suiteType === SuiteType.SmartInt) {
            return <UI.NavLink
                path={`/app/integration/smart-ints/${this.props.id}/edit`}>{this.props.runId ? `#${this.props.runId}` : ''} {this.props.name}</UI.NavLink>;
        }
        return <UI.NavLink path={`/app/integration/pipelines/${this.props.id}/edit`}>{this.props.name}</UI.NavLink>;
    }
}
