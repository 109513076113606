import { GenericSoftwareStep } from './software/step/generic-software-step';
import {StepSpecification} from "./software/step/step-specification";

export class StepsFactory {
    registeredSteps: Array<GenericSoftwareStep> = [];
    

    getRegisteredSteps(): Array<GenericSoftwareStep> {
        return this.registeredSteps;
    };

    registerStep(step: GenericSoftwareStep) {
        this.registeredSteps.push(step);
    };

    getStepsSpecifications(): StepSpecification[] {
        return this.registeredSteps.map(
            step => step.getStepSpecification()
        );
    }

    getStepBySpecification(specification: StepSpecification): GenericSoftwareStep {
        return this.registeredSteps.filter(step =>
            step.getStepSpecification().software === specification.software &&
            step.getStepSpecification().key === specification.key
        )[0];
    }
};