import React from 'react';
import { UI } from 'src/engrator-core';
import { ZendeskSmartIntsTriggerConfiguration } from './zendesk-smart-ints-trigger-configuration';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class ZendeskSmartIntsEditTrigger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const configuration = (this.props.trigger.configuration as ZendeskSmartIntsTriggerConfiguration);
        return <React.Fragment>
            <UI.FormGroup>
                { configuration.organization?.name && <UI.LabelValue
                    label={`Organization`}
                    value={configuration.organization.name}
                /> }
                { !configuration.organization?.name && <UI.LabelValue
                    label={`Organization`}
                    value={`-`}
                /> }
            </UI.FormGroup>
            <UI.FormGroup
                label={`Additional Query`}
                description={`You can provide additional query params which will be appended to the url generated when searching for issues in Zendesk`}
            >
                <UI.Input defaultValue={configuration.query} label={``} onChange={this.onJqlInputChanged.bind(this)}/>
            </UI.FormGroup>
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.props.trigger.configuration.query = newValue;
    }
}
