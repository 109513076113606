import {WrikeSpace} from "../../wrike/steps/common";
import {axiosWrapper, FieldError} from "../../../engrator-core";
import {ClickUpFolderDTO, ClickUpSpaceDTO} from "./clickup-smart-ints-trigger-configuration";

export const ENDPOINT_URL = '/connectors/clickup';

export function fetchSpaces(connectionId: number): Promise<ClickUpSpaceDTO[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/spaces')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchFolders(connectionId: number, spaceId: string): Promise<ClickUpFolderDTO[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/spaces/' + spaceId + '/folders' )
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
