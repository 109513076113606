import React from 'react';
import { UI } from 'src/engrator-core';
import { ArtifactVersion } from './artifact-version.type';

type Props = {
    artifactVersion: ArtifactVersion;
}
type State = {
};

export class SubitemsData extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    render() {
        return <UI.FormGroup>
            { this.props.artifactVersion.subItemsData?.subItems && <table className={`subitems-data`}>
                { this.props.artifactVersion.subItemsData?.subItems.map((subItem, index) => <tr key={index}>
                    <td>{ subItem.subItemType }</td>
                    <td>{ subItem.leftId }</td>
                    <td>{ subItem.rightId }</td>
                </tr> )}
            </table> }
        </UI.FormGroup>
    }
}
