import React, { Component } from 'react';

import { UI } from 'src/engrator-core';
import { WebhookHeaders } from '../notification-alert.type';
import { Icon20 } from 'src/engrator-core/ui';

type Props = {
    allHeaders: WebhookHeaders[];
    saveAllHeadersHandler: (allHeaders: WebhookHeaders[]) => void;
};

type State = {
    header: {
        name: string;
        value: string;
    };
    allHeaders: WebhookHeaders[];
};

export class NotificationsWebhooksHeader extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            header: {
                name: '',
                value: ''
            },
            allHeaders: this.props.allHeaders
        };
    }

    render() {
        return (
            <>
                {this.state.allHeaders.length > 0 && (
                    <>
                    <UI.LabelValue label={'Headers'} value={''}/>
                    <ul className={`headers-list`}>
                        {this.state.allHeaders.map((header, index) => (
                            <li onClick={() => this.removeHeader(header.id)} key={index}>
                                {header.name}: {header.value} <UI.Icon icon20={Icon20.Trash} />
                            </li>
                        ))}
                    </ul>
                    </>
                )}
                <div className={`flex row two-columns add-header`}>
                    <div>
                        <UI.Input
                            onChange={value => this.setName(value)}
                            label={`Header name`}
                            placeholder={`Header name`}
                            defaultValue={this.state.header.name}
                        />
                    </div>
                    <div>
                        <UI.Input
                            onChange={value => this.setValue(value)}
                            label={`Header value`}
                            placeholder={`Header value`}
                            defaultValue={this.state.header.value}
                        />
                    </div>
                    <div className={`btn`}>
                        <UI.Button
                            disabled={!this.state.header.name || !this.state.header.value}
                            onClick={() => this.addClicked()}
                            text={`Add`}
                        />
                    </div>
                </div>
            </>
        );
    }

    private setName(value: string) {
        const header = this.state.header;
        header.name = value;
        this.setState({ header });
    }

    private setValue(value: string) {
        const header = this.state.header;
        header.value = value;
        this.setState({ header });
    }

    private addClicked() {
        this.addHeader(this.state.header.name, this.state.header.value);
        const header = this.state.header;
        header.name = '';
        header.value = '';
        this.setState({ header });
    }

    private addHeader(name: string, value: string) {
        const allHeaders = this.state.allHeaders
        const lastId = this.state.allHeaders.length > 0 ? this.state.allHeaders[this.state.allHeaders.length-1].id + 1 : 0;
        allHeaders.push({ name, value, id: lastId });
        this.setState({ allHeaders }, () => {
            this.props.saveAllHeadersHandler(allHeaders);
        })
    }

    private removeHeader(id: number) {
        const allHeaders = this.state.allHeaders.filter(el => el.id !== id);
        this.setState({ allHeaders }, () => {
            this.props.saveAllHeadersHandler(allHeaders);
        });
    }
}
