import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { FreshserviceSmartIntsTriggerConfiguration } from './freshservice-smart-ints-trigger-configuration';

type Props = {
    connectionId: number;
    trigger: SmartIntTrigger;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class FreshserviceSmartIntsEditTrigger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const configuration = (this.props.trigger.configuration as FreshserviceSmartIntsTriggerConfiguration);
        return <React.Fragment>
            { configuration.workspace && <UI.LabelValue label={`Workspace`} value={ configuration.workspace.name } /> }
            <UI.FormGroup
                label={`Additional query`}
                description={``}
            >
                <UI.Input defaultValue={configuration.query} label={``} onChange={this.onJqlInputChanged.bind(this)}/>
            </UI.FormGroup>
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.props.trigger.configuration.query = newValue;
    }
}
