import React from "react";
import {PipelineStepRuleRenderer} from "./pipeline-step-rule-renderer";
import {EntityFormHelper, UI} from "src/engrator-core";
import {Condition} from "../../conditions";
import {PipelineStepRuleName, PipelineStepRulesWithDescription} from "./pipeline-step-rule-name";
import { DropdownOption } from "src/engrator-core/ui/dropdown";
import {PipelineStepRule} from "./pipeline-step-rule.type";

type State = {
    showNewRuleForm: boolean;
    isAddButtonEnabled: boolean;
    showRulesConfiguration: boolean;
};
type Props = {
    onRulesChanged: (rules: PipelineStepRule[]) => void;
};

export class PipelineStepRules extends React.Component<Props, State> {
    private entityHelper: EntityFormHelper;
    private ruleOptions: DropdownOption[];
    private rules: PipelineStepRule[] = [];

    private newRuleData: {
        rule?: {
            name: PipelineStepRuleName,
            description: string;
        },
        condition?: string;
    } = {};

    constructor(props: Props) {
        super(props);
        this.state = { showNewRuleForm: false, isAddButtonEnabled: false, showRulesConfiguration: false };
        this.entityHelper = this.getEntityFormHelper();
        this.ruleOptions = PipelineStepRulesWithDescription.map(item => ({
            label: item.description,
            value: item.rule,
            object: item
        }));
    }

    render() {
        return <React.Fragment>
            <UI.Checkbox
                label={`Step Rules`}
                checkedValue={`true`}
                uncheckedValue={`false`}
                onChange={ this.switchConditionFieldsVisibility.bind(this) }
            />
            { this.state.showRulesConfiguration && 
            <UI.FormGroup
                label={``}
                description={`You can specify step rules which will be evaluated when the current step is executed`}
            >
                { this.rules.map((rule, index) =>
                    <PipelineStepRuleRenderer key={ index } rule={ rule }/> )
                }
                { !this.state.showNewRuleForm && <UI.ButtonsBar
                primary={ <UI.Button
                    appearance={"secondary"}
                    onClick={ this.switchShowNewRuleForm.bind(this) } text={`Add Rule`} /> }
                /> }
                { this.state.showNewRuleForm && <React.Fragment>
                        <UI.FormGroup
                            label={`Rule to evaluate`}
                            isRequired={true}
                            description={`Select rule that you want to evaluate`}
                        >
                            <UI.Dropdown options={ this.ruleOptions } onChange={ this.entityHelper.onChangeHandler('rule')} />
                        </UI.FormGroup>
                        { this.newRuleData.rule && <Condition
                            isRequired={true}
                            onConditionChanged={ this.entityHelper.onChangeHandler('condition') } />
                        }
                        <UI.ButtonsBar primary={
                            <UI.Button
                                appearance={"elementary"}
                                onClick={ this.addRule.bind(this) }
                                disabled={ !this.state.isAddButtonEnabled }
                                text={`Add`} />
                        } secondary={ [
                            <UI.Button
                                appearance={"secondary"}
                                onClick={ this.switchShowNewRuleForm.bind(this) } text={`Cancel`} />
                        ]}/>
                </React.Fragment> }
            </UI.FormGroup>  }
        </React.Fragment>;
    }

    private switchShowNewRuleForm() {
        this.newRuleData = {};
        this.setState({
            showNewRuleForm: !this.state.showNewRuleForm,
            isAddButtonEnabled: false
        });
    }

    private addRule() {
        if (!this.newRuleData.condition || !this.newRuleData.rule) {
            return;
        }
        this.rules.push({
            condition: this.newRuleData.condition!,
            rule: this.newRuleData.rule!
        });
        this.newRuleData = {};
        this.setState({ showNewRuleForm: false, isAddButtonEnabled: false });
        this.props.onRulesChanged(this.rules);
    }

    private getEntityFormHelper() {
        return new EntityFormHelper((propertyName: 'rule' | 'condition', newValue, additionalArgument?: any) => {
            if (propertyName === 'rule') {
                this.newRuleData.rule = additionalArgument;
                this.newRuleData.condition = '';
            } else if (propertyName === 'condition') {
                this.newRuleData.condition = newValue;
            }
            this.setState({isAddButtonEnabled: !!this.newRuleData.rule && !!this.newRuleData.condition});
        });
    }

    private switchConditionFieldsVisibility() {
        const showRulesConfiguration = !this.state.showRulesConfiguration;
        this.setState({ showRulesConfiguration });
    }
}