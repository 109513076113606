import React from "react";
import {EntityFormHelper, UI} from "src/engrator-core";
import {DropdownOption} from "../../../engrator-core/ui";
import {StepArtifact} from "../../../generic/software/step";
import {FormError} from "../../../engrator-core/form-error.type";
import {CreateCommentsAction} from "./create-comments-action.type";
import {CreateCommentsConfiguration} from "./create-comments-configuration.type";

type Props = {
    configuration: CreateCommentsConfiguration;
    formError?: FormError;
    onConfigurationChanged: (newConfiguration: CreateCommentsConfiguration) => void;
};
type State = {};


export class EditCommentsStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: CreateCommentsConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = Object.assign({}, this.props.configuration);
        this.entityFormHelper = this.getEntityFormHelper();
    }

    render() {
        return <React.Fragment>
                <UI.LabelValue
                    label={`Select artifact for which you want to create comment(s)`}
                    value={ this.configuration.targetArtifact }
                />
            <UI.LabelValue
                label={`How to create comment(s)`}
                value={ this.configuration.action! }
            />
                { this.configuration.action === CreateCommentsAction.Predefined && <UI.FormGroup
                        description={``}
                        isRequired={ true }
                        error={ this.props.formError?.content }
                        label={`Provide comment content`}
                    >
                        <UI.Input defaultValue={ this.configuration.predefinedBody } onChange={ this.entityFormHelper.onChangeHandler('predefinedBody')} />
                    </UI.FormGroup>
                }
        </React.Fragment>;
    }

    private getEntityFormHelper(): EntityFormHelper {
        return new EntityFormHelper((propertyName: 'targetArtifact' | 'action' | 'predefinedBody', newValue) => {
            this.configuration[propertyName] = newValue;
            if (propertyName === 'targetArtifact' || propertyName === "action") {
                this.configuration['predefinedBody'] = '';
            }
            if (propertyName === 'targetArtifact') {
                delete this.configuration['action'];
            }
            this.props.onConfigurationChanged(this.configuration);
            this.forceUpdate();
        });
    }
}