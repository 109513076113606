import React from 'react';
import {JiraProjectIssuetypePicker, JiraProjectIssuetypePickerData} from "../steps/common";
import {SmartIntTrigger} from "../../../app/integration/smartints/definition/smart-int-trigger.type";
import {UI} from 'src/engrator-core';
import { SoftwareName } from '../../software-name';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class JiraSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.Jira,
            artifactName: '',
            className: "",
            configuration: {
                project: undefined,
                jql: ''
            },
            connectionId: this.props.connectionId
        };
    }

    render() {
        return <React.Fragment>
            { !this.smartIntTrigger.configuration.project && <JiraProjectIssuetypePicker
                connectionId={this.props.connectionId}
                onDataChanged={this.onDataChanged.bind(this)}
                configureIssueType={false}
            /> }
            { this.smartIntTrigger.configuration.project && <UI.FormGroup>
                <UI.LabelValue
                    label={`Project`}
                    value={this.smartIntTrigger.configuration.project.name}
                />
            </UI.FormGroup>
            }
            { /* candidate for removal, we are handling rendered (html) values in other more transparent way*/ }
            {/*<UI.FormGroup>*/}
            {/*    <UI.Checkbox*/}
            {/*        label={`Fetch rendered fields`}*/}
            {/*        checkedValue={`true`}*/}
            {/*        uncheckedValue={`false`}*/}
            {/*        defaultValue={`${this.smartIntTrigger.configuration.fetchRenderedFields}`}*/}
            {/*        onChange={ this.switchFetchRenderedFields.bind(this) }*/}
            {/*    />*/}
            {/*</UI.FormGroup>*/}
            {/*<UI.FormGroup */}
            {/*    label={`Custom JQL`}*/}
            {/*    description={`You can provide JQL which will be appended to the one generated when searching for issues in Jira (eg: labels = sync)`}*/}
            {/*>*/}
            {/*    <UI.Input label={``} placeholder={`eg: labels = sync`} onChange={ this.onJqlInputChanged.bind(this)} />*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>
    }

    private switchFetchRenderedFields(newValue: string) {
        this.smartIntTrigger.configuration.fetchRenderedFields = (newValue === 'true') ? true : false;
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.jql = newValue;
    }

    private onDataChanged(data: JiraProjectIssuetypePickerData) {
        if (data.project) {
            this.smartIntTrigger.artifactName = data.project.name;
            this.smartIntTrigger.configuration.project = data.project;
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }
}
