import { axiosWrapper } from 'src/engrator-core';

const ENDPOINT_URL = '/system/general';

export type SystemCustomProperty = {
    name: string;
    value: string;
}

export function fetchCustomProperties(): Promise<SystemCustomProperty[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/custom-properties`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function saveSystemCustomProperties(properties: SystemCustomProperty[]): Promise<SystemCustomProperty[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}/custom-properties`, { properties })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}


