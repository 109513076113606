import React from "react";

import {UI} from "src/engrator-core";
import {StatusRule} from "./status-rule.type";

type Props = {
    rule: StatusRule;
};

type State = {
};

export class StatusRuleRenderer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <UI.LabelValue label={`Status: `} value={ this.props.rule.status.name } />
            <UI.LabelValue label={`Condition: `} value={ this.props.rule.condition } />
        </React.Fragment>;
    }
}