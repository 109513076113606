import { UpdateStrategySettings } from './update-strategy-settings.type';
import { MigrationConfiguration } from '../../smartints/migration/migration-configuration.type';

export type PipelineSettings = {
    status: string,
    groupName: string,
    workingWeekends: boolean,
    workingHourFrom?: number,
    workingHourTo?: number,
    intervalInSeconds: number;
    threadId: number,
    groupId?: number
    updateStrategySettings: UpdateStrategySettings;
    migrationConfiguration?: MigrationConfiguration;
    additionalSettings?: {
        propertiesGuards?: PropertiesGuards,
        multiFieldsUsage: boolean,
    };
};

export type PropertiesGuards = {
    enabled: boolean;
    left: string;
    right: string;
};

export function createDefaultPropertiesGuards(): PropertiesGuards {
    return {
        enabled: false,
        left: '',
        right: ''
    };
}
