import React from 'react';

import {isJiraServerNativeApp, UI} from 'src/engrator-core';
import { ImportPopup } from './import';
import { ReportName } from './report-name.type';
import {SupportPackagePopup} from "./support";
import {BulkResync} from "./bulk-resync/bulk-resync";

type Props = {
    currentReport: ReportName;
    filtersData: any;
    onFiltersClickHandler: (show: boolean) => void;
};

type State = {
    isFiltersBarVisible: boolean;
    showImport: boolean;
    showGenerateSupportPackage: boolean;
}

export class ReportsTabBar extends React.Component<Props,  State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            isFiltersBarVisible: false,
            showImport: false,
            showGenerateSupportPackage: false
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(this.props.filtersData) !== JSON.stringify(prevProps.filtersData)) {
            this.forceUpdate();
        }
    }

    render() {
        const filterButtonAppearance = (this.state.isFiltersBarVisible) ? 'elementary' : 'secondary';
        return <React.Fragment>
            <div className="run-results-tab-bar">
                <UI.TabBar>
                    {/*<UI.TabButton text="Performed Syncs" path={`/app/reporting?report=${ReportName.PerformedFlows}`}*/}
                    {/*              isSelected={this.props.currentReport === ReportName.PerformedFlows}/>*/}
                    {/*<UI.TabButton text="Runs" path={`/app/reporting?report=${ReportName.PipelinesRuns}`}*/}
                    {/*              isSelected={this.props.currentReport === ReportName.PipelinesRuns}/>*/}
                    {/*<UI.TabButton text="Steps Executions" path={`/app/reporting?report=${ReportName.StepsExecutions}`}*/}
                    {/*              isSelected={this.props.currentReport === ReportName.StepsExecutions}/>*/}
                    {/*<UI.TabButton text="Synced Items" path={`/app/reporting?report=${ReportName.PersistedArtifacts}`}*/}
                    {/*              isSelected={this.props.currentReport === ReportName.PersistedArtifacts}/>*/}
                    {/*<UI.TabButton text="Synced Items" path={`/app/reporting?report=${ReportName.SyncedArtifacts}`} isSelected={ this.props.currentReport === ReportName.SyncedArtifacts } />*/}
                </UI.TabBar>
                { this.props.currentReport === ReportName.SyncedArtifacts && <React.Fragment>
                    <UI.Button
                        appearance={ filterButtonAppearance }
                        className={`import-btn`}
                        onClick={ () => this.showImportPopUp(true) }
                        text={ `Import` }
                    />
                    <UI.Button
                        appearance={ filterButtonAppearance }
                        className={`import-btn`}
                        onClick={ () => this.showGenerateSupportPackage(true) }
                        text={ `Export support package` }
                    />
                </React.Fragment> }
                { this.state.showImport && <ImportPopup
                    close={ () => this.showImportPopUp(false) }
                /> }
                { this.state.showGenerateSupportPackage && <SupportPackagePopup
                    close={ () => this.showGenerateSupportPackage(false) }
                /> }
                { this.props.currentReport === ReportName.PerformedFlows && <React.Fragment>
                    { !isJiraServerNativeApp && <BulkResync
                        filtersData={ this.props.filtersData }
                    /> }
                </React.Fragment> }
                { !isJiraServerNativeApp && <UI.Button
                    appearance={ filterButtonAppearance }
                    icon={ <UI.Icon icon={"filter"} /> }
                    onClick={ this.switchFilters.bind(this) }
                    text={ `` }
                /> }
            </div>
        </React.Fragment>
    }

    private switchFilters() {
        this.setState({ isFiltersBarVisible: !this.state.isFiltersBarVisible }, () => {
            this.props.onFiltersClickHandler( this.state.isFiltersBarVisible );
        });
    }

    private showGenerateSupportPackage(showGenerateSupportPackage: boolean) {
        this.setState({ showGenerateSupportPackage });
    }

    private showImportPopUp(showImport: boolean) {
        this.setState({ showImport });
    }
}
