import React from 'react';
import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';

import { SmartIntTrigger } from 'src/app/integration/smartints/definition/smart-int-trigger.type';
import { DropdownOption } from 'src/engrator-core/ui';
import { AzureSmartIntsEditTrigger } from './azure-smart-ints-edit-trigger';
import { AzureSmartIntsTrigger } from './azure-smart-ints-trigger';
import {fetchAzureSubtasksRelationshipOptions} from "./rest-api";

export class AzureSmartintsSupport extends AppSmartIntsSupport {
    getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <AzureSmartIntsTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
        />;
    }

    getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <AzureSmartIntsEditTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
            trigger={trigger}
        />;
    }

    hasTriggerConfigurationComponent(): boolean {
        return true;
    }

    isSupportingHierarchyEpics() {
        return true;
    }

    getEpicRelationshipsOptions(): Promise<DropdownOption[]> {
        return Promise.resolve([
            {value: 'Parent/Child', label: 'Create Parent/Child link'},
        ]);
    }

    getSubtasksRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
        return fetchAzureSubtasksRelationshipOptions(trigger);
    }
}
