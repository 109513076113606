import React from 'react';
import { AzureProjectItemTypePickerData } from './azure-project-item-type-picker';
import { UI } from '../../../../engrator-core';

type Props = {
    context: AzureProjectItemTypePickerData;
}

type State = {}

export class AzureProjectItemTypeDisplay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            { this.props.context.project && <UI.LabelValue label={ `Project`} value={ this.props.context.project!.name } /> }
        </React.Fragment>;
    }
}
