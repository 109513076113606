import { ArtifactDirection } from "src/generic/artifacts";
import { ConfiguredProperty } from "src/generic/artifacts/data-artifact";
import {SoftwareName} from "../../../software";

export type PersistedStepArtifact = {
    name: string,
    direction: ArtifactDirection
    properties: Array<ConfiguredProperty>,
};

export class StepArtifact {
    name: string;
    private softwareName: SoftwareName;
    properties: ConfiguredProperty[];
    direction: ArtifactDirection;
    private stepConfigurationData: any;
    private connectionId: number;

    constructor(name: string, direction: ArtifactDirection, properties: Array<ConfiguredProperty>, softwareName: SoftwareName, stepConfigurationData: any, connectionId: number) {
        this.softwareName = softwareName;
        this.name = name;
        this.properties = properties;
        this.direction = direction;
        this.stepConfigurationData = stepConfigurationData;
        this.connectionId = connectionId;
    }

    getName() {
        return this.name
    }

    getDirection() {
        return this.direction;
    }

    getStepConfigurationData(): any {
        return this.stepConfigurationData;
    }

    getSoftwareName(): SoftwareName {
        return this.softwareName;
    }

    getConnectionId() : number {
        return this.connectionId;
    }

    persist(): PersistedStepArtifact {
        return {
            name: this.name,
            direction: this.direction,
            properties: this.properties
        }
    }
}