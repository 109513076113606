import {axiosWrapper, FieldError} from "src/engrator-core";
import {ZendeskOrganizationDTO} from "./common/zendesk-organization-dto.type";

export const ENDPOINT_URL = '/connectors/zendesk';

export const fetchOrganizations = (connectionId: number): Promise<ZendeskOrganizationDTO[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/organizations')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};