import React from "react";
import {UI} from "../../../engrator-core";

type Props = {
    showOwnerPrefix?: boolean;
    ownerId?: number;
    maxLength?: number;
    owner?: {
        identifier: string;
        name?: string;
    };
};
type State = {
};

export class OwnerTag extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const prefix = (this.props.showOwnerPrefix === true) ? 'Owner: ' : '';
        if (this.props.owner) {
            return <UI.Tag transparentBg={ true } name={`${ prefix }${ this.getIdentifier(this.props.owner.name || this.props.owner.identifier) }`} />
        }
        if (this.props.ownerId) {
            return <UI.Tag transparentBg={ true } name={`${ prefix }#${ this.getIdentifier(this.props.ownerId + '') }`} />
        }
        return <UI.Tag transparentBg={ true } name={`${ prefix }global`} />;
    }

    getIdentifier(value: string): string {
        if (this.props.maxLength && value.length > this.props.maxLength) {
            return value.substr(0, this.props.maxLength) + '...';
        }
        return value;
    }
}