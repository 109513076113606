import { GenericSoftwareStep } from "./generic-software-step";
import {StepSpecification} from "./step-specification";
import {PipelineGenericStep} from "../../pipeline-step";

export class StepPicker {
    onSoftwareAndActionSelectedCallback: any;
    stepFactory: any;
    selectedSoftware: any;

    constructor(stepFactory: any, onSoftwareAndActionSelectedCallback: any) {
        this.stepFactory = stepFactory;
        this.onSoftwareAndActionSelectedCallback = onSoftwareAndActionSelectedCallback;
    }

    getSoftwareList() {
        const listWithDuplicates = this.stepFactory.getRegisteredSteps()
            .map((step: GenericSoftwareStep) => step.getStepSpecification())
            .map((specification: StepSpecification) => specification.software);
        const softwareList: any = [];
        listWithDuplicates.forEach((softwareName: any) => {
            if (softwareList.indexOf(softwareName) === -1) {
                softwareList.push(softwareName);
            }
        })
        return softwareList;
    };

    getActionsForSoftware(stepsFilter: any) {
        const filterToUse = (stepsFilter !== undefined) ? stepsFilter : () => { return true};
        const steps: Array<GenericSoftwareStep> = this.stepFactory.getRegisteredSteps();
        console.log(this.stepFactory.getRegisteredSteps());
        return steps
            .filter(step => step.getStepSpecification().software === this.selectedSoftware)
            .filter(filterToUse)
            .map(step => ({
                action: step.getStepSpecification().action,
                key: step.getStepSpecification().key,
                description: step.getStepSpecification().description,
                requiresConnectionPicker: (step as PipelineGenericStep<any>).isRequiringConnectionId()
            }));
    };

    selectSoftware(selectedSoftware: any) {
        this.selectedSoftware = selectedSoftware;
    };

    selectAction(selectedKey: any) {
        if (this.selectedSoftware === undefined) {
            throw 'Can not select action while software is not selected';
        }
        const targetStep = this.stepFactory.getRegisteredSteps()
            .filter((step: GenericSoftwareStep) => step.getStepSpecification().software === this.selectedSoftware
            && step.getStepSpecification().key === selectedKey);
        if (targetStep.length === 0) {
            throw 'Step matching selected criteria was not found (' + this.selectedSoftware + ',' + selectedKey + ')';
        }
        this.onSoftwareAndActionSelectedCallback(
            targetStep[0]
        );
    };
};