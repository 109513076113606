import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {AzureStepKey} from "../azure-step-key.type";
import {AzureCreateItemConfiguration} from "./azure-create-item-configuration";
import {AzureCreateItemEditStep} from "./azure-create-item-edit-step";
import {AzureCreateItemNewStep} from "./azure-create-item-new-step";

export const AzureCreateItemPipelineStep: PipelineStep<AzureCreateItemConfiguration> = {
    addStepComponentClass: AzureCreateItemNewStep,
    editStepComponentClass: AzureCreateItemEditStep,
    specification: {
        action: "Create Azure Item",
        description: "Create Azure item of specific type",
        key: AzureStepKey.AzureCreateItem,
        software: SoftwareName.Azure,
        stepType: StepType.Action
    },
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AzureCreateItemConfiguration;
        return `Create ${stepConfiguration.itemType.name} item in ${stepConfiguration.project.name}`;
    }
}