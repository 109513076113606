import React from 'react';
// import clickUpLogo from 'src/software/clickup/clickup-logo.png';
import asanaLogo from 'src/software/asana/asana-logo.png';
import azureLogo from 'src/software/azure/azure-logo.png';
import gitHubLogo from 'src/software/github/github-logo.png';
import gitlabLogo from 'src/software/gitlab/gitlab-logo.png';
import intHubLogo from 'src/software/inthub/inthub-logo.png';

import jiraMark from 'src/software/jira/jira-logo.png';
import mondayLogo from 'src/software/monday/monday-logo.png';
import servicenowLogo from 'src/software/servicenow/servicenow-logo.png';
import trelloLogo from 'src/software/apps-icons/trello-logo.png';
import wrikeLogo from 'src/software/wrike/wrike-logo.png';
import zendeskLogo from 'src/software/zendesk/zendesk-logo.png';
import freshdeskLogo from 'src/software/freshdesk/freshdesk-logo.png';
import archiverLogo from 'src/software/archiver/archiver-logo.png';
import {getSoftwareName, SoftwareName} from '../../software';
import {getAppIcon} from "../../software/apps-icons";

type Props = {
    softwareName: string;
    showName?: boolean;
};

const HEIGHT = 50;
export class SoftwareLogotype extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <div className={`app-logotype`}>
            <img src={ getAppIcon(this.props.softwareName as SoftwareName) } height={ HEIGHT } />
            {(this.props.showName === undefined || this.props.showName === true) && <span>{ getSoftwareName(this.props.softwareName)}</span>}
        </div>
    }
}
