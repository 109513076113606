import React from 'react';

import './add-step-button.css';

type AddStepButtonProps = {
    onClickHandler: (() => void);
    stepsCount: number;
};

export class AddStepButton extends React.Component<AddStepButtonProps> {
    constructor(props: AddStepButtonProps) {
        super(props);
    }

    componentDidUpdate(prevProps: Readonly<AddStepButtonProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.stepsCount !== this.props.stepsCount) {
            this.forceUpdate();
        }
    }

    render() {
        return <div className="AddStepButton" onClick={ this.props.onClickHandler }>
            <p>+</p>
            { this.props.stepsCount === 0 && <span>Add Trigger</span> }
            { this.props.stepsCount > 0 && <span>Add Action</span> }
        </div>
    }
}