import React from 'react';

import { Connection, ConnectionFactory } from 'src/connections';
import { IntHubConnection } from './int-hub-connection';
import {fetchConnectionsForSoftware} from "src/software/generic/connections-rest-api";
import {SOFTWARE_NAME} from "../index";


export class IntHubConnectionFactory implements ConnectionFactory {
    getConnectionComponent(onConnectionAddedHandler: (() => void)): any {
        return <IntHubConnection onConnectionAddedHandler={ onConnectionAddedHandler } />;
    }

    fetchConnections(): Promise<Connection[]> {
        return fetchConnectionsForSoftware(SOFTWARE_NAME);
    }
}