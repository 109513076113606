import { PipelineStep } from 'src/generic';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';
import { GetintArchiverStepKey } from '../archiver-step-key.type';
import { GetintArchiverFindItemConfiguration } from './monday-find-item-configuration';
import { GetintArchiverFindItemEditStep } from './monday-find-item-edit-step';
import { GetintArchiverFindItemNewStep } from './monday-find-item-new-step';


export const GetintArchiverFindItemPipelineStep: PipelineStep<GetintArchiverFindItemConfiguration> = {
    addStepComponentClass: GetintArchiverFindItemNewStep,
    editStepComponentClass: GetintArchiverFindItemEditStep,
    specification: {
        action: 'Find issue',
        description: 'Find issue with specific id',
        key: GetintArchiverStepKey.GetintArchiverFindItem,
        software: SoftwareName.GetintArchiver,
        stepType: StepType.Action,
    },
    preferredNameForArtifact: `GetintArchiverItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GetintArchiverFindItemConfiguration;
        return `Find issue with id='${stepConfiguration.id}'`;
    },
};
