import { axiosWrapper } from 'src/engrator-core';

const ENDPOINT_URL = '/system/general';

export enum StepName {
    Downloading = 'Downloading',
    Idle = 'Idle',
    StoppingThreads = 'StoppingThreads',
    PreparingToRebooting = 'PreparingToRebooting',
    Rebooting = 'Rebooting'
}

export type UpgradeProgress = {
    currentStep: StepName;
    packageUrl: string;
    doneSteps: StepName[];
}

export type SystemCustomProperty = {
    name: string;
    value: string;
}

export function upgradeVersion(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}/upgrade`, { packageUrl: url })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function upgradeFromFile(file: any): Promise<void> {
    const data = new FormData()
    data.append('file', file);
    return new Promise((resolve, reject) => {
        axiosWrapper.post(ENDPOINT_URL + '/upgrade-with-file', data)
            .then( () => resolve() )
            .catch( (err) => reject(err) );
    });
}

export function getUpgradeProgress(): Promise<UpgradeProgress> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/upgrade`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function stopUpgrade(): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.delete(`${ENDPOINT_URL}/upgrade`)
                .then(() => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchCustomProperties(): Promise<SystemCustomProperty[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/custom-properties`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function saveSystemCustomProperties(properties: SystemCustomProperty[]): Promise<SystemCustomProperty[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}/custom-properties`, { properties })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}


