import React, {Component, useEffect} from 'react';
import {FormError} from '../../../engrator-core/form-error.type';
import {useGlobalState} from "../../global-store";
import {MainMenuItem} from "../../global-store/create-global-state";
import {FieldError, UI} from 'src/engrator-core';
import {SystemUsersCreate} from "../../system/users/system-users-create";
import {fetchUsers, GetintNewUserEnvironment, GetintShortUser} from "../../system/users/users-management-rest-api";
import {Icon20} from "../../../engrator-core/ui";
import {ClusterUsersContextMenu} from "./cluster-users-context-menu";
import {ClusterUsersEdit} from "./cluster-users-edit";
import {UserRoleLabel} from "../../system/users/user-role-label";
import {ClusterUsersChangePassword} from "./cluster-users-change-password";
import { FetchData } from 'src/app/integration/smartints/pages/list/rest-api';

type State = {
    users: FetchData;
    isLoading: boolean;
    error?: any;
    showCreateWindow: boolean;
    userToEdit?: number;
    userToChangePassword?: number;
    shouldReload: boolean;
};

class ClusterUsersPageCmp extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: true,
            showCreateWindow: false,
            users: {
                headers: [],
                data: []
            },
            shouldReload: false
        };
    }

    componentDidMount() {
        this.loadDataSource();
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevState.shouldReload !== this.state.shouldReload) {
            this.loadDataSource();
        }
    }

    render() {
        return (
            <UI.Page>
                <UI.ButtonsBar
                    primary={<UI.Button
                        icon={ <UI.Icon icon20={ Icon20.CirclePlusWhite } /> }
                        className={`selenium-create-user`}
                        onClick={() => this.createClicked() }
                        text={`Create User`}
                    />}
                /><br/>
                { this.state.showCreateWindow && <SystemUsersCreate
                    closeHandler={ () => this.closeCreateWindow() }
                    mode={GetintNewUserEnvironment.Cluster}
                    refreshList={ () => this.refreshList() }
                /> }
                { !this.state.isLoading || this.state.error ? <UI.Table
                        error={ this.state.error }
                        dataSource={ this.state.users }
                        rowDecorator={ (item: GetintShortUser) => [
                            item.id,
                            item.username,
                            item.email,
                            <UserRoleLabel role={ item.role } />,
                            item.workspacesCount,
                            <UI.EntityStatus status={ item.status } />,
                            <ClusterUsersContextMenu
                                onSelected={ (menuItem) => {
                                    if (menuItem.id === 'Edit') {
                                        this.setState({userToEdit: item.id})
                                    } else if (menuItem.id === 'ChangePassword') {
                                        this.setState({userToChangePassword: item.id})
                                    }
                                }
                                } />
                            ] } /> : <UI.CenteredLoader text={`Loading...`} />
                }
                { this.state.userToEdit && <ClusterUsersEdit
                    closeHandler={ () => this.setState({ userToEdit: undefined })}
                    userId={ this.state.userToEdit }
                    refreshList={ () => this.refreshList() }
                /> }
                { this.state.userToChangePassword && <ClusterUsersChangePassword
                    closeHandler={ () => this.setState({ userToChangePassword: undefined })}
                    userId={ this.state.userToChangePassword }
                /> }
            </UI.Page>
        );
    }

    private async loadDataSource() {
        if(!this.state.isLoading) {
            this.setState({ isLoading: true })
        }
        try {
            const users = await fetchUsers();
            this.setState({ users, isLoading: false });
        } catch (error) {
            this.setState({ isLoading: false, error: error as FieldError });
        }
    }

    private async closeCreateWindow(): Promise<boolean> {
        this.setState({ showCreateWindow: false });
        return false;
    }

    private createClicked() {
        this.setState({ showCreateWindow: true });
    }

    private refreshList() {
        this.setState({ shouldReload: !this.state.shouldReload });
    }
}

export function ClusterUsersPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.Users);
        setMenu(null);
    }, []);
    return <ClusterUsersPageCmp/>
}