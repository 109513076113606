import React from 'react';

import {SmartIntTrigger} from "src/app/integration/smartints/definition/smart-int-trigger.type";
import {AppSmartIntsSupport} from "src/app/integration/smartints/app-smart-ints-support";
import { GitLabSartIntsTrigger } from './gitlab-smart-ints-trigger';
import { GitLabSmartIntsEditTrigger } from './gitlab-smart-ints-edit-trigger';

export class GitLabSmartIntsSupport extends AppSmartIntsSupport {
    getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <GitLabSartIntsTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
        />;
    }

    getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <GitLabSmartIntsEditTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
            trigger={ trigger }
        />;
    }

    hasTriggerConfigurationComponent(): boolean {
        return true;
    }
}
