import React from 'react';

type Props = {
    dateTime: string | undefined;
};

export class TimeDisplay extends React.Component<Props> {
    render() {
        if (!this.props.dateTime) {
            return <React.Fragment />
        }
        const dateTimeToDisplay = (this.props.dateTime.indexOf('.') >= 0) 
            ? this.props.dateTime
                .substr(0, this.props.dateTime.indexOf('.'))
                .replace('T', ' ')
            : this.props.dateTime;
        return <React.Fragment>
            <span>{ dateTimeToDisplay }</span>
        </React.Fragment>
    }
};
