import React, { Component } from 'react';

import { UI } from '../../../../engrator-core';
import { PropertiesGuards } from './pipeline-settings-type';

type State = {
    showProperties: boolean;
};
type Props = {
    propertiesGuards: PropertiesGuards;
};

export class ResyncSettings extends Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = { showProperties: this.props.propertiesGuards.enabled };
    }

    render() {
        return <React.Fragment>
            <UI.Checkbox
                checkedValue={`true`}
                uncheckedValue={`false`}
                defaultValue={`${this.props.propertiesGuards.enabled}`}
                label={`Enable resync`}
                onChange={() => this.switchPropertiesVisibility()}
            />
            {this.state.showProperties && <React.Fragment>
                <UI.FormGroup
                    description={`Left app properties to guard (comma separated)`}
                >
                    <UI.Input
                        defaultValue={this.props.propertiesGuards.left}
                        onChange={(value) => this.setProperties('left', value)}
                    />
                </UI.FormGroup>
                <UI.FormGroup
                    description={`Right app properties to guard (comma separated)`}
                >
                    <UI.Input
                        defaultValue={this.props.propertiesGuards.right}
                        onChange={(value) => this.setProperties('right', value)}
                    />
                </UI.FormGroup>
            </React.Fragment>}
        </React.Fragment>
    }

    private setProperties(side: 'left' | 'right', value: string) {
        this.props.propertiesGuards[side] = value;
    }

    private switchPropertiesVisibility() {
        this.props.propertiesGuards.enabled = !this.state.showProperties;
        this.setState({ showProperties: !this.state.showProperties });
    }
}
