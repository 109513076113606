import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { Software } from 'src/generic';
import { createGenericConnectionFactory } from 'src/generic/connections';
import { AuthMethod } from 'src/generic/connections/auth-method.type';
import { SoftwareName } from '../software-name';

// import {SalesforceSmartintsSupport} from "./smart-ints";
import {OAuthData, OAuthSupport} from "../../generic/connections/oauth/oauth-support";
import { SalesforceSmartintsSupport } from '../salesforce/smart-ints';
import {TreloSmartintsSupport} from "./smart-ints";


export class TrelloSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.Trello, [AuthMethod.OAuth]);
    }

    getName(): string {
        return 'Trello';
    }

    getID() {
        return 'Trello';
    }

    getAvailableSteps(): object[] {
        return [];
    }

    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new TreloSmartintsSupport();
        // return null;
    }
}

export function getTrelloOAuth(): OAuthSupport {
    return {
        extractDataFromObject(data: any): OAuthData | undefined {
            if (typeof data === 'string') {
                return {
                    token: data,
                    refreshToken: '',
                    instanceUrl: ''
                }
            }
            return undefined;
        },
        getUrl(): string {
            return 'https://app.getint.io/app/oauth/redirect?instanceUrl=https://getint-dev-ed.develop.my.salesforce.com';
        },
        getApiUrl(): string {
            return '';
        }
    };
}
