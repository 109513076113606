import React, { Component } from 'react';
import { CreateSharedMapping, fetchSharedMappingsForConnections, handleCreateSharedMapping, SharedMapping } from 'src/app/integration/shared-mappings/rest-api';

import {copyObject, FieldError, UI} from 'src/engrator-core';
import { SmartIntTrigger } from '../../../definition/smart-int-trigger.type';
import { DefaultsPropertyMapping, PropertyMapping } from '../../property-mapping.type';
import { ValueMappingsGroup } from './value-mappings-group.type';
import { SmartIntDefinitionTypeMapping } from '../../../definition/smart-int-definition--type-mapping.type';

enum DoneAction { SELECT, CREATE }

type Props = {
    closeHandler: (refresh: boolean) => void;
    onSelectedSharedMappingHandler: (value: number) => void;
    onMappingsChangedHandler: (groups: ValueMappingsGroup[], defaultMappings?: DefaultsPropertyMapping, sharedMappingId?: number) => void;
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
    properties: PropertyMapping[];
    groups: ValueMappingsGroup[];
    typeMapping: SmartIntDefinitionTypeMapping;
    mapping: PropertyMapping;
};
type State = {
    error?: FieldError;
    allSharedMappings: SharedMapping[];
    sharedMappingName: string;
    showCreateSharedMappingForm: boolean;
    selectedSharedMapping?: SharedMapping;
};

export class SharedMappingConfiguration extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            allSharedMappings: [],
            sharedMappingName: '',
            showCreateSharedMappingForm: false
        };
    }

    async componentDidMount(): Promise<void> {
        try {
            const allSharedMappings = await fetchSharedMappingsForConnections(this.props.leftTrigger.connectionId, this.props.rightTrigger.connectionId);
            this.setState({ allSharedMappings });
        } catch (error) {
            this.setState({error: error as FieldError });
        }
    }

    render() {
        return (
            <UI.FullScreenModal
                closeBtnHandler={() => this.closeClicked()}
                error={this.state.error?.message}
                header={`Convert mapping to a Shared Mapping`}
                className={`shared-mapping-configuration maximized`}
                showPrimaryBtn={false}
                showSecondaryBtn={false}
                depth={3}
            >
                <UI.Form>
                    <UI.Message appearance={'info'}>
                        To find out more about Shared Mapping concept, you can <UI.DocumentationLink text={`check our docs`} url={`https://docs.getint.io/getintio-platform/workflows/shared-mappings-in-getint-centralized-mapping-for-efficient-integrations`} />.
                    </UI.Message>
                    {!this.state.showCreateSharedMappingForm ? (
                        <>
                            <UI.FormGroup label={`Select Shared Mapping`}>
                                <UI.Dropdown
                                    defaultValue={this.state.selectedSharedMapping?.name}
                                    options={this.state.allSharedMappings}
                                    onChange={(newValue: number) => this.changeSharedMapping(newValue)}
                                />
                                {this.state.selectedSharedMapping && <div className={`button-done-container`}>
                                    <UI.Button appearance="elementary" text="Done" onClick={() => this.doneClicked(DoneAction.SELECT)}/>
                                </div>}
                            </UI.FormGroup>
                            <UI.Button
                                className={`create-new-btn`}
                                onClick={() => this.createSharedConnectionClicked()}
                                appearance={'secondary'}
                                text={`Create New Shared Mapping`}
                            />
                        </>
                    ) : (
                        <UI.FormGroup>
                            <UI.Input
                                isRequired={true}
                                label={`Shared Mapping Name`}
                                placeholder={``}
                                onChange={newValue => this.changeName(newValue)}
                            />
                            <UI.Button
                                className={`create-shared-mapping-btn`}
                                onClick={() => this.doneClicked(DoneAction.CREATE)}
                                appearance={'elementary'}
                                text={`Create`}
                                disabled={!this.state.sharedMappingName}
                            />
                        </UI.FormGroup>
                    )}
                </UI.Form>
            </UI.FullScreenModal>
        );
    }

    private createSharedConnectionClicked() {
        this.setState({ showCreateSharedMappingForm: true });
    }

    changeSharedMapping(value: number) {
        this.setState({ selectedSharedMapping: this.state.allSharedMappings.find(el => el.id === value) });
    }

    changeName(newValue: string) {
        this.setState({ sharedMappingName: newValue });
    }

    doneClicked(action: DoneAction): Promise<boolean> {
        return new Promise(resolve => {
            if (action === DoneAction.CREATE) {
                const obj: CreateSharedMapping = {
                    name: this.state.sharedMappingName,
                    connectionsIds: {
                        left: this.props.leftTrigger.connectionId,
                        right: this.props.rightTrigger.connectionId
                    },
                    properties: {
                        left: this.props.mapping.left,
                        right: this.props.mapping.right
                    },
                    configuration: {
                        groups: this.props.groups,
                        leftTriggerConfiguration: this.props.leftTrigger.configuration,
                        rightTriggerConfiguration: this.props.rightTrigger.configuration,
                        typeMappingLeft: this.props.typeMapping.left,
                        typeMappingRight: this.props.typeMapping.right,
                        mappingOptions: this.props.mapping.options,
                        mappingDirection: this.props.mapping.direction,
                        azureEmailIsJiraUsername: this.props.mapping.additional.azureEmailIsJiraUsername,
                        mappingIsNewlyCreated: this.props.mapping.isNewlyCreated || false
                    }
                }
                handleCreateSharedMapping(copyObject(obj)).then((res) => {
                    this.setState({ showCreateSharedMappingForm: false, sharedMappingName: '' });
                    this.props.onSelectedSharedMappingHandler(res);
                    this.props.onMappingsChangedHandler(this.props.groups, undefined, res);
                    resolve(true);
                    this.closeClicked();
                }).catch(err => {
                    console.log(err)
                })
            } else if (action === DoneAction.SELECT) {
                this.props.onSelectedSharedMappingHandler(this.state.selectedSharedMapping!.id);
                this.props.onMappingsChangedHandler(this.props.groups, undefined, this.state.selectedSharedMapping!.id);
                this.closeClicked();
            }
        });
    }

    closeClicked() {
        this.props.closeHandler(false);
    }
}
