import React from 'react';
import { Icon, Icon20 } from './icon';
import {DocumentationLink} from "./documentation-link";
import { UI } from '..';

type Props = {
    appearance: 'success' | 'error' | 'error-message' | 'info' | 'warning',
    message?: any
    docLink?: string;
    margin?: string;
    closeHandler?: () => void;
    rawHTML?: string;
};
type State = {
    message?: string;
    docLink?: string;
}
const MESSAGE_MAX_LENGTH = 500;
export class Message extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        const state = { message: '', docLink: this.props.docLink || '' };
        if (props.message) {
            if (props.message.message) {
                state.message = props.message.message;
            } else {
                state.message = props.message;
            }
            const regex = /{link='(.*)'}/gm;
            let m = regex.exec(state.message);
            if (m && m[1]) {
                state.message = state.message.replace(m[0], '');
                state.docLink = m[1];
            }
        }
        if (state.message?.length > MESSAGE_MAX_LENGTH) {
            state.message = state.message.substring(0, MESSAGE_MAX_LENGTH) + '(truncated)';
            console.debug(state.message);
        }
        this.state = state;
    }

    render() {
        return <div className={`engrator-ui-message ${this.props.appearance}`} style={{ margin: this.props.margin }}>
            { this.props.closeHandler && <div className="close-icon"><UI.Icon icon20={ Icon20.Close } onClick={() => this.props.closeHandler!()} /></div>}
            { this.props.appearance === 'warning' && <Icon icon="exclamation-triangle" /> }
            { this.props.appearance === 'error-message' && <Icon icon="exclamation-triangle" /> }
            { this.state.message }
            { this.props.rawHTML && <div dangerouslySetInnerHTML={{ __html: this.props.rawHTML }}></div>}
            { this.state.docLink && <DocumentationLink text={`Read more here`} url={ this.state.docLink } /> }
            { this.props.children }
        </div>
    }
}
