import React from 'react';
import {UI} from "src/engrator-core";
import {GitHubStepContextData} from "./github-step-context";

type Props = {
    context: GitHubStepContextData;
}

type State = {
}

export class GitHubStepContextDisplay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            { this.props.context.repository && <UI.LabelValue label={ `Repository`} value={ this.props.context.repository.name } /> }
        </React.Fragment>;
    }
};