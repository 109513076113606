import React, {useEffect} from 'react';
import {UI} from 'src/engrator-core';
import {useGlobalState} from "../global-store";
import {VerticalMenuItem} from "../global-store/create-global-state";

export function MainVerticalMenu() {
    const [menu] = useGlobalState('menu');
    const [currentUrl] = useGlobalState('currentUrl');
    useEffect(() => {
    }, [currentUrl]);
    if (!menu || !menu.header) {
        document.body.classList.remove('vertical-menu');
        return <React.Fragment />
    } else {
        document.body.classList.add('vertical-menu');
    }
    return <div className={`horizontal-sub-menu`}>
        <p className={`caption`}>{ menu.header }</p>
        <ul>
            { menu.items.map((item: VerticalMenuItem, index: number) => <li
                key={ index }
                { ...getDataSel(item.name) }
                className={ ((currentUrl.indexOf(item.path) > 0) ? 'selected' : '')  + ' ' + (item.icon ? 'with-img' : '')}>
                <UI.NavLink path={ item.path }>
                    { item.icon && <UI.Icon icon20={ item.icon }/> }
                    { item.name }
                </UI.NavLink>
            </li> )}
        </ul>
    </div>;

    function getDataSel(name: string) {
        return {'data-sel': name.toLowerCase().replace(' ', '-')};
    }
}