import React from 'react';
import { EntityFormHelper } from 'src/engrator-core';
import { NewPipelineStepComponentProps } from 'src/generic';
import { StatusTransition, StatusTransitionConfiguration } from 'src/software/generic/status-transition';
import { SoftwareName } from 'src/software/software-name';

import { MondayStatusTransitionConfiguration } from './monday-status-transition-configuration';

type Props = {} & NewPipelineStepComponentProps;
type State = {};

export class MondayStatusTransitionNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: MondayStatusTransitionConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as MondayStatusTransitionConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'targetArtifact', newValue, additionalArgument?: any) => {
            if (propertyName === 'targetArtifact' && additionalArgument) {
                this.configuration[propertyName] = newValue;
            }
            this.configurationChanged();
        });
    }

    render() {
        return <React.Fragment>
            <StatusTransition
                onConfigurationChanged={this.onConfigurationChanged.bind(this)}
                artifactsFactory={this.props.context.artifactsFactory}
                stepSpecification={this.props.context.stepSpecification}
                connectionId={this.props.context.connectionId}
                softwareName={SoftwareName.Monday}
                availableArtifacts={this.props.context.artifactsFactory.getAvailableOutArtifactsForSoftware(SoftwareName.Monday)}
            />
        </React.Fragment>;
    }

    private configurationChanged() {
        this.forceUpdate();
    }

    private onConfigurationChanged(configuration: StatusTransitionConfiguration) {
        Object.assign(this.configuration, configuration);
        if (this.configuration.targetArtifact) {
            const artifactConnectionId = this.props.context.artifactsFactory.getConnectionIdForArtifact(this.configuration.targetArtifact);
            if (artifactConnectionId) {
                this.props.context.connectionId = artifactConnectionId;
            }
        }
    }
};
