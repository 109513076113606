import React from 'react';

type Props = {
    label: string;
    description?: string;
    dataSel?: string;
};

export class FormSection extends React.Component<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {};
    }

    render() {
        return <div data-sel={ this.props.dataSel } className={ `engrator-form-section` }>
            <h2>{ this.props.label }</h2>
            { this.props.description && <p>{ this.props.description }</p> }
            <div className="content">
                { this.props.children }
            </div>
        </div>;
    }
};