import React from 'react';

import {EditPipelineStepComponentProps} from "src/generic";
import {EntityFormHelper, UI} from "src/engrator-core";
import {ArtifactDirection} from "src/generic/artifacts";
import {ServiceNowSearchItemsConfiguration} from "./servicenow-search-items-configuration";
import {ServicenowStepContextDisplay} from "../common/servicenow-step-context-display";

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class ServiceNowSearchItemsEditStep extends React.Component<Props, State> {
    private configuration: ServiceNowSearchItemsConfiguration;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as ServiceNowSearchItemsConfiguration;

    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <ServicenowStepContextDisplay configuration={ this.configuration.context } />
            <UI.FormGroup>
                <UI.Checkbox
                    checkedValue={ "true" }
                    uncheckedValue={ "false" }
                    defaultValue={ this.configuration.onlyNewChanges.toString() }
                    label="Only items that changed from last integration"
                    onChange={ (newValue) => { this.onlyNewChanged(newValue) }   } />
            </UI.FormGroup>
        </React.Fragment>;
    }

    private onlyNewChanged(newValue: any) {
        this.configuration.onlyNewChanges = !this.configuration.onlyNewChanges;
    }
};