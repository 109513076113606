import React from "react";

import { UI } from 'src/engrator-core';
import {FlowStep} from "../flow-steps-factory";
import {LogsViewer} from "../../../../engrator-core/ui";

type Props = {
    flowStep: FlowStep;
};
type State = {
};

export class Configuration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <div className={`tab configuration`}>
            <LogsViewer logs={ JSON.stringify(this.props.flowStep.stepConfiguration, undefined, 2) } />
        </div>
    }
}