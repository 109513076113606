import React from 'react';

import { UI } from 'src/engrator-core';
import {StepsProgress} from "./wizard-steps/steps-progress";
import {SelectAppsStep} from "./wizard-steps/select-apps-step";
import {SmartIntStepConfiguration} from "./smartint-step-configuration.type";
import softwareFactory, {ISoftwareFactory} from "../../../software/software-factory";
import {createDefaultDefinition, SmartIntDefinition} from "./definition/smart-int-definition.type";
import {SmartIntDefinitionApps} from "./definition/smart-int-definition-apps.type";
import {SmartIntDefinitionConnections} from "./definition/smart-int-definition-connections.type";

type Props = {
    onPipelinesBuildUp: (initialIntegration: any) => void;
};
type State = {
    currentStepIndex: number
};

export class SmartintsWizard extends React.Component<Props, State> {
    private configuration: SmartIntStepConfiguration[] = [];
    private softwareFactory: ISoftwareFactory = softwareFactory;
    private definition: SmartIntDefinition;

    state = {
        currentStepIndex: 0
    };

    constructor(props: Props) {
        super(props);
        this.definition = createDefaultDefinition();
    }

    render() {
        return <div className={`smartints-wizard`}>
            <div className={`content`}>
                <StepsProgress currentStepIndex={ this.state.currentStepIndex }/>
                <div className={`header`}>
                    <h1>SmartInt Wizard (~5 min.)</h1>
                    <p>With <UI.DocumentationLink text={`SmartInts`} url={``} /> you can quickly create integration between different apps matching most common use cases we found during our researches.</p>
                </div>
                { this.state.currentStepIndex === 0 && <SelectAppsStep
                    setConfigurationHandler={  this.setApps.bind(this) }
                    softwareFactory={ this.softwareFactory }
                />
                }
                {/*{ this.state.currentStepIndex === 1 && <SelectConnectionsStep*/}
                {/*    apps={ this.definition.apps }*/}
                {/*    setConfigurationHandler={ this.setConnections.bind(this) }*/}
                {/*    softwareFactory={ this.softwareFactory }*/}
                {/*/>*/}
                {/*}*/}
                {/*{ this.state.currentStepIndex === 2 && <SmartIntWizardConfigurationStep*/}
                {/*        definition={ this.definition }*/}
                {/*        softwareFactory={ this.softwareFactory }*/}
                {/*    />*/}
                {/*}*/}
                { this.state.currentStepIndex === 3 && <div className={`review`}>
                    <UI.Button onClick={() => this.buildUpPipelines() } text={`Create Integration Pipelines`} />
                </div> }
                <UI.ButtonsBar
                    secondary={ [
                        (this.state.currentStepIndex > 0) ? <UI.Button appearance={"secondary"} onClick={ this.back.bind(this) } text={`Back`} /> : null
                    ] }
                    primary={
                        (this.state.currentStepIndex < 3) ? <UI.Button disabled={ !this.configuration[this.state.currentStepIndex] } onClick={ this.next.bind(this) } text={`Next`} /> : null
                    } />
            </div>
        </div>;
    }

    private setConnections(configuration: SmartIntDefinitionConnections) {
        this.definition.triggers.left.connectionId = configuration.left;
        this.definition.triggers.right.connectionId = configuration.right;
        this.configuration[1] = {};
        this.forceUpdate();
    }
    private setApps(configuration: SmartIntDefinitionApps) {
        // this.definition.apps = configuration;
        this.configuration[0] = {};
        this.forceUpdate();
    }

    private setConfiguration(stepIndex: number, configuration: SmartIntStepConfiguration) {
        this.configuration[stepIndex] = configuration;
        console.log(this.configuration);
        this.forceUpdate();
    }

    private next() {
        this.setState({ currentStepIndex: this.state.currentStepIndex + 1 });
    }

    private back() {
        this.configuration.splice(this.state.currentStepIndex - 1, 1);
        this.setState({ currentStepIndex: this.state.currentStepIndex - 1 });
    }

    private buildUpPipelines() {
    }
}
