import {axiosWrapper} from "../../../engrator-core";
import {DropdownOption} from "../../reporting/filters/dropdown-option.type";

const ENDPOINT_URL = '/system/settings20';
export enum SettingsGroupName {
    DataStorage = 'DataStorage',
    General = 'General',
}

export enum SettingName {
    Encryption = 'Encryption', 
    Enabled = 'Enabled', 
    Retention = 'Retention', 
    ItemsDataEncryptionInLogs = 'ItemsDataEncryptionInLogs',
    MinimumLogLevel = 'MinimumLogLevel',
    LogHttpRequests = 'LogHttpRequests',
    LogHttpRequestsExpiresAfterUtc = 'LogHttpRequestsExpiresAfterUtc'
}

export type Settings20 = BaseSettings20[];
export type BaseSettings20 = {[key : string] : string};

export type GeneralSettings = {
    MaxNumberOfItemsPerSync: string;
} & BaseSettings20;

export type DataStorageSettings = {
    Enabled: string;
    Retention: string;
    Encryption: string;
    ItemsDataEncryptionInLogs: string;
    LogHttpRequests: string;
    MinimumLogLevel: string;
    LogHttpRequestsExpiresAfterUtc: string;
} & BaseSettings20;

export function getMinimumLogLevelOptions(): DropdownOption[] {
    return [
        { value: 'INFO', label: 'Info', isSelected: false},
        { value: 'DEBUG', label: 'Debug', isSelected: false}
    ];
}

function convertToSettingsEntity(emptySettings: BaseSettings20, settings20: Settings20): void {
    for (const key of Object.keys(emptySettings)) {
        for (const index in settings20) {
            if (settings20[index].name === key) {
                emptySettings[key] = settings20[index].value;
            }
        }
    }
}

function convertToRequest(groupName: SettingsGroupName, settings: BaseSettings20): Settings20 {
    const list: Settings20 = [];
    for (const key of Object.keys(settings)) {
        list.push({
            name: key,
            value: settings[key],
            groupName: groupName
        });
    }
    return list;
}

export async function fetchSettings(emptySettings: BaseSettings20, groupName: SettingsGroupName): Promise<BaseSettings20> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/${ groupName }`)
                .then((data: any) => {
                    const settings20 = data as Settings20;
                    convertToSettingsEntity(emptySettings, settings20);
                    resolve(emptySettings as GeneralSettings);
                })
                .catch(error => reject(error));

        } catch (error) {
            reject(error);
        }
    });
}

export function saveSettings(groupName: SettingsGroupName, settings: BaseSettings20): Promise<BaseSettings20[]> {
    return new Promise((resolve, reject) => {
        axiosWrapper.post(`${ENDPOINT_URL}`, convertToRequest(groupName, settings))
            .then((data: any) => resolve(data))
            .catch(reject);
    });
}

export function getEmptyDataStorageSettings(): DataStorageSettings {
    return {
        MinimumLogLevel: 'INFO',
        Enabled: 'true',
        Encryption: 'false',
        Retention: '14',
        ItemsDataEncryptionInLogs: 'false',
        LogHttpRequests: 'false',
        LogHttpRequestsExpiresAfterUtc: new Date().toString()
    }
}

export function getEmptyGeneralSettings(): GeneralSettings {
    return {
        MaxNumberOfItemsPerSync: '200',
    }
}