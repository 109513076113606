import React from 'react';
import {UI} from '..';
import {Form, FormGroup} from "./form";
import {Input} from "./input";
import {Dropdown, DropdownOption} from "./dropdown";
import {Checkbox} from "./checkbox";

type Props = {
    logs: string
    downloadLogsHandler?: () => void;
};
type State = {
    currentLog?: LogLine;
    isSearching: boolean;
    everyLogWasHidden: boolean;
    search: {
        query: string;
        logLevel: 'ERROR' | 'WARN' | 'INFO' | 'DEBUG';
        wrapLines: string;
    }
};

export type LogLine = {
    level: 'ERROR' | 'WARN' | 'INFO' | 'DEBUG';
    text: string;
    index: number;
    className: string;
    hidden: boolean;
};

export class LogsViewer extends React.Component<Props, State> {
    logs: LogLine[] = [];
    logsSections: DropdownOption[] = [];
    logLevelOptions: DropdownOption[] = [
        {label: 'ERROR', value: 'ERROR'},
        {label: 'WARN', value: 'WARN'},
        {label: 'INFO', value: 'INFO'},
        {label: 'DEBUG', value: 'DEBUG'}
    ];
    private timeout?: NodeJS.Timeout | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isSearching: false,
            everyLogWasHidden: false,
            search: {
                logLevel: 'DEBUG',
                wrapLines: 'false',
                query: ''
            }
        };
    }

    componentDidMount() {
        this.logs = this.parseLogs(this.props.logs);
        this.forceUpdate();
    }

    render() {
        return <div>
            <div className={`engrator-ui-logs-search`}>
                <Form>
                    <FormGroup
                        label={`Search in logs`}
                    >
                        <Input
                            defaultValue={ this.state.search.query }
                            placeholder={`Type word to search`}
                            onChange={(newValue) => this.setSearchParam('query', newValue)}
                        />
                    </FormGroup>
                    <FormGroup
                        label={`Min. log level`}
                    >
                        <Dropdown
                            defaultValue={ this.state.search.logLevel }
                            sortOptions={ false }
                            options={ this.logLevelOptions }
                            onChange={(newValue: string) => this.setSearchParam('logLevel', newValue)}
                        />
                    </FormGroup>
                    <FormGroup
                        label={`Wrap lines`}
                    >
                        <Checkbox
                            defaultValue={ this.state.search.wrapLines }
                            onChange={ (newValue: any) => this.setSearchParam('wrapLines', newValue) }
                            checkedValue={`true`}
                            uncheckedValue={`false`}
                        />
                    </FormGroup>
                    { (this.state.search.query || this.state.search.logLevel !== 'DEBUG' || this.state.search.wrapLines === 'true') && <UI.Button
                            text={`Clear`}
                            onClick={ () => this.clearSearch() }
                            appearance={"link-inline"}
                    /> }
                    { this.logsSections.length > 0 && <FormGroup
                        label={`Jump to section`}
                    >
                        <Dropdown
                            options={ this.logsSections }
                            onChange={(newValue: string) => this.jumpToSection(newValue) }
                        />
                    </FormGroup> }
                    { this.props.downloadLogsHandler && <div className={`download`}>
                        <UI.Button
                            icon={ <UI.Icon icon={'download'} /> }
                            onClick={() => this.props.downloadLogsHandler!() }
                            text={`Download Logs`}
                            appearance={"secondary"}
                        />
                    </div> }
                </Form>
            </div>
            <div className={`engrator-ui-logs-viewer`}>
                <pre className={ (this.state.search.wrapLines === 'true') ? 'wrapped' : '' }>
                    { this.state.everyLogWasHidden && <p>No logs matching criteria</p> }
                    {this.logs.filter(l => !l.hidden).map((logLine, index) =>
                        <p
                            onClick={() => this.setState({currentLog: logLine})}
                            className={logLine.className}>
                            <span className={`line-number`}>{logLine.index}</span>
                            {this.formatLogLine(logLine.text)}
                        </p>
                    )}
                </pre>
                {this.state.currentLog && <UI.FullScreenModal
                    header={`Log preview`}
                    maximized={ true }
                    primaryBtnText={`Ok`}
                    showPrimaryBtn={false}
                    primaryBtnHandler={() => this.closeModal()}
                    closeBtnHandler={() => this.closeModal()}
                >
                    <div className={`engrator-ui-logs-viewer no-interactions`}>
                        <pre className={`wrapped`}>
                            <p
                                className={this.state.currentLog.className}>
                                <span className={`line-number`}>{this.state.currentLog.index}</span>
                                {this.formatLogLine(this.state.currentLog.text, true)}
                            </p>
                        </pre>
                    </div>
                </UI.FullScreenModal>}
            </div>
        </div>
    }

    private jumpToSection(sectionNumber: string): void {
        const element = document.getElementsByClassName('section-' + sectionNumber)?.[0];
        if (element) {
            // element.scrollIntoView({behavior: 'smooth', block: 'start'});
            // We need to "- 150px" because of the search box which overflow the logs
            window.scrollTo({top: element.getBoundingClientRect().top + window.scrollY - 150, behavior: 'smooth'});
        }
    }

    private formatLogLine(text: string, shouldParseJSON?: boolean) {
        // Replace url property with real link element
        const lines = text.split('\n');
        const finalLogLine = [];
        for (const line of lines) {
            if (line.indexOf(`- _url: `) > 0) {
                const split = line.split(`- _url: `);
                finalLogLine.push(<span>{split[0]}- _url: <a href={split[1]}
                                                             target={`_blank`}>{split[1]}</a><br/></span>);
            } else {
                if (shouldParseJSON) {
                    if (line.replace('\t', '').startsWith('(JSON)')) {
                        try {
                            finalLogLine.push(
                                JSON.stringify(
                                    JSON.parse(line.substr(7))
                                    , null, 2)
                            );
                        } catch (e) {
                            finalLogLine.push(
                                line
                            );
                        }
                        continue;
                    }
                }
                finalLogLine.push(<span>{line}<br/></span>);
            }
        }
        return finalLogLine;
    }

    private closeModal(): Promise<boolean> {
        this.setState({currentLog: undefined});
        return Promise.resolve(true);
    }

    private async setSearchParam(param: 'query' | 'logLevel' | 'wrapLines', newValue: any): Promise<void> {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        const search = this.state.search;
        search[param] = newValue;
        await this.setState({ search });
        this.timeout = setTimeout(() => {
            this.timeout = null;
            this.searchInLogs();
        }, 1 * 1000);
    }

    private searchInLogs() {
        this.setState({ isSearching: true }, () => {
            let everyLogWasHidden = true;
            for (const logLine of this.logs) {
                logLine.hidden = false;
                if (this.state.search.query) {
                    logLine.hidden = (logLine.text.toLowerCase().indexOf(this.state.search.query.toLowerCase()) === -1);
                }
                if (!logLine.hidden) {
                    if (this.state.search.logLevel) {
                        if (this.state.search.logLevel === 'ERROR') {
                            if (logLine.level !== 'ERROR') {
                                logLine.hidden = true;
                            }
                        } else if (this.state.search.logLevel === 'WARN') {
                            if (logLine.level !== 'ERROR' && logLine.level !== 'WARN') {
                                logLine.hidden = true;
                            }
                        } else if (this.state.search.logLevel === 'INFO') {
                            if (logLine.level !== 'ERROR' && logLine.level !== 'WARN' && logLine.level !== 'INFO') {
                                logLine.hidden = true;
                            }
                        }
                    }
                }
                if (!logLine.hidden) {
                    everyLogWasHidden = false;
                }
            }
            this.setState({ isSearching: false, everyLogWasHidden });
        });
    }

    private parseLogs(logs: string): LogLine[] {
        const splitLogs: string[] = logs.split("\n");
        const finalLogs: LogLine[] = [];
        const logsToMerge: string[] = [];
        let isFirstLog = true;
        let currentLogLevel = '';
        let logLineIndex = 0;
        let logSectionInfo = '';
        for (const log of splitLogs) {
            // Checks if it is a log line (so a one with log level and timestamp)
            // Not every log line contains above, e.g. stack trace is printed on several lines
            const isLogLine = getLogLevel(log) !== '';
            if (isLogLine && !isFirstLog) {
                const textToPush = logsToMerge.join('\n');
                finalLogs.push({
                    level: currentLogLevel as any,
                    text: textToPush,
                    className: getClassName(textToPush, currentLogLevel, logSectionInfo),
                    index: logLineIndex,
                    hidden: false
                });
                logLineIndex++;
                logsToMerge.length = 0;
            }
            if (isLogLine) {
                currentLogLevel = getLogLevel(log);
            }
            if (log.indexOf('Executing Step ') >= 0) {
                const regex = /Executing Step #(.*?), software='(.*?)', .*action='(.*)'/gm;
                const matches = regex.exec(log)
                if (matches && matches[3]) {
                    logSectionInfo = matches[1];
                    this.logsSections.push({
                        value: matches[1], label: getLabelForSection(matches[1], matches[2], matches[3])
                    });
                }
            } else {
                logSectionInfo = '';
            }
            logsToMerge.push(log);
            isFirstLog = false;
        }
        return finalLogs;
    }

    private clearSearch() {
        this.setState({ search: { logLevel: 'DEBUG', query: '', wrapLines: 'false' }}, () => {
            this.searchInLogs();
        });
    }
}

function getLogLevel(line: string): 'ERROR' | 'WARN' | 'INFO' | 'DEBUG' | '' {
    if (line.indexOf('[DEBUG]') === 0) {
        return 'DEBUG';
    } else if (line.indexOf('[INFO') === 0) {
        return 'INFO';
    } else if (line.indexOf('[ERROR') === 0) {
        return 'ERROR';
    } else if (line.indexOf('[WARN') === 0) {
        return 'WARN';
    }
    return '';
}

function getClassName(logLine: string, level: string, logSectionInfo: string): string {
    const levelClassName = level.toLowerCase();
    let secondClassName = '';
    let thirdClassName = '';
    if (logLine.indexOf('] Performing Flow #') > 0) {
        secondClassName = `performing-flow`;
    } else if (logLine.indexOf('] Executing Step #') > 0 || (logLine.indexOf('Capturing artifacts versions persisted during sync') > 0)) {
        secondClassName = `executing-step`;
    } else if (logLine.indexOf('Integration will be run in') > 0) {
        secondClassName = `blue`;
    }
    if (logSectionInfo) {
        thirdClassName = ' section-' + logSectionInfo;
    }
    return `${levelClassName} ${secondClassName} ${thirdClassName}`;
}

function getLabelForSection(number: string, softwareName: string, stepName: string): string {
    let stepNameNormalized = stepName;
    if (stepName.indexOf('FindItem') >= 0) {
        stepNameNormalized = softwareName + ' - ' + 'Find item';
    } else if (stepName.indexOf('CreateItem') >= 0) {
        stepNameNormalized = softwareName + ' - ' + 'Create item';
    } else if (stepName.indexOf('ArtifactsComparator') >= 0) {
        stepNameNormalized = 'Update item(s)';
    } else if (stepName.indexOf('UpdateItem') >= 0) {
        stepNameNormalized = 'Update item(s)';
    } else if (stepName.indexOf('SmartIntStatus') >= 0) {
        stepNameNormalized = 'Synchronize status';
    } else if (stepName.indexOf('SyncComments') >= 0) {
        stepNameNormalized = 'Synchronize comments';
    } else if (stepName.indexOf('SyncAttachments') >= 0) {
        stepNameNormalized = 'Synchronize attachments';
    }
    return '#' + number + ' ' + stepNameNormalized;
}