import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {ServiceNowStepKey} from "../servicenow-step-key.type";
import {ServiceNowCreateCcommentsNewStep} from "./servicenow-create-comments-new-step";
import {ServiceNowCreateCommentsEditStep} from "./servicenow-create-comments-edit-step";
import {ServiceNowCreateCommentsConfiguration} from "./servicenow-create-comments-configuration";


export const ServiceNowCreateCommentsPipelineStep: PipelineStep<ServiceNowCreateCommentsConfiguration> = {
    addStepComponentClass: ServiceNowCreateCcommentsNewStep,
    editStepComponentClass: ServiceNowCreateCommentsEditStep,
    specification: {
        action: "Create item comment(s)",
        description: "Create comment(s) for selected item",
        key: ServiceNowStepKey.ServiceNowCreateComments,
        software: SoftwareName.ServiceNow,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `ServiceNowCard`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as ServiceNowCreateCommentsConfiguration;
        return `Create comment(s) for item '${stepConfiguration.targetArtifact}'`;
    }
};