import { PipelineStep } from 'src/generic/pipeline-step';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';

import { GetintArchiverStepKey } from '../archiver-step-key.type';
import { GetintArchiverSearchItemsConfiguration } from './monday-search-items-configuration';
import { GetintArchiverSearchItemsEditStep } from './monday-search-items-edit-step';
import { GetintArchiverSearchItemsNewStep } from './monday-search-items-new-step';


export const GetintArchiverSearchItemsPipelineStep: PipelineStep<GetintArchiverSearchItemsConfiguration> = {
    addStepComponentClass: GetintArchiverSearchItemsNewStep,
    editStepComponentClass: GetintArchiverSearchItemsEditStep,
    specification: {
        action: 'Search GetintArchiver Issues',
        description: 'Search issues in specified project/repository',
        key: GetintArchiverStepKey.GetintArchiverSearchItems,
        software: SoftwareName.GetintArchiver,
        stepType: StepType.Trigger,
    },
    preferredNameForArtifact: `GetintArchiverItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GetintArchiverSearchItemsConfiguration;
        return `Search for issues in ${stepConfiguration.context.board?.name}`;
    },
};
