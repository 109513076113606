import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {GitLabStepKey} from "../gitlab-step-key.type";
import {GitLabFindItemNewStep} from "./gitlab-find-item-new-step";
import {GitLabFindItemEditStep} from "./gitlab-find-item-edit-step";
import {GitLabFindItemConfiguration} from "./gitlab-find-item-configuration";


export const GitLabFindItemPipelineStep: PipelineStep<GitLabFindItemConfiguration> = {
    addStepComponentClass: GitLabFindItemNewStep,
    editStepComponentClass: GitLabFindItemEditStep,
    specification: {
        action: "Find Issue",
        description: "Find issue in specific project",
        key: GitLabStepKey.GitLabFindItem,
        software: SoftwareName.GitLab,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `GitLabItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GitLabFindItemConfiguration;
        return `Find item in ${stepConfiguration.context.project?.name}  with id='${stepConfiguration.id}'`;
    }
};