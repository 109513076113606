import React from 'react';

import {UI} from 'src/engrator-core';
import {ContextMenuItem, Icon20, TableGridData} from '../../engrator-core/ui';
import {SuiteType} from '../../generic/integrations/suite-type';
import {IntegrationEditLink} from '../integration/integration-edit-link';
import {ArtifactVersionPreviewLink} from './artifacts-versions';
import {ReportData} from './report-data.type';
import {ReportName} from './report-name.type';
import {SubItems, SubItemsMode} from './sub-items';
import {getOppositeSide, Side} from "../../generic";
import {PerformedFlow} from "./run-results/performed-flow";
import {SyncedItemsContextMenu} from "./synced-items-context-menu";
import {RunsContextMenu} from "./runs-context-menu";

export function createTableGridData(reportName: ReportName, reportData: ReportData, moreCallback?: any): TableGridData {
    if (reportName === ReportName.PipelinesRuns) {
        return {
            data: reportData.data.map(item => [
                <span className={`${ item.newEntry ? 'new-entry' : '' }`}><UI.NavLink path={`/app/reporting/suite-run/${item.id}/details`}>#{item.id}</UI.NavLink></span>,
                <IntegrationEditLink id={item.suiteId as any as number} name={item.suiteName}
                                     suiteType={item.suiteType as any as SuiteType}/>,
                ((!!item.runMode) ? <span className={`flow-mode run-mode ${ item.runMode.toLowerCase() }`}>{ item.runMode }</span> : ''),
                <UI.ExecutionTime
                    startTime={item.formattedStartTime}
                    formattedExecutionTime={item.formattedExecutionTime}
                />,
                (item.performedFlows) ? item.performedFlows : `-`,
                (item.erroredFlows) ? item.erroredFlows : `-`,
                (item.persistedArtifacts) ? item.persistedArtifacts : `-`,
                <UI.ExecutionStatus status={item.status}/>,
                <RunsContextMenu
                    showReschedule={ (!!item.suiteConfiguration?.migrationConfiguration )}
                    onDetailsSelected={ () => moreCallback(item.id) }
                    runId={ item.id as any} suiteId={ item.suiteId as any}
                />
            ]),
            headers: ['ID', 'Integration Name', 'Mode', 'Execution Time', '# Performed Syncs', '# Failed Syncs', '# Synced Items', 'Status', 'Actions'],
        };
    } else if (reportName === ReportName.PerformedFlows) {
        return {
            data: reportData.data.map(item => [
                <UI.NavLink path={`/app/reporting/suite-run/${item.runId}/flow/${item.id}/details`}>#{item.id}</UI.NavLink>,
                <ArtifactVersionPreviewLink lookUpData={
                    {
                        itemId: item.triggeredByDescription,
                        suiteId: item.suiteId as any,
                        side: item.triggerSide as Side
                    }
                } />,
                <ArtifactVersionPreviewLink lookUpData={
                    {
                        itemId: item.syncedWithItemId,
                        suiteId: item.suiteId as any,
                        side: getOppositeSide(item.triggerSide as Side)
                    }
                } appName={ item.syncedWithAppName }/>,
                ((!!item.flowMode) ? <span className={`flow-mode ${item.flowMode.toLowerCase()}`}>{ item.flowMode }</span> : ''),
                // (item.persistedArtifacts) ? item.persistedArtifacts : `-`,
                <SubItems
                    mode={ SubItemsMode.NotWarnings }
                    performedFlow={ item as any as PerformedFlow }
                    subItemsData={ item.subItemsData as any} />,
                <SubItems
                    mode={ SubItemsMode.Warnings }
                    performedFlow={ item as any as PerformedFlow }
                    subItemsData={ item.subItemsData as any} />,
                <UI.ExecutionTime
                    startTime={item.formattedStartTime}
                    formattedExecutionTime={item.formattedExecutionTime}
                />,
                <div className={`flow-status`}>
                    <UI.ExecutionStatus status={item.status}/>
                    { item.breakStatus === 'NotMet' && <UI.Tooltip position={'right'} message={`One of items did not met filters parameters. Check details for more info.`} icon={ Icon20.Filter } /> }
                </div>,
                <SyncedItemsContextMenu onSelected={ (menuItem: ContextMenuItem) => {
                    if (menuItem.id === 'details')
                        moreCallback(item);
                    }
                } listItem={ item } />
            ]),
            headers: ['ID ', 'Triggered By', 'Synced With', 'Mode', 'Subitems', 'Warnings', 'Execution Time', 'Status', 'Actions'],
        };
    }
    // else if (reportName === ReportName.PersistedArtifacts) {
    //     return {
    //         data: reportData.data.map(item => [
    //             <UI.NavLink
    //                 path={`/app/reporting/suite-run/${item.runId}/flow/${item.flowId}?artifactId=${item.identifier}&stepIndex=${item.stepIndex}`}>#{item.id}</UI.NavLink>,
    //             <UI.NavLink path={`/app/reporting/suite-run/${item.runId}`}>#{item.runId}</UI.NavLink>,
    //             <UI.NavLink path={`/app/reporting/suite-run/${item.runId}/flow/${item.flowId}`}>#{item.flowId}</UI.NavLink>,
    //             <ArtifactVersionPreviewLink lookUpData={
    //                 {
    //                     itemId: item.identifier,
    //                     suiteId: item.suiteId as any,
    //                     side: 'Left'
    //                 }
    //             } />,
    //             <ArtifactVersionPreviewLink lookUpData={
    //                 {
    //                     itemId: item.flowTriggerId,
    //                     suiteId: item.suiteId as any,
    //                     side: 'Left'
    //                 }
    //             } />,
    //             `${item.stepDescription} (${item.stepIndex})`,
    //             <UI.ExecutionTime
    //                 startTime={item.formattedPersistedAtTime}
    //             />,
    //         ]),
    //         headers: ['ID ', 'Run Id', 'Flow Id', 'Identifier', 'Trigger Id', 'Step Desc.', 'Synced At'],
    //     };
    // }
    else if (reportName === ReportName.StepsExecutions) {
        return {
            data: reportData.data.map(item => [
                <UI.NavLink path={`/app/reporting/suite-run/${item.runId}/flow/${item.flowId}/details?stepIndex=${item.stepIndex}`}>#{item.id}</UI.NavLink>,
                `${item.stepDescription} (${item.stepIndex})`,
                <UI.ExecutionTime
                    startTime={item.formattedStartTime}
                    formattedExecutionTime={ item.formattedExecutionTime}
                />,
                (item.persistedArtifacts) ? item.persistedArtifacts : `-`,
                <UI.ExecutionStatus status={ item.status }/>,
            ]),
            headers: ['Flow', 'Step Spec.', 'Execution Time', 'Synced Items', 'Status'],
        };
    } else if (reportName === ReportName.SyncedArtifacts) {
        return {
            data: reportData.data.map(item => [
                <ArtifactVersionPreviewLink lookUpData={
                    {
                        itemId: item.identifier,
                        suiteId: item.suiteId as any,
                        side: item.side as Side
                    }
                } />,
                `${item.softwareName}`,
                <UI.NavLink path={`/app/integration/smart-ints/${item.suiteId}/edit`}>#{item.suiteId}</UI.NavLink>,
                <UI.ExecutionTime
                    startTime={item.syncedAt}
                />,
                `${((item.shouldResync) ? 'Yes' : '-')}`,
                // <UI.Button text={`Show`} appearance={'link-inline'} onClick={() => moreCallback(item)}></UI.Button>,
            ]),
            headers: ['ID', 'Software', 'Integration Id', 'Version timestamp', 'Forced resync'],
        };
    }
    throw new Error(`Unknown report: ${reportName}`);
}
