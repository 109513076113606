import React from 'react';
import { EntityFormHelper, UI } from 'src/engrator-core';
import { NewPipelineStepComponentProps } from 'src/generic';
import { ArtifactDirection } from '../../../../generic/artifacts';
import { GetintArchiverFindItemConfiguration } from './monday-find-item-configuration';

type Props = {} & NewPipelineStepComponentProps;
type State = {
    isContextSetUp: boolean;
};

export class GetintArchiverFindItemNewStep extends React.Component<Props, State> {
    private configuration: GetintArchiverFindItemConfiguration;
    private entityFormHelper: EntityFormHelper;
    private isLoadedAlreadyBuilders: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {isContextSetUp: false};
        this.configuration = this.props.configuration as GetintArchiverFindItemConfiguration;
        this.entityFormHelper = this.getEntityFormHelper();
    }

    render() {
        return <React.Fragment>
            {this.props.formError?.general && <UI.Message
				appearance={`error`}
			>{this.props.formError.general}</UI.Message>
            }
            <UI.FormGroup
                label={`Item Key`}
                isRequired={true}
                description={`Provide key of the item that should be found. You can use variables e.g. properties from other Artifacts.`}
                error={this.props.formError?.id}>
                <UI.Input onChange={this.entityFormHelper.onChangeHandler('id')}/>
            </UI.FormGroup>
        </React.Fragment>;
    }

    private getEntityFormHelper() {
        return new EntityFormHelper((propertyName: 'id', newValue) => {
            this.configuration[propertyName] = newValue;
            this.configurationChanged();
        });
    }

    private configurationChanged() {
        if (!this.isLoadedAlreadyBuilders && this.configuration.id) {
            this.isLoadedAlreadyBuilders = true;
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }
}
