import {axiosWrapper, FieldError} from "src/engrator-core";
import {GitHubRepositoryDTO} from "./common";

export const ENDPOINT_URL = '/connectors/github';

export const fetchTables = (connectionId: number): Promise<GitHubRepositoryDTO[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/repositories')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};