import React, { useState, KeyboardEvent, useEffect } from 'react';
import { AuthMethod } from './auth-method.type';
import { UI } from 'src/engrator-core';
import { NewConnectionData } from 'src/connections';
import { ConnectionModel } from 'src/app/integration/connections/connection-model';

type Props = {
    setCredential: (field: string, value: string) => void;
    handleSecondStepOnKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
    newConnectionData: NewConnectionData;
    connectionToEdit?: ConnectionModel;
};

export default function ServiceNowAuthSelector({
    setCredential,
    handleSecondStepOnKeyDown,
    newConnectionData,
    connectionToEdit
}: Props) {
    const NEW_CONNECTION_DEFAULT_VALUES = {
        basicAuth: {
            login: newConnectionData.authCredentials.login,
            password: newConnectionData.authCredentials.password
        },
        oAuth: {
            clientId: newConnectionData.authCredentials.clientId,
            clientSecret: newConnectionData.authCredentials.clientSecret,
            login: newConnectionData.authCredentials.login,
            password: newConnectionData.authCredentials.password
        },
        bearerToken: {
            clientId: newConnectionData.authCredentials.clientId,
            clientSecret: newConnectionData.authCredentials.clientSecret,
            token: newConnectionData.authCredentials.token,
            refreshToken: newConnectionData.authCredentials.refreshToken
        }
    };

    const [authMethod, setAuthMethod] = useState<AuthMethod>(AuthMethod.BasicAuth);
    const [initialAuthMethod, setInitialAuthMethod] = useState<AuthMethod>(AuthMethod.BasicAuth)
    const [showPassword, setShowPassword] = useState(false);
    const [showRefreshToken, setShowRefreshToken] = useState(false);
    const [showPasswordBox, setShowPasswordBox] = useState(true);
    const [showRefreshTokenBox, setShowRefreshTokenBox] = useState(true);

    useEffect(() => {
        if (connectionToEdit) {
            setAuthMethod(connectionToEdit.authMethod as AuthMethod);
            setInitialAuthMethod(connectionToEdit.authMethod as AuthMethod);
        }
    }, []);

    useEffect(() => {
        newConnectionData.authMethod = authMethod;
        if (newConnectionData.auth) {
            newConnectionData.auth = {
                method: authMethod
            };
        }
        setShowPassword(false);
        setShowRefreshToken(false);
        if (connectionToEdit) {
            setShowPasswordBox(false);
            setShowRefreshTokenBox(false);
            const showPasswordBoxForOauthBasic = initialAuthMethod === AuthMethod.BearerToken && authMethod !== AuthMethod.BearerToken;
            const showPasswordBoxesForBearer = initialAuthMethod === AuthMethod.BasicAuth && authMethod === AuthMethod.BearerToken;
            if (showPasswordBoxForOauthBasic) {
                setShowPasswordBox(true);
            } else if (showPasswordBoxesForBearer) {
                setShowPasswordBox(true);
                setShowRefreshTokenBox(true);
            }
        }
    }, [authMethod]);

    const handleAuthMethod = (authMethod: AuthMethod) => {
        if (!connectionToEdit) {
            newConnectionData.authCredentials = {};
        }
        setAuthMethod(authMethod);
    };

    return (
        <>
            <UI.FormGroup label={`Authorization type`} description={`Select your preferred authorization type`}>
                <UI.Dropdown
                    defaultValue={authMethod}
                    options={[
                        { label: 'Username & Password', value: AuthMethod.BasicAuth },
                        { label: 'OAuth', value: AuthMethod.OAuth },
                        { label: 'Access token & Refresh token', value: AuthMethod.BearerToken }
                    ]}
                    onChange={(newValue: AuthMethod) => handleAuthMethod(newValue)}
                />
            </UI.FormGroup>
            {authMethod === AuthMethod.BasicAuth && (
                <>
                    <UI.FormGroup
                        label={'Username'}
                        description={'Username used during sign in'}
                        dataSel={`username`}
                        isRequired={true}
                    >
                        <UI.Input
                            onChange={newValue => setCredential('login', newValue)}
                            onKeyDown={e => handleSecondStepOnKeyDown(e)}
                            defaultValue={
                                connectionToEdit
                                    ? connectionToEdit.authData.login
                                    : NEW_CONNECTION_DEFAULT_VALUES.basicAuth.login
                            }
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={'Password'}
                        isRequired={true}
                        description={'Password used during sign in'}
                        dataSel={`password`}
                    >
                        {showPasswordBox && (
                            <React.Fragment>
                                <UI.Input
                                    type={showPassword ? `text` : `password`}
                                    onChange={newValue => setCredential('password', newValue)}
                                    onKeyDown={e => handleSecondStepOnKeyDown(e)}
                                    defaultValue={
                                        connectionToEdit
                                            ? connectionToEdit.authData.password
                                            : NEW_CONNECTION_DEFAULT_VALUES.basicAuth.password
                                    }
                                />
                                <UI.Checkbox
                                    label={`Show password`}
                                    onChange={() => setShowPassword(!showPassword)}
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                />
                            </React.Fragment>
                        )}
                        {!showPasswordBox && (
                            <React.Fragment>
                                <UI.Button
                                    appearance={'secondary'}
                                    text={`Edit password`}
                                    onClick={() => setShowPasswordBox(true)}
                                />
                            </React.Fragment>
                        )}
                    </UI.FormGroup>
                </>
            )}
            {authMethod === AuthMethod.OAuth && (
                <>
                    <UI.FormGroup
                        label={'Client ID'}
                        isRequired={true}
                        description={'Provide client id'}
                        dataSel={`clientId`}
                    >
                        <UI.Input
                            onChange={newValue => setCredential('clientId', newValue)}
                            onKeyDown={e => handleSecondStepOnKeyDown(e)}
                            defaultValue={
                                connectionToEdit
                                    ? connectionToEdit.authData.clientId
                                    : NEW_CONNECTION_DEFAULT_VALUES.oAuth.clientId
                            }
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={'Client secret'}
                        isRequired={true}
                        description={'Provide client secret'}
                        dataSel={`clientSecret`}
                    >
                        <React.Fragment>
                            <UI.Input
                                onChange={newValue => setCredential('clientSecret', newValue)}
                                onKeyDown={e => handleSecondStepOnKeyDown(e)}
                                defaultValue={
                                    connectionToEdit
                                        ? connectionToEdit.authData.clientSecret
                                        : NEW_CONNECTION_DEFAULT_VALUES.oAuth.clientSecret
                                }
                            />
                        </React.Fragment>
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={'Username'}
                        isRequired={true}
                        description={'Provide username'}
                        dataSel={`username`}
                    >
                        <UI.Input
                            onChange={newValue => setCredential('login', newValue)}
                            onKeyDown={e => handleSecondStepOnKeyDown(e)}
                            defaultValue={
                                connectionToEdit
                                    ? connectionToEdit.authData.login
                                    : NEW_CONNECTION_DEFAULT_VALUES.oAuth.login
                            }
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={'Password'}
                        isRequired={true}
                        description={'Provide password'}
                        dataSel={`password`}
                    >
                        {showPasswordBox && (
                            <React.Fragment>
                                <UI.Input
                                    type={showPassword ? `text` : `password`}
                                    onChange={newValue => setCredential('password', newValue)}
                                    onKeyDown={e => handleSecondStepOnKeyDown(e)}
                                    defaultValue={
                                        connectionToEdit
                                            ? connectionToEdit.authData.password
                                            : NEW_CONNECTION_DEFAULT_VALUES.oAuth.password
                                    }
                                />
                                <UI.Checkbox
                                    label={`Show password`}
                                    onChange={() => setShowPassword(!showPassword)}
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                />
                            </React.Fragment>
                        )}
                        {!showPasswordBox && (
                            <React.Fragment>
                                <UI.Button
                                    appearance={'secondary'}
                                    text={`Edit password`}
                                    onClick={() => setShowPasswordBox(true)}
                                />
                            </React.Fragment>
                        )}
                    </UI.FormGroup>
                </>
            )}
            {authMethod === AuthMethod.BearerToken && (
                <>
                    <UI.FormGroup
                        label={'Client ID'}
                        isRequired={true}
                        description={'Provide client id'}
                        dataSel={`clientId`}
                    >
                        <UI.Input
                            onChange={newValue => setCredential('clientId', newValue)}
                            onKeyDown={e => handleSecondStepOnKeyDown(e)}
                            defaultValue={
                                connectionToEdit
                                    ? connectionToEdit.authData.clientId
                                    : NEW_CONNECTION_DEFAULT_VALUES.bearerToken.clientId
                            }
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={'Client secret'}
                        isRequired={true}
                        description={'Provide client secret'}
                        dataSel={`clientSecret`}
                    >
                        <React.Fragment>
                            <UI.Input
                                onChange={newValue => setCredential('clientSecret', newValue)}
                                onKeyDown={e => handleSecondStepOnKeyDown(e)}
                                defaultValue={
                                    connectionToEdit
                                        ? connectionToEdit.authData.clientSecret
                                        : NEW_CONNECTION_DEFAULT_VALUES.bearerToken.clientSecret
                                }
                            />
                        </React.Fragment>
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={'Personal Access Token'}
                        isRequired={true}
                        description={'Provide personal access token'}
                        dataSel={`token`}
                    >
                        {showPasswordBox && (
                            <React.Fragment>
                                <UI.Input
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={newValue => setCredential('token', newValue)}
                                    onKeyDown={e => handleSecondStepOnKeyDown(e)}
                                    defaultValue={
                                        connectionToEdit
                                            ? connectionToEdit.authData.token
                                            : NEW_CONNECTION_DEFAULT_VALUES.bearerToken.token
                                    }
                                />
                                <UI.Checkbox
                                    label={`Show token`}
                                    onChange={() => setShowPassword(!showPassword)}
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                />
                            </React.Fragment>
                        )}
                        {!showPasswordBox && (
                            <React.Fragment>
                                <UI.Button
                                    appearance={'secondary'}
                                    text={`Edit personal access token`}
                                    onClick={() => setShowPasswordBox(true)}
                                />
                            </React.Fragment>
                        )}
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={'Refresh Token'}
                        isRequired={true}
                        description={'Provide refresh token'}
                        dataSel={`refreshToken`}
                    >
                        {showRefreshTokenBox && (
                            <React.Fragment>
                                <UI.Input
                                    type={showRefreshToken ? 'text' : 'password'}
                                    onChange={newValue => setCredential('refreshToken', newValue)}
                                    onKeyDown={e => handleSecondStepOnKeyDown(e)}
                                    defaultValue={
                                        connectionToEdit
                                            ? connectionToEdit.authData.refreshToken
                                            : NEW_CONNECTION_DEFAULT_VALUES.bearerToken.refreshToken
                                    }
                                />
                                <UI.Checkbox
                                    label={`Show refresh token`}
                                    onChange={() => setShowRefreshToken(!showPassword)}
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                />
                            </React.Fragment>
                        )}
                        {!showRefreshTokenBox && (
                            <React.Fragment>
                                <UI.Button
                                    appearance={'secondary'}
                                    text={`Edit refresh token`}
                                    onClick={() => setShowRefreshTokenBox(true)}
                                />
                            </React.Fragment>
                        )}
                    </UI.FormGroup>
                </>
            )}
        </>
    );
}
