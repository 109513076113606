import React from 'react';
import {FieldError, UI} from 'src/engrator-core';
import {SmartIntDefinition} from "../../../definition";
import {AdvancedConfiguration} from "../advanced-configuration";
import {Scripting} from "./scripting";
import {validateScriptingConfiguration} from "./rest-api";
import {CustomProperties} from "./customProperties";
import { IntegrationScript } from './integration-script';

export type ModifyIntegrationCallback = (scriptJSON: string) => void;

type Props = {
    integrationId: number;
    integrationDefinition: SmartIntDefinition;
    closeHandler: () => void;
    persistConfiguration: (newConfiguration: AdvancedConfiguration) => void;
    modifyIntegrationScript: ModifyIntegrationCallback;
};

enum CurrentTab {
    scripting = 'scripting',
    customProperties = 'customproperties',
    integrationScript = 'integrationscript'
}

type State = {
    isLoading: boolean;
    currentTab: CurrentTab;
    error?: FieldError;
    configuration: AdvancedConfiguration;
    interval: null | NodeJS.Timeout;
    isGetIntDev: boolean;
};

export class AdvancedConfigurationComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const configuration: AdvancedConfiguration = JSON.parse(JSON.stringify(this.props.integrationDefinition.advancedConfiguration));
        if (!configuration.scripting.onBeforeCommentCreated) {
            configuration.scripting.onBeforeCommentCreated = {
                left: '',
                right: ''
            }
        }
        if (!configuration.customProperties) {
            configuration.customProperties = {
                serviceNowImageBaseUrl: ''
            };
        }
        this.state ={ configuration, isLoading: false, currentTab: CurrentTab.scripting, interval: null, isGetIntDev: false };
    }

    componentDidMount(): void {
        const interval = setInterval(() => {
            this.checkLocalStorage();
        }, 1000)

        this.setState({interval});
    }

    componentWillUnmount(): void {
        if (this.state.interval) {
            clearInterval(this.state.interval);
        }
    }

    checkLocalStorage() {
        const found = localStorage.getItem("getintDev") === 'true';
        const isIntegrationScriptTab = this.state.currentTab === CurrentTab.integrationScript;

        if (!found && isIntegrationScriptTab) {
            this.setState({isGetIntDev: false, currentTab: CurrentTab.scripting});
            return;
        }

        if (found) {
            this.setState({isGetIntDev: true});
            return;
        }

        this.setState({isGetIntDev: false});

    }


    render() {
        return <UI.FullScreenModal
            maximized={true}
            header={`Advanced`}
            closeBtnHandler={ () => this.props.closeHandler() }
            primaryBtnHandler={ () => this.applyClicked() }
        >
            <UI.TabBar>
                <UI.TabButton onClick={ () => this.setState({ currentTab: CurrentTab.scripting}) } text={`Scripting`} isSelected={ this.state.currentTab === CurrentTab.scripting } />
                <UI.TabButton onClick={ () => this.setState({ currentTab: CurrentTab.customProperties}) }  text={`Custom properties`} isSelected={ this.state.currentTab === CurrentTab.customProperties } />
                {this.state.isGetIntDev && <UI.TabButton onClick={ () => this.setState({ currentTab: CurrentTab.integrationScript}) }  text={`Integration Script`} isSelected={ this.state.currentTab === CurrentTab.integrationScript } />}
            </UI.TabBar>
            <UI.Message appearance={`error`}>
                {this.state.error?.message}
            </UI.Message>
            { this.state.currentTab === CurrentTab.customProperties && <CustomProperties
                properties={ this.state.configuration.customProperties }
                leftApp={ this.props.integrationDefinition.triggers.left?.app }
                rightApp={ this.props.integrationDefinition.triggers.right?.app }
            /> }
            { this.state.currentTab === CurrentTab.scripting && <Scripting
                leftApp={ this.props.integrationDefinition.triggers.left?.app }
                rightApp={ this.props.integrationDefinition.triggers.right?.app }
                scripting={ this.state.configuration.scripting }
            /> }
            { this.state.currentTab === CurrentTab.integrationScript && <IntegrationScript
                leftApp={ this.props.integrationDefinition.triggers.left?.app }
                modifyIntegrationScript={ (script: string) => this.props.modifyIntegrationScript(script) }
                rightApp={ this.props.integrationDefinition.triggers.right?.app }
                integrationId={this.props.integrationId}
            /> }
        </UI.FullScreenModal>;
    }

    private applyClicked(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true}, () => {
                validateScriptingConfiguration(this.state.configuration.scripting)
                    .then(() => {
                        this.props.persistConfiguration(this.state.configuration);
                        resolve(true);
                    })
                    .catch((error: FieldError) => {
                        this.setState({isLoading: false, error});
                        resolve(false);
                    });
            });
        });
    }
}
