import React from 'react';

type RightPanelContentProps = {
    header: any,
    footer?: any,
    className?: string
};

export class RightPanelContent extends React.Component<RightPanelContentProps> {
    render() {
        const className = 'engrator-right-panel-content ' + ((this.props.className) ? this.props.className : '');
        return <div className={`${className}`}>
            <h1>{ this.props.header }</h1>
            <div className="inner-content">{ this.props.children }</div>
            { this.props.footer && <div className="footer">
                <div className="inner">
                    { this.props.footer }
                </div>
            </div>}
        </div>;
    }
}