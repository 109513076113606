import React from "react";
import {UI} from "src/engrator-core";
import {AddFilter, FilterField} from "../../filtering/add-filter";
import {ItemFilter} from "../../filtering/rest-api";
import {PropertyDataType} from "../../../../../../generic/artifacts";
import {DisplayFilter} from "../../filtering/display-filter";
import {DropdownOption} from "../../../../../../engrator-core/ui";

export type CommentFilter = {
    mode: 'Pass' | 'Skip'
    conditions: ItemFilter[]
}

type Props = {
    filters: CommentFilter;
};

type State = {
    filters: CommentFilter;
};

export class CommentsFiltering extends React.Component<Props, State> {
    private readonly fieldsOptions: FilterField[];
    private readonly modes: DropdownOption[] = [
        { value: 'Pass', label: 'Permit comments that match the filter criteria'},
        { value: 'Skip', label: 'Exclude comments that match the filter criteria'}
    ];

    constructor(props: Readonly<Props>) {
        super(props);
        this.fieldsOptions = [
            {dataType: PropertyDataType.String, id: 'content', name: 'Content'},
            {dataType: PropertyDataType.String, id: 'author', name: 'Author Name'},
            {dataType: PropertyDataType.Date, id: 'createdAt', name: 'Created At'},
        ];
        this.state = {
            filters: (this.props.filters)
        };
    }

    private removeFilterHandler(index: number): void {
        const filters = this.state.filters;
        filters.conditions.splice(index, 1);
        this.setState({filters});
    }

    private addFilter(filter: ItemFilter) {
        const filters = this.state.filters;
        filters.conditions.push(filter);
        this.setState({filters});
    }

    private changeMode(mode: 'Pass' | 'Skip'): void {
        const filters = this.state.filters;
        filters.mode = mode;
        this.setState({ filters });
    }

    public render() {
        return <UI.Form>
            <UI.Dropdown
                defaultValue={ this.state.filters.mode}
                options={ this.modes}
                onChange={(value: any) => this.changeMode(value)}
            />
            {this.state.filters?.conditions.length > 0 && <div className={`filters-list`}>
                {this.state.filters?.conditions.map((filter, index) => <DisplayFilter
                    deleteHandler={() => this.removeFilterHandler(index)}
                    filter={filter}
                    key={index}
                />)}
                </div>
            }
            <AddFilter
                fieldLabel={`Select Attribute`}
                operatorLabel={`Select Condition`}
                fields={this.fieldsOptions}
                addFilterHandler={(filter) => this.addFilter(filter)}
            />
        </UI.Form>;
    }
}