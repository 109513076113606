import { axiosWrapper, FieldError } from 'src/engrator-core';
import { MondayBoardDTO } from './common';

export const ENDPOINT_URL = '/connectors/monday';

export const fetchTables = (connectionId: number): Promise<MondayBoardDTO[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/boards')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
