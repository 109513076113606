import React, { Component } from "react";

export class OauthRedirectPage extends Component<{}, {}> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
        window.opener.postMessage(window.location.href, window.location.origin);
        window.close();
    }

    render() {
        return <React.Fragment></React.Fragment>
    }
}