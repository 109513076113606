import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {JiraStepKey} from "../jira-step-key.type";
import {JiraCreateCcommentsNewStep} from "./jira-create-comments-new-step";
import {JiraCreateCommentsEditStep} from "./jira-create-comments-edit-step";
import {JiraCreateCommentsConfiguration} from "./jira-create-comments-configuration";


export const JiraCreateCommentsPipelineStep: PipelineStep<JiraCreateCommentsConfiguration> = {
    addStepComponentClass: JiraCreateCcommentsNewStep,
    editStepComponentClass: JiraCreateCommentsEditStep,
    specification: {
        action: "Create comment(s)",
        description: "Create comment(s) for selected issue",
        key: JiraStepKey.JiraCreateComments,
        software: SoftwareName.Jira,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `jiraIssue`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as JiraCreateCommentsConfiguration;
        return `Create comment for '${stepConfiguration.targetArtifact}' issue`;
    }
};