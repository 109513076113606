import React, { Component } from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntIntegration } from '../../../../generic';
import softwareFactory from '../../../../software/software-factory';
import { TopBar, TopBarActions } from '../../../../designer/top-bar';
import { VisualIntegrationDesigner } from '../visual/visual-integration-designer';
import { IntegrationPipelineSettings } from '../../integration-suites';
import { PipelineSettings } from '../../integration-suites/details/pipeline-settings-type';
import { createToolbarActions } from '../../../../designer/container/toolbar-controls';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IntegrationDeletePopUp } from 'src/designer/container/integration-delete-popup';
import {AdvancedConfigurationComponent} from "../configuration/advanced/scripting/advanced-configuration-component";
import {AdvancedConfiguration} from "../configuration/advanced/advanced-configuration";
import {SharingIntegration} from "../configuration/sharing-integration";
import {ShareAccessOption} from "../../../../software/generic/connections-rest-api";

type Props = {
    integration: SmartIntIntegration;
} & RouteComponentProps;

type State = {
    showSettings: boolean;
    showDeleteIntegration: boolean;
    showMigrationConfiguration: boolean;
    showAdvancedConfiguration: boolean;
    showShare: boolean;
}

class SmartIntsBuilder extends Component<Props, State> {
    private readonly toolbarActions: TopBarActions;

    constructor(props: Props) {
        super(props);
        this.state = { showShare: false, showSettings: false, showDeleteIntegration: false, showMigrationConfiguration: false, showAdvancedConfiguration: false };
        this.toolbarActions = this.createTopBarActionsInstance();
    }

    render() {
        return (
            <UI.Page className={`${ (this.props.integration.id) ? 'no-padding-page' : '' } smart-ints-create-page`}>
                <TopBar
                    integrationId={ this.props.integration.id }
                    definition={ this.props.integration.definition }
                    toolbarActions={ this.toolbarActions }
                    history={ this.props.history }
                />
                <VisualIntegrationDesigner
                    integration={ this.props.integration }
                    softwareFactory={ softwareFactory }
                />
                {this.state.showSettings && <IntegrationPipelineSettings
                    deleteHandler={(this.props.integration.id) ? this.deleteHandler.bind(this) : undefined}
                    settings={this.props.integration.settings}
                    closeHandler={this.closeSettings.bind(this)}
                    persistSettingsHandler={(settings: PipelineSettings) => this.props.integration.settings = settings}
                /> }
                {this.state.showAdvancedConfiguration && <AdvancedConfigurationComponent
                    integrationId={this.props.integration.id}
                    integrationDefinition={this.props.integration.definition}
                    persistConfiguration={(newConfiguration: AdvancedConfiguration) => this.props.integration.definition.advancedConfiguration = newConfiguration}
                    modifyIntegrationScript={ (scriptJSON: string) => this.props.integration.updateScript(scriptJSON) }
                    closeHandler={() => this.setState({ showAdvancedConfiguration: false })}
                /> }
                {this.state.showShare && <SharingIntegration
                    shareAccess={ this.props.integration.shareAccess }
                    ownerId={ this.props.integration.ownerId }
                    persistConfiguration={(newOwnerId?: number, shareAccess?: ShareAccessOption) => {
                        if (newOwnerId) {
                            this.props.integration.ownerId = newOwnerId;
                        }
                        if (shareAccess) {
                            this.props.integration.shareAccess = shareAccess;
                        }
                    } }
                    closeHandler={() => this.setState({ showShare: false })}
                /> }
                {this.state.showDeleteIntegration && <IntegrationDeletePopUp
                    integrationId={this.props.integration.id}
                    closeHandler={this.closeDeleteIntegration.bind(this)}
                    deleteSuccessHandler={this.deleteSuccessHandler.bind(this)}
                />
                }
            </UI.Page>
        )
    }

    private closeDeleteIntegration() {
        this.setState({ showDeleteIntegration: false });
    }

    private deleteSuccessHandler() {
        const module = (window.location.href.indexOf('smart-ints') >= 0) ? 'smart-ints' : 'pipelines';
        (this.props as any).history.push(`/app/integration/${module}`);
    }

    private deleteHandler() {
        this.setState({ showDeleteIntegration: true });
    }

    private closeSettings() {
        this.setState({ showSettings: false });
    }

    private createTopBarActionsInstance() {
        return createToolbarActions(
            this.props.integration.id,
            this.props.integration,
            () => {
                this.setState({ showSettings: true });
            },
            () => {
                this.setState({ showMigrationConfiguration: true });
            },
            () => {
                this.setState({ showAdvancedConfiguration: true });
            },
            () => {
                this.setState({ showShare: true });
            }
        );
    }
}

export default withRouter(SmartIntsBuilder);
