import React, { ChangeEvent } from 'react';
import { UI } from 'src/engrator-core';
import { getScriptForExport } from '../../../pages/list/import-export/rest-api';
import { FormError } from 'src/engrator-core/form-error.type';
import jsonValidation from './jsonValidation';
import { ModifyIntegrationCallback } from './advanced-configuration-component';

type Props = {
    integrationId: number;
    leftApp?: string;
    rightApp?: string;
    modifyIntegrationScript: ModifyIntegrationCallback;
};
type State = {
    isLoading: boolean;
    initialScript?: string;
    script: string;
    error?: FormError;
    showButton: boolean;
    errorApply: string;
};

enum Visibility {
    hidden = 'hidden',
    visible = 'visible'
}

export class IntegrationScript extends React.Component<Props, State> {
    buttonsBarCol1: null | HTMLDivElement;
    buttonsBarCol2: null | HTMLDivElement;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            showButton: false,
            errorApply: '',
            script: ''
        };

        this.buttonsBarCol1 = null;
        this.buttonsBarCol2 = null;
    }

    componentDidMount() {
        getScriptForExport(this.props.integrationId)
            .then(script => {
                this.buttonsBarCol1 = document.querySelector('.engrator-buttons-bar .left') as HTMLDivElement;
                this.buttonsBarCol2 = document.querySelector('.engrator-buttons-bar .right') as HTMLDivElement;

                this.setState({
                    isLoading: false,
                    script: JSON.stringify(script, undefined, 2),
                    initialScript: JSON.stringify(script, undefined, 2)
                });
            })
            .catch(error => {
                this.setState({ isLoading: false, error });
            });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.script && prevState.initialScript) {
            if (prevState.script !== this.state.script) {
                if (this.state.initialScript !== this.state.script) {
                    this.setState({ showButton: true, errorApply: '' });

                    this.setButtonsHidden();
                } else {
                    this.setState({ showButton: false, errorApply: '' });

                    this.setButtonsVisible();
                }
            }
        }
    }

    componentWillUnmount(): void {
        this.setButtonsVisible();
    }

    errorHandler(value: string) {
        this.setState({ errorApply: value });
    }

    async handleSaveIntegrationScript() {
        await this.setState({ errorApply: '' });
        if (!this.state.script) {
            await this.setState({ errorApply: 'Script cannot be empty' });
            return;
        }
        const isValid = jsonValidation(this.state.script);
        if (!isValid.status) {
            this.errorHandler(isValid.msg);
            return;
        }

        this.props.modifyIntegrationScript(this.state.script);

        await this.setState({ showButton: false, initialScript: this.state.script });

        this.setButtonsVisible();
    }

    handleTextArea(newValue: string) {
        this.setState({ script: newValue });
    }

    setButtonsVisible() {
        if (this.buttonsBarCol1 && this.buttonsBarCol2) {
            this.buttonsBarCol1.style.visibility = Visibility.visible;
            this.buttonsBarCol2.style.visibility = Visibility.visible;
        }
    }

    setButtonsHidden() {
        if (this.buttonsBarCol1 && this.buttonsBarCol2) {
            this.buttonsBarCol1.style.visibility = Visibility.hidden;
            this.buttonsBarCol2.style.visibility = Visibility.hidden;
        }
    }

    render() {
        return (
            <UI.Form>
                {this.state.isLoading && <UI.Loader appearance={'darkgray'} visible={true} />}
                {this.state.error && (
                    <UI.Message appearance={'error'}>
                        <p>Something went wrong: {this.state.error.message}</p>
                    </UI.Message>
                )}
                <div>
                    <p>Please edit script below</p>
                    <UI.Textarea
                        rows={20}
                        onChange={newValue => this.handleTextArea(newValue)}
                        defaultValue={this.state.script}
                    />
                </div>
                {this.state.showButton && (
                    <div className="flex justify-end align-center mt-20">
                        {this.state.errorApply && (
                            <UI.Message appearance={'error'}>
                                <p style={{ margin: 0, marginRight: 20 }}>{this.state.errorApply}</p>
                            </UI.Message>
                        )}
                        <UI.Button
                            appearance={'elementary'}
                            onClick={() => this.handleSaveIntegrationScript()}
                            text={`Apply`}
                        />
                    </div>
                )}
            </UI.Form>
        );
    }
}
