import React, { KeyboardEvent } from 'react';
import { Button } from './button';
import {Icon} from "./icon";
import { UI } from '..';

type Props = {
    currentPage: number,
    totalPages: number,
    onPageChangeHandler: (pageNumber: number) => void
};

type State = {
    goToPageValue: number;
}

export class Pagination extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            goToPageValue: 1
        }
    }
    
    render() {
        if (this.props.totalPages === 0) {
            return <React.Fragment></React.Fragment>
        }
        return <div className={`engrator-ui-pagination`}>
            <div className={`go-to-page`}>Go to Page:</div>
            <UI.Input type="number" min={1} onChange={ (newValue: string) => this.handleGoToPageOnChange(newValue) } onBlur={ () => this.handleGoToPageBlur() } onKeyDown={(event) => this.handleGoToPageOnKeyDown(event)} defaultValue={ this.props.currentPage + 1 + '' } />
            {this.props.currentPage > 0 &&
            <Button appearance={"secondary"}
                    onClick={() => this.props.onPageChangeHandler(this.props.currentPage - 1)}
                    text={ ``}
                    icon={ <Icon icon={"prev"} />} />
            }
            <div className={`page-info`}>{ this.props.currentPage + 1 } of { this.props.totalPages }</div>
            {this.props.currentPage + 1 < this.props.totalPages &&
            <Button appearance={"secondary"}
                    onClick={() => this.props.onPageChangeHandler(this.props.currentPage + 1)}
                    text={ ``}
                    icon={ <Icon icon={"next"} />} />
            }
        </div>
    }

    private handleGoToPageOnChange(value: string) {
        this.setState({ goToPageValue: +value })
    }

    private handleGoToPageBlur() {
        if (this.state.goToPageValue > this.props.totalPages) {
            this.props.onPageChangeHandler(this.props.totalPages - 1);
            return;
        }
        this.props.onPageChangeHandler(this.state.goToPageValue - 1);
    }

    private handleGoToPageOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        if(event.key === 'Enter') {
            this.handleGoToPageBlur();
        }
    }
};