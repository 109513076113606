import React from 'react';
import {NewPipelineStepComponentProps} from "src/generic";
import {EntityFormHelper, UI} from 'src/engrator-core';
import {ArtifactDirection} from "src/generic/artifacts";
import {GitLabStepContext, GitLabStepContextData} from "../common";

import {GitLabCreateItemConfiguration} from "./gitlab-create-item-configuration";

type Props = {
} & NewPipelineStepComponentProps;
type State = {};

export class GitLabCreateItemNewStep extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private configuration: GitLabCreateItemConfiguration;

    constructor(props: Readonly<Props>) {
        super(props);
        this.configuration = this.props.configuration as GitLabCreateItemConfiguration;
        this.entityFormHelper = new EntityFormHelper((propertyName: '', newValue) => {
            // this.onConfigurationChanged();
        });
    }

    render() {
        return <React.Fragment>
            { !this.configuration.context && <GitLabStepContext 
                connectionId={ this.props.context.connectionId } 
                onDataChanged={ this.stepContextDataChanged.bind(this) }
            /> }
            { this.configuration.context && <UI.LabelValue label={ `Table`} value={ this.configuration.context.project!.name } /> }
        </React.Fragment>
    }

    private stepContextDataChanged(contextData: GitLabStepContextData, isValid: boolean) {
        if (isValid) {
            this.configuration.context = contextData;
            this.props.showArtifactBuilder(ArtifactDirection.In);
            this.props.showArtifactBuilder(ArtifactDirection.Out);
        }
    }
};
