import { StepsFactory } from '../../../generic';
import softwareFactory from '../../../software/software-factory';
import { GenericSoftwareStep, StepType } from '../../../generic/software/step';
import {EffectiveFlowStep, StepExecution} from './flow-details.type';
import { RunDetails } from '../run-results/run-details';
import { SuiteType } from '../../../generic/integrations/suite-type';
import { JiraStepKey } from '../../../software/jira/steps/jira-step-key.type';
import { SoftwareName } from '../../../software';
import { createCommonGenericSoftwareStep } from 'src/software/generic/common-generic-software-step';

export type FlowStep = {
    genericSoftwareStep: GenericSoftwareStep,
    stepConfiguration: any,
    execution?: StepExecution,
    stepIndex: number,
    flowId: number
};

export function flowStepsFactory(runDetails: RunDetails, stepExecutions: StepExecution[], effectiveFlowSteps: EffectiveFlowStep[] | undefined): FlowStep[] {
    const suiteConfiguration = runDetails.suiteConfiguration;
    if (runDetails.suiteType === SuiteType.SmartInt) {
        return createSmartIntFlowSteps(runDetails, stepExecutions, effectiveFlowSteps!);
    }
    if (!suiteConfiguration.steps) {
        return [];
    }
    const stepsFactory =  new StepsFactory();
    const stepsList: FlowStep[] = [];
    softwareFactory.getSoftwareList().forEach(software => {
        software.getAvailableSteps().forEach(step => stepsFactory.registerStep(step as GenericSoftwareStep));
    });
    suiteConfiguration.steps.forEach((step: any, index: number) => {
        const genericSoftwareStep: GenericSoftwareStep = stepsFactory.getStepBySpecification(step.stepSpecification);
        if (genericSoftwareStep && stepExecutions[index]) {
            stepsList.push({
                genericSoftwareStep,
                stepConfiguration: step,
                execution: stepExecutions[index],
                stepIndex: index + 1,
                flowId: stepExecutions[index].flowId
            });
        }
    });
    return stepsList;
}

function createSmartIntFlowSteps(runDetails: RunDetails, stepExecutions: StepExecution[], effectiveFlowSteps: EffectiveFlowStep[]) {
    const stepsFactory =  new StepsFactory();
    const stepsList: FlowStep[] = [];
    softwareFactory.getSoftwareList().forEach(software => {
        software.getAvailableSteps().forEach(step => stepsFactory.registerStep(step as GenericSoftwareStep));
    });
    effectiveFlowSteps?.forEach((step, index) => {
        const genericSoftwareStep: GenericSoftwareStep = stepsFactory.getStepBySpecification(step.stepSpecification);
        if (genericSoftwareStep && stepExecutions[index]) {
            stepsList.push({
                genericSoftwareStep,
                stepConfiguration: { stepSpecification: step.stepSpecification, data: step.data },
                execution: stepExecutions[index],
                stepIndex: index + 1,
                flowId: stepExecutions[index].flowId
            });
        } else {
            // console.log('did not find step for', step.stepSpecification);
            const commonStep  = createCommonGenericSoftwareStep(step.stepSpecification);
            if (commonStep !== undefined && stepExecutions[index]) {
                stepsList.push({
                    genericSoftwareStep: commonStep,
                    stepConfiguration: { stepSpecification: step.stepSpecification, data: step.data },
                    execution: stepExecutions[index],
                    stepIndex: index + 1,
                    flowId: stepExecutions[index]?.flowId
                });
            }
        }
    });
    return stepsList;
}
