import { axiosWrapper } from 'src/engrator-core';
import {SmartIntTrigger} from "../../definition/smart-int-trigger.type";
import {SmartIntPlugin} from "./plugins";

const ENDPOINT_URL = '/integration/plugins/debug';

export function debugPlugin(plugin: SmartIntPlugin, debugContext: string, triggers: { left: SmartIntTrigger, right: SmartIntTrigger }): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            const data = {plugin, debugContext, triggers};
            axiosWrapper.post(ENDPOINT_URL, data)
                .then((data: any) => {
                    resolve(data as any);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
