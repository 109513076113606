import {Software} from 'src/generic';
import {AppSmartIntsSupport} from "src/app/integration/smartints/app-smart-ints-support";
import {createGenericConnectionFactory} from "src/generic/connections";
import {SoftwareName} from "../software-name";
import {AuthMethod} from "src/generic/connections/auth-method.type";
import {PipelineGenericStep} from "src/generic/pipeline-step";

import {ServiceNowPipelineSteps} from "./servicenow-pipeline-steps";
import {ServiceNowSmartIntsSupport} from "./smart-ints";

export class ServiceNowSoftware implements Software {
    getConnectionFactory() {
        return createGenericConnectionFactory(SoftwareName.ServiceNow, [AuthMethod.BasicAuth])
    }
    getName(): string {
        return 'ServiceNow';
    }
    getID() {
        return 'ServiceNow';
    }
    getAvailableSteps(): object[] {
        return ServiceNowPipelineSteps.map(pipelineStep => new PipelineGenericStep(pipelineStep));
    }
    getSmartIntsSupport(): AppSmartIntsSupport | null {
        return new ServiceNowSmartIntsSupport();
    }
}
