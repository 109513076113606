import {OauthUrlCallbackFn} from "./oauth-url-callback-fn.type";
import {OAuthSupport} from "./oauth-support";
import {SoftwareName} from "../../../software";

export class OauthFlow {
    private oAuthSupport: OAuthSupport;
    private currentWindow?: Window | null = null;
    private checkWindowInterval?: NodeJS.Timeout;
    private onTokenFetched: (tokenData: any) => void;
    private interval?: NodeJS.Timeout;

    constructor(oAuthSupport: OAuthSupport, onTokenFetched: (tokenData: any) => void) {
        this.oAuthSupport = oAuthSupport;
        this.onTokenFetched = onTokenFetched;
    }

    public start(url: string, app: SoftwareName): void {
        this.currentWindow?.close();
        window.addEventListener("message", this.extractTokenIfSuccessful.bind(this));
        const windowUrl = `https://app.getint.io/app/oauth/redirect?instanceUrl=${ url }&app=${ app }`;
        this.currentWindow = window.open(windowUrl, 'oAuthFlow','width=1024,height=768');
    }

    protected extractTokenIfSuccessful(event: any): void {
        if (event.data?.type === "intercom-snippet__ready" && event.data?.source !== 'react-devtools-bridge') {
            return;
        }
        const tokenData = this.oAuthSupport.extractDataFromObject(event.data);
        if (tokenData) {
            this.currentWindow?.close();
            this.onTokenFetched(tokenData);
        }
    }

    public end() {
        window.removeEventListener("message", this.extractTokenIfSuccessful);
    }
}