import { PipelineStep } from 'src/generic';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';

import { GetintArchiverStepKey } from '../archiver-step-key.type';
import { GetintArchiverStatusTransitionConfiguration } from './monday-status-transition-configuration';
import { GetintArchiverStatusTransitionEditStep } from './monday-status-transition-edit-step';
import { GetintArchiverStatusTransitionNewStep } from './monday-status-transition-new-step';

export const GetintArchiverStatusTransitionPipelineStep: PipelineStep<GetintArchiverStatusTransitionConfiguration> = {
    addStepComponentClass: GetintArchiverStatusTransitionNewStep,
    editStepComponentClass: GetintArchiverStatusTransitionEditStep,
    specification: {
        action: 'Change Item Status',
        description: 'Move GetintArchiver Item from one status to another',
        key: GetintArchiverStepKey.GetintArchiverStatusTransition,
        software: SoftwareName.GetintArchiver,
        stepType: StepType.Action,
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GetintArchiverStatusTransitionConfiguration;
        return `Change item status`;
    },
};
