import React from 'react';
import {FieldError, UI} from 'src/engrator-core';
import {getUsersToShare} from "../../../../designer/top-bar/rest-api";
import {DropdownOption} from "../../../../engrator-core/ui";
import {
    getShareAccessOptionsForIntegration,
    ShareAccessOption
} from "../../../../software/generic/connections-rest-api";

type Props = {
    ownerId?: number;
    shareAccess?: ShareAccessOption;
    closeHandler: () => void;
    persistConfiguration: (newOwnerId?: number, shareAccess?: ShareAccessOption) => void;
};
type State = {
    isLoading: boolean;
    options?: DropdownOption[];
    error?: FieldError;
    selectedOwnerId?: number;
    shareAccess?: ShareAccessOption;
};

export class SharingIntegration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    async componentDidMount() {
        const options = await getUsersToShare();
        this.setState({ options });
    }

    render() {
        return <UI.FullScreenModal
            maximized={false}
            header={`Share Integration`}
            closeBtnHandler={ () => this.props.closeHandler() }
            primaryBtnHandler={ () => this.applyClicked() }
            isPrimaryDisabled={ false }
        >
            <UI.Message appearance={`error`}>
                {this.state.error?.message}
            </UI.Message>
            <UI.Message appearance={"info"}>
                Select a team member to be the owner of this integration. The owner will have full control over managing and modifying the integration setup. Assigning an owner ensures clear responsibility and streamlined management of your integration processes.
                {/*<UI.DocumentationLink text={`Read more`} url={`https://example.com`} />*/}
            </UI.Message>
            { !this.state.options && <UI.Loader visible={ true } appearance={'darkgray'} /> }
            { this.state.options && <UI.Form>
                <UI.FormGroup
                    label={`Owner`}
                    description={`Assign Integration Owner`}
                >
                    <UI.Dropdown
                        defaultValue={ (this.props.ownerId || '') + '' }
                        options={ this.state.options }
                        onChange={ (newValue: any) => this.setState({ selectedOwnerId: newValue }) }
                    />
                </UI.FormGroup>
            </UI.Form> }
            <UI.Form>
                <UI.FormGroup
                    label={`Team Access`}
                    description={`Allow workspace members to view and collaborate on this integration`}
                >
                    <UI.Dropdown
                        defaultValue={ this.props.shareAccess }
                        options={ getShareAccessOptionsForIntegration() }
                        onChange={ (newValue: any) => this.setState({ shareAccess: newValue }) }
                    />
                </UI.FormGroup>
            </UI.Form>
        </UI.FullScreenModal>;
    }

    private async applyClicked(): Promise<boolean> {
        await this.setState({isLoading: true});
        this.props.persistConfiguration(this.state.selectedOwnerId, this.state.shareAccess);
        return true;
    }
}
