import React from 'react';
import {UI} from 'src/engrator-core';
import {SmartIntTrigger} from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {SoftwareName} from '../../software-name';
import {fetchBoards} from "./rest-api";
import {TrelloBoardDTO} from "./trello-smart-ints-trigger-configuration";
import {DropdownOption} from "../../../engrator-core/ui";

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {
    isLoading: boolean;
    boards?: DropdownOption[];
};

export class TreloSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false
        };
        this.smartIntTrigger = {
            app: SoftwareName.Trello,
            artifactName: '',
            className: '',
            configuration: {
                query: '',
            },
            connectionId: this.props.connectionId,
        };
    }

    async componentDidMount(): Promise<void> {
        await this.setState({isLoading: true});
        const results = await fetchBoards(this.smartIntTrigger.connectionId);
        const databaseOptions: DropdownOption[] = results.map(s => ({
            value: s.id,
            label: s.name,
            object: s
        }));
        this.setState({isLoading: false, boards: databaseOptions});
    }

    render() {
        return <React.Fragment>
            {this.state.boards && <UI.FormGroup
                label={`Board`}
                isRequired={true}
            >
                <UI.Dropdown
                    options={this.state.boards}
                    onChange={(value: string, obj: TrelloBoardDTO) => this.setBase(obj)}
                />
            </UI.FormGroup>}
            {this.state.isLoading && <UI.Loader visible={true} appearance={"darkgray"}/>}
            {/*<UI.FormGroup*/}
            {/*    label={`Additional query`}*/}
            {/*    description={``}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={this.onJqlInputChanged.bind(this)}/>*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>;
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private setBase(item: TrelloBoardDTO) {
        this.smartIntTrigger.configuration.board = item;
        this.smartIntTrigger.artifactName = 'card';
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}