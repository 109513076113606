import { ArtifactDefinition } from './artifact-definition';
import { ArtifactDirection } from './artifact-direction';

abstract class Artifact {
    name: string;
    id: string;
    artifactDefinition: ArtifactDefinition | null;
    direction: ArtifactDirection;

    constructor(direction: ArtifactDirection) {
        this.id = '';
        this.name = '';
        this.direction = direction;
        this.artifactDefinition = null;
    }

    getDefinition(): ArtifactDefinition {
        return {
            id: this.id,
            name: this.name,
            direction: this.direction
        };
    }

    setId(id: string) {
        this.id = id;
    }

    setName(name: string): void {
        this.name = name;
    }
}

export default Artifact;