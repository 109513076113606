import React from 'react';

import { UI } from 'src/engrator-core';
import {discoverWrikeStatuses, WrikeItemStatus} from "../../../../../../software/wrike/steps/rest-api";

export type ManualOption = {
    id: string;
    name: string
};

type State = {
    manualOption?: ManualOption;
    discoveredStatuses?: WrikeItemStatus[];
}

type Props = {
    wrikeConfiguration: {
        connectionId: number;
        folderId: string;
    }
    addOptionHandler: (manualOption?: ManualOption) => void;
};

export class AddOptionManually extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            manualOption: {
                id: '',
                name: ''
            }
        };
    }

    async componentDidMount() {
        const statuses = await discoverWrikeStatuses(
            this.props.wrikeConfiguration.connectionId, this.props.wrikeConfiguration.folderId
        );
        this.setState({ discoveredStatuses: statuses });
    }

    render() {
        return <UI.Modal
            header={`Add option manually`}>
            <UI.Form>
                <UI.Input
                    onChange={(newValue) => this.setManualOptionField('id', newValue) }
                    label={`Id`}
                />
                <UI.Input
                    onChange={(newValue) => this.setManualOptionField('name', newValue) }
                    label={`Name`}
                />
                <UI.ButtonsBar
                    primary={ <UI.Button
                        disabled={ !(this.state.manualOption?.name && this.state.manualOption.id) }
                        onClick={() => this.addManualOption()}
                        text={`Add option`}
                    /> }
                    secondary={ [<UI.Button text={`Close`}
                        appearance={"secondary"}
                        onClick={ () => this.close() }
                    />] }
                />
                { this.state.discoveredStatuses && <UI.TableGrid
                    headers={['Status Id', 'Task', 'Link']}
                    data={ this.state.discoveredStatuses?.map(s => [s.customStatusId, s.title.substr(0, 30), <a href={s.permalink}>Link</a> ]) }
                /> }
            </UI.Form>
        </UI.Modal>
    }

    private addManualOption(): void {
        this.props.addOptionHandler(this.state.manualOption!);
    }

    private close(): void {
        this.props.addOptionHandler();
    }

    private setManualOptionField(field: 'id' | 'name', newValue: string): void {
        const manualOption = this.state.manualOption;
        manualOption![field] = newValue?.trim();
        this.setState({ manualOption });
    }
}
