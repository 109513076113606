import React from 'react';

import { SmartIntTrigger } from 'src/app/integration/smartints/definition/smart-int-trigger.type';
import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { ServiceNowSmartIntsTrigger } from './servicenow-smart-ints-trigger';
import { ServiceNowSmartIntsEditTrigger } from './servicenow-smart-ints-edit-trigger';

export class ServiceNowSmartIntsSupport extends AppSmartIntsSupport {
    getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <ServiceNowSmartIntsTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
        />;
    }

    getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <ServiceNowSmartIntsEditTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
            trigger={trigger}
        />;
    }

    hasTriggerConfigurationComponent(): boolean {
        return false;
    }
}
