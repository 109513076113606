import { PipelineStep } from 'src/generic';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';
import { GetintArchiverStepKey } from '../archiver-step-key.type';
import { GetintArchiverCreateCommentsConfiguration } from './monday-create-comments-configuration';
import { GetintArchiverCreateCommentsEditStep } from './monday-create-comments-edit-step';
import { GetintArchiverCreateCcommentsNewStep } from './monday-create-comments-new-step';


export const GetintArchiverCreateCommentsPipelineStep: PipelineStep<GetintArchiverCreateCommentsConfiguration> = {
    addStepComponentClass: GetintArchiverCreateCcommentsNewStep,
    editStepComponentClass: GetintArchiverCreateCommentsEditStep,
    specification: {
        action: 'Create issue comment(s)',
        description: 'Create comment(s) for selected issue',
        key: GetintArchiverStepKey.GetintArchiverCreateComments,
        software: SoftwareName.GetintArchiver,
        stepType: StepType.Action,
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: `GetintArchiverCard`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as GetintArchiverCreateCommentsConfiguration;
        return `Create comment(s) for issue '${stepConfiguration.targetArtifact}'`;
    },
};
