import {PropertyMapping} from "../visual/property-mapping.type";

/**
 * Merge current fields mappings with new mappings.
 * Only fields mappings that do not contain a field used by any of current mappings
 * should be added to the current mappings
 * @param current
 * @param toBeMerged
 */
export function mergeFieldsMappings(current: PropertyMapping[], toBeMerged: PropertyMapping[]): PropertyMapping[] {
    const leftIds = new Set<string>();
    const rightIds = new Set<string>();
    current.forEach(item => leftIds.add(item.left.id));
    current.forEach(item => rightIds.add(item.right.id));
    for (const mapping of toBeMerged) {
        if (leftIds.has(mapping.left.id) || rightIds.has(mapping.right.id)) {
            continue;
        }
        current.push(mapping);
    }
    return current;
}