import { axiosWrapper } from 'src/engrator-core';

const ENDPOINT_URL = '/integration/connections';

export type ImportValidateResult = {
};

export type ScriptToImport = {
    name: string;
    leftConnectionId: number;
    rightConnectionId: number;
    script: string;
};

/**
 * Export connection as a script (JSON)
 * @param id
 * @param key - Is encryption key used to encrypt the connection auth data
 */
export function getScriptForExport(id: number, key: string): Promise<string>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/${id}/export`, { encryptionKey: key })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function validateImportedScript(script: string): Promise<ImportValidateResult>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/import/validate`, { script: JSON.parse(script) })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function importConnectionScript(name: string, script: any, encryptionKey: string): Promise<number>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/import`, { name, script, encryptionKey } )
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
