import {axiosWrapper, UI} from "src/engrator-core";
import {SoftwareName} from "src/software";
import {DropdownOption} from "../../engrator-core/ui";

const ENDPOINT_URL = '/connectors';

export const fetchOptionsForProperty = (softwareName: SoftwareName, connectionId: number, propertyId: string, configurationData: any): Promise<UI.DropdownOption[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(`${ENDPOINT_URL}/${softwareName.toLowerCase()}/meta/${connectionId}/properties/${propertyId}/options`, configurationData)
                .then((data: any) => {
                    const options: DropdownOption[] = data.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                        object: {
                            id: item.id,
                            label: item.name
                        }
                    }));
                    resolve(options);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};