import { PipelineStep } from 'src/generic';
import {
    MondayCreateCommentsPipelineStep,
    MondayCreateItemPipelineStep,
    MondayFindItemPipelineStep,
    MondaySearchItemsPipelineStep,
    MondayStatusTransitionPipelineStep,
    MondayUpdateItemPipelineStep,
} from './steps';

export const FreshdeskPipelineSteps: PipelineStep<any>[] = [
    MondaySearchItemsPipelineStep,
    MondayCreateItemPipelineStep,
    MondayCreateCommentsPipelineStep,
    MondayUpdateItemPipelineStep,
    MondayFindItemPipelineStep,
    MondayStatusTransitionPipelineStep,
];
