import React from 'react';
import {EntityFormHelper, FieldError, UI} from "src/engrator-core";
import {FormError} from "src/engrator-core/form-error.type";
import {DropdownOption} from "src/engrator-core/ui";
import { fetchProjects, fetchWorkspaces } from "./../rest-api";
import {AsanaProjectDTO} from "./asana-project-dto.type";
import {AsanaWorkspaceTypeDTO} from "./asana-workspace-type-dto.type";

export type AsanaWorkspaceProjectPickerData = {
    project?: AsanaProjectDTO,
    workspace?: AsanaWorkspaceTypeDTO
};

type Props = {
    connectionId: number;
    onDataChanged: (data: AsanaWorkspaceProjectPickerData) => void;
    formError?: FormError;
}

type State = {
    formError: FormError;
    isLoadingWorkspaces: boolean;
    isLoadingProjects: boolean;
}

export class AsanaWorkspaceProjectPicker extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private workspaceOptions: DropdownOption[] = [];
    private projectOptions: DropdownOption[] = [];
    private data : AsanaWorkspaceProjectPickerData = {};

    constructor(props: Props) {
        super(props);
        this.state = {formError: {}, isLoadingWorkspaces: true, isLoadingProjects: false};
        this.entityFormHelper = new EntityFormHelper((propertyName: 'project' | 'workspace', newValue, additionalArgument?: any) => {
            if (!additionalArgument) {
                return;
            }
            if (propertyName === 'workspace') {
                this.data.workspace = additionalArgument as AsanaWorkspaceTypeDTO;
                this.data.project = undefined;
                this.fetchProjects();
            } else if (propertyName === 'project') {
                this.data.project = additionalArgument as AsanaProjectDTO;
            }
            this.props.onDataChanged(this.data);
            this.forceUpdate();
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.forceUpdate();
        }
    }

    componentDidMount(): void {
        fetchWorkspaces(this.props.connectionId)
            .then((workspaces: AsanaWorkspaceTypeDTO[]) => {
                this.workspaceOptions = workspaces.map(workspace => ({
                    label: workspace.name,
                    value: workspace.id,
                    object: workspace
                }));
                this.setState({isLoadingWorkspaces: false});
            })
            .catch((error: FieldError) => {
                this.setState({ isLoadingWorkspaces: false, formError: { ['pickerError']: error.message  } });
            });
    }

    render() {
        return <React.Fragment>
            { this.state.formError.pickerError && <UI.Message appearance={"error"}>{ this.state.formError.pickerError}</UI.Message> }
            <UI.FormGroup
                dataSel={`workspace`}
                label={`Workspace`}
                error={ this.props.formError?.workspace }
                description={``}
                isRequired={true}>
                { this.state.isLoadingWorkspaces && <UI.Loader visible={true} appearance={"darkgray"} /> }
                { !this.state.isLoadingWorkspaces && <UI.Dropdown options={ this.workspaceOptions } onChange={ this.entityFormHelper.onChangeHandler('workspace')   }/> }
            </UI.FormGroup>
            { this.data.workspace && <UI.FormGroup
                label={`Project`}
                dataSel={`project`}
                error={ this.props.formError?.issueType }
                description={``}
                isRequired={ true }>
                { this.state.isLoadingProjects && <UI.Loader visible={true} appearance={"darkgray"} /> }
                { !this.state.isLoadingProjects &&
                <UI.Dropdown defaultValue={this.data.project?.id + ''} options={this.projectOptions}
                             onChange={this.entityFormHelper.onChangeHandler('project')}/>
                }
            </UI.FormGroup> }
        </React.Fragment>;
    }

    private fetchProjects() {
        if (!this.data.workspace) {
            return;
        }
        this.setState({ isLoadingProjects: true }, () => {
            fetchProjects(this.props.connectionId, this.data.workspace!.id)
                .then((projects: AsanaProjectDTO[]) => {
                    this.projectOptions = projects.map(project => ({
                        label: project.name,
                        value: project.id,
                        object: project
                    }));
                    this.setState({isLoadingProjects: false});
                })
                .catch((error: FieldError) => {
                    this.setState({ isLoadingProjects: false, formError: {[`pickerError`]: error.message}});
                });
        });
    }
};