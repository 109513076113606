import React from 'react';
import { UI } from 'src/engrator-core';
import { ContextMenuItem, EntityStatusValue, OnContextMenuItemSelected } from 'src/engrator-core/ui';
import { NotificationAlert } from './notification-alert.type';

type Props = {
    onSelected: OnContextMenuItemSelected;
    listItem: NotificationAlert;
};

type State = {
};
export const EDIT_ITEM: ContextMenuItem = { id: 'Edit', name: 'Edit'};
export const DELETE_ITEM: ContextMenuItem = { id: 'Delete', name: 'Delete'};
export const ENABLE_ITEM: ContextMenuItem = { id: 'Enable', name: 'Enable'};
export const DISABLE_ITEM: ContextMenuItem = { id: 'Disable', name: 'Disable'};

export function getAvailableContextItems(listItem: NotificationAlert): ContextMenuItem[] {
    const items = [
        EDIT_ITEM,
        DELETE_ITEM
    ];
    if (listItem.status === EntityStatusValue.Disabled) {
        items.push(ENABLE_ITEM);
    } else if (listItem.status === EntityStatusValue.Enabled) {
        items.push(DISABLE_ITEM);
    }
    return items;
}

export class NotificationsListItemContextMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div className={`integrations-list-context-menu`}>
            <UI.ContextMenu
                getItems={ () => getAvailableContextItems(this.props.listItem) }
                onSelected={ this.onSelectedHandler.bind(this) }
            />
        </div>
    }

    private onSelectedHandler(item: ContextMenuItem) {
        this.props.onSelected(item);
    }
}
