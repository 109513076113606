import {axiosWrapper} from 'src/engrator-core';
import {AdvancedConfiguration, AdvancedScripting} from "../advanced-configuration";

const INTEGRATION_SUITES_ENDPOINT_URL = '/integration/suites';

export const validateScriptingConfiguration = (configuration: AdvancedScripting): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(INTEGRATION_SUITES_ENDPOINT_URL + `/advanced-scripting/validate`, configuration)
                .then(() => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
