import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Artifacts, Debugging, DesignerEventType, StepedIntegration } from './../../generic';
import { SceneContainer } from './scene';
import softwareFactory from './../../software/software-factory';
import { TopBar, TopBarActions } from '../top-bar';
import { createToolbarActions } from './toolbar-controls';
import { IntegrationDebugging, processStep } from './../debugging';
import { restoreIntegration } from './integration-persistence';
import { StepsFactory } from 'src/generic/steps-factory';
import { GenericSoftwareStep } from 'src/generic/software/step';
import { EventsBus } from 'src/generic';
import './designer-container.css';
import { DesignerContainerContext } from './designer-container-context';

type IntegrationDesignerContainerProps = {
    integrationData: any
} & RouteComponentProps;

type IntegrationDesignerContainerState = {
    scenePopUp?: any,
    refreshStepsList: boolean
};

class IntegrationDesignerContainer extends React.Component<IntegrationDesignerContainerProps, IntegrationDesignerContainerState> {
    artifactsFactory = new Artifacts.ArtifactsFactory(0);
    stepsFactory = new StepsFactory();
    integration: StepedIntegration;
    toolbarActions?: TopBarActions | null;
    eventsBus: EventsBus;

    constructor(props: any) {
        super(props);
        this.state = {
            refreshStepsList: false
        };
        this.eventsBus = new EventsBus();
        softwareFactory.getSoftwareList().forEach(software => {
            software.getAvailableSteps().forEach(step => this.stepsFactory.registerStep(step as GenericSoftwareStep));
        });
        this.integration = (props.integrationData) 
            ? restoreIntegration(props.integrationData, this.artifactsFactory, softwareFactory, this.stepsFactory, this.eventsBus)
            : new StepedIntegration('', this.artifactsFactory, this.stepsFactory, this.eventsBus);
        softwareFactory.getSoftwareList().forEach(software => {
            this.integration.registerSoftware(software);
        });
        this.createToolbarActions();
    }

    componentDidUpdate(prevProps: Readonly<IntegrationDesignerContainerProps>, prevState: Readonly<IntegrationDesignerContainerState>, snapshot?: any): void {
        if (prevProps.integrationData !== this.props.integrationData) {
            console.log('integration data changed');
        }
    }

    private createToolbarActions() {
        this.toolbarActions = createToolbarActions(
            (this.props.integrationData) ? this.props.integrationData.id : null,
            this.integration,
            () => {
                this.eventsBus.notify(DesignerEventType.ShowSettings, {});
            },
            () => {
            },
            () => {},
            () => {}
        );
    }

    nameChanged(newValue: any) {
        this.integration.setName(newValue);
        this.forceUpdate();
    }

    showDebuggingConfiguration(debuggingConfigurationComponent: any) {
        this.setState({ scenePopUp: debuggingConfigurationComponent });
    }

    onStepDebugged(state: Debugging.StepDebuggingState) : Promise<void> {
        return new Promise((resolve) => {
            this.integration.setDebuggingStateForStep(state);
            this.refreshStepsList();
            resolve();
        });
    }

    startDebugging(firstStepConfiguration: any) {
        const debuggingSteps = this.integration.getDebuggingSteps(firstStepConfiguration);
        this.integration.startDebugging();
        const debugging = new IntegrationDebugging(debuggingSteps, this.onStepDebugged.bind(this), processStep);
        this.setState({ scenePopUp: null });
        debugging.startDebugging();
    }

    refreshStepsList() {
        this.setState({ refreshStepsList: !this.state.refreshStepsList });
    }

    render() {
        return <div className={`engrator-page no-padding-page`}>
            <DesignerContainerContext.Provider value={{}}>
                <TopBar
                    history={ this.props.history }
                    integrationId={ (this.props.integrationData) ? this.props.integrationData.id : null }
                    toolbarActions={ this.toolbarActions! }
                />
                <SceneContainer
                    eventsBus={ this.eventsBus }
                    integration={ this.integration }
                    artifactsFactory={ this.artifactsFactory }
                />
            </DesignerContainerContext.Provider>
        </div>
    }
}

export default withRouter(IntegrationDesignerContainer);
