import React from 'react';

import { SoftwareName } from '../../software';
import { EntityFormHelper, FieldError, UI } from '../../engrator-core';
import { NewConnectionData } from '../../connections';
import { BasicAuth } from './basic-auth';
import { OAuth } from './o-auth';
import { DropdownOption } from '../../engrator-core/ui/dropdown';
import { AuthMethod } from './auth-method.type';
import { createConnection } from './rest-api';
import { FormError } from '../../engrator-core/form-error.type';
import { OAuthSupport } from './oauth/oauth-support';
import { BearerToken } from './bearer-token';
import { registerUserActivityAction, UserActivityAction } from '../../app/system/user-activity';
import { JiraBasicAuth } from '../../software/jira/jira-basic-auth';
import { ConnectionSetupVideo } from './connection-setup-video';
import {AzureBasicAuth} from "../../software/azure/azure-basic-auth";
import {FreshdeskBasicAuth} from "../../software/freshdesk/freshdesk-basic-auth";

type Props = {
    softwareName: SoftwareName,
    supportedAuthMethods: AuthMethod[],
    onConnectionAddedHandler: () => void;
    oAuthSupport?: OAuthSupport;
};
type State = {
    formError: FormError,
    isAddingConnection: boolean
};

export class GenericConnectionComponent extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private newConnectionData: NewConnectionData;
    private authMethodOptions: DropdownOption[];

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = { formError: {}, isAddingConnection: false };
        this.newConnectionData = {
            login: "", name: "", password: "", softwareName: this.props.softwareName, url: "", authCredentials: {},
            auth: { method: undefined}}
        ;
        this.authMethodOptions = this.props.supportedAuthMethods.map(authMethod => ({
            value: authMethod,
            label: authMethod
        }));
        this.entityFormHelper = new EntityFormHelper((propertyName: 'url', newValue) => {
            this.newConnectionData[propertyName] = newValue;
        })
    }

    componentDidMount() {
        // Select automatically authentication method if there is only one
        if (this.authMethodOptions.length === 1) {
            console.log(this.authMethodOptions);
            this.authMethodChanged(this.authMethodOptions[0].value as AuthMethod);
        }
    }

    render() {
        return <div>
            <UI.Form
                errors={ [] }
                error={ this.state.formError['general'] }
            >
                <UI.FormSection label={`Create New Connection`}>
                    <ConnectionSetupVideo sofwareName={this.props.softwareName}/>
                {/*<UI.Message appearance="info">
                    <span>Problems finding your connection details for {this.props.softwareName}?</span><br/>
                    <button className={`tawk-to`} type="button" onClick={() => this.openHelp() }>Click to find help</button>
                </UI.Message>*/}
                { this.authMethodOptions.length > 1 && <UI.FormGroup
                    isRequired={true}
                    label={`Authentication Method`}>
                    <UI.Dropdown options={ this.authMethodOptions } onChange={ this.authMethodChanged.bind(this) } />
                </UI.FormGroup> }
                { this.newConnectionData.authMethod && <UI.FormGroup
                    label={`Name`}
                    dataSel={'name'}
                    error={ this.state.formError['name'] }
                    description={`Name your connection`}
                    isRequired={true}>
                    <UI.Input onChange={this.entityFormHelper.onChangeHandler('name')}/>
                </UI.FormGroup>}
                {this.newConnectionData.authMethod === AuthMethod.BasicAuth && <UI.FormGroup
                    dataSel={`url`}
                    label={`Url`}
                    error={this.state.formError['url']}
                    description={`Url to your app instance starting with http:// or https://`}
                    isRequired={true}>
                    <UI.Input onChange={this.entityFormHelper.onChangeHandler('url')}/>
                </UI.FormGroup>}
                {this.newConnectionData && this.newConnectionData.authMethod === AuthMethod.BasicAuth &&
                <React.Fragment>
                    { this.props.softwareName === SoftwareName.Jira && <JiraBasicAuth
                        connectionData={this.newConnectionData} formError={this.state.formError}
                        auth={this.newConnectionData.authCredentials!}/>
                    }
                    { this.props.softwareName === SoftwareName.Azure && <AzureBasicAuth
						connectionData={this.newConnectionData} formError={this.state.formError}
						auth={this.newConnectionData.authCredentials!}/>
                    }
                    { this.props.softwareName === SoftwareName.Freshdesk && <FreshdeskBasicAuth
                        connectionData={this.newConnectionData} formError={this.state.formError}
                        auth={this.newConnectionData.authCredentials!}/>
                    }
                    { this.props.softwareName !== SoftwareName.Jira && this.props.softwareName !== SoftwareName.Azure && this.props.softwareName !== SoftwareName.Freshdesk && <BasicAuth
                        formError={this.state.formError} auth={this.newConnectionData.authCredentials!}/>
                    }
                </React.Fragment>
                }
                {this.newConnectionData && this.newConnectionData.authMethod === AuthMethod.BearerToken &&
                <BearerToken formError={this.state.formError} auth={this.newConnectionData.authCredentials!}/>}
                {/*{this.newConnectionData && this.newConnectionData.authMethod === AuthMethod.OAuth && <OAuth*/}
                {/*    oAuthSupport={this.props.oAuthSupport!}*/}
                {/*    formError={this.state.formError}*/}
                {/*    newConnectionData={this.newConnectionData}*/}
                {/*    auth={this.newConnectionData.authCredentials!}/>}*/}

                </UI.FormSection>
                <br/>
                {this.newConnectionData && this.newConnectionData.authMethod && <UI.ButtonsBar
                    primary={<UI.Button appearance="elementary" onClick={this.addConnection.bind(this)}
                                        isLoading={this.state.isAddingConnection} text="Add"/>}/>}
            </UI.Form>
        </div>
    }

    private addConnection() {
        registerUserActivityAction(UserActivityAction.ClickedAddConnection);
        this.setState({ isAddingConnection: true }, () => {
            createConnection(this.newConnectionData)
                .then(() => {
                    this.props.onConnectionAddedHandler();
                    registerUserActivityAction(UserActivityAction.CreatedConnection);
                }).catch((error: FieldError) => {
                const formError = { [error.fieldName]: error.message };
                this.setState({ isAddingConnection: false, formError });
            });
        });
    }

    private authMethodChanged(optionValue: AuthMethod) {
        this.newConnectionData.authMethod = optionValue;
        this.forceUpdate();
    }

    private openHelp() {
        (window as any).Tawk_API.maximize();
    }
}
