import React from 'react';

import { SmartIntTrigger } from 'src/app/integration/smartints/definition/smart-int-trigger.type';
import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';
import { WrikeSmartIntsEditTrigger } from './wrike-smart-ints-edit-trigger';
import { WrikeSmartIntsTrigger } from './wrike-smart-ints-trigger';
import {DropdownOption} from "../../../engrator-core/ui";

export class WrikeSmartintsSupport extends AppSmartIntsSupport {
    getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <WrikeSmartIntsTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
        />;
    }

    getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <WrikeSmartIntsEditTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
            trigger={trigger}
        />;
    }

    hasTriggerConfigurationComponent(): boolean {
        return true;
    }

    getSubtasksRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
        return Promise.resolve([
            {value: 'ParentLink', label: 'Parent Link'},
        ]);
    }
}
