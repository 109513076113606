import {axiosWrapper, FieldError} from "../../../engrator-core";
import {AirtableBaseDTO, AirtableTableDTO} from "./airtable-smart-ints-trigger-configuration";

export const ENDPOINT_URL = '/connectors/airtable';

export function fetchBases(connectionId: number): Promise<AirtableBaseDTO[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/bases')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchTables(connectionId: number, base: AirtableBaseDTO): Promise<AirtableTableDTO[]> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/meta/' + connectionId + '/bases/' + base.id + '/tables')
                .then((data: any) => resolve(data))
                .catch((error: FieldError) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
