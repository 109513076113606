import React, { Component } from "react";
import { FieldError, UI } from 'src/engrator-core';

import { fetchAll } from './rest-api';
import { IntegrationModuleTabBar } from './../../integration-module-tab-bar';
import { IntegrationListItem, LastRun } from "./integration-list-item";
import { RouteComponentProps } from "react-router-dom";
import { FormError } from "src/engrator-core/form-error.type";
import { FetchData } from "../../smartints/pages/list/rest-api";

type State = {
    integrations: FetchData;
    error: FieldError | undefined;
    isLoading: boolean;
}
type Props = {} & RouteComponentProps;

function LastRunShortSummary(props: {runDetails: LastRun}) {
    if (!props.runDetails) {
        return <div>-</div>;
    }
    return <div className={`status`}>
        <UI.ExecutionStatus status={ props.runDetails.status } />
        <UI.ExecutionTime startTime={ props.runDetails.formattedStartTime } formattedExecutionTime={ props.runDetails.formattedExecutionTime} />
    </div>
}
export class IntegrationsListPage extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            integrations: {
                headers: [],
                data: []
            },
            error: undefined,
            isLoading: true
        };
    }

    async componentDidMount(): Promise<void> {
        try {
            const integrations = await fetchAll();
            this.setState({integrations, isLoading: false});
        } catch (error) {
            this.setState({error: error as FieldError, isLoading: false})
        }
    }

    render() {
        return (
            <UI.Page className={`pipelines-page`}>
                <IntegrationModuleTabBar selectedIndex={ 1 }>
                    <UI.NavLink path="/app/integration/create"><UI.Button onClick={() => {}} text={`Create Workflow`} /></UI.NavLink>
                </IntegrationModuleTabBar>
                { !this.state.isLoading || this.state.error ? <UI.Table
                    error={ this.state.error }
                    dataSource={ this.state.integrations }
                    rowDecorator={(listItem: IntegrationListItem) => [
                        <UI.NavLink path={`/app/integration/pipelines/${listItem.id}/edit`}>{ listItem.name }</UI.NavLink>,
                        <LastRunShortSummary runDetails={ listItem.lastRun } />,
                        <LastRunShortSummary runDetails={ listItem.lastSuccessfulRun } />,
                        (listItem.status) ? <UI.EntityStatus status={listItem.status} /> : null
                    ]} /> : <UI.CenteredLoader text={`Loading...`} />
                }
            </UI.Page>
        )
    }
}
