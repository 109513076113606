import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { SoftwareName } from '../../software-name';
import { MondayStepContext, MondayStepContextData } from '../steps/common';
import {fetchWorkspaces} from "./rest-api";
import {DropdownOption} from "../../../engrator-core/ui";

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {
    isLoadingWorkspaces: boolean;
    workspaces: DropdownOption[];
    error?: any;
};

export class FreshdeskSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.Freshdesk,
            artifactName: '',
            className: '',
            configuration: {
                query: '',
            },
            connectionId: this.props.connectionId,
        };
        this.state = { isLoadingWorkspaces: false, workspaces: [] };
        this.onDataChanged({});
    }

    render() {
        return <React.Fragment>
            {/*<UI.FormGroup*/}
            {/*    label={`Additional query`}*/}
            {/*    description={``}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={ this.onJqlInputChanged.bind(this)} />*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>;
    }

    private onDataChanged(data: MondayStepContextData) {
        if (data) {
            this.smartIntTrigger.artifactName = 'task';
            this.props.onConfigured(this.smartIntTrigger!);
            this.forceUpdate();
        }
    }
}
