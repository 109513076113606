import React from 'react';

import {UI} from 'src/engrator-core';
import {downloadRunLogs} from "../run-results/rest-api";

export enum FlowsNavBarItem {
    Overview,
    Logs
};

type ReportsTaBbarProps = {
    flowId: number;
    runId: number;
    suiteId: number;
    selectedItem: FlowsNavBarItem;
    onTabItemChanged: (selectedTab: FlowsNavBarItem) => void;
};

export class FlowsNavBar extends React.Component<ReportsTaBbarProps> {
    render() {
        return <React.Fragment>
            <div className={`nav-bar flex row two-columns`}>
                {/*<UI.TabBar>*/}
                {/*    <UI.TabButton text={`Performance`} path={`/app/analysis/threads`} isSelected={ this.props.selectedItem === 'Performance' } />*/}
                    {/*<UI.TabButton text={`Overview`} onClick={ () => this.props.onTabItemChanged(FlowsNavBarItem.Overview) } isSelected={ this.props.selectedItem === FlowsNavBarItem.Overview } />*/}
                    {/*<UI.TabButton text={`Logs`} onClick={ () => this.props.onTabItemChanged(FlowsNavBarItem.Logs) } isSelected={ this.props.selectedItem === FlowsNavBarItem.Logs } />*/}
                    {/*<UI.TabButton text={`Logs`} path={`/app/analysis/logs`} isSelected={ this.props.selectedItem === 'Logs' } />*/}
                {/*</UI.TabBar>*/}
                <UI.ButtonsBar
                    secondary={[
                        // <UI.Button
                        //     appearance={'secondary'}
                        //     path={`/app/integration/smart-ints/${this.props.suiteId}/edit`}
                        //     text={`Edit Integration`}
                        //     icon={<UI.Icon icon={'edit'} /> }
                        // />,
                        <UI.Button
                            icon={ <UI.Icon icon={'download'} /> }
                            onClick={() => this.downloadLogsClicked() }
                            text={`Download Logs`}
                            appearance={"secondary"}
                        />
                    ]}
                />
            </div>
        </React.Fragment>
    }

    private downloadLogsClicked() {
        downloadRunLogs(this.props.runId)
            .then(() => {} );
    }
}