import React from 'react';

import { UI } from 'src/engrator-core';
import { createDefaultPropertiesGuards, PipelineSettings } from './pipeline-settings-type';
import { IntegrationUpdateStrategy } from './integration-update-strategy';
import { ResyncSettings } from './resync-settings';
import {getSystemInfo} from "../../../top-navigation/rest-api";

type Props = {
    settings: PipelineSettings,
    closeHandler: () => void,
    persistSettingsHandler: (settings: PipelineSettings) => void;
    deleteHandler?: () => void;
};

type State = {
    formError?: { [key: string]: string },
    minIntervalInSeconds?: number,
    currentInterval: string
};

export class IntegrationPipelineSettings extends React.Component<Props, State> {
    private settings: PipelineSettings;
    timeout: any;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            currentInterval: this.props.settings.intervalInSeconds.toString()
        };
        this.settings = Object.assign({}, this.props.settings);
        if (!this.settings.additionalSettings) {
            this.settings.additionalSettings = {
                multiFieldsUsage: false,
                propertiesGuards: createDefaultPropertiesGuards()
            };
        }
    }

    async componentDidMount(): Promise<void> {
        const systemInfo = await getSystemInfo();
        if (systemInfo) {
            this.setState({ minIntervalInSeconds: +systemInfo.featureFlags.MIN_INTERVAL_IN_SECONDS })
        }
    }

    componentWillUnmount(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    onFormChange(elementThatChanged: 'status' | 'groupName' | 'workingWeekends' | 'workingHourFrom' | 'workingHourTo' | 'threadId' | 'intervalInSeconds', newValue: string) {
        if (this.timeout) clearTimeout(this.timeout);
        if ((this.state.minIntervalInSeconds || this.state.minIntervalInSeconds === 0) && elementThatChanged === 'intervalInSeconds') {
            if (+newValue < this.state.minIntervalInSeconds!) {
                this.timeout = setTimeout(() => {
                        this.settings.intervalInSeconds = this.state.minIntervalInSeconds!;
                        this.setState({currentInterval: this.state.minIntervalInSeconds!.toString()})
                    }, 500);
            } else {
                this.settings.intervalInSeconds = newValue as never;
            }
            this.setState({ currentInterval: newValue })
        } else {
            this.settings[elementThatChanged] = newValue as never;
            this.forceUpdate();
        }
    }

    onMultiFieldsUseage(newValue: boolean): void {
        this.settings.additionalSettings!.multiFieldsUsage = newValue;
    }

    onGroupIdChanged(groupId: number) {
        this.settings['groupId'] = groupId as never;
        this.forceUpdate();
    }

    saveClicked(): Promise<boolean> {
        console.log(this.settings);
        this.props.persistSettingsHandler(this.settings);
        this.props.closeHandler();
        return Promise.resolve(true);
    }

    cancelClicked(): Promise<boolean> {
        this.props.closeHandler();
        return Promise.resolve(true);
    }

    render() {
        return <UI.FullScreenModal
            header={`Settings`}
            primaryBtnHandler={ () => this.saveClicked() }
            primaryBtnText={`Apply`}
            closeBtnHandler={() => this.cancelClicked() }
            // footer={ <UI.ButtonsBar
            //     primary={ <UI.Button appearance="elementary" onClick={() => this.saveClicked() }  text="Save" /> }
            //     secondary={ [
            //         <UI.Button appearance="secondary" onClick={() => this.cancelClicked() }  text={ `Cancel` } />,
            //         (this.props.deleteHandler) ? <UI.Button appearance="danger" onClick={ this.props.deleteHandler.bind(this) }  text={ `Delete` } />  : ''
            //     ]} />
            // }
            className={`pipeline-settings`}
        >
            <div>
                <UI.Form
                    errors={ [] }
                    error={ this.state.formError && this.state.formError!['general'] }>
                    <UI.FormGroup
                        label={`Enabled`}
                        description={`If not checked, integration wont be working at all.`}
                        error={ this.state.formError && this.state.formError!['general'] }>
                        <UI.Checkbox checkedValue={`Enabled`} uncheckedValue={`Disabled`} defaultValue={ this.settings.status } onChange={ (newValue: string) => {this.onFormChange("status", newValue)}   }/>
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Group`}
                        description={`Provide group name to which this integration should be assigned.`}
                        error={this.state.formError && this.state.formError!['groupNmae']}>
                        <UI.Input defaultValue={this.settings.groupName as unknown as string} label={``}
                                  placeholder={`Group name`} isRequired={false}
                                  onChange={(newValue: string) => {
                                      this.onFormChange('groupName', newValue)
                                  }}
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Working on weekends`}
                        description={`If checked, integration will be working on Saturdays and Sundays.`}
                        error={ this.state.formError && this.state.formError!['general'] }>
                        <UI.Checkbox checkedValue={`true`} uncheckedValue={`false`}
                                     defaultValue={this.settings.workingWeekends + ''}
                                     onChange={(newValue: string) => {
                                         this.onFormChange('workingWeekends', newValue)
                                     }}/>
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Working hours`}
                        description={`Define hours range in which integration should be working. Hours should be defined in UTC timezone. Leave fields empty to not restrict pipeline to hours range.`}
                        error={this.state.formError && this.state.formError!['general']}>
                        <div className={`working-hours`}>
                            <UI.Input min={0} max={23} defaultValue={this.settings.workingHourFrom as unknown as string} label={`from`}
                                      type={`number`} placeholder={`From`} isRequired={false}
                                      onChange={(newValue: string) => {
                                          this.onFormChange('workingHourFrom', newValue)
                                      }}/>
                            <UI.Input min={0} max={23} defaultValue={this.settings.workingHourTo as unknown as string} label={`to`}
                                      type={`number`} placeholder={`To`} isRequired={false}
                                      onChange={(newValue: string) => {
                                          this.onFormChange('workingHourTo', newValue)
                                      }}/>
                        </div>
                    </UI.FormGroup>
                    <UI.FormGroup
                        dataSel={`interval`}
                        label={`Run interval`}
                        description={
                            <>
                                <span>Minimum interval between successive runs, specified in seconds.<UI.DocumentationLink text={`Read how integrations are executed`} url={`https://docs.getint.io/getintio-platform/how-integrations-are-executed-by-getint`} /></span>
                                {this.state.minIntervalInSeconds ? <UI.Message margin="10px 0px 0px 0px" appearance='warning' message={`Currently Getint cloud apps are limited to min. ${this.state.minIntervalInSeconds} seconds interval.`}/> : null}
                            </>
                        }
                        error={this.state.formError && this.state.formError!['general']}>
                        <UI.Input defaultValue={this.state.currentInterval} label={``}
                                  min={0}
                                  type={`number`} placeholder={`To`} isRequired={false}
                                  onChange={(newValue: string) => {
                                      this.onFormChange('intervalInSeconds', newValue)
                                  }}
                        />
                    </UI.FormGroup>
                    <ResyncSettings propertiesGuards={this.settings.additionalSettings!.propertiesGuards!}/>
                    <UI.FormGroup
                        label={`Multi-fields mapping`}
                        description={`If checked, you will be able to map one field multiple times`}
                        error={ this.state.formError && this.state.formError!['general'] }>
                        <UI.Checkbox checkedValue={`true`} uncheckedValue={`false`}
                                     defaultValue={this.settings.additionalSettings!.multiFieldsUsage + ''}
                                     onChange={(newValue: string) => {
                                         this.onMultiFieldsUseage(!!newValue)
                                     }}/>
                    </UI.FormGroup>
                    <IntegrationUpdateStrategy settings={ this.settings.updateStrategySettings }/>
                    { this.props.deleteHandler && <UI.FormGroup
                        description={`Delete integration if you no longer need it. It won't be possible to restore it.`}
                        label={`Delete integration`}
                    >
                        <UI.Button appearance="danger" onClick={ this.props.deleteHandler.bind(this) }  text={ `Delete` } />
                    </UI.FormGroup> }
                    {/*{ <UI.FormGroup*/}
                    {/*    label={`Assigned Integration Thread ID`}*/}
                    {/*    isRequired={true}*/}
                    {/*    description={`Specify Integration Thread ID on which this pipeline should be working. In general, threads are easy way to improve performance and time of integrations.`}*/}
                    {/*    error={ this.state.formError && this.state.formError!['general'] }>*/}
                    {/*    <UI.Input defaultValue={ this.settings.threadId as unknown as string } type={`number`} isRequired={false} onChange={ (newValue: string) => {this.onFormChange("threadId", newValue)}   }/>*/}
                    {/*</UI.FormGroup> }*/}
                    {/*<PipelineGroupPicker*/}
                    {/*    defaultGroupId={ this.settings.groupId }*/}
                    {/*    onGroupChanged={(groupId: number) => this.onGroupIdChanged(groupId)}/>*/}
                </UI.Form>
            </div>
        </UI.FullScreenModal>
    }
}
