import React from 'react';

import './SnippetsListScene.css';
import { AddStepButton } from './AddStepButton';
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EventsBus, DesignerEventType} from './../../../generic';

type SnippetsListSceneProps = {
    // refreshStepsList: boolean,
    onStepEdit: (step: any) => void,
    addStepClicked: () => void,
    eventsBus: EventsBus,
    openDebuggingReportHandler: (debuggingOutput: any) => void,
    steps: Array<any>
};

export default class SnippetsListScene extends React.Component<SnippetsListSceneProps> {
    blocksPerRow: number = 0;

    constructor(props: SnippetsListSceneProps) {
        super(props);
        this.props.eventsBus.subscribe(DesignerEventType.StepsChangedEvent, () => {
            this.forceUpdate();
        });
    }

    componentDidMount() {
        this.blocksPerRow = parseInt(((document.getElementsByClassName('engrator-page')[0] as any).offsetWidth / 250) + '');
        this.forceUpdate();
        // if (prevProps.refreshStepsList !== this.props.refreshStepsList) {
        //     this.forceUpdate();
        //     console.log('refreshed');
        // }
    }

    render() {
        return <div className="SnippetsList">
            { this.props.steps.map((step: any, index: number) => <React.Fragment>
                    { index % (this.blocksPerRow - 1) === 0 && index > 0 &&  <div className={`divider`}></div> }
                    <div className="snippet-container" key={ index*2 }>
                        <div className={`join-line ${(index === 0) ? 'blank' : ''}`}  key={ index*2 - 1 }></div>
                        <div className={`snippet-tile`} onClick={ () => this.props.onStepEdit(step) }>
                        { step.getSnippetComponent() }
                        </div>
                        {/*{ step.hasDebuggingReport() &&*/}
                        {/*    <p className="debugging-report" onClick={ () => this.props.openDebuggingReportHandler(step.getDebuggingOutput()) }>*/}
                        {/*        <span className="report-icon"><FontAwesomeIcon icon={ faFile } /></span>*/}
                        {/*        Debug report*/}
                        {/*    </p>*/}
                        {/*}*/}
                        <div className="join-line"  key={ index*2 - 1 }></div>
                    </div>
                </React.Fragment>
            )}
            <div className="snippet-container">
                <div className={`join-line`}></div>
                <AddStepButton
                    stepsCount={ this.props.steps.length }
                    onClickHandler={ () => this.props.addStepClicked() }
                />
            </div>
        </div>
    }
}