import React from 'react';
import { UI } from 'src/engrator-core';
import { ReadMore } from '../../../../../engrator-core/ui/read-more';

import { SmartIntDefinitionTypeMapping } from '../../definition/smart-int-definition--type-mapping.type';
import {
    getDefaultAdvacnedSyncConfiguration,
    SmartIntConfigurationType,
} from '../../definition/smart-int-definition-configuration.type';
import { AppsSupport } from '../visual-integration-designer';
import { AdvancedBreakingFlow } from './advanced-breaking-flow';
import { AdvancedSync } from './advanced-sync';

type Props = {
    appsSupport: AppsSupport;
    mapping: SmartIntDefinitionTypeMapping;
};

export class Advanced extends React.Component<Props> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        if (this.props.mapping.advancedConfiguration?.syncConfiguration === undefined) {
            this.props.mapping.advancedConfiguration.syncConfiguration = getDefaultAdvacnedSyncConfiguration();
        }
    }

    render() {
        const isPostCreateUsed = (!!this.props.mapping.advancedConfiguration?.postCreate?.left) || (!!this.props.mapping.advancedConfiguration?.postCreate?.right);
        return <React.Fragment>
            <div className={`advanced`}>
                <AdvancedSync
                    appsSupport={this.props.appsSupport}
                    mapping={this.props.mapping}
                    syncConfiguration={this.props.mapping.advancedConfiguration?.syncConfiguration!}
                />
                <AdvancedBreakingFlow
                    appsSupport={this.props.appsSupport}
                    mapping={this.props.mapping}
                    syncConfiguration={this.props.mapping.advancedConfiguration?.syncConfiguration!}
                />
                {/* From version 1.59 this will be avaialble if someone already defined sth in post-create updated */}
                { isPostCreateUsed && <UI.FormSection
                    label={`Post-Create updates`}
                >
                    <UI.FormGroup
                        description={
                            <div>
                                Provide properties that will be used to update artifact which triggered creation of new
                                artifact in the other-side app.<br/>
                                JSON format should be used, starting and ending with curly brackets (&#123;&#125;).
                                <ReadMore
                                    url={`https://docs.getint.io/getting-started-with-the-platform/integration-configuration/post-create-updates`}/>
                            </div>
                        }
                    >
                        <div className={`flex row`}>
                            <div className={`flex-equal with-padding`}>
                                <UI.Textarea
                                    rows={5}
                                    defaultValue={this.props.mapping.advancedConfiguration?.postCreate?.left}
                                    onChange={(newValue) => this.changeCondition('left', newValue)}/>
                            </div>
                            <div className={`flex-equal with-padding`}>
                                <UI.Textarea
                                    rows={5}
                                    defaultValue={this.props.mapping.advancedConfiguration?.postCreate?.right}
                                    onChange={(newValue) => this.changeCondition('right', newValue)}/>
                            </div>
                        </div>
                    </UI.FormGroup>
                </UI.FormSection> }
            </div>
        </React.Fragment>
    }

    private changeCondition(side: 'left' | 'right', newValue: string) {
        if (!this.props.mapping.advancedConfiguration) {
            this.props.mapping.advancedConfiguration = {
                configurationType: SmartIntConfigurationType.Advanced,
                postCreate: { left: '', right: '' }
            };
        }
        this.props.mapping.advancedConfiguration.postCreate[side] = newValue;
    }
}
