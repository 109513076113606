import { PipelineStep } from 'src/generic';
import { IntegrationStepConfiguration, StepType } from 'src/generic/software/step';
import { SoftwareName } from 'src/software/software-name';
import { MondayStepKey } from '../freshdesk-step-key.type';
import { MondayCreateItemConfiguration } from './monday-create-item-configuration';
import { MondayCreateItemEditStep } from './monday-create-item-edit-step';
import { MondayCreateItemNewStep } from './monday-create-item-new-step';

export const MondayCreateItemPipelineStep: PipelineStep<MondayCreateItemConfiguration> = {
    addStepComponentClass: MondayCreateItemNewStep,
    editStepComponentClass: MondayCreateItemEditStep,
    specification: {
        action: 'Create issue',
        description: 'Create issue in specified board',
        key: MondayStepKey.FreshdeskCreateItem,
        software: SoftwareName.Monday,
        stepType: StepType.Action,
    },
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as MondayCreateItemConfiguration;
        return `Create issue in ${stepConfiguration.context.board?.name}`;
    },
};
