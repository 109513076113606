import React from 'react';
import {SmartIntTrigger} from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import {UI} from 'src/engrator-core';
import {SoftwareName} from '../../software-name';
import {AsanaWorkspaceProjectPicker, AsanaWorkspaceProjectPickerData} from '../steps/common';
import {canUsePremiumSearchEndpoint} from "../steps/rest-api";

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class AsanaSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.Asana,
            artifactName: '',
            className: '',
            configuration: {
                project: undefined,
                query: ''
            },
            connectionId: this.props.connectionId
        };
    }

    render() {
        return <React.Fragment>
            {!this.smartIntTrigger.configuration.project && <AsanaWorkspaceProjectPicker
                connectionId={this.props.connectionId}
                onDataChanged={this.onDataChanged.bind(this)}
            />}
            {this.smartIntTrigger.configuration.project && <UI.FormGroup>
                <UI.LabelValue
                    label={`Project`}
                    value={this.smartIntTrigger.configuration.project.name}
                />
            </UI.FormGroup>
            }
            <div style={{'display': 'none'}}>
                <UI.FormGroup>
                    <UI.Checkbox
                        label={`Use Premium search endpoint`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                        defaultValue={`${this.smartIntTrigger.configuration.usePremiumSearch}`}
                        onChange={this.switchPremiumSearchFlag.bind(this)}
                    />
                </UI.FormGroup>
            </div>
            <UI.FormGroup
                label={`Additional Query`}
                description={`You can provide additional query params which will be appended to the url generated when searching for issues in Asana`}
            >
                <UI.Input label={``} onChange={this.onJqlInputChanged.bind(this)}/>
            </UI.FormGroup>
        </React.Fragment>
    }

    private switchPremiumSearchFlag(newValue: string) {
        this.smartIntTrigger.configuration.usePremiumSearch = (newValue === 'true') ? true : false;
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.query = newValue;
    }

    private onDataChanged(data: AsanaWorkspaceProjectPickerData) {
        if (data.project && data.workspace) {
            canUsePremiumSearchEndpoint(this.props.connectionId, data.workspace.id, data.project.id)
                .then((result: boolean) => {
                    this.smartIntTrigger.configuration.workspace = data.workspace;
                    this.smartIntTrigger.configuration.usePremiumSearch = result;
                    this.smartIntTrigger.artifactName = 'asanaIssue';
                    this.smartIntTrigger.configuration.project = data.project;
                    this.props.onConfigured(this.smartIntTrigger!);
                    this.forceUpdate();
                })
        }
    }
}
