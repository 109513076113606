import React from 'react';

import {AzureFindItemConfiguration} from "./azure-find-item-configuration";
import {EditPipelineStepComponentProps} from "src/generic/pipeline-step";
import {UI} from "../../../../engrator-core";
import {AzureProjectItemTypeDisplay} from "../common/azure-project-item-type-display";
import {AzureProjectItemTypePickerData} from "../common";
import {ArtifactDirection} from "../../../../generic/artifacts";

type Props = {
} & EditPipelineStepComponentProps;
type State = {};

export class AzureFindItemEditStep extends React.Component<Props, State> {
    private configuration: AzureFindItemConfiguration;
    private pickerData: AzureProjectItemTypePickerData;

    constructor(props: Props) {
        super(props);
        this.configuration = this.props.configuration.data as AzureFindItemConfiguration;
        this.pickerData = {
            project: this.configuration.project,
            itemType: this.configuration.itemType
        } as AzureProjectItemTypePickerData;
    }

    componentDidMount(): void {
        this.props.showArtifactBuilder(ArtifactDirection.Out);
    }

    render() {
        return <React.Fragment>
            <AzureProjectItemTypeDisplay context={ this.pickerData } />
            <UI.LabelValue label={`Item Key`} value={ this.configuration.id } />
        </React.Fragment>;
    }
};