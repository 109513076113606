import React, { ChangeEvent } from 'react';

type Props = {
    onChange: (newValue: string, event?: ChangeEvent<HTMLTextAreaElement>) => void,
    type?: string,
    name?: string,
    label?: string,
    min?: number;
    defaultValue?: string,
    placeholder?: string,
    isRequired?: boolean
    rows: number;
};

type State = {
    value: string
};

export class Textarea extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { value: (props.defaultValue !== undefined) ? props.defaultValue : '' };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({ value: this.props.defaultValue || '' });
        }
    }

    inputValueChanged(newValue: string, event: ChangeEvent<HTMLTextAreaElement>): void {
        this.setState({ value: newValue });
        this.props.onChange(newValue, event);
    }

    render() {
        const attributes: any = {
            type: (this.props.type) ? this.props.type : 'text'
        };
        if (this.props.name !== undefined) {
            attributes['name'] = this.props.name;
        }
        if (this.props.placeholder !== undefined) {
            attributes['placeholder'] = this.props.placeholder;
        }
        if (this.props.min !== undefined) {
            attributes['min'] = this.props.min;
        }
        return <div>
            {this.props.label &&
            <label>{this.props.label} {this.props.isRequired === true && <span className={`required`}>*</span>}</label>}
            <textarea {...attributes} rows={this.props.rows}
                      value={  this.state.value }
                      onChange={(event) => this.inputValueChanged(event.target.value, event)}>
                 {this.state.value}
            </textarea>
        </div>;
    }
}
