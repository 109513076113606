import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";

import {AsanaStepKey} from "../asana-step-key.type";
import {AsanaStatusTransitionEditStep} from "./asana-status-transition-edit-step";
import {AsanaStatusTransitionNewStep} from "./asana-status-transition-new-step";
import {AsanaStatusTransitionConfiguration} from "./asana-status-transition-configuration";

export const AsanaStatusTransitionPipelineStep: PipelineStep<AsanaStatusTransitionConfiguration> = {
    addStepComponentClass: AsanaStatusTransitionNewStep,
    editStepComponentClass: AsanaStatusTransitionEditStep,
    specification: {
        action: "Change task status",
        description: "Move Asana task from one status to another",
        key: AsanaStepKey.AsanaStatusTransition,
        software: SoftwareName.Asana,
        stepType: StepType.Action
    },
    requiresConnectionPicker: false,
    preferredNameForArtifact: ``,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AsanaStatusTransitionConfiguration;
        return `Change task status`;
    }
}