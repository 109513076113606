import React, {ChangeEvent} from 'react';

export interface InputChangeListener {
    subscribe: (listener: (value: string, event: ChangeEvent<HTMLInputElement>) => void) => void;
    notify: (newValue: string, event: ChangeEvent<HTMLInputElement>) => void;
}

type Props = {
    listener: InputChangeListener;
    availableArtifacts: Array<Object>;
};
type State = {
    options: string[];
};

const ARTIFACT_NAME_END = '}';

export class ArtifactsAutocomplete extends React.Component<Props, State> {
    private availableOptions: string[] = [];

    constructor(props: Props) {
        super(props);
        this.state = { options: [] };
        this.availableOptions = this.getAvailableOptions();
        this.props.listener.subscribe((newValue: string, event: ChangeEvent<HTMLInputElement>) => {
            const autocompleteIndicator = this.getAutocompleteIndicator(newValue, event.target.selectionStart);
            if (autocompleteIndicator !== false) {
                const options = this.filterOutOptions(autocompleteIndicator);
                this.setState({ options })
            } else {
                this.setState({ options: [] });
            }
        });
    }

    render() {
        if (this.state.options.length === 0) {
            return <React.Fragment></React.Fragment>;
        }
        return <div className={`ui-artifacts-autocomplete`}>
            <p>Available artifacts:</p>
            <ul>
                { this.state.options.map((option: string, index: number) => <li key={ index }>{ option }</li> ) }
            </ul>
        </div>;
    }

    private getAutocompleteIndicator(value: string, cursorPosition: number | null): string | false {
        if (!cursorPosition) {
            return false;
        }
        for (let i = cursorPosition; i>= 0; i--) {
            if (value[i] === ARTIFACT_NAME_END) {
                return false;
            }
            if (value[i] === '{' && value[i-1] === '$') {
                return value.substr(i + 1, cursorPosition);
            }
        }
        return false;
    }

    private getAvailableOptions(): string[] {
        const options: string[] = [];
        if (!this.props.availableArtifacts) {
            return [];
        }
        this.props.availableArtifacts.forEach((artifact: any) => {
            if (artifact.propertyDefinitions) {
                artifact.propertyDefinitions.forEach((property: any) => {
                    options.push(`${artifact.id}.${property.property.name}`);
                });
            }
            if (artifact.properties) {
                artifact.properties.forEach((property: any) => {
                    options.push(`${artifact.id}.${property.property.name}`);
                });
            }
        });
        return options;
    }

    private filterOutOptions(autocompleteIndicator: string): any[] {
        return this.availableOptions
            .filter((artifactName) => artifactName.indexOf(autocompleteIndicator) === 0)
            .map((artifactName) => (<React.Fragment>
                <span className={`bolder`}>{autocompleteIndicator}</span>
                {artifactName.replace(autocompleteIndicator, '')}
            </React.Fragment>));
    }
}