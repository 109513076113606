import {axiosWrapper, isJiraServerNativeApp} from 'src/engrator-core';
import { UserActivityAction } from './user-activity-action.type';
import KeenTracking from 'keen-tracking';

const ENDPOINT_URL = '/user-activity';
const KEEN_BUCKET = 'ev';
const keenClient = (isJiraServerNativeApp) ? new KeenTracking({
    projectId: '63c6f7ca075b0f06c07587cf',
    writeKey: 'a38fdc66ba8766ced6e19a80bbf768f0fb495faa9138cf367a222a0be1c6fdfab6369946c62027ef56b59841f7348fcb70274835ec9f26bc939183be836eeab884a7bd0c16b7754182b263420b18223a628c3f96161a0f13534b867d33d155b5'
}) : undefined;

let jiraSen: string = '';

export function registerUserActivityAction(action: UserActivityAction) {
    try {
        if (isJiraServerNativeApp) {
            getSen()
                .then((sen) => {
                    if (!sen) {
                        sen = 'unknown';
                    }
                    keenClient?.recordEvent(KEEN_BUCKET, {
                        sen,
                        data: action
                    });
                })
            return;
        }
        axiosWrapper.put(`${ENDPOINT_URL}`, { action })
            .then((data: any) => {})
            .catch(error => console.log(error));
    } catch (error) {
        console.log(error)
    }
}

export function shouldShowCreateInteagrationGuide(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/show-create-integration-guide`)
                .then((result: any) => resolve(!!result))
                .catch(error => console.log(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function hasUserCompleted(action: UserActivityAction): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/completed/${action}`)
                .then((result: any) => resolve(!!result))
                .catch(error => console.log(error));
        } catch (error) {
            console.log(error)
        }
    });
}

export function getPreferredApps(): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(`${ENDPOINT_URL}/preferred-apps`)
                .then((result: any) => resolve(result))
                .catch(error => console.log(error));
        } catch (error) {
            console.log(error)
        }
    });
}

function getSen(): Promise<string> {
    if (jiraSen) {
        return Promise.resolve(jiraSen);
    }
    return new Promise((resolve) => {
        axiosWrapper.get(`/settings/jira-server/license`)
            .then((license: any) => {
                resolve(license.sen);
            })
    })
}