import React from 'react';
import { SmartIntTrigger } from '../../../app/integration/smartints/definition/smart-int-trigger.type';
import { UI } from 'src/engrator-core';
import { SoftwareName } from '../../software-name';

type Props = {
    connectionId: number;
    onConfigured: (configuration: SmartIntTrigger) => void;
};
type State = {};

export class ServiceNowSmartIntsTrigger extends React.Component<Props, State> {
    private smartIntTrigger: SmartIntTrigger;

    constructor(props: Props) {
        super(props);
        this.smartIntTrigger = {
            app: SoftwareName.ServiceNow,
            artifactName: '',
            className: '',
            configuration: {
                project: undefined,
                jql: ''
            },
            connectionId: this.props.connectionId
        };
        this.onJqlInputChanged('');
    }

    render() {
        return <React.Fragment>
            {/*<UI.FormGroup*/}
            {/*    label={`Custom query`}*/}
            {/*    description={`You can provide custom query which will be appended to the one generated when searching for ServiceNow items`}*/}
            {/*>*/}
            {/*    <UI.Input label={``} onChange={this.onJqlInputChanged.bind(this)}/>*/}
            {/*</UI.FormGroup>*/}
        </React.Fragment>
    }

    private onJqlInputChanged(newValue: string) {
        this.smartIntTrigger.configuration.jql = newValue;
        this.props.onConfigured(this.smartIntTrigger!);
        this.forceUpdate();
    }
}
