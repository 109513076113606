import {IntegrationStepConfiguration, StepType} from "src/generic/software/step";
import {PipelineStep} from "src/generic";
import {SoftwareName} from "src/software/software-name";
import {AsanaStepKey} from "../asana-step-key.type";
import {AsanaFindItemNewStep} from "./asana-find-item-new-step";
import {AsanaFindItemEditStep} from "./asana-find-item-edit-step";
import {AsanaFindItemConfiguration} from "./asana-find-item-configuration";


export const AsanaFindItemPipelineStep: PipelineStep<AsanaFindItemConfiguration> = {
    addStepComponentClass: AsanaFindItemNewStep,
    editStepComponentClass: AsanaFindItemEditStep,
    specification: {
        action: "Find Task",
        description: "Find Asana task in specific project",
        key: AsanaStepKey.AsanaFindItem,
        software: SoftwareName.Asana,
        stepType: StepType.Action
    },
    preferredNameForArtifact: `asanaItem`,
    getSnippetTitle(configuration: IntegrationStepConfiguration): string {
        const stepConfiguration = configuration.data as unknown as AsanaFindItemConfiguration;
        return `Find task in ${stepConfiguration.workspace.name}, ${stepConfiguration.project.name} with id='${stepConfiguration.id}'`;
    }
};