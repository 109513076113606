import React from 'react';

import './ActionsList.css';

type ActionKey = {
    key: string,
    action: string,
    description: string
};

type ActionsListProps = {
    onSelect: any;
    selectedAction: string,
    selectedSoftware: string,
    list: Array<ActionKey>
};

export default class ActionsList extends React.Component<ActionsListProps> {
    constructor(props: ActionsListProps) {
        super(props);
    }

    componentDidUpdate(prevProps: Readonly<ActionsListProps>, prevState: Readonly<{}>) {
        console.log(this.props, prevProps);
    }

    render() {
        return <div className="actions-list">
            { this.props.list.map((actionKey, idx) =>
                <div key={idx} onClick={ () => this.props.onSelect(actionKey.key) }>
                    { actionKey.action }
                    <p>{ actionKey.description }</p>
                </div>
            )}
        </div>
    }
}