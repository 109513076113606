import React from 'react';
import { UI } from 'src/engrator-core';

type Props = {
    onMigrationSelected: () => void;
};
type State = {
    isClosed: boolean;
};

export class IntegrationMode extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isClosed: false
        };
    }


    render() {
        if (this.state.isClosed) {
            return <React.Fragment />
        }
        return <UI.Modal
            header={`What do you want to build?`}
            isWide={ true }
            isLeftAligned={ true }
        >
            <div className={`integration-mode`}>
                <div className={`modes`}>
                    <div onClick={ () => this.selected('Integration') } className={`sync`}>
                        <h2>Continuous Sync</h2>
                        <p>Sync items between apps and propagate changes</p>
                    </div>
                    <div onClick={ () => this.selected('Migration') } className={`migration`}>
                        <h2>Migration</h2>
                        <p>Migrate items from one app to another</p>
                    </div>
                </div>
                <UI.ButtonsBar
                    primary={
                        <UI.Button
                            appearance={"secondary"}
                            text={`Close`}
                            onClick={ () => this.hide() }
                        /> }
                />
            </div>
        </UI.Modal>
    }

    private selected(mode: 'Integration' | 'Migration') {
        this.hide();
        if (mode === "Migration") {
            this.props.onMigrationSelected();
        }
    }

    private hide() {
        this.setState({ isClosed: true });
    }
}
