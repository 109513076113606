import React from 'react';
import {fetchSystemInfo} from './rest-api';
import {SystemInfo} from './threads-summary.type';

type Props = {
    onCloseHandler: () => void;
}

type State = {
    info?: SystemInfo;
    isOpened: boolean;
    version: string;
}

export class HelpDropdown extends React.Component<Props, State> {
    private wrapperRef: any;

    constructor(props: Props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.state = {
            version: '',
            isOpened: true
        };
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.documentClick.bind(this));
        document.addEventListener('touchstart', this.documentClick.bind(this));
        const info = await fetchSystemInfo();
        let version = info.platformVersion;
        if (info.buildId) {
            version += '.' + info.buildId;
        }
        this.setState({info, version});
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.documentClick);
        document.removeEventListener('touchstart', this.documentClick);
    }

    documentClick(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpened) {
            this.setState({isOpened: false}, () => {
                this.props.onCloseHandler();
            });
        }
    }

    private switchPopup() {
        this.setState({isOpened: !this.state.isOpened});
    }

    render() {
        if (!this.state.isOpened) {
            return <React.Fragment />;
        }
        return <div className={`help-dropdown`} ref={ this.setWrapperRef }>
            {/*<span className={`close`} onClick={this.props.onCloseHandler}><UI.Icon icon20={ Icon20.Close }/></span>*/}
            <ul>
                <li>
                    <a href={'https://docs.getint.io/'} target={'_blank'}>Documentation</a>
                </li>
                {/*<li><a onClick={this.chatWithUs.bind(this)} target={'_blank'}><UI.Icon icon={'chat'}/>Chat with us</a>*/}
                {/*</li>*/}
                <li><a href={'https://getintio.atlassian.net/servicedesk/customer/portals'} target={'_blank'}>Submit support ticket</a></li>
                <li>
                    <a href={'https://getint.io/terms/eula'} target={'_blank'}>EULA</a>
                </li>
                <li>
                    <a href={'https://getint.io/privacy-policy'} target={'_blank'}>Privacy policy</a>
                </li>
            </ul>
            <p className={`version`}>
                <a target={`_blank`} href={`https://getint.io`}>Getint Platform</a> {this.state.info &&
                <React.Fragment>, v{this.state.version}, instance: {this.state.info?.tenantName}</React.Fragment>
            }
            </p>
        </div>;
    }

    private chatWithUs() {
        const script = document.createElement("script");
        script.src = "//js.hs-scripts.com/8577034.js#hs-chat-open";
        script.async = true;
        script.id = 'hs-script-loader';
        document.body.appendChild(script);

        function listenForChatLoad() {
            if (!((window as any).HubSpotConversations)) {
                return setTimeout(listenForChatLoad, 100);
            }
            setTimeout(() => {
                console.log('there is');
                (window as any).HubSpotConversations.widget.open();
            }, 1300);
        }

        listenForChatLoad();
        this.props.onCloseHandler();
    }
}
