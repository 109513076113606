import { StepSpecification } from './step-specification';
import { DataArtifact, ArtifactsFactory } from './../../artifacts';
import { StepDebuggingState, StepDebuggingStatus, StartDebuggingHandler } from './../../debugging';
import {PipelineStepRule} from "../../pipeline-step/rules/pipeline-step-rule.type";

export type IntegrationStepConfiguration = {
    stepSpecification: StepSpecification,
    connectionId: number,
    data: any,
    inDataArtifact: any,
    outDataArtifact: any,
    rules: PipelineStepRule[]
};

export abstract class IntegrationStep {
    abstract configuration: any;
    // debuggingState: StepDebuggingState | null;

    constructor() {
        // this.debuggingState = null;
    }
    abstract getSnippetComponent(): any;
    abstract getEditStepComponent(artifactsFactory: ArtifactsFactory, deleteStepHandler: () => void, afterSaveHandler: () => void): any;
    // abstract getDebugConfigurationComponent(startDebuggingHandler: StartDebuggingHandler): any;
    abstract getInArtifact(): DataArtifact | null;
    abstract getOutArtifact(): DataArtifact | null;
    // abstract getStepType(): string;
    // abstract restore(savedConfiguration: any): void;
    abstract getConfiguration(): IntegrationStepConfiguration;

    // setDebuggingState(result: StepDebuggingState): void {
    //     this.debuggingState = result;
    // }
    // hasDebuggingReport(): boolean {
    //     return this.debuggingState !== null
    //         && this.debuggingState.status === StepDebuggingStatus.Success
    //         && this.debuggingState.output;
    // }
    // getDebuggingOutput(): any {
    //     return this.debuggingState?.output;
    // }
}
