import React from 'react';

type Props = {
    header?: string;
    className?: string;
};

export class Tile extends React.Component<Props> {
    render() {
        const additionalClassName = (this.props.className) ? this.props.className : '';
        return <React.Fragment>
            { this.props.header && <h2 className={`engrator-ui-tile-header`}>{ this.props.header }</h2> }
            <div className={`engrator-ui-tile ${additionalClassName}`}>
                { this.props.children }
            </div>
        </React.Fragment>;
    }
}