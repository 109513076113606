import React from "react";
import {AddMappingOptions, PropertyValue} from "../../visual/components/value-mappings";
import {UI} from "../../../../../engrator-core";
import {AutoMappedOptions} from "./automap-options";
import {PropertyMapping} from "../../visual/property-mapping.type";
import {SmartIntDefinitionTypeMapping} from "../../definition/smart-int-definition--type-mapping.type";
import {SmartIntTrigger} from "../../definition/smart-int-trigger.type";
import {autoMapOptions} from "../../visual/rest-api";
import {DropdownOption} from "../../../../../engrator-core/ui";

type State = {
    isWorking: boolean;
};
type Props = {
    propertyMapping: PropertyMapping;
    typeMapping: SmartIntDefinitionTypeMapping;
    triggers: { left: SmartIntTrigger, right: SmartIntTrigger };
    options: AddMappingOptions;
    depth: number;
    closeHandler: (options?: AutoMappedOptions[], clear?: boolean) => void;
};

export class SharingAutoMapping extends React.Component<Props, State> {
    private minSimilarity: number = 80;
    private autoMappingResult: AutoMappedOptions[] = [];

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            isWorking: true
        };
    }

    async componentDidMount() {
        await this.setState({ isWorking: true });
        try {
            const result = await autoMapOptions(
                this.props.triggers,
                this.props.typeMapping,
                this.props.propertyMapping
            );
            if (result.definition.types[0] && result.definition.types[0].propertiesConfiguration.properties[0]) {
                const groups = result.definition.types[0].propertiesConfiguration.properties[0].groups;
                this.autoMappingResult = [];
                console.log(groups);
                for (const group of groups) {
                    this.autoMappingResult.push({
                        left: this.convertToOption(group.valueMappings[0].left),
                        right: this.convertToOption(group.valueMappings[0].right),
                        approved: true,
                        similarity: 0
                    });
                }
            }
        } catch (e) {

        } finally {
            await this.setState({ isWorking: false });
        }
    }

    render() {
        return <UI.FullScreenModal
            primaryBtnHandler={ () => this.applyClicked() }
            closeBtnHandler={ () => this.closeClicked() }
            isPrimaryDisabled={ this.state.isWorking}
            depth={ this.props.depth }
            header={`Quick Match`}
            maximized={ true }
        >
            <UI.Message
                appearance={"warning"}
            >Quick Match feature is currently in <b>experimental</b> phase. If you have any feedback or possible improvements <a href={'https://getintio.atlassian.net/servicedesk/customer/portals'} target={'_blank'}>contact with us</a>.</UI.Message>
            <br/><br/>
            {/*<UI.Form>*/}
            {/*    <UI.FormGroup*/}
            {/*        label={`Minimum similarity`}*/}
            {/*    >*/}
            {/*        <UI.Input*/}
            {/*            defaultValue={ this.minSimilarity + '' }*/}
            {/*            type={'number'}*/}
            {/*            onChange={ (value) => this.changeSimilarity(value) }*/}
            {/*        />*/}
            {/*    </UI.FormGroup>*/}
            {/*    <UI.ButtonsBar*/}
            {/*        primary={ <UI.Button text={`Change`} onClick={ () => this.automapOptions() }/> }*/}
            {/*    />*/}
            {/*</UI.Form>*/}
            { this.state.isWorking && <React.Fragment>
                <UI.CenteredLoader text={`Matching options`} />
            </React.Fragment> }
            { !this.state.isWorking && <UI.TableGrid
                noDataText={`Unfortunately, no options were mapped.`}
                headers={ ['Left Option', 'Right Option'/*, 'Similarity'*/, 'Approved'] }
                data={ this.autoMappingResult.map(result => [
                    result.left.label,
                    result.right.label,
                    // result.similarity * 100 + '%',
                    <UI.Checkbox
                        onChange={ () => this.switchApproveForMapping(result) }
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                        defaultValue={`true`}
                    />
                ]) }
            /> }
        </UI.FullScreenModal>
    }

    private applyClicked(): Promise<boolean> {
        this.props.closeHandler(this.autoMappingResult, true);
        return Promise.resolve(true);
    }

    private closeClicked(): Promise<boolean> {
        this.props.closeHandler();
        return Promise.resolve(true);
    }

    private switchApproveForMapping(result: AutoMappedOptions): void {
        result.approved = !result.approved;
    }

    private automapOptions() {
        // this.setState({ isWorking: true });
        // setTimeout(() => {
        //     mapAutomaticallyOptions(this.props.options, this.minSimilarity).then(res => {
        //         this.autoMappingResult = res;
        //         this.setState({isWorking: false});
        //     })
        // }, 1000);
    }

    private changeSimilarity(newValue: string) {
        if (parseInt(newValue) > 0 && parseInt(newValue) <= 100) {
            this.minSimilarity = parseInt(newValue);
        }
    }

    private convertToOption(left: PropertyValue): DropdownOption {
        return {
            value: left.value,
            label: left.name
        }
    }
}