import React from 'react';

import {UI, FieldError, EntityFormHelper} from "src/engrator-core";
import {createSystemApiKey} from "./rest-api";
import {NewSystemApiKey} from "./new-system-api-key.type";
import {generateApiKey} from "./api-key-generator";
import {FormError} from "../../../engrator-core/form-error.type";

type Props = {
    onKeyAdded: () => void;
};
type State = { isLoading: boolean };

export class CreateApiKeyForm extends React.Component<Props, State> {
    state: State = {
        isLoading: false
    };
    private newSystemApiKey: NewSystemApiKey = {
        key: '',
        name: ''
    };
    private entityFormHelper: EntityFormHelper;

    constructor(props: Props) {
        super(props);
        this.entityFormHelper = new EntityFormHelper(this.onFormChange.bind(this));
    }

    componentDidMount(): void {
        this.generate();
    }

    onFormChange(propertyName: 'name', newValue: any) {
        this.newSystemApiKey[propertyName] = newValue;
    }

    private addHandler() {
        this.setState({ isLoading: true }, () => {
            createSystemApiKey(this.newSystemApiKey)
                .then(() => {
                    this.props.onKeyAdded();
                })
                .catch((error: FieldError) => {
                    this.entityFormHelper.formError = {[error.fieldName]: error.message};
                    this.setState({isLoading: false});
                });
        });
    }

    private generate() {
        this.newSystemApiKey.key = generateApiKey();
        this.forceUpdate();
    }

    render() {
        return <UI.RightPanelContent header={`Create System Api Key`}
                                  footer={ <UI.Button appearance="elementary" isLoading={ this.state.isLoading } disabled={ this.state.isLoading } onClick={() => { this.addHandler() } } text="Create" /> } >
            <div className="connectors-jira-search-issues-step">
                <UI.Form
                    errors={ [] }
                    error={ this.entityFormHelper.formError['general'] }>
                    <UI.FormGroup
                        label={`Name`}
                        isRequired={ true }
                        description={ `Please provide meaningful name so later on you can distinguish keys`}
                        error={ this.entityFormHelper.formError['name'] }>
                        <UI.Input onChange={ this.entityFormHelper.onChangeHandler('name') } placeholder={`Name`} defaultValue={ `` } />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Api Key`}
                        description={ <span>Please copy below key and keep it somewhere secured. More about about <UI.DocumentationLink text={`API Keys`} url={``} />.</span>}>
                        <UI.Input onChange={ () => {} } defaultValue={ this.newSystemApiKey.key } />
                    </UI.FormGroup>
                    <UI.Button appearance={"secondary"} onClick={ () => this.generate() } text={`Generate Api Key`} />
                </UI.Form>
            </div>
        </UI.RightPanelContent>;
    }
}