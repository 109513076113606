import React from 'react';
import {UI} from 'src/engrator-core';
import {SmartIntDefinitionTypeMapping} from '../../definition/smart-int-definition--type-mapping.type';
import {SmartIntTrigger} from '../../definition/smart-int-trigger.type';
import {AppsSupport} from '../visual-integration-designer';
import {Advanced} from './advanced';
import {CommentsAttachments} from './comments-attachments';
import {FieldsMappings} from './fields-mappings';
import {Hierarchy} from './hierarchy';
import {StatusMapping} from './status-mapping';
import {TypeMappingNavBar, TypeMappingNavBarItemName} from './type-mapping-nav-bar';

type State = {
    currentTabItemName: TypeMappingNavBarItemName;
};

type Props = {
    mapping: SmartIntDefinitionTypeMapping;
    appsSupport: AppsSupport;
    closeHandler: () => void;
    deleteHandler: () => void;
    multiFieldsUsage: boolean;
    doneHandler: (mapping: SmartIntDefinitionTypeMapping) => void;
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
}

export class TypeMappingConfiguration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {currentTabItemName: TypeMappingNavBarItemName.Fields};
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        console.log(this.props, prevProps);
    }

    render() {
        return <UI.FullScreenModal
            maximized={ true }
            primaryBtnText={`Apply`}
            closeBtnHandler={ () => this.closeClicked() }
            primaryBtnHandler={ () => this.doneClicked() }
            header={`Type mapping configuration`}
        >
            <div className={`type-mapping-configuration`}>
                <div className={`inner`}>
                    <h1>{this.props.mapping.left.name} - {this.props.mapping.right.name}</h1>
                    <TypeMappingNavBar
                        appSupport={ this.props.appsSupport }
                        selectedItem={this.state.currentTabItemName}
                        onChange={this.onTabItemNameChanged.bind(this)}
                        mapping={this.props.mapping}
                    />
                    {this.state.currentTabItemName === TypeMappingNavBarItemName.Fields && <FieldsMappings
                        appsSupport={this.props.appsSupport}
                        mapping={this.props.mapping}
                        multiFieldsUsage={this.props.multiFieldsUsage}
                        leftTrigger={this.props.leftTrigger}
                        rightTrigger={this.props.rightTrigger}
                    />}
                    {this.state.currentTabItemName === TypeMappingNavBarItemName.CommentsAttachments &&
                        <CommentsAttachments
                            appsSupport={this.props.appsSupport}
                            mapping={this.props.mapping}
                        />}
                    {this.state.currentTabItemName === TypeMappingNavBarItemName.Advanced && <Advanced
                        appsSupport={this.props.appsSupport}
                        mapping={this.props.mapping}
                    />}
                    {this.state.currentTabItemName === TypeMappingNavBarItemName.Hierarchy && <Hierarchy
                        appsSupport={this.props.appsSupport}
                        leftTrigger={this.props.leftTrigger}
                        rightTrigger={this.props.rightTrigger}
                        mapping={this.props.mapping}
                    />}
                    {this.state.currentTabItemName === TypeMappingNavBarItemName.Status && <StatusMapping
                        appsSupport={this.props.appsSupport}
                        mapping={this.props.mapping}
                        leftTrigger={this.props.leftTrigger}
                        rightTrigger={this.props.rightTrigger}
                    />}
                    {/*<UI.ButtonsBar*/}
                    {/*    primary={<UI.Button onClick={() => this.props.doneHandler(this.props.mapping)} text={`Done`}/>}*/}
                    {/*    secondary={[*/}
                    {/*        <UI.Button onClick={() => this.props.closeHandler()} text={`Close`}*/}
                    {/*                   appearance={'secondary'}/>,*/}
                    {/*        <UI.Button className={`delete-btn`} onClick={() => this.props.deleteHandler()}*/}
                    {/*                   text={`Delete`}*/}
                    {/*                   appearance={'danger'}/>*/}
                    {/*    ]}*/}
                    {/*/>*/}
                </div>
            </div>
        </UI.FullScreenModal>
    }

    doneClicked(): Promise<boolean> {
        this.props.doneHandler(this.props.mapping);
        return Promise.resolve(true);
    }

    closeClicked(): Promise<boolean> {
        this.props.closeHandler();
        return Promise.resolve(true);
    }

    private onTabItemNameChanged(itemName: TypeMappingNavBarItemName) {
        this.setState({currentTabItemName: itemName});
    }
}
