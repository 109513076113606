import { DropdownOption } from '../../../../engrator-core/ui';
import {SoftwareName} from "../../../../software";

export type MigrationConfiguration = {
    fromSide?: 'Left' | 'Right';
    alreadyMigratedItems?: AlreadyMigratedItemsOption;
    fromDateTime?: string;
    toDateTime?: string;
    fieldsToResync?: string;
    itemsIdsToMigrate?: string;
    threadsNumber?: string;
    enabled: boolean;
    disableIntegrationAfterRun?: boolean;
};

export enum AlreadyMigratedItemsOption {
    'Skip' = 'Skip',
    'Recreate' = 'Recreate',
    'Update' = 'Update'
};

export const AlreadyMigratedOptions: DropdownOption[] = [
    {
        label: 'Skip already migrated items',
        value: AlreadyMigratedItemsOption.Skip
    },
    {
        label: 'Migrate them and recreate their counterparts',
        value: AlreadyMigratedItemsOption.Recreate
    },
    {
        label: 'Migrate them and update their counterparts',
        value: AlreadyMigratedItemsOption.Update
    }
];

export function getFromSideOptions(leftApp: string, rightApp: string): DropdownOption[]  {
    /* If one of apps is GetIntArchiver, then there will be just one option of migration */
    if (leftApp === SoftwareName.GetintArchiver) {
        return [
            {
                label: `From Right (${rightApp}) to Left (${leftApp})`,
                value: 'Right'
            }
        ];
    }
    if (rightApp === SoftwareName.GetintArchiver) {
        return [
            {
                label: `From Left (${leftApp})  to Right (${rightApp})`,
                value: 'Left'
            }
        ];
    }
    return [
        {
            label: `From Left (${leftApp})  to Right (${rightApp})`,
            value: 'Left'
        },
        {
            label: `From Right (${rightApp}) to Left (${leftApp})`,
            value: 'Right'
        }
    ];
}
